import * as React from 'react';

import Date from 'commons/Date';
import { DataBoxHeader, DataBox, DataBoxContent } from 'commons/DataBox';
import FakeInputBlock from 'commons/FakeInputBlock';
import { GpvSaisine } from 'api/tepv/gpv/types';
import { BKG_BLUE } from 'theme';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_COLUMNS_CONTAINER: React.CSSProperties = {
  width: '90%',
  justifyContent: 'space-around',
};

const SaisineBlock = ({ saisine }: { saisine: GpvSaisine }) => (
  <DataBox panel style={{ width: '95%', marginTop: 40 }}>
    <DataBoxHeader color={BKG_BLUE}>{_tg('commons.saisine')}</DataBoxHeader>
    <DataBoxContent>
      <div style={STYLE_COLUMNS_CONTAINER}>
        <FakeInputBlock title={_t('element.reason')} value={saisine.motif} />
        <FakeInputBlock title={_tg('field.comment')} value={saisine.comment} />
        <FakeInputBlock
          title={_tg('field.date.date')}
          value={<Date datetime={saisine.date} withTime />}
        />
      </div>
    </DataBoxContent>
  </DataBox>
);

export default SaisineBlock;
