import React, { CSSProperties, useEffect, useState } from 'react';
import { Card, CardHeader, CardText, Dialog } from 'material-ui';
import FileIcon from 'material-ui/svg-icons/content/content-paste';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import moment from 'moment';

import BoButton from 'facade/BoButton';

import './OrderDetailPage.css';
import { SubscriberDTO, SubscriberMediaDTO } from '@cvfm-front/tefps-types';
import Date from 'commons/Date';
import { ICON_BLACK, TEXT_COLOR_LIGHT, TXT_BLACK } from 'theme';
import { deleteEvidence, getRemovableEvidences } from 'api/subscribers';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import SimpleTable from 'commons/SimpleTable';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_CARD = {
  flex: 1,
  margin: 10,
  minWidth: 300,
};

const STYLE_ICON: CSSProperties = {
  width: 40,
  height: 50,
};

type SubscriberDetailModalEvidencesProps = {
  subscriber: SubscriberDTO;
  loadSubscriberSilently: () => void;
  canDelete: boolean;
};

const SubscriberDetailEvidences = ({
  subscriber,
  loadSubscriberSilently,
  canDelete,
}: SubscriberDetailModalEvidencesProps): JSX.Element => {
  const setMessage = useSnackbar();
  const [removableEvidences, setRemovableEvidences] = useState<
    Array<SubscriberMediaDTO>
  >([]);
  const [evidenceIdToRemove, setEvidenceIdToRemove] = useState<string | null>(
    null
  );

  const translateCols = (): Array<{
    label: string;
    width: number;
    grow: number;
    headerStyle?: React.CSSProperties;
  }> => {
    const cols = [
      { label: _t('header.name'), width: 200, grow: 10 },
      { label: _t('header.createdAt'), width: 200, grow: 10 },
      {
        label: _t('header.delete'),
        width: 60,
        grow: 1,
        headerStyle: { justifyContent: 'center' },
      },
    ];
    if (!canDelete) {
      cols.pop();
    }
    return cols;
  };

  const fetchRemovableEvidences = async (subscriberId: string) =>
    setRemovableEvidences(await getRemovableEvidences(subscriberId));

  const handleClickDelete = (evidenceId: string, disabled: boolean) => {
    if (disabled) {
      setMessage(_t('error.evidenceInUse'));
    } else {
      setEvidenceIdToRemove(evidenceId);
    }
  };

  const confirmDeleteEvidence = async () => {
    if (evidenceIdToRemove !== null) {
      try {
        await deleteEvidence(subscriber.subscriberId, evidenceIdToRemove);
        loadSubscriberSilently();
      } catch (err) {
        const error = err as { message: string; name: string };
        if (error.name === 'EvidenceInUse') {
          setMessage(_t('error.evidenceInUse'));
        }
      }
      setEvidenceIdToRemove(null);
    }
  };

  const confirmDialogActions = [
    <BoButton
      secondary
      label={_tg('action.cancel')}
      onClick={() => setEvidenceIdToRemove(null)}
    />,
    <BoButton
      primary
      label={_tg('action.confirm')}
      onClick={confirmDeleteEvidence}
      style={{ marginLeft: 20 }}
    />,
  ];

  useEffect(() => {
    void fetchRemovableEvidences(subscriber.subscriberId);
  }, [subscriber.evidences]);

  return (
    <div style={{ flex: 1, width: '85%', marginTop: '40px' }}>
      <Card style={STYLE_CARD}>
        <CardHeader
          title={_t('cardHeader')}
          showExpandableButton
          actAsExpander
        />
        <CardText expandable>
          <SimpleTable
            maxHeight={10000}
            cols={translateCols()}
            rowHeight={50}
            header
            itemsRenderer={(evidence: SubscriberMediaDTO) => {
              const disabled = removableEvidences.every(
                removableEvidence => removableEvidence.id !== evidence.id
              );
              const items = [
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={evidence.contentUrl}
                  title={_tg('action.downloadAttachment')}
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <FileIcon style={STYLE_ICON} color={TEXT_COLOR_LIGHT} />
                  {evidence.name}
                </a>,
                <Date datetime={evidence.dateCreated} withTime />,
                <DeleteIcon
                  color={disabled ? TXT_BLACK : ICON_BLACK}
                  onClick={() => handleClickDelete(evidence.id, disabled)}
                  style={{ textAlign: 'center', width: '100%' }}
                />,
              ];
              if (!canDelete) {
                items.pop();
              }
              return items;
            }}
            items={subscriber.evidences.sort((a, b) =>
              // descending sort
              moment(a.dateCreated).isBefore(moment(b.dateCreated)) ? 1 : -1
            )}
          />
        </CardText>
      </Card>
      <Dialog
        open={evidenceIdToRemove !== null}
        actions={confirmDialogActions}
        title={_t('dialog.title')}
      />
    </div>
  );
};

export default SubscriberDetailEvidences;
