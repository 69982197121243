import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import IntlPolyfill from 'intl';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import DatePicker from 'material-ui/DatePicker';
import { connect } from 'react-redux';

import { TXT_BLACK } from 'theme';
import { InstrumentDTO, InstrumentNature } from 'api/tepv/instruments/types';
import { formatDate } from 'commons/Utils/dateUtil';
import ErrorBlock from 'commons/ErrorBlock';
import { CityOrganizationDTO } from 'api/organizations/types';
import { getConfigState } from 'config/duck';

import { NATURE_OPTIONS } from './commons';

const { _t, _tg } = window.loadTranslations(__filename);

type DeletionProps = {
  deletionIdentifier: string | null | undefined;
  closeModal: () => void;
  removeInstrument: () => Promise<void>;
  error: Error | null | undefined;
};

export const DeletionModal = ({
  deletionIdentifier,
  closeModal,
  removeInstrument,
  error,
}: DeletionProps) => (
  <Dialog
    title={_t('element.dialogDelete.title')}
    open={!!deletionIdentifier}
    autoScrollBodyContent
    onRequestClose={closeModal}
    actions={[
      <FlatButton label={_tg('action.cancel')} onClick={closeModal} />,
      <FlatButton
        label={_tg('action.delete')}
        secondary
        onClick={removeInstrument}
      />,
    ]}
  >
    {_t('feedback.confirm.delete', { id: deletionIdentifier || '' })}
    {error && (
      <ErrorBlock message={_t('feedback.error.delete')} error={error} />
    )}
  </Dialog>
);

type EditionProps = {
  closeModal: () => void;
  editInstrument?: (instrument: InstrumentDTO) => Promise<void>;
  instrument: InstrumentDTO | null | undefined;
  open: boolean;
  organizations: CityOrganizationDTO[] | null | undefined;
  organizationFilterEnabled: boolean;
  instrumentsIdentifiers?: Array<string>;
  display?: boolean;
  error?: Error | null | undefined;
};

type EditionState = {
  nature: InstrumentNature;
  type: string;
  brand: string;
  model: string;
  serialNumber: string;
  softwareVersion: string;
  validityDate: Date;
  verificationDate: Date;
  identifier: string;
  selectedOrganizationId: string | null;
  errorTextId: string | null | undefined;
  errorTextDateVerification: string | null | undefined;
  errorTextDateValidity: string | null | undefined;
};

const buildState = (props: EditionProps): EditionState => {
  if (!props.instrument) {
    return {
      nature: 'SPEED',
      type: '',
      brand: '',
      model: '',
      serialNumber: '',
      softwareVersion: '',
      validityDate: new Date(),
      verificationDate: new Date(),
      identifier: uuidv4(),
      selectedOrganizationId:
        props.organizations && props.organizations.length > 0
          ? props.organizations[0].organizationId
          : null,
      errorTextId: null,
      errorTextDateVerification: null,
      errorTextDateValidity: null,
    };
  }
  return {
    nature: props.instrument.nature,
    type: props.instrument.type,
    brand: props.instrument.brand,
    model: props.instrument.model,
    serialNumber: props.instrument.serialNumber,
    softwareVersion: props.instrument.softwareVersion,
    validityDate: new Date(props.instrument.validityDatetime),
    verificationDate: new Date(props.instrument.verificationDatetime),
    identifier: props.instrument.identifier,
    selectedOrganizationId: props.instrument.organizationId,
    errorTextId: null,
    errorTextDateVerification: null,
    errorTextDateValidity: null,
  };
};

class EditionModal extends React.Component<EditionProps, EditionState> {
  constructor(props: EditionProps) {
    super(props);
    this.state = buildState(props);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: EditionProps) {
    if (nextProps.open && !this.props.open) {
      this.setState({ ...this.state, ...buildState(nextProps) });
    }
  }

  onFieldChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const {
      value,
      dataset: { id },
    } = target as HTMLInputElement;

    this.setState({ ...this.state, [id as string]: value });
  };

  onNatureChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number,
    payload: any
  ) => this.setState({ nature: payload });

  onIdentifierChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = target as HTMLInputElement;

    this.setState({
      errorTextId: this.isIdAlreadyUsed(value)
        ? _t('feedback.error.alreadyUsed')
        : null,
      identifier: value,
    });
  };

  onChangeVerificationDate = (useless: any, newDate: Date) => {
    if (newDate > this.state.validityDate) {
      this.setState({
        errorTextDateVerification: _t('feedback.error.unvalidDate'),
      });
    } else {
      this.setState({
        errorTextDateVerification: null,
        errorTextDateValidity: null,
      });
    }
    this.setState({ verificationDate: newDate });
  };

  onChangeValidityDate = (useless: any, newDate: Date) => {
    if (newDate < this.state.verificationDate) {
      this.setState({
        errorTextDateValidity: _t('feedback.error.unvalidDate'),
      });
    } else {
      this.setState({
        errorTextDateValidity: null,
        errorTextDateVerification: null,
      });
    }
    this.setState({ validityDate: newDate });
  };

  onOrganizationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number,
    value: any
  ) => this.setState({ selectedOrganizationId: value });

  editInstrument = () => {
    const { organizationFilterEnabled } = this.props;
    const {
      nature,
      type,
      brand,
      model,
      selectedOrganizationId,
      serialNumber,
      softwareVersion,
      validityDate,
      verificationDate,
      identifier,
    } = this.state;

    if (organizationFilterEnabled && !selectedOrganizationId) {
      return;
    }

    const instrument: InstrumentDTO = {
      nature,
      type,
      brand,
      model,
      organizationId: selectedOrganizationId,
      serialNumber,
      softwareVersion,
      validityDatetime: moment(validityDate).toISOString(),
      verificationDatetime: moment(verificationDate).toISOString(),
      identifier,
    };

    if (this.props.editInstrument) {
      this.props.editInstrument(instrument);
    }
  };

  isIdAlreadyUsed = (value: string) => {
    const { instrumentsIdentifiers } = this.props;
    return !!instrumentsIdentifiers && instrumentsIdentifiers.includes(value);
  };

  render() {
    const {
      closeModal,
      instrument,
      open,
      display,
      error,
      organizations,
      organizationFilterEnabled,
    } = this.props;
    const {
      nature,
      type,
      brand,
      model,
      serialNumber,
      softwareVersion,
      validityDate,
      verificationDate,
      identifier,
      selectedOrganizationId,
      errorTextId,
      errorTextDateValidity,
      errorTextDateVerification,
    } = this.state;

    const creationMode = !instrument;
    let titleModal;
    if (display) {
      titleModal = _t('element.dialogUpdate.title.display');
    } else {
      titleModal = creationMode
        ? _t('element.dialogUpdate.title.create')
        : _t('element.dialogUpdate.title.edit');
    }

    const actions = [
      <FlatButton label={_tg('action.cancel')} onClick={closeModal} />,
    ];
    if (!display) {
      actions.push(
        <FlatButton
          label={creationMode ? _tg('action.create') : _tg('action.edit')}
          primary
          onClick={this.editInstrument}
          disabled={
            errorTextId !== null ||
            errorTextDateValidity !== null ||
            errorTextDateVerification !== null
          }
        />
      );
    }

    return (
      <Dialog
        title={titleModal}
        open={open}
        onRequestClose={closeModal}
        autoScrollBodyContent
        actions={actions}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <SelectField
            data-id="nature"
            floatingLabelText={`${_tg('field.device.nature')}*`}
            value={nature}
            errorText=""
            onChange={this.onNatureChange}
            disabled={display}
            style={display ? { color: TXT_BLACK } : undefined}
          >
            {NATURE_OPTIONS.map(opts => (
              <MenuItem value={opts.value} primaryText={opts.label} />
            ))}
          </SelectField>
          <br />
          {organizationFilterEnabled &&
            organizations &&
            organizations?.length > 0 && (
              <>
                <SelectField
                  data-id="organization"
                  floatingLabelText={`${_tg(
                    'field.organisation.organisation'
                  )}*`}
                  value={selectedOrganizationId}
                  onChange={this.onOrganizationChange}
                  disabled={display}
                  style={display ? { color: TXT_BLACK } : undefined}
                >
                  {organizations?.map(organization => (
                    <MenuItem
                      value={organization.organizationId}
                      primaryText={organization.name}
                    />
                  ))}
                </SelectField>
                <br />
              </>
            )}
          <TextField
            data-id="type"
            onChange={this.onFieldChange}
            placeholder={_tg('field.device.type')}
            value={type}
            errorText=""
            disabled={display}
            inputStyle={display ? { color: TXT_BLACK } : undefined}
          />
          <br />
          <TextField
            data-id="brand"
            onChange={this.onFieldChange}
            placeholder={_tg('field.device.brand')}
            value={brand}
            errorText=""
            disabled={display}
            inputStyle={display ? { color: TXT_BLACK } : undefined}
          />
          <br />
          <TextField
            data-id="model"
            onChange={this.onFieldChange}
            placeholder={_tg('field.device.model')}
            value={model}
            errorText=""
            disabled={display}
            inputStyle={display ? { color: TXT_BLACK } : undefined}
          />
          <br />
          <TextField
            data-id="softwareVersion"
            onChange={this.onFieldChange}
            placeholder={_tg('field.device.softwareVersion')}
            value={softwareVersion}
            errorText=""
            disabled={display}
            inputStyle={display ? { color: TXT_BLACK } : undefined}
          />
          <br />
          <TextField
            data-id="serialNumber"
            onChange={this.onFieldChange}
            placeholder={_tg('field.device.serialNumber')}
            value={serialNumber}
            errorText=""
            disabled={display}
            inputStyle={display ? { color: TXT_BLACK } : undefined}
          />
          <br />
          <TextField
            data-id="identifier"
            onChange={this.onIdentifierChange}
            placeholder={_tg('field.device.id')}
            value={identifier}
            errorText={errorTextId}
            style={{ width: 'auto', flexGrow: 1, maxWidth: '80%' }}
            disabled={display || !creationMode}
            inputStyle={display ? { color: TXT_BLACK } : undefined}
          />
          <DatePicker
            DateTimeFormat={IntlPolyfill.DateTimeFormat}
            locale="fr"
            autoOk
            errorText={errorTextDateVerification}
            textFieldStyle={{ width: 200, marginRight: 5 }}
            value={verificationDate}
            onChange={this.onChangeVerificationDate}
            floatingLabelText={_tg('field.date.lastCheck')}
            container="inline"
            formatDate={formatDate}
            disabled={display}
            inputStyle={display ? { color: TXT_BLACK } : undefined}
          />
          <br />
          <DatePicker
            DateTimeFormat={IntlPolyfill.DateTimeFormat}
            locale="fr"
            autoOk
            errorText={errorTextDateValidity}
            textFieldStyle={{ width: 200, marginRight: 5 }}
            value={validityDate}
            onChange={this.onChangeValidityDate}
            floatingLabelText={_tg('field.date.endValidityDate')}
            container="inline"
            formatDate={formatDate}
            disabled={display}
            inputStyle={display ? { color: TXT_BLACK } : undefined}
          />
          <br />
          {error && (
            <ErrorBlock
              message={
                creationMode
                  ? _t('feedback.error.create')
                  : _t('feedback.error.update')
              }
              error={error}
            />
          )}
        </div>
      </Dialog>
    );
  }
}

export default connect(state => {
  const { organizationFilterEnabled } = getConfigState(state);
  return {
    organizationFilterEnabled,
  };
})(EditionModal);
