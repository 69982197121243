import {
  AutocompleteFilterType,
  CheckboxFilterType,
  DatetimeFilterType,
  Filters,
  FilterType,
  MultiSelectFilterType,
} from 'commons/types/filterbar';
import {
  RESPONSIBLE_PARTY_CIVILITY_OPTIONS,
  STATUSES_FILTER_OPTIONS,
  VEHICLE_CATEGORIES_OPTIONS,
} from 'api/tepv/gpv/utils';
import { BOOL_OPTION } from 'commons/Generics/FilterBar/utils';
import { SelectOption } from 'commons/SidebarV2/Components/Select';
import { ZoningDTO } from '@cvfm-front/tefps-types';
import { ItemIdName } from 'api/commonTypes';
import { INFRACTION_CASES_OPTIONS } from 'api/tepv/gpv/types';

const { _t, _tg } = window.loadTranslations(__filename);

export const gpvIdsFilter: FilterType = {
  id: 'gpvIds',
  label: _t('label.gpvId'),
  hint: _t('searchBy.gpvId'),
  type: 'MULTISEARCH',
  multiSearchValue: new Set(),
  modalTitle: _t('title.gpvList'),
  modalFloatingLabelText: _t('floatingLabel.addGpv'),
  multiSearchDefaultValue: new Set<string>(),
};

export const motifFilter: FilterType = {
  id: 'motif',
  label: _t('label.motifId'),
  hint: _t('searchBy.motifId'),
  type: 'INPUT',
};

export const motifQualificationFilter = (
  options: ItemIdName[],
  filterNatinfText: string,
  onAutocomplete?: (search: string) => Promise<unknown>
): AutocompleteFilterType => {
  return {
    id: 'motifQualification',
    label: _t('label.motifQualification'),
    hint: _t('searchBy.motifQualification'),
    onAutocomplete: onAutocomplete as (search: string) => Promise<unknown>,
    selectOptions: options,
    type: 'AUTOCOMPLETE',
    search: filterNatinfText,
  };
};

export const gpvStatusesFilter: CheckboxFilterType = {
  id: 'status',
  checkboxFacetName: 'status',
  label: _t('label.status'),
  type: 'CHECKBOX',
  checkboxOptions: STATUSES_FILTER_OPTIONS,
};

export const signatureDateFitler: DatetimeFilterType = {
  id: 'signatureDate',
  label: _tg('field.date.signDate'),
  type: 'DATETIME',
};

export const presetNotesFilter = (
  presetNote: SelectOption[]
): MultiSelectFilterType => {
  return {
    id: 'presetNote',
    label: _t('label.presetNote'),
    type: 'MULTISELECT',
    selectOptions: presetNote,
  };
};
export const infractionLocationsFilter = (
  infractionLocations: SelectOption[]
): MultiSelectFilterType => {
  return {
    id: 'infractionLocations',
    label: _t('label.infractionLocation'),
    type: 'MULTISELECT',
    selectOptions: infractionLocations,
  };
};

export const addressFilter: FilterType = {
  id: 'address',
  label: _tg('field.address.address'),
  hint: _t('searchBy.address'),
  type: 'INPUT',
};

export const photosFilter: CheckboxFilterType = {
  id: 'hasPhotos',
  checkboxFacetName: 'hasPhotos',
  label: _t('label.photos.name'),
  type: 'CHECKBOX',
  checkboxOptions: BOOL_OPTION(
    _t('label.photos.checkbox.with'),
    _t('label.photos.checkbox.without')
  ),
};

export const offenderSignatureFilter: CheckboxFilterType = {
  id: 'hasOffenderSignature',
  checkboxFacetName: 'hasOffenderSignature',
  label: _t('label.offenderSignature.name'),
  type: 'CHECKBOX',
  checkboxOptions: BOOL_OPTION(
    _t('label.offenderSignature.checkbox.with'),
    _t('label.offenderSignature.checkbox.without')
  ),
};

export const agentFilter: FilterType = {
  id: 'agent',
  label: _tg('field.agent.agent'),
  hint: _t('searchBy.agent'),
  type: 'INPUT',
};

export const postalCode: FilterType = {
  id: 'postalCode',
  label: _tg('field.address.postalCode'),
  hint: _t('searchBy.postalCode'),
  type: 'INPUT',
};

export const organizationsFilter = (
  organizations: SelectOption[]
): MultiSelectFilterType => {
  return {
    id: 'organizations',
    label: _tg('field.organisation.organisation_plural'),
    type: 'MULTISELECT',
    selectOptions: organizations,
  };
};

export const agentProfilesFilter = (
  agentProfiles: SelectOption[]
): MultiSelectFilterType => {
  return {
    id: 'agentProfiles',
    label: _t('label.profiles'),
    type: 'MULTISELECT',
    selectOptions: agentProfiles,
  };
};

export const vehicleCategoriesFilter: MultiSelectFilterType = {
  id: 'vehicleCategories',
  label: _t('label.vehicle'),
  type: 'MULTISELECT',
  selectOptions: VEHICLE_CATEGORIES_OPTIONS,
};

export const plateFilter: FilterType = {
  id: 'plate',
  type: 'INPUT',
  label: _tg('field.vehicle.licensePlate'),
  hint: _t('searchBy.licensePlate'),
};

export const zoneFilter = (
  zoning: ZoningDTO | null | undefined
): CheckboxFilterType => {
  return {
    id: 'zoneIds',
    checkboxFacetName: 'zoneId',
    label: 'Zone',
    type: 'CHECKBOX',
    checkboxOptions: zoning
      ? zoning.zones.map(zone => ({
          value: zone.id,
          label: zone.name,
        }))
      : [],
  };
};

export const infractionCasesFilter: MultiSelectFilterType = {
  id: 'infractionCases',
  label: _t('label.infractionCases'),
  type: 'MULTISELECT',
  selectOptions: INFRACTION_CASES_OPTIONS,
};

export const responsiblePartyCivility: CheckboxFilterType = {
  id: 'responsiblePartyCivility',
  checkboxFacetName: 'responsiblePartyCivility',
  label: _t('label.civility'),
  type: 'CHECKBOX',
  checkboxOptions: RESPONSIBLE_PARTY_CIVILITY_OPTIONS,
};

export const initGpvFilters = (
  presetNote: SelectOption[],
  infractionLocations: SelectOption[],
  organizations: SelectOption[],
  agentProfiles: SelectOption[],
  zoning: ZoningDTO | null | undefined,
  motifOptions: ItemIdName[],
  filterNatinfText: string,
  fetchMotifs: (natinfFilter?: string) => Promise<undefined>
): Filters => {
  const filters: Filters = {
    gpvIds: gpvIdsFilter,
    motif: motifFilter,
    motifQualification: motifQualificationFilter(
      motifOptions,
      filterNatinfText,
      fetchMotifs
    ),
    infractionCases: infractionCasesFilter,
    status: gpvStatusesFilter,
    signatureDate: signatureDateFitler,
    presetNote: presetNotesFilter(presetNote),
    infractionLocations: infractionLocationsFilter(infractionLocations),
    address: addressFilter,
    postalCode,
    hasPhotos: photosFilter,
    hasOffenderSignature: offenderSignatureFilter,
    agent: agentFilter,
    organizations: organizationsFilter(organizations),
    agentProfiles: agentProfilesFilter(agentProfiles),
    vehicleCategories: vehicleCategoriesFilter,
    plate: plateFilter,
    zoneIds: zoneFilter(zoning),
    responsiblePartyCivility,
  };

  return filters;
};
