import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { History } from 'history';

import { BKG_CYAN, BKG_PINK, TXT_BLACK } from 'theme';
import { NotificationType } from 'api/tasks/types';
import Separator from 'commons/Separator';
import {
  DataBox,
  DataBoxContent,
  DataBoxHeader,
  DataBoxItemWrapper,
} from 'commons/DataBox';
import { OrderEsSearchQueryDTO } from '@cvfm-front/tefps-types';

import TaskData from './components/TaskData';
import TaskButtons from './components/TaskButtons';
import { TaskStats } from './types';

const { _tg } = window.loadTranslations();

type OrderTaskProps = {
  title: string;
  history: History;
  data: TaskStats;
  notifications: Array<NotificationType>;
  filters: OrderEsSearchQueryDTO;
  userNotif: Array<NotificationType>;
  onChangeNotif: () => void;
} & RouteComponentProps;

function OrderTask({
  data,
  notifications,
  title,
  userNotif,
  onChangeNotif,
  history,
  filters,
}: OrderTaskProps): JSX.Element {
  const redirect = () => {
    history.push({
      pathname: '/subscription/eligibility/order',
      state: { ...filters },
    });
  };

  return (
    <DataBox panel style={{ marginBottom: 40 }}>
      <DataBoxHeader color={BKG_CYAN}>
        <div style={{ fontWeight: 'bold' }}>{title}</div>
      </DataBoxHeader>
      <DataBoxContent>
        <DataBoxItemWrapper>
          <TaskData
            width="25%"
            value={data.total}
            text={_tg('commons.inTotal').toLocaleLowerCase()}
            color={TXT_BLACK}
          />
          <Separator />
          <TaskData
            width="25%"
            value={data.statOneDay}
            text={_tg('tefps.tasks.waitingForMoreThanOneDay')}
            color={BKG_CYAN}
          />
          <Separator />
          <TaskData
            width="25%"
            value={data.statOneWeek}
            text={_tg('tefps.tasks.waitingForMoreThanFiveDay')}
            color={BKG_PINK}
          />
          <Separator />
          <TaskButtons
            userNotif={userNotif}
            notifications={notifications}
            redirect={redirect}
            onSave={onChangeNotif}
          />
        </DataBoxItemWrapper>
      </DataBoxContent>
    </DataBox>
  );
}

export default withRouter(OrderTask);
