import { NotificationType } from 'api/tasks/types';
import { SubscriptionApplicationsSearchCriteria } from 'tefps/Beneficiaries/SubscriptionApplications/types';
import { CcspSearchCriterias } from 'tefps/CcspRecourses/List/types';
import { CheckboxFilterType } from 'commons/types/filterbar';
import { refundStatusFilter } from 'Dashboard/fps/Refund/List/Filters';
import { OrderEsSearchQueryDTO, OrderStatus } from '@cvfm-front/tefps-types';
import { RecourseSearchCriterias } from 'tefps/RecoursesV2/utils/recourseUtils';

const { _tg } = window.loadTranslations(__filename);

const BASE_FILTER = {
  postalStatuses: new Set<string>(),
  reasons: new Set<string>(),
  cityReasons: new Set<string>(),
  tags: new Set<string>(),
  sources: new Set<string>(),
  creationDate: { from: undefined, to: undefined },
  receptionDate: { from: undefined, to: undefined },
  agentId: null,
  fpsId: null,
  userName: null,
  plate: null,
  comment: null,
  agentComment: null,
  rapoNumber: null,
};

const BASE_APPLICATIONS_FILTER = {
  firstName: null,
  lastName: null,
  subscriberId: null,
  subscriptionPlanIds: new Set<string>(),
  zoneIds: new Set<string>(),
  plate: null,
  customFields: null,
  dates: { from: undefined, to: undefined },
};

const BASE_CCSP_FILTER: CcspSearchCriterias = {
  statuses: new Set(),
  openingDate: { from: undefined, to: undefined },
  responseLimitDate: { from: undefined, to: undefined },
  ccspIds: new Set(),
  ccspNumbers: new Set(),
  fpsOrFpsmId: null,
  userFirstName: '',
  userLastName: '',
};

export const BASE_REFUND_FILTER: {
  fpsRefundStatuses: CheckboxFilterType;
} = {
  fpsRefundStatuses: {
    ...refundStatusFilter(),
    checkboxValues: new Set(['REFUND_PENDING']),
  },
};

export const recourseWaitingFilters = (): RecourseSearchCriterias => ({
  ...BASE_FILTER,
  creationDate: { ...BASE_FILTER.creationDate },
  receptionDate: { ...BASE_FILTER.receptionDate },
  statuses: new Set(['IS_WAITING']),
  fpsStatementDatetime: { ...BASE_FILTER.creationDate },
  decisionDatetime: { from: undefined, to: undefined },
  antaiNotificationDatetime: { from: undefined, to: undefined },
});

export const recourseTreatmentFilters = (): RecourseSearchCriterias => ({
  ...BASE_FILTER,
  creationDate: { ...BASE_FILTER.creationDate },
  receptionDate: { ...BASE_FILTER.receptionDate },
  statuses: new Set(['IS_TO_PROCESS']),
  fpsStatementDatetime: { ...BASE_FILTER.creationDate },
  decisionDatetime: { from: undefined, to: undefined },
  antaiNotificationDatetime: { from: undefined, to: undefined },
});

export const recourseToValidateFilters = (): RecourseSearchCriterias => ({
  ...BASE_FILTER,
  creationDate: { ...BASE_FILTER.creationDate },
  receptionDate: { ...BASE_FILTER.receptionDate },
  statuses: new Set(['ONGOING_VALIDATION']),
  fpsStatementDatetime: { ...BASE_FILTER.creationDate },
  decisionDatetime: { from: undefined, to: undefined },
  antaiNotificationDatetime: { from: undefined, to: undefined },
});

export const applicationsToValidateFilters = (): SubscriptionApplicationsSearchCriteria => ({
  ...BASE_APPLICATIONS_FILTER,
  subscriptionStatus: ['AWAITING_VALIDATION'],
});

export const ccspWaitingPropositionFilters = (): CcspSearchCriterias => ({
  ...BASE_CCSP_FILTER,
  statuses: new Set(['EN_ATTENTE_DE_PROPOSITION']),
});

export const ccspWaitingValidationFilters = (): CcspSearchCriterias => ({
  ...BASE_CCSP_FILTER,
  statuses: new Set(['EN_ATTENTE_DE_VALIDATION']),
});

export const ccspWaitingEnregistrementFilters = (): CcspSearchCriterias => ({
  ...BASE_CCSP_FILTER,
  statuses: new Set(['EN_ATTENTE_DENREGISTREMENT']),
});

export const ccspFpsIdFilters = (fpsId: string): CcspSearchCriterias => ({
  ...BASE_CCSP_FILTER,
  fpsOrFpsmId: fpsId,
});

export const orderWaitingDecisionFilters = (): OrderEsSearchQueryDTO => ({
  orderStatuses: [OrderStatus.AWAIT_DECISION],
});

export const orderWaitingTreatmentRenewalFilters = (): OrderEsSearchQueryDTO => ({
  orderStatuses: [OrderStatus.AWAIT_TREATMENT_RENEWAL],
});

export const orderWaitingPlateDecisionFilters = (): OrderEsSearchQueryDTO => ({
  orderStatuses: [OrderStatus.AWAIT_PLATE_DECISION],
});

export const orderWaitingClaimDecisionFilters = (): OrderEsSearchQueryDTO => ({
  orderStatuses: [OrderStatus.AWAIT_CLAIM_DECISION],
});

export const orderWaitingClaimTreatmentCompletudeFilters = (): OrderEsSearchQueryDTO => ({
  orderStatuses: [OrderStatus.AWAIT_CLAIM_TREATMENT_COMPLETUDE],
});

export const orderWaitingTreatmentCompletudeFilters = (): OrderEsSearchQueryDTO => ({
  orderStatuses: [OrderStatus.AWAIT_TREATMENT_COMPLETUDE],
});

export type NotifCheckboxInfo = {
  label: string;
  dependances: Array<NotificationType>;
};

type NotifCheckBoxKey = NotificationType | string;
export const NOTIF_CHECKBOX: () => {
  [key in NotifCheckBoxKey]: NotifCheckboxInfo;
} = () => {
  return {
    RECOURSE_TREATMENT: {
      label: _tg('tefps.tasks.notifications.RECOURSE_TREATMENT'),
      dependances: [],
    },
    RECOURSE_TREATMENT_ONE_WEEK: {
      label: _tg('tefps.tasks.notifications.RECOURSE_TREATMENT_ONE_WEEK'),
      dependances: [],
    },
    RECOURSE_TREATMENT_ONE_DAY: {
      label: _tg('tefps.tasks.notifications.RECOURSE_TREATMENT_ONE_DAY'),
      dependances: [],
    },
    RECOURSE_WAITING: {
      label: _tg('tefps.tasks.notifications.RECOURSE_WAITING'),
      dependances: [],
    },
    RECOURSE_WAITING_ONE_WEEK: {
      label: _tg('tefps.tasks.notifications.RECOURSE_WAITING_ONE_WEEK'),
      dependances: [],
    },
    RECOURSE_WAITING_ONE_DAY: {
      label: _tg('tefps.tasks.notifications.RECOURSE_WAITING_ONE_DAY'),
      dependances: [],
    },
    RECOURSE_VALIDATION: {
      label: _tg('tefps.tasks.notifications.RECOURSE_VALIDATION'),
      dependances: [],
    },
    RECOURSE_VALIDATION_ONE_WEEK: {
      label: _tg('tefps.tasks.notifications.RECOURSE_VALIDATION_ONE_WEEK'),
      dependances: [],
    },
    RECOURSE_VALIDATION_ONE_DAY: {
      label: _tg('tefps.tasks.notifications.RECOURSE_VALIDATION_ONE_DAY'),
      dependances: [],
    },
    SUBSCRIPTION_WAITING: {
      label: _tg('tefps.tasks.notifications.SUBSCRIPTION_WAITING'),
      dependances: [],
    },
    SUBSCRIPTION_WAITING_ONE_DAY: {
      label: _tg('tefps.tasks.notifications.SUBSCRIPTION_WAITING_ONE_DAY'),
      dependances: [],
    },
    SUBSCRIPTION_WAITING_FIVE_DAY: {
      label: _tg('tefps.tasks.notifications.SUBSCRIPTION_WAITING_FIVE_DAY'),
      dependances: [],
    },
    ORDER_WAITING: {
      label: _tg('tefps.tasks.notifications.ORDER_WAITING'),
      dependances: [],
    },
    ORDER_WAITING_ONE_DAY: {
      label: _tg('tefps.tasks.notifications.ORDER_WAITING_ONE_DAY'),
      dependances: [],
    },
    ORDER_WAITING_FIVE_DAY: {
      label: _tg('tefps.tasks.notifications.ORDER_WAITING_FIVE_DAY'),
      dependances: [],
    },
    ORDER_AWAIT_TREATMENT_COMPLETUDE: {
      label: _tg('tefps.tasks.notifications.ORDER_AWAIT_TREATMENT_COMPLETUDE'),
      dependances: [],
    },
    ORDER_AWAIT_TREATMENT_COMPLETUDE_ONE_DAY: {
      label: _tg(
        'tefps.tasks.notifications.ORDER_AWAIT_TREATMENT_COMPLETUDE_ONE_DAY'
      ),
      dependances: [],
    },
    ORDER_AWAIT_TREATMENT_COMPLETUDE_FIVE_DAY: {
      label: _tg(
        'tefps.tasks.notifications.ORDER_AWAIT_TREATMENT_COMPLETUDE_FIVE_DAY'
      ),
      dependances: [],
    },
    ORDER_AWAIT_TREATMENT_RENEWAL: {
      label: _tg('tefps.tasks.notifications.ORDER_AWAIT_TREATMENT_RENEWAL'),
      dependances: [],
    },
    ORDER_AWAIT_TREATMENT_RENEWAL_ONE_DAY: {
      label: _tg(
        'tefps.tasks.notifications.ORDER_AWAIT_TREATMENT_RENEWAL_ONE_DAY'
      ),
      dependances: [],
    },
    ORDER_AWAIT_TREATMENT_RENEWAL_FIVE_DAY: {
      label: _tg(
        'tefps.tasks.notifications.ORDER_AWAIT_TREATMENT_RENEWAL_FIVE_DAY'
      ),
      dependances: [],
    },
    ORDER_AWAIT_PLATE_DECISION: {
      label: _tg('tefps.tasks.notifications.ORDER_AWAIT_PLATE_DECISION'),
      dependances: [],
    },
    ORDER_AWAIT_PLATE_DECISION_ONE_DAY: {
      label: _tg(
        'tefps.tasks.notifications.ORDER_AWAIT_PLATE_DECISION_ONE_DAY'
      ),
      dependances: [],
    },
    ORDER_AWAIT_PLATE_DECISION_FIVE_DAY: {
      label: _tg(
        'tefps.tasks.notifications.ORDER_AWAIT_PLATE_DECISION_FIVE_DAY'
      ),
      dependances: [],
    },
    ORDER_AWAIT_CLAIM_DECISION: {
      label: _tg('tefps.tasks.notifications.ORDER_AWAIT_CLAIM_DECISION'),
      dependances: [],
    },
    ORDER_AWAIT_CLAIM_DECISION_ONE_DAY: {
      label: _tg(
        'tefps.tasks.notifications.ORDER_AWAIT_CLAIM_DECISION_ONE_DAY'
      ),
      dependances: [],
    },
    ORDER_AWAIT_CLAIM_DECISION_FIVE_DAY: {
      label: _tg(
        'tefps.tasks.notifications.ORDER_AWAIT_CLAIM_DECISION_FIVE_DAY'
      ),
      dependances: [],
    },
    ORDER_AWAIT_CLAIM_TREATMENT_COMPLETUDE: {
      label: _tg(
        'tefps.tasks.notifications.ORDER_AWAIT_CLAIM_TREATMENT_COMPLETUDE'
      ),
      dependances: [],
    },
    ORDER_AWAIT_CLAIM_TREATMENT_COMPLETUDE_ONE_DAY: {
      label: _tg(
        'tefps.tasks.notifications.ORDER_AWAIT_CLAIM_TREATMENT_COMPLETUDE_ONE_DAY'
      ),
      dependances: [],
    },
    ORDER_AWAIT_CLAIM_TREATMENT_COMPLETUDE_FIVE_DAY: {
      label: _tg(
        'tefps.tasks.notifications.ORDER_AWAIT_CLAIM_TREATMENT_COMPLETUDE_FIVE_DAY'
      ),
      dependances: [],
    },
    CCSP_AWAITING_VALIDATION: {
      label: _tg('tefps.tasks.notifications.CCSP_AWAITING_VALIDATION'),
      dependances: [],
    },
    CCSP_AWAITING_DECISION: {
      label: _tg('tefps.tasks.notifications.CCSP_AWAITING_DECISION'),
      dependances: [],
    },
    CCSP_AWAITING_PROPOSAL: {
      label: _tg('tefps.tasks.notifications.CCSP_AWAITING_PROPOSAL'),
      dependances: [],
    },
    CCSP_TO_SEND_FIVE_DAY: {
      label: _tg('tefps.tasks.notifications.CCSP_TO_SEND_FIVE_DAY'),
      dependances: [],
    },
    CCSP_PROPOSITION_TEN_DAY: {
      label: _tg('tefps.tasks.notifications.CCSP_PROPOSITION_TEN_DAY'),
      dependances: [],
    },
    CCSP_VALIDATION_TEN_DAY: {
      label: _tg('tefps.tasks.notifications.CCSP_VALIDATION_TEN_DAY'),
      dependances: [],
    },
    CCSP_DECISION_TEN_DAY: {
      label: _tg('tefps.tasks.notifications.CCSP_DECISION_TEN_DAY'),
      dependances: [],
    },
    REFUND_WAITING: {
      label: _tg('tefps.tasks.notifications.REFUND_WAITING'),
      dependances: [],
    },
    REFUND_WAITING_ONE_DAY: {
      label: _tg('tefps.tasks.notifications.REFUND_WAITING_ONE_DAY'),
      dependances: [],
    },
    REFUND_WAITING_FIVE_DAY: {
      label: _tg('tefps.tasks.notifications.REFUND_WAITING_FIVE_DAY'),
      dependances: [],
    },
  };
};

export const computeNewNotifications = (
  notif: NotificationType,
  currentNotif: Array<NotificationType>
): Array<NotificationType> => {
  if (!currentNotif.includes(notif)) {
    // notif is always NotificationType
    // Find notification checked
    const targetNotification = NOTIF_CHECKBOX()[notif];

    if (targetNotification) {
      // Merge new notifications and get a unique list
      return Array.from(
        new Set([...currentNotif, ...targetNotification.dependances, notif])
      );
    }
  } else {
    // Find notifications depending on removed notification
    const notifsToRemove = Object.keys(NOTIF_CHECKBOX()).filter(n =>
      NOTIF_CHECKBOX()[n].dependances.includes(notif)
    );

    // Remove the notification
    return currentNotif.filter(n => n !== notif && !notifsToRemove.includes(n));
  }

  return currentNotif;
};
