import * as React from 'react';
import { connect } from 'react-redux';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useLocation,
} from 'react-router-dom';

import MissNotFound from 'commons/MissNotFound';
import { getConfigState } from 'config/duck';
import { OrderStatus, ProductType, SortOrder } from '@cvfm-front/tefps-types';

import ProductTablePage from './ProductTablePage';
import ProductDetailPage from './ProductDetailPage';
import OrderTablePage from './OrderTablePage';
import OrderDetailPage from './OrderDetailPage';
import SubscriberTablePage from './SubscriberTablePage';
import SubscriberDetailPage from './SubscriberDetailPage';
import OrderCreatePage from './OrderCreatePage';

type SubscriptionRouterProps = RouteComponentProps & {
  enabled: boolean;
};

const SubscriptionRouter = ({ match, enabled }: SubscriptionRouterProps) => {
  if (!enabled) {
    return (
      <div style={{ height: '100%' }}>
        <MissNotFound />
      </div>
    );
  }

  const routerQuery = new URLSearchParams(useLocation().search);
  const baseRoute = match.url;

  return (
    <div style={{ height: '100%' }}>
      <Switch>
        <Redirect
          exact
          path={baseRoute}
          to={`${baseRoute}/eligibility/order`}
        />
        <Route
          path={`${baseRoute}/subscribers`}
          exact
          component={() => <SubscriberTablePage />}
        />
        <Route
          path={`${baseRoute}/subscribers/:subscriberId`}
          exact
          component={() => <SubscriberDetailPage />}
        />
        <Route
          path={`${baseRoute}/eligibility/product`}
          exact
          component={() => (
            <ProductTablePage productType={ProductType.ELIGIBILITY} />
          )}
        />
        <Route
          path={`${baseRoute}/eligibility/product/:productId`}
          exact
          component={() => <ProductDetailPage />}
        />
        <Route
          path={`${baseRoute}/eligibility/order`}
          exact
          component={() =>
            routerQuery.get('passed') !== null ? (
              <OrderTablePage
                productType={ProductType.ELIGIBILITY}
                statuses={[
                  OrderStatus.FULFILLED,
                  OrderStatus.REJECTED,
                  OrderStatus.CANCELED,
                ]}
                validityEnd={{}}
              />
            ) : (
              <OrderTablePage
                productType={ProductType.ELIGIBILITY}
                statuses={[
                  OrderStatus.AWAIT_DECISION,
                  OrderStatus.AWAIT_PAYMENT,
                  OrderStatus.AWAIT_PLATE_DECISION,
                  OrderStatus.AWAIT_COMPLETION_USER,
                  OrderStatus.AWAIT_TREATMENT_COMPLETUDE,
                  OrderStatus.AWAIT_TREATMENT_RENEWAL,
                  OrderStatus.AWAIT_CLAIM_DECISION,
                  OrderStatus.AWAIT_CLAIM_COMPLETION_USER,
                  OrderStatus.AWAIT_CLAIM_TREATMENT_COMPLETUDE,
                ]}
                validityEnd={{}}
              />
            )
          }
        />
        <Route
          path={`${baseRoute}/eligibility/order/:orderId`}
          exact
          component={() => <OrderDetailPage />}
        />
        <Route
          path={`${baseRoute}/eligibility/eligibilities`}
          exact
          component={() => (
            <OrderTablePage
              productType={ProductType.ELIGIBILITY}
              statuses={[OrderStatus.FULFILLED]}
              validityEnd={{ from: new Date() }}
              pageSort={{
                index: 0,
                page: 0,
                sortOrder: SortOrder.DESC,
                field: 'endOfValidity',
              }}
            />
          )}
        />
        <Route
          path={`${baseRoute}/eligibility/eligibilities/:orderId`}
          exact
          component={() => <OrderDetailPage />}
        />
        <Route
          path={`${baseRoute}/bundle/product`}
          exact
          component={() => (
            <ProductTablePage productType={ProductType.BUNDLE} />
          )}
        />
        <Route
          path={`${baseRoute}/bundle/product/:productId`}
          exact
          component={() => <ProductDetailPage />}
        />
        <Route
          path={`${baseRoute}/bundle/order/`}
          exact
          component={() => (
            <OrderTablePage
              productType={ProductType.BUNDLE}
              statuses={[
                OrderStatus.AWAIT_DECISION,
                OrderStatus.AWAIT_PAYMENT,
                OrderStatus.FULFILLED,
              ]}
              validityEnd={{ from: new Date() }}
              pageSort={{
                index: 0,
                page: 0,
                sortOrder: SortOrder.DESC,
                field: 'endOfValidity',
              }}
            />
          )}
        />
        <Route
          path={`${baseRoute}/bundle/order/:orderId`}
          exact
          component={() => <OrderDetailPage />}
        />
        <Route
          path={`${baseRoute}/create/order/bundle`}
          exact
          component={() => <OrderCreatePage productType={ProductType.BUNDLE} />}
        />
        <Route
          path={`${baseRoute}/create/order/eligibility`}
          exact
          component={() => (
            <OrderCreatePage productType={ProductType.ELIGIBILITY} />
          )}
        />
        <MissNotFound />
      </Switch>
    </div>
  );
};

export default connect(state => {
  const {
    modulesConfiguration: {
      subscribers: { enabled },
    },
  } = getConfigState(state);
  return {
    enabled,
  };
})(SubscriptionRouter);
