import * as React from 'react';

import { DataBoxItem } from 'commons/DataBox';
import FakeInputBlock from 'commons/FakeInputBlock';
import { GpvVehicleDetail } from 'api/tepv/gpv/types';

const VehicleBlock = ({
  vehicle: {
    brand,
    model,
    vehicleClass,
    plate,
    plateCountry,
    mandatoryLicenceExchange,
  },
  vehicleImmobilization,
  vehicleRemoval,
}: {
  vehicle: GpvVehicleDetail;
  vehicleImmobilization: boolean;
  vehicleRemoval: boolean;
}) => (
  <DataBoxItem
    style={{
      maxWidth: '100%',
    }}
  >
    {vehicleClass !== 'PIETON' && ( // don't need those if there's no vehicle concerned
      <>
        <FakeInputBlock title="Marque du véhicule" value={brand} />
        <FakeInputBlock title="Modèle du véhicule" value={model} />
      </>
    )}
    <FakeInputBlock title="Catégorie" value={vehicleClass} />
    {plate && plateCountry && (
      <FakeInputBlock
        title="Immatriculation"
        value={`${plate} [${plateCountry}]`}
      />
    )}
    {vehicleRemoval && (
      <FakeInputBlock title="Enlèvement du véhicule demandé" />
    )}
    {vehicleImmobilization && (
      <FakeInputBlock title="Immobilisation du véhicule demandé" />
    )}
    {mandatoryLicenceExchange && (
      <FakeInputBlock title="Échange du permis de conduire obligatoire" />
    )}
  </DataBoxItem>
);

export default VehicleBlock;
