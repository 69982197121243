import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { History } from 'history';

import {
  DataBox,
  DataBoxContent,
  DataBoxHeader,
  DataBoxItemWrapper,
} from 'commons/DataBox';
import { BKG_CYAN, BKG_PINK, TXT_BLACK } from 'theme';
import Separator from 'commons/Separator';
import { NotificationType } from 'api/tasks/types';
import { Filters } from 'commons/types/filterbar';

import TaskData from './components/TaskData';
import TaskButtons from './components/TaskButtons';
import { TaskStats } from './types';

const { _tg } = window.loadTranslations();

type RefundTaskProps = {
  title: string;
  history: History;
  notifications: Array<NotificationType>;
  userNotif: Array<NotificationType>;
  onChangeNotif: () => void;
  data: TaskStats;
  filters: Filters;
} & RouteComponentProps;

function RefundTask({
  notifications,
  title,
  userNotif,
  onChangeNotif,
  history,
  filters,
  data,
}: RefundTaskProps) {
  function redirect() {
    // redirecting to the refund page, with filters
    history.push({ pathname: '/dashboard/refund', state: { filters } });
  }

  return (
    <DataBox panel style={{ marginBottom: 40 }}>
      <DataBoxHeader color={BKG_CYAN}>
        <div style={{ fontWeight: 'bold' }}>{title}</div>
      </DataBoxHeader>
      <DataBoxContent>
        <DataBoxItemWrapper>
          <TaskData
            value={data.total}
            text={_tg('commons.inTotal')}
            color={TXT_BLACK}
            width="25%"
          />
          <Separator />
          <TaskData
            value={data.statOneDay}
            text={_tg('tefps.tasks.waitingForMoreThanOneDay')}
            color={BKG_CYAN}
            width="25%"
          />
          <Separator />
          <TaskData
            value={data.statOneWeek}
            text={_tg('tefps.tasks.waitingForMoreThanFiveDay')}
            color={BKG_PINK}
            width="25%"
          />
          <Separator />
          <TaskButtons
            userNotif={userNotif}
            notifications={notifications}
            redirect={redirect}
            onSave={onChangeNotif}
          />
        </DataBoxItemWrapper>
      </DataBoxContent>
    </DataBox>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
export default withRouter(RefundTask);
