import * as React from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import CircularProgress from 'material-ui/CircularProgress';

import { importParkingRighsFromCSV } from 'api/tickets';
import CsvImportDropZone from 'commons/CsvImportDropZone';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  onImport: (message: string) => Promise<void>;
};

type State = {
  success: boolean;
  loading: boolean;
  openModal: boolean;
  errors: Array<string>;
};

class ImportTickets extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      success: false,
      loading: false,
      openModal: false,
      errors: [],
    };
  }

  onDrop = async (files: Array<File>): Promise<void> => {
    if (files.length === 0) {
      return;
    }

    this.setState({ loading: true, openModal: true });
    const { onImport } = this.props;

    const { success, errors } = await importParkingRighsFromCSV(files[0]);

    this.setState({ success, errors, loading: false, openModal: !success });
    if (success) {
      void onImport(_tg('tefps.tickets.success.creatingTickets'));
    }
  };

  handleClose = (): void => {
    this.setState({
      openModal: false,
      errors: [],
    });
  };

  render(): JSX.Element {
    const { success, loading, openModal, errors } = this.state;

    let title = _tg('feedback.loading.importInProgress');
    if (!success && !loading) {
      title = _tg('feedback.error.requestNotProcessed');
    }

    return (
      <div style={{ marginLeft: 30 }}>
        <CsvImportDropZone
          buttonLabel={_tg('action.importCSV')}
          onDrop={this.onDrop}
        />

        <Dialog
          title={title}
          onRequestClose={this.handleClose}
          modal
          open={openModal}
          autoScrollBodyContent
          actions={[
            <FlatButton
              label={_tg('action.close')}
              onClick={this.handleClose}
              disabled={loading}
            />,
          ]}
        >
          {!loading && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 10,
              }}
            >
              <ul style={{ marginTop: 20 }}>
                {errors.map(error => (
                  <li style={{ marginBottom: 2 }}>
                    {_tg('characters.dot')} {error}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {loading && <CircularProgress />}
        </Dialog>
      </div>
    );
  }
}

export default ImportTickets;
