import React from 'react';

import SubscriberSimpleOrderHistoryAction from './SubscriberSimpleOrderHistoryAction';

const { _t } = window.loadTranslations(__filename);

interface Props {
  action: {
    planName: string;
    plates: string[];
  };
}

function SubscriberCreateClaimAction({ action }: Props): JSX.Element {
  return (
    <SubscriberSimpleOrderHistoryAction
      message={_t('element.content', {
        planName: action.planName,
        plates: action.plates.join(', '),
      })}
      action={action}
    />
  );
}

export default SubscriberCreateClaimAction;
