import * as React from 'react';

import { DataBoxItem } from 'commons/DataBox';
import FakeInputBlock from 'commons/FakeInputBlock';
import EditionModal from 'tepv/Devices/modals';
import { InstrumentDTO, InstrumentNature } from 'api/tepv/instruments/types';

const { _tg } = window.loadTranslations();

type Props = {
  nature: InstrumentNature;
  type: string;
  brand: string;
  model: string;
  serialNumber: string;
  softwareVersion: string;
  validityDate: string;
  verificationDate: string;
  identifier: string;
};

type State = {
  isDisplayModalOpen: boolean;
};

class InstrumentBlock extends React.Component<Props, State> {
  state = {
    isDisplayModalOpen: false,
  };

  openDisplayModal = () => {
    this.setState({
      isDisplayModalOpen: true,
    });
  };

  closeDisplayModal = () => {
    this.setState({
      isDisplayModalOpen: false,
    });
  };

  render() {
    // TODO organizationID
    const displayedInstrument: InstrumentDTO = {
      nature: this.props.nature,
      type: this.props.type,
      brand: this.props.brand,
      model: this.props.model,
      serialNumber: this.props.serialNumber,
      softwareVersion: this.props.softwareVersion,
      validityDatetime: this.props.validityDate,
      verificationDatetime: this.props.verificationDate,
      identifier: this.props.identifier,
      organizationId: null,
    };

    return (
      <DataBoxItem
        style={{
          maxWidth: '100%',
        }}
      >
        {this.props.identifier && (
          <div onClick={this.openDisplayModal} style={{ cursor: 'pointer' }}>
            <FakeInputBlock
              title={_tg('field.device.brandDevice')}
              value={this.props.brand}
            />
            <FakeInputBlock
              title={_tg('field.device.modelDevice')}
              value={this.props.model}
            />
            <EditionModal
              closeModal={this.closeDisplayModal}
              organizations={[]}
              instrument={displayedInstrument}
              open={this.state.isDisplayModalOpen}
              display
            />
          </div>
        )}
      </DataBoxItem>
    );
  }
}

export default InstrumentBlock;
