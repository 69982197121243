import React from 'react';
import { useHistory } from 'react-router';

import { BKG_CYAN, BKG_PINK, TXT_BLACK } from 'theme';
import { NotificationType } from 'api/tasks/types';
import Separator from 'commons/Separator';
import {
  DataBox,
  DataBoxContent,
  DataBoxHeader,
  DataBoxItemWrapper,
} from 'commons/DataBox';
import { RecourseSearchCriterias } from 'tefps/RecoursesV2/utils/recourseUtils';
import useRecourseFilters from 'commons/hooks/useRecourseFilters';

import TaskData from './components/TaskData';
import TaskButtons from './components/TaskButtons';
import { TaskStats } from './types';

const { _tg } = window.loadTranslations();

type RecourseTaskProps = {
  title: string;
  userNotif: Array<NotificationType>;
  data: TaskStats;
  notifications: Array<NotificationType>;
  filters: RecourseSearchCriterias;
  onChangeNotif: () => void;
};

const RecourseTask = ({
  filters,
  data,
  notifications,
  title,
  userNotif,
  onChangeNotif,
}: RecourseTaskProps): JSX.Element => {
  const history = useHistory();
  const [, setRecourseFilters] = useRecourseFilters();

  const redirect = () => {
    setRecourseFilters(filters);
    history.push('/recourses/list');
  };

  return (
    <DataBox panel style={{ marginBottom: 40 }}>
      <DataBoxHeader color={BKG_CYAN}>
        <div style={{ fontWeight: 'bold' }}>{title}</div>
      </DataBoxHeader>
      <DataBoxContent>
        <DataBoxItemWrapper>
          <TaskData
            width="25%"
            value={data.total}
            text={_tg('commons.inTotal').toLocaleLowerCase()}
            color={TXT_BLACK}
          />
          <Separator />
          <TaskData
            width="25%"
            value={data.statOneWeek}
            text={_tg('tefps.tasks.treatBeforeFiveDay')}
            color={BKG_CYAN}
          />
          <Separator />
          <TaskData
            width="25%"
            value={data.statOneDay}
            text={_tg('tefps.tasks.treatBeforeOneDay')}
            color={BKG_PINK}
          />
          <Separator />
          <TaskButtons
            userNotif={userNotif}
            notifications={notifications}
            redirect={redirect}
            onSave={onChangeNotif}
          />
        </DataBoxItemWrapper>
      </DataBoxContent>
    </DataBox>
  );
};

export default RecourseTask;
