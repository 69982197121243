import FlatButton from 'material-ui/FlatButton';
import * as React from 'react';

import { openNewAuthentifiedTab } from 'api/helpers';
import { getMifPdfUrl } from 'api/tepv/mif';

const { _tg } = window.loadTranslations();

type Props = {
  mifId: string;
};

class PdfGenerateButton extends React.Component<Props> {
  openPdf = () => {
    const { mifId } = this.props;
    const url = getMifPdfUrl(mifId);
    openNewAuthentifiedTab(url, mifId);
  };

  render() {
    return <FlatButton label={_tg('action.export')} onClick={this.openPdf} />;
  }
}

export default PdfGenerateButton;
