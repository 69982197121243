import React from 'react';

import { SubscriberDTO } from '@cvfm-front/tefps-types';

import SubscriberSimpleOrderHistoryAction from './SubscriberSimpleOrderHistoryAction';

const { _t } = window.loadTranslations(__filename);

function SubscriberCreateEligibilityAction({
  action,
  subscriber,
}: {
  action: any;
  subscriber: SubscriberDTO;
}): JSX.Element {
  return (
    <SubscriberSimpleOrderHistoryAction
      message={_t('element.content', {
        planName: action.planName,
        plates: action.plates.join(', '),
      })}
      action={action}
      subscriber={subscriber}
    />
  );
}

export default SubscriberCreateEligibilityAction;
