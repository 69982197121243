import React, { useState } from 'react';
import { Checkbox, Dialog, MenuItem, SelectField } from 'material-ui';
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';

import {
  SubscriberDTO,
  SubscriberMediaDTO,
  SubscriberVehicle,
  SubscriberVehicleUploadDTO,
} from '@cvfm-front/tefps-types';
import { Files, Flex } from '@cvfm-front/commons-ui';
import {
  getEvidenceDirectUploadLink,
  patchSubscriber,
} from 'api/cvfm-core-subscription/subscriber';
import { uploadFileV2 } from 'api/mediaupload';
import { InternalApiState } from 'api/duck';
import { getConfigState } from 'config/duck';
import BoButton from 'facade/BoButton';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  subscriber: SubscriberDTO;
  updateSubscriber: React.Dispatch<React.SetStateAction<SubscriberDTO>>;
  vehicleId: string | null;
  isVehicleUsed: boolean;
  watermarkText: string | null;
  closeModal: () => void;
  setOpenSecondConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
};

const vehiclePatchEndpoint = {
  pro: '/professional-vehicle',
  perso: '/personal-vehicle',
};

const documentsUploaded: SubscriberMediaDTO[] = [];

const SubscriberUpdateVehicle = ({
  subscriber,
  updateSubscriber,
  vehicleId,
  isVehicleUsed,
  watermarkText,
  closeModal,
  setOpenSecondConfirmation,
}: Props): JSX.Element => {
  if (!vehicleId) {
    closeModal();
    return <></>;
  }

  const setMessage = useSnackbar();

  const [manuallyChecked, setManuallyChecked] = useState<boolean>(false);
  const [
    evidenceSelected,
    setEvidenceSelected,
  ] = useState<SubscriberMediaDTO | null>(null);
  const [documents, setDocuments] = useState<Set<SubscriberMediaDTO>>(
    new Set()
  );

  let vehicle: SubscriberVehicle | undefined;
  let vehicleFound = false;
  let vehicleType = '';
  if (subscriber.personalProfile) {
    const pos = subscriber.personalProfile.vehicles
      .map(v => v.id)
      .indexOf(vehicleId);
    if (pos !== -1) {
      vehicleType = vehiclePatchEndpoint.perso;
      vehicle = subscriber.personalProfile.vehicles[pos];
      vehicleFound = true;
    }
  }
  if (subscriber.professionalProfile && !vehicleFound) {
    const pos = subscriber.professionalProfile.vehicles
      .map(v => v.id)
      .indexOf(vehicleId);
    if (pos !== -1) {
      vehicleType = vehiclePatchEndpoint.pro;
      vehicle = subscriber.professionalProfile.vehicles[pos];
      vehicleFound = true;
    }
  }

  const reset = () => {
    // reset all values if the agent wants to update another vehicle
    setManuallyChecked(false);
    setEvidenceSelected(null);
    documentsUploaded.length = 0;
    setDocuments(new Set());
    closeModal();
  };

  const onChangeFile = async (file: File) => {
    const directUploadDTO = await getEvidenceDirectUploadLink(
      subscriber.subscriberId,
      file.type
    );

    void uploadFileV2(
      directUploadDTO.signedUploadUrl,
      file,
      watermarkText,
      undefined,
      _err => {
        setMessage(_tg('commons.error'));
      }
    )
      .then(_ => {
        const document: SubscriberMediaDTO = {
          id: uuidv4(),
          name: file.name,
          url: directUploadDTO.fileUrn,
          dateCreated: new Date().toISOString(),
        };
        documentsUploaded.push(document);
        setDocuments(new Set([...documentsUploaded, document]));
      })

      .catch(err =>
        setMessage((err as Error)?.message || _tg('commons.error'))
      );
  };

  const onSelectExistingEvidence = (_e: never, _idx: never, value: string) => {
    const document = subscriber.evidences.find(e => e.id === value);
    if (document) {
      setEvidenceSelected(document);
      documentsUploaded.push(document);
      setDocuments(new Set([...documentsUploaded, document]));
    }
  };

  const updateVehicle = async () => {
    if ((documents.size > 0 || manuallyChecked) && vehicle) {
      const patchObject = [
        {
          path: vehicleType,
          op: 'replace',
          value: {
            ...vehicle,
            documents: vehicle?.registrationDocuments?.documents?.length
              ? [...documents, ...vehicle.registrationDocuments.documents]
              : [...documents],
            manuallyChecked,
          } as SubscriberVehicleUploadDTO,
        },
      ];
      const updatedSubscriber = await patchSubscriber(
        subscriber.subscriberId,
        patchObject
      );
      updateSubscriber(updatedSubscriber);
      reset();
    }
  };

  const actions = [
    <BoButton
      secondary
      key="remove"
      label={_t('removeProof')}
      disabled={isVehicleUsed}
      onClick={(): void => {
        closeModal();
        setOpenSecondConfirmation(true);
      }}
      className="action-buttons--margin"
    />,
    <BoButton
      key="cancel"
      label={_tg('action.cancel')}
      onClick={reset}
      className="action-buttons--margin"
    />,
    <BoButton
      primary
      key="confirm"
      label={_tg('action.add')}
      onClick={updateVehicle}
      className="action-buttons--margin"
      disabled={documents.size === 0 && !manuallyChecked}
    />,
  ];

  if (!vehicleFound) {
    closeModal();
    return <></>;
  }

  return (
    <Dialog title={_t('title')} open actions={actions}>
      <Flex flexDirection="column">
        <Files
          label={_tg('action.addUpToXFiles', {
            X: 4,
          })}
          fileIcon="/static/vehicle-card.svg"
          closeIcon="/static/close.svg"
          withDropzone={false}
          maxSize={2000000}
          maxFiles={4}
          onChange={onChangeFile}
          onError={err => setMessage(err)}
          onRemoveFile={file => {
            const newDocuments = Array.from(documents).filter(
              document => document.name !== file.name
            );
            documentsUploaded.length = 0;
            documentsUploaded.concat(newDocuments);
            setDocuments(new Set(newDocuments));
          }}
          disabled={manuallyChecked}
        />
        {subscriber.evidences.length > 0 ? (
          <SelectField
            id="evidences"
            floatingLabelText={_t('existingEvidenceLabel')}
            onChange={onSelectExistingEvidence}
            value={evidenceSelected?.id}
            disabled={manuallyChecked}
            style={{ width: '45%' }}
          >
            {subscriber.evidences.map(evidence => (
              <MenuItem
                id={evidence.id}
                key={evidence.id}
                value={evidence.id}
                primaryText={evidence.name}
              />
            ))}
          </SelectField>
        ) : (
          <></>
        )}
        <Checkbox
          label={_tg('field.manuallyChecked')}
          onCheck={(_, checked) => setManuallyChecked(checked)}
        />
      </Flex>
    </Dialog>
  );
};

function mapStateToProps(state: InternalApiState) {
  const { watermarkText } = getConfigState(state);
  return {
    watermarkText,
  };
}

export default connect(mapStateToProps)(SubscriberUpdateVehicle);
