import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { SubscribersSearchResultDTO, ZoneDTO } from '@cvfm-front/tefps-types';
import { CheckboxOption } from 'commons/SidebarV2/types';
import Sidebar from 'commons/SidebarV2';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import HitsCounter from 'commons/SidebarV2/Components/HitsCounter';
import Checkboxes from 'commons/SidebarV2/Components/Checkboxes';
import Input from 'commons/SidebarV2/Components/Input';
import Select, { SelectOption } from 'commons/SidebarV2/Components/Select';
import { SubscribersSearchCriteria } from 'tefps/Beneficiaries/Subscribers/types';
import { InternalApiState } from 'api/duck';
import { getConfigState } from 'config/duck';
import SimpleDate from 'commons/SidebarV2/Components/SimpleDate';
import Dates from 'commons/SidebarV2/Components/Dates';

import SubscriberFilterService from './SubscriberFilterService';

const { _t, _tg } = window.loadTranslations(__filename);

type SubscribersTableSideBarProps = {
  zones: Array<ZoneDTO>;
  searchResults?: SubscribersSearchResultDTO;
  defaultFilters: SubscribersSearchCriteria;
};

const translateProccessStatus = (): Array<SelectOption> => [
  { key: 'DONE', label: _t('proccessStatus.DONE') },
  {
    key: 'NOT_FOUND',
    label: _t('proccessStatus.NOT_FOUND'),
  },
  { key: 'ERROR', label: _t('proccessStatus.ERROR') },
  { key: 'PENDING', label: _t('proccessStatus.PENDING') },
];

type CityConfigReduxProps = {
  adV3Enabled: boolean;
};
const SubscribersTableSideBar = ({
  zones,
  searchResults,
  defaultFilters,
  adV3Enabled,
}: SubscribersTableSideBarProps & CityConfigReduxProps): JSX.Element => {
  const [zoneItems, setZoneItems] = useState<Array<CheckboxOption>>([]);
  const [filters, setFilters] = useState<SubscribersSearchCriteria>(
    SubscriberFilterService.getFilters()
  );

  const proccessStatus = translateProccessStatus();
  const accountTypeOptions = Array<CheckboxOption>(
    { value: 'PERSONAL', label: _t('accountType.personal') },
    {
      value: 'PROFESSIONAL',
      label: _t('accountType.professional'),
    }
  );

  useMemo(() => {
    const items = zones.map(zone => ({
      value: zone.id,
      label: zone.name,
    }));
    setZoneItems(items);
  }, [zones]);

  const onChange = (id: string, value: unknown) => {
    SubscriberFilterService.setFilters(
      (prevFilters: SubscribersSearchCriteria) => {
        return {
          ...prevFilters,
          [id]: filters[id] === value ? undefined : value,
        };
      }
    );
  };

  function resetFilters() {
    SubscriberFilterService.setFilters(() => {
      return defaultFilters;
    });
  }

  useEffect(() => {
    return SubscriberFilterService.watchFilters(setFilters);
  }, []);

  return (
    <Sidebar>
      <BarTitle resetFilters={resetFilters} />
      <HitsCounter hits={searchResults?.totalHits || 0} />
      <Input
        id="subscriberId"
        title={_tg('field.id')}
        placeholder=""
        onChange={onChange}
        value={filters.subscriberId || ''}
      />
      <Input
        id="lastName"
        title={_tg('field.human.lastname_short')}
        placeholder=""
        onChange={onChange}
        value={filters.lastName || ''}
      />
      <Input
        id="firstName"
        title={_tg('field.human.firstname')}
        placeholder=""
        onChange={onChange}
        value={filters.firstName || ''}
      />
      <Input
        id="companyName"
        title={_tg('field.company.name')}
        placeholder=""
        onChange={onChange}
        value={filters.companyName || ''}
      />
      <Input
        id="email"
        title={_tg('field.human.email')}
        placeholder=""
        onChange={onChange}
        value={filters.email || ''}
      />
      <Input
        id="phoneNumber"
        title={_tg('field.human.phoneNumber')}
        placeholder=""
        onChange={onChange}
        value={filters.phoneNumber || ''}
      />
      <Input
        id="streetName"
        title={_t('element.filterStreetName.title')}
        placeholder=""
        onChange={onChange}
        value={filters.streetName || ''}
      />
      <Input
        id="postalCode"
        title={_tg('field.address.postalCode')}
        placeholder=""
        onChange={onChange}
        value={filters.postalCode || ''}
      />
      <Select
        id="zoneResolverStatus"
        title={_t('element.zoneResolverStatus.title')}
        onChange={onChange}
        selected={filters.zoneResolverStatus}
        options={proccessStatus}
      />
      <Select
        id="geocodingStatus"
        title={_t('element.geocodingStatus.title')}
        onChange={onChange}
        selected={filters.geocodingStatus}
        options={proccessStatus}
      />
      <Checkboxes
        id="zoneIds"
        title={_tg('field.address.zone_plural')}
        options={zoneItems}
        filters={filters.zoneIds}
        onChange={onChange}
        faceting={
          searchResults?.checkboxFaceting
            ? searchResults.checkboxFaceting.validityAreas
            : null
        }
      />
      <Input
        id="professionalStreetName"
        title={_tg('field.address.professionalAddress')}
        placeholder=""
        onChange={onChange}
        value={filters.professionalStreetName || ''}
      />
      <Input
        id="professionalPostalCode"
        title={_tg('field.address.professionalPostalCode')}
        placeholder=""
        onChange={onChange}
        value={filters.professionalPostalCode || ''}
      />
      <Select
        id="professionalZoneResolverStatus"
        title={_t('element.professionalZoneResolverStatus.title')}
        onChange={onChange}
        selected={filters.professionalZoneResolverStatus}
        options={proccessStatus}
      />
      <Select
        id="professionalGeocodingStatus"
        title={_t('element.professionalGeocodingStatus.title')}
        onChange={onChange}
        selected={filters.professionalGeocodingStatus}
        options={proccessStatus}
      />
      <Checkboxes
        id="professionalZoneIds"
        title={_tg('field.address.pro_zone_plural')}
        options={zoneItems}
        filters={filters.professionalZoneIds}
        onChange={onChange}
        faceting={
          searchResults?.checkboxFaceting
            ? searchResults.checkboxFaceting.professionalValidityAreas
            : null
        }
      />
      <Dates
        id="creationDateTimeRange"
        title={_tg('field.subscription.subscriberCreationDate')}
        dates={
          filters.creationDateTimeRange ?? {
            from: undefined,
            to: undefined,
          }
        }
        onChange={onChange}
      />
      {adV3Enabled && (
        <Checkboxes
          id="accountTypes"
          title={_t('element.accountType.title')}
          onChange={onChange}
          filters={filters.accountTypes}
          options={accountTypeOptions}
        />
      )}
    </Sidebar>
  );
};
function mapStateToProps(state: InternalApiState): CityConfigReduxProps {
  const { adV3Enabled } = getConfigState(state).subscriptionConfigurationDTO;
  return {
    adV3Enabled,
  };
}

export default connect(mapStateToProps)(SubscribersTableSideBar);
