import React, { useState } from 'react';
import TextField from 'material-ui/TextField';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import { connect } from 'react-redux';
import { MenuItem, SelectField } from 'material-ui';

import { CityOrganizationDTO } from 'api/organizations/types';
import { getConfigState } from 'config/duck';
import { PresetDTO } from 'api/tepv/presetNote/types';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  presets: Array<PresetDTO>;
  organizations: CityOrganizationDTO[] | null | undefined;
  organizationFilterEnabled: boolean;
  addNote: (text: string, organisationId: string | null) => void;
  closeCreationModal: () => void;
};

const CreationModal = ({
  addNote,
  closeCreationModal,
  organizationFilterEnabled,
  organizations,
  presets,
}: Props) => {
  const [creationModalError, setCreationModalError] = useState<string | null>(
    null
  );

  const [text, setText] = useState<string>('');
  const [organizationId, seOrganisationId] = useState<string | null>(
    organizations ? organizations[0].organizationId : null
  );

  const onTextChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    const alreadyExists = presets.some(
      ({ preset }: PresetDTO) => preset.toLowerCase() === value.toLowerCase()
    );

    setText(value);
    setCreationModalError(
      alreadyExists ? _t('feedback.error.alreadyExist') : null
    );
  };

  const onOrganizationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number,
    value: any
  ) => seOrganisationId(value);

  const onAddNote = () => {
    if (organizationFilterEnabled && !organizationId) {
      return;
    }

    addNote(text, organizationId);
  };

  return (
    <Dialog
      title={_t('element.dialogCreate.title')}
      open
      onRequestClose={closeCreationModal}
      actions={[
        <FlatButton
          label={_tg('action.cancel')}
          onClick={closeCreationModal}
        />,
        <FlatButton
          label={_tg('action.create')}
          primary
          onClick={onAddNote}
          disabled={!!creationModalError || !text}
        />,
      ]}
    >
      <TextField
        id="create"
        autoFocus
        onChange={onTextChange}
        placeholder={`${_tg('field.address.place')} *`}
        value={text}
        errorText={creationModalError}
      />
      <br />
      {organizationFilterEnabled && organizations && organizations?.length > 0 && (
        <>
          <SelectField
            data-id="organization"
            floatingLabelText={`${_tg('field.organisation.organisation')} *`}
            value={organizationId}
            onChange={onOrganizationChange}
          >
            {organizations?.map(organization => (
              <MenuItem
                value={organization.organizationId}
                primaryText={organization.name}
              />
            ))}
          </SelectField>
          <br />
        </>
      )}
    </Dialog>
  );
};

export default connect(state => {
  const { organizationFilterEnabled } = getConfigState(state);
  return {
    organizationFilterEnabled,
  };
})(CreationModal);
