import React, { useState } from 'react';
import { Dialog, TextField } from 'material-ui';

import BoButton from 'facade/BoButton';

import './OrderDetailPage.css';

const { _t, _tg } = window.loadTranslations(__filename);

type OrderDetailModalDecisionProps = {
  open: boolean;
  onCancel: () => void;
  onConfirm: (
    password: string | null | undefined,
    confirmPassword: string | null | undefined
  ) => void;
};

const SubscriberDetailModalPassword = ({
  open,
  onCancel,
  onConfirm,
}: OrderDetailModalDecisionProps): JSX.Element => {
  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();

  function onCancelAction() {
    setPassword(undefined);
    setConfirmPassword(undefined);
    onCancel();
  }

  function onConfirmAction() {
    onConfirm(password, confirmPassword);
  }

  function onChangePassword(e: never, text: string) {
    setPassword(text);
  }

  function onChangeConfirmPassword(e: never, text: string) {
    setConfirmPassword(text);
  }

  const actions = [
    <BoButton
      style={{ marginRight: 10 }}
      label={_tg('action.cancel')}
      key="password-cancel"
      onClick={onCancelAction}
    />,
    <BoButton
      style={{ marginRight: 10 }}
      label={_tg('action.confirm')}
      key="password-confirme"
      primary
      onClick={onConfirmAction}
    />,
  ];

  return (
    <Dialog title={_t('title')} open={open} actions={actions}>
      <div className="order-detail_row">
        <span className="order-detail_cell-50">
          <TextField
            fullWidth
            type="password"
            value={password}
            onChange={onChangePassword}
            floatingLabelText={_t('element.newPassword')}
          />
          <TextField
            fullWidth
            type="password"
            value={confirmPassword}
            onChange={onChangeConfirmPassword}
            floatingLabelText={_t('element.confirmPassword')}
          />
        </span>
        <span className="order-detail_cell-50" />
      </div>
    </Dialog>
  );
};

export default SubscriberDetailModalPassword;
