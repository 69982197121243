import * as React from 'react';

import { DataBoxItem } from 'commons/DataBox';
import FakeInputBlock from 'commons/FakeInputBlock';
import { MifVehicleDetail } from 'api/tepv/mif/types';

const { _t, _tg } = window.loadTranslations(__filename);

const VehicleBlock = ({
  vehicleDetails: { brand, model, category, plate, plateCountry },
  enlevementDemande,
}: {
  vehicleDetails: MifVehicleDetail;
  enlevementDemande: boolean;
}) => (
  <DataBoxItem
    style={{
      maxWidth: '100%',
    }}
  >
    <FakeInputBlock title={_tg('field.vehicle.brand')} value={brand} />
    <FakeInputBlock title={_tg('field.vehicle.model')} value={model} />
    <FakeInputBlock title={_tg('field.vehicle.category')} value={category} />
    {plate && plateCountry && (
      <FakeInputBlock
        title={_tg('field.vehicle.license')}
        value={`${plate} [${plateCountry}]`}
      />
    )}
    {enlevementDemande && (
      <FakeInputBlock title={_t('element.fieldPoundRequest.title')} />
    )}
  </DataBoxItem>
);

export default VehicleBlock;
