import moment from 'moment';

import { ApiAction, LOGOUT } from 'api/duck';
import { SortParameters } from 'api/commonTypes';

import { MifFilters } from './types';

const SET_FILTERS = 'Mif/List/SET_FILTERS';
const SET_SORT_PARAMETERS = 'Mif/List/SET_SORT_PARAMETERS';

type State = {
  filters: MifFilters;
  sort: SortParameters;
};

type SetFiltersAction = {
  type: typeof SET_FILTERS;
  filters?: MifFilters;
};

type SortParametersAction = {
  type: typeof SET_SORT_PARAMETERS;
  sort?: SortParameters;
};

type Action = SetFiltersAction | SortParametersAction | ApiAction;

export const initialFilters = (): MifFilters => {
  return {
    natinfText: '',
    cas: [],
    groupIds: [],
    presetNotes: [],
    infractionLocations: [],
    infractionDate: {
      from: moment()
        .subtract(3, 'months')
        .startOf('day')
        .toDate(),
      to: undefined,
    },
    infractionTime: {
      from: undefined,
      to: undefined,
    },
    address: '',
    vehicleRemovalStatus: new Set(),
    saisineStatus: new Set(),
    photosStatus: new Set(),
    antaiStatus: new Set(),
    agentSearchText: '',
    organizationIds: [],
    profiles: [],
    vehicleCategory: [],
    immatriculationFulltext: '',
    zoneIds: new Set(),
    mifId: '',
    postalCode: '',
  };
};

const initialState = (): State => ({
  filters: initialFilters(),
  sort: {
    sortField: 1,
    increasingOrder: false,
  },
});

export default function mifListReducer(
  state: State = initialState(),
  action: Action
): State {
  switch (action.type) {
    case SET_SORT_PARAMETERS:
      return {
        ...state,
        sort: action.sort as SortParameters,
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: action.filters as MifFilters,
      };
    case LOGOUT:
      return initialState();
    default:
      return state;
  }
}

export function getMifFiltersState(state: any): State {
  return state.mifList;
}

export function setFilters(filters: MifFilters): any {
  return {
    type: SET_FILTERS,
    filters,
  };
}

export function setSortParameters(sort: SortParameters): any {
  return {
    type: SET_SORT_PARAMETERS,
    sort,
  };
}
