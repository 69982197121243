import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CircularProgress, FlatButton } from 'material-ui';

import {
  OrderEsPrivateDTO,
  OrderEsSearchQueryDTO,
  OrderStatus,
  ProductType,
  Proof,
  SubscriberCreationOrUpdateDTO,
  SubscriberDTO,
  SubscriptionConfigurationDTO,
  ZoneDTO,
} from '@cvfm-front/tefps-types';
import { ItemIdName } from 'api/commonTypes';
import FlexCenter from 'commons/FlexCenter';
import ErrorBlock from 'commons/ErrorBlock';
import ConfirmAction from 'commons/ConfirmAction';
import Content from 'commons/Content';
import {
  deleteSubscribers,
  forgotPassword,
  getSubscriber,
  sendSignEmail,
  upsertSubscriber,
  getVehiclesUsed,
} from 'api/cvfm-core-subscription/subscriber';
import { searchEsOrderPaged } from 'api/cvfm-core-subscription/order';
import { getApiState } from 'api/duck';
import { getConfigState } from 'config/duck';
import UpdateSubscriber from 'tefps/Beneficiaries/Subscribers/UpdateSubscriber';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import { fetchZoning } from 'api/pricing';
import { FETCH_LIGHT_ZONING_CONFIG } from 'commons/FetchZoningConfigs';
import { modifyPassword } from 'api/subscribers';
import { searchProducts } from 'api/cvfm-core-subscription/product';

import OrderFilterService from './OrderFilterService';
import SubscriberDetail from './SubscriberDetail';
import './OrderDetailPage.css';
import SubscriberDetailModalPassword from './SubscriberDetailModalPassword';

import { NotificationService } from '@cvfm-front/commons-services';

import SubscriberUpdateVehicle from './SubscriberUpdateVehicle';

import translateError from 'commons/Utils/translateErrorUtil';

import SubscriberDeleteVehicle from './SubscriberDeleteVehicle';
import SubscriberDeleteProof from './SubscriberDeleteProof';
import SubscriberDeleteVehicleProof from './SubscriberDeleteVehicleProof';
import SubscriberRenameVehicle from './SubscriberRenameVehicle';

import Watchings from 'commons/Watchings';

const { _t } = window.loadTranslations(__filename);

const OrderStatusNotRejected = [
  OrderStatus.AWAIT_COMPLETION_USER,
  OrderStatus.AWAIT_DECISION,
  OrderStatus.AWAIT_EVIDENCE,
  OrderStatus.AWAIT_PAYMENT,
  OrderStatus.AWAIT_PLATE_DECISION,
  OrderStatus.AWAIT_TREATMENT_COMPLETUDE,
  OrderStatus.AWAIT_TREATMENT_RENEWAL,
  OrderStatus.FULFILLED,
];

type SubscriberDetailPageReduxProps = {
  canEdit: boolean;
  smsEnabled: boolean;
  hasPmr: boolean;
  subscriptionConfigurationDTO: SubscriptionConfigurationDTO;
};

type SubscriberDetailPageProps = SubscriberDetailPageReduxProps &
  RouteComponentProps<{ subscriberId: string }>;

const SubscriberDetailPage = ({
  canEdit,
  smsEnabled,
  match,
  history,
  hasPmr,
  subscriptionConfigurationDTO,
}: SubscriberDetailPageProps): JSX.Element => {
  const { subscriberId } = match.params;
  const [loading, setLoading] = useState<boolean>(true);
  const [editing, setEditing] = useState<boolean>(false);
  const [updateVehicle, setUpdateVehicle] = useState<string | null>(null);
  const [openUpdateVehicle, setOpenUpdateVehicle] = useState<boolean>(false);
  const [openRenameVehicle, setOpenRenameVehicle] = useState<boolean>(false);
  const [openDeleteVehicle, setOpenDeleteVehicle] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [modifyingPassword, setModifyingPassword] = useState<boolean>(false);
  const [error, setError] = useState<Error>();
  const [feedback, setFeedback] = useState<string>();
  const [subscriber, setSubscriber] = useState<SubscriberDTO>();
  const [productItems, setProductItems] = useState<Array<ItemIdName>>([]);
  const [activeOrders, setActiveOrders] = useState<Array<OrderEsPrivateDTO>>(
    []
  );
  const [zones, setZones] = useState<Array<ZoneDTO>>();
  const displaySnackbar = useSnackbar();
  const [hasOrders, setHasOrders] = useState<boolean>(true);
  const [proofToDelete, setProofToDelete] = useState<Proof | null>(null);
  const [
    openSecondConfirmationDeleteProofVehicle,
    setOpenSecondConfirmationDeleteProofVehicle,
  ] = useState<boolean>(false);
  const [isVehicleUsedMap, setIsVehicleUsedMap] = useState<
    Map<string, boolean>
  >(new Map());

  function loadSubscriberSilently() {
    if (subscriberId) {
      getSubscriber(subscriberId, true)
        .then(fetchedSubscriber => {
          setSubscriber(fetchedSubscriber);
        })
        .catch(err => {
          setError(err);
        });
    }
  }

  function loadSubscriber() {
    if (subscriberId) {
      setLoading(true);
      getSubscriber(subscriberId, true)
        .then(fetchedSubscriber => {
          setSubscriber(fetchedSubscriber);
        })
        .catch(err => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  function loadOrders() {
    if (subscriberId) {
      const searchOrders = {
        query: {
          orderStatuses: OrderStatusNotRejected,
          productType: [ProductType.BUNDLE, ProductType.ELIGIBILITY],
          strictSubscriberIds: [subscriberId],
          validityEnd: { from: new Date() },
        },
        page: 0,
        maxRecords: 5000,
      };
      void searchEsOrderPaged(searchOrders).then(result => {
        setHasOrders(result.results.length > 0);
        setActiveOrders(
          result.results.filter(
            result =>
              result.orderStatus === OrderStatus.FULFILLED &&
              result.productType === ProductType.ELIGIBILITY &&
              new Date(result.startOfValidity) < new Date()
          )
        );
      });
    }
  }

  function loadZoning(): void {
    fetchZoning(FETCH_LIGHT_ZONING_CONFIG)
      .then(fetchedZones => {
        setZones(fetchedZones.zones);
      })
      .catch(err => {
        setError(err as Error);
      });
  }

  function updateSubscriber(
    updateSubscriberId: string,
    formValue: SubscriberCreationOrUpdateDTO
  ): Promise<void> {
    return upsertSubscriber(updateSubscriberId, formValue)
      .then(() => {
        setFeedback(_t('feedback.success.accountUpdated'));
        loadSubscriber();
      })
      .catch(err => {
        if (err.name === 'SubscriberConflict') {
          setEditing(false);
          NotificationService.pushNotification({
            id: 'email-conflict',
            type: 'error',
            message: _t('feedback.error.emailConflict'),
          });
        } else {
          setError(err as Error);
        }
      })
      .finally(() => {
        setEditing(false);
      });
  }

  function handleClose() {
    history.goBack();
  }

  const handleDeleteSubscriber = () => {
    if (!subscriber) {
      return;
    }
    deleteSubscribers([subscriber.subscriberId])
      .then(() => {
        setFeedback(_t('feedback.success.deleted'));
      })
      .catch(err => {
        const message = err?.json?.error
          ? translateError(err?.json?.error)
          : _t('feedback.error.deleteAccount');
        setFeedback(message);
      })
      .finally(() => {
        handleClose();
      });
  };

  const handleSendSignEmail = async (signSubscriberId: string) => {
    try {
      await sendSignEmail(signSubscriberId);
      setFeedback(_t('feedback.success.emailSent'));
    } catch (err) {
      setError(err as Error);
    }
  };

  const handleForgotPassword = async (email: string): Promise<void> => {
    try {
      await forgotPassword(email);
      setFeedback(_t('feedback.success.emailSent'));
    } catch (err) {
      setError(err as Error);
    }
  };

  const toggleModifyPassword = () => {
    setModifyingPassword(!modifyingPassword);
  };

  const toggleDeleteSubscriber = () => {
    setDeleting(!deleting);
  };

  const updateSubscriberFilter = () => {
    OrderFilterService.setFilters((prevFilters: OrderEsSearchQueryDTO) => {
      return {
        strictSubscriberIds: [subscriberId],
        subscriberIds: [subscriberId],
      };
    });
  };

  const handleModifyPassword = async (
    password: string,
    confirmPassword: string
  ) => {
    if (!subscriber) {
      return;
    }

    if (password === confirmPassword) {
      try {
        await modifyPassword(subscriber.email, password);
        setFeedback(_t('feedback.success.passwordUpdated'));
        toggleModifyPassword();
      } catch (err) {
        setError(err as Error);
      }
    } else {
      setFeedback(_t('feedback.error.matchPassword'));
    }
  };
  // Load eligibility products for history
  // Load subscriber
  // Load active orders
  // Load zoning
  useEffect(() => {
    loadSubscriber();
    loadOrders();
    loadZoning();

    searchProducts({
      productType: ProductType.ELIGIBILITY,
      activeOnly: false,
    })
      .then(availableProducts => {
        const items = availableProducts.map(p => ({
          id: p.productId,
          name: p.name,
        }));
        setProductItems(items);
      })
      .catch(err => {
        setError(err as Error);
      });
  }, [subscriberId]);

  // Load vehicles used or not
  useEffect(() => {
    void (async () => {
      if (subscriber && subscriptionConfigurationDTO.adV3Enabled) {
        const isVehicleUsedObject = await getVehiclesUsed(
          subscriber.subscriberId
        );
        setIsVehicleUsedMap(new Map(Object.entries(isVehicleUsedObject)));
      }
    })();
  }, [subscriber]);

  useEffect(() => {
    if (error) {
      displaySnackbar(error.message);
    }
    if (feedback) {
      displaySnackbar(feedback);
    }
  }, [error, feedback]);

  return (
    <Content>
      <div className="order-detail-page_header">
        <FlatButton href="#/subscription/subscribers" label={_t('back')} />
      </div>

      <div className="order-detail-page_container">
        {loading && (
          <FlexCenter>
            <CircularProgress />
          </FlexCenter>
        )}
        {error && (
          <FlexCenter>
            <ErrorBlock error={error} />
          </FlexCenter>
        )}

        {!loading && !editing && subscriber && zones && !error && (
          <SubscriberDetail
            subscriber={subscriber}
            isVehicleUsedMap={isVehicleUsedMap}
            productItems={productItems}
            activeOrders={activeOrders}
            hasOrders={hasOrders}
            smsEnabled={smsEnabled}
            handleSendSignEmail={handleSendSignEmail}
            zones={zones}
            accessToEligibilityList={() => {
              updateSubscriberFilter();
              history.push('/subscription/eligibility/eligibilities');
            }}
            accessToEligibilityOrderList={() => {
              updateSubscriberFilter();
              history.push('/subscription/eligibility/order');
            }}
            accessToPassedEligibilityOrderList={() => {
              updateSubscriberFilter();
              history.push('/subscription/eligibility/order?passed');
            }}
            accessToBundleOrdersList={() => {
              updateSubscriberFilter();
              history.push('/subscription/bundle/order');
            }}
            openAddEligibity={() => {
              history.push(
                `/subscription/create/order/eligibility?subscriberId=${subscriberId}`
              );
            }}
            openAddBundle={() => {
              history.push(
                `/subscription/create/order/bundle?subscriberId=${subscriberId}`
              );
            }}
            openUpdateSubscriber={() => {
              setEditing(true);
            }}
            setVehicleToUpdate={(vehicleId: string | null) =>
              setUpdateVehicle(vehicleId)
            }
            setOpenUpdateVehicle={(open: boolean) => setOpenUpdateVehicle(open)}
            setOpenRenameVehicle={(open: boolean) => setOpenRenameVehicle(open)}
            setOpenDeleteVehicle={(open: boolean) => setOpenDeleteVehicle(open)}
            loadSubscriberSilently={loadSubscriberSilently}
            handleForgotPassword={handleForgotPassword}
            openDeleteSubscriber={toggleDeleteSubscriber}
            setSubscriber={setSubscriber}
            setProofToDelete={setProofToDelete}
            canEdit={canEdit}
            hasPmr={hasPmr}
            adV3Enabled={subscriptionConfigurationDTO.adV3Enabled}
          />
        )}

        {!loading && subscriber && editing && (
          <UpdateSubscriber
            updateSubscriber={updateSubscriber}
            subscriber={subscriber}
            closeModal={() => {
              setEditing(false);
            }}
          />
        )}

        {!loading && subscriber && modifyingPassword && (
          <SubscriberDetailModalPassword
            open
            onCancel={toggleModifyPassword}
            onConfirm={handleModifyPassword}
          />
        )}
        {!loading &&
          subscriptionConfigurationDTO.adV3Enabled &&
          subscriber &&
          !!updateVehicle &&
          openRenameVehicle && (
            <SubscriberRenameVehicle
              subscriber={subscriber}
              updateSubscriber={setSubscriber}
              vehicleId={updateVehicle}
              closeModal={() => setOpenRenameVehicle(false)}
            />
          )}
        {!loading &&
          subscriptionConfigurationDTO.adV3Enabled &&
          subscriber &&
          !!updateVehicle &&
          openUpdateVehicle && (
            <SubscriberUpdateVehicle
              subscriber={subscriber}
              updateSubscriber={setSubscriber}
              vehicleId={updateVehicle}
              isVehicleUsed={isVehicleUsedMap.get(updateVehicle) ?? false}
              closeModal={() => setOpenUpdateVehicle(false)}
              setOpenSecondConfirmation={
                setOpenSecondConfirmationDeleteProofVehicle
              }
            />
          )}
        {!loading &&
          subscriptionConfigurationDTO.adV3Enabled &&
          subscriber &&
          !!updateVehicle &&
          openDeleteVehicle && (
            <SubscriberDeleteVehicle
              subscriber={subscriber}
              updateSubscriber={setSubscriber}
              vehicleId={updateVehicle}
              closeModal={() => setOpenDeleteVehicle(false)}
            />
          )}
        {!loading &&
          subscriptionConfigurationDTO.adV3Enabled &&
          subscriber &&
          updateVehicle &&
          openSecondConfirmationDeleteProofVehicle && (
            <SubscriberDeleteVehicleProof
              subscriber={subscriber}
              setSubscriber={setSubscriber}
              vehicleId={updateVehicle}
              setOpenSecondConfirmation={
                setOpenSecondConfirmationDeleteProofVehicle
              }
            />
          )}
        {!loading &&
          subscriptionConfigurationDTO.adV3Enabled &&
          subscriber &&
          proofToDelete && (
            <SubscriberDeleteProof
              subscriber={subscriber}
              updateSubscriber={setSubscriber}
              proof={proofToDelete}
              closeModal={() => setProofToDelete(null)}
            />
          )}

        <ConfirmAction
          enabled
          action={handleDeleteSubscriber}
          message={_t('feedback.warning.deleteAccountConfirmation')}
          onClose={toggleDeleteSubscriber}
          isOpen={deleting}
        />
      </div>
      <Watchings resourceType="AD" resourceId={subscriberId} />
    </Content>
  );
};

function mapStateToProps(state: unknown): SubscriberDetailPageReduxProps {
  const { userInfo } = getApiState(state);
  const {
    smsEnabled,
    frontPmrUrl,
    subscriptionConfigurationDTO,
  } = getConfigState(state);
  return {
    canEdit: !!userInfo && userInfo.rights.includes('SUBSCRIBER_WRITE'),
    smsEnabled,
    hasPmr: !!frontPmrUrl,
    subscriptionConfigurationDTO,
  };
}

export default withRouter(connect(mapStateToProps)(SubscriberDetailPage));
