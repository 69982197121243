import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import Sidebar from 'commons/SidebarV2';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import HitsCounter from 'commons/SidebarV2/Components/HitsCounter';
import Checkboxes from 'commons/SidebarV2/Components/Checkboxes';
import Dates from 'commons/SidebarV2/Components/Dates';
import DurationRange from 'commons/SidebarV2/Components/DurationRange';
import Times from 'commons/SidebarV2/Components/Times';
import Select from 'commons/SidebarV2/Components/Select';
import Input from 'commons/SidebarV2/Components/Input';
import PriceRange from 'commons/SidebarV2/Components/PriceRange';
import { buildZoneSelect } from 'commons/Utils/zoneUtils';
import { buildClientAppOptions } from 'commons/Utils/clientAppUtils';
import { fetchClientAppTV } from 'api/clientapp';
import { KeyBucketDTO } from 'api/commonTypes';
import { translateVehicleTypeFilterOptions } from 'commons/Utils/vehicleTypeUtils';
import { AuthorizedVehicleCategory, getConfigState } from 'config/duck';
import useZoning from 'commons/hooks/useZoning';
import services from 'commons/services';
import useWatcher from 'commons/hooks/useWatcher';

import { initialFilters } from './utils';

const { _tg } = window.loadTranslations(__filename);

type TicketFiltersProps = {
  totalHits: number;
  authorizedVehicleCategories: Array<AuthorizedVehicleCategory>;
  facetings:
    | {
        [key: string]: Array<KeyBucketDTO>;
      }
    | null
    | undefined;
};

const TicketFilters = (props: TicketFiltersProps) => {
  const filters = useWatcher(
    services.ticketsList.watchFilters,
    services.ticketsList.getFilters()
  );

  const zoning = useZoning();

  // Buid memo zone options
  const zoneOptions = useMemo(() => {
    if (!zoning) return [];
    return buildZoneSelect(zoning.zones);
  }, [zoning]);

  const [clientAppOptions, setClientAppOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const onChange = (id: string, value: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    void services.ticketsList.updateFilters({ ...filters, [id]: value });
  };

  const fetchClientAppAndBuildOptions = async () => {
    try {
      const clientApp = await fetchClientAppTV();
      setClientAppOptions(buildClientAppOptions(clientApp));
    } catch (e) {
      setClientAppOptions([]);
    }
  };

  /**
   * Effect sur le mount, on charge et biuld les options client apps
   */
  useEffect(() => {
    void fetchClientAppAndBuildOptions();
    void services.zoning.init();
  }, []);

  const resetFilters = () =>
    services.ticketsList.updateFilters(initialFilters());

  const { totalHits, facetings, authorizedVehicleCategories } = props;

  return (
    <Sidebar>
      <BarTitle resetFilters={resetFilters} />
      <HitsCounter hits={totalHits} />
      <Input
        id="plate"
        title={_tg('field.vehicle.licensePlate')}
        placeholder={_tg('commons.plateFilter')}
        onChange={onChange}
        value={filters.plate || ''}
      />
      {authorizedVehicleCategories.length > 0 && (
        <Checkboxes
          id="vehicleCategories"
          title={_tg('field.vehicle.category')}
          options={translateVehicleTypeFilterOptions(
            authorizedVehicleCategories
          )}
          filters={filters.vehicleCategories}
          onChange={onChange}
          faceting={facetings ? facetings.vehicleCategories : null}
        />
      )}
      <Checkboxes
        id="clientAppIds"
        title={_tg('field.organisation.supplier')}
        options={clientAppOptions}
        filters={filters.clientAppIds}
        onChange={onChange}
        faceting={facetings ? facetings.clientAppIds : null}
      />
      <PriceRange
        id="price"
        title={_tg('field.payment.amount')}
        price={filters.price}
        onChange={onChange}
      />
      <DurationRange
        id="duration"
        title={_tg('field.date.duration')}
        durations={filters.duration}
        onChange={onChange}
      />
      <Dates
        id="creationDate"
        title={_tg('field.date.creation')}
        dates={filters.creationDate}
        onChange={onChange}
      />
      <Times
        id="creationTime"
        title={_tg('field.date.creationTime')}
        times={filters.creationTime}
        onChange={onChange}
      />
      <Dates
        id="startDate"
        title={_tg('field.date.dateOfValidityStart')}
        dates={filters.startDate}
        onChange={onChange}
      />
      <Times
        id="startTime"
        title={_tg('field.date.timeOfValidityStart')}
        times={filters.startTime}
        onChange={onChange}
      />
      <Dates
        id="endDate"
        title={_tg('field.date.dateOfValidityEnd')}
        dates={filters.endDate}
        onChange={onChange}
      />
      <Times
        id="endTime"
        title={_tg('field.date.timeOfValidityEnd')}
        times={filters.endTime}
        onChange={onChange}
      />
      <Select
        id="zoneId"
        title={_tg('field.address.zone')}
        onChange={onChange}
        selected={filters.zoneId}
        options={zoneOptions}
      />
      <Input
        id="ticketId"
        title={_tg('field.ticket.ticketNumber')}
        placeholder={_tg('commons.ticketFilter')}
        onChange={onChange}
        value={filters.ticketId || ''}
      />
    </Sidebar>
  );
};

export default connect(state => {
  const { authorizedVehicleCategories } = getConfigState(state);
  return {
    authorizedVehicleCategories,
  };
})(TicketFilters);
