import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { History } from 'history';

import { BKG_CYAN, BKG_PINK, TXT_BLACK } from 'theme';
import { NotificationType } from 'api/tasks/types';
import { CcspTaskItem } from 'api/ccsp/types';
import { CcspSearchCriterias } from 'tefps/CcspRecourses/List/types';
import Separator from 'commons/Separator';
import {
  DataBox,
  DataBoxContent,
  DataBoxHeader,
  DataBoxItemWrapper,
} from 'commons/DataBox';

import TaskData from './components/TaskData';
import TaskButtons from './components/TaskButtons';
import services from '../../commons/services';

const { _tg } = window.loadTranslations(__filename);

type CcspRecourseTaskProps = {
  title: string;
  label: string;
  history: History;
  userNotif: Array<NotificationType>;
  data: CcspTaskItem;
  notifications: Array<NotificationType>;
  filters: CcspSearchCriterias;
  onChangeNotif: () => void;
} & RouteComponentProps;

const CcspRecourseTask = (props: CcspRecourseTaskProps) => {
  const redirect = () => {
    const { history, filters } = props;
    void services.ccspList.updateFilters(filters);
    history.push('/tsp/list');
  };

  const { data, notifications, title, label, userNotif, onChangeNotif } = props;

  return (
    <DataBox panel style={{ marginBottom: 40 }}>
      <DataBoxHeader color={BKG_CYAN}>
        <div style={{ fontWeight: 'bold' }}>{title}</div>
      </DataBoxHeader>
      <DataBoxContent>
        <DataBoxItemWrapper>
          <TaskData
            width="25%"
            value={data.waitingNumber}
            text={label}
            color={TXT_BLACK}
          />
          <Separator />
          <TaskData
            width="25%"
            value={data.waitingNumberTenDays}
            text={`${label} ${_tg('tefps.tasks.forTenDays')}`}
            color={BKG_PINK}
          />
          <Separator />
          <TaskButtons
            userNotif={userNotif}
            notifications={notifications}
            redirect={redirect}
            onSave={onChangeNotif}
          />
        </DataBoxItemWrapper>
      </DataBoxContent>
    </DataBox>
  );
};

export default withRouter(CcspRecourseTask);
