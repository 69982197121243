import { Dispatch } from 'redux';

import { LOGOUT } from 'api/duck';
import { detectTokenAlert } from 'api/devices';
import { LicenseTokenAlertDTO } from 'api/devices/types';
import { AgentRight } from '@cvfm-front/tefps-types';

export const RECEIVE_TOKEN_ALERT = 'alert/RECEIVE_TOKEN_ALERT';

type Action = {
  type: string;
  tokenAlert?: LicenseTokenAlertDTO | null | undefined;
};

export default function tokenAlertReducer(
  state: LicenseTokenAlertDTO | null | undefined = null,
  action: Action
): LicenseTokenAlertDTO | null | undefined {
  switch (action.type) {
    case RECEIVE_TOKEN_ALERT:
      return action.tokenAlert ? action.tokenAlert : state;
    case LOGOUT:
      return null;
    default:
      return state;
  }
}

export function fetchTokenAlert(rights: Array<AgentRight>): any {
  return async (dispatch: Dispatch<any>): Promise<void> => {
    const canWriteDevices = rights.includes('DIRECTORY_LICENSE_TOKEN_WRITE');
    const tokenAlert = canWriteDevices ? await detectTokenAlert() : null;

    dispatch({
      type: RECEIVE_TOKEN_ALERT,
      tokenAlert,
    });
  };
}

export function getTokenAlertState(
  state: any
): LicenseTokenAlertDTO | null | undefined {
  return state.tokenAlert;
}
