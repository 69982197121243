import React from 'react';
import { connect } from 'react-redux';
import { Snackbar } from 'material-ui';

import Content from 'commons/Content';
import AdvertisingModal from 'commons/AdvertisingModal';
import { BKG_FLASHY } from 'theme';
import { RapoTaskWrapper } from 'api/recourse/types';
import { CcspTaskItem, CcspTaskStats } from 'api/ccsp/types';
import { fetchRecoursesTasks } from 'api/recourse';
import { InternalAgent } from 'api/auth/types';
import { fetchAgentNotifications } from 'api/tasks';
import { NotificationType } from 'api/tasks/types';
import { fetchCcspTasks } from 'api/ccsp';
import { getConfigState } from 'config/duck';
import { getApiState } from 'api/duck';
import { fetchRefundTask } from 'api/refund';
import { fetchOrderTasks } from 'api/cvfm-core-subscription/order';

import { TaskStats, OrderTaskWrapper } from './types';
import RecourseTask from './RecourseTask';
import CcspRecourseTask from './CcspRecourseTask';
import {
  BASE_REFUND_FILTER,
  ccspWaitingEnregistrementFilters,
  ccspWaitingPropositionFilters,
  ccspWaitingValidationFilters,
  orderWaitingClaimDecisionFilters,
  orderWaitingClaimTreatmentCompletudeFilters,
  orderWaitingDecisionFilters,
  orderWaitingPlateDecisionFilters,
  orderWaitingTreatmentCompletudeFilters,
  orderWaitingTreatmentRenewalFilters,
  recourseToValidateFilters,
  recourseTreatmentFilters,
  recourseWaitingFilters,
} from './helpers';
import RefundTask from './RefundTask';
import OrderTask from './OrderTask';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  userInfo: InternalAgent;
  isRapoDisplayed: boolean;
  isSubscribersDisplayed: boolean;
  isCcspDisplayed: boolean;
  fpsRefundEnabled: boolean;
};

type State = {
  userNotif: Array<NotificationType>;
  rapoData: RapoTaskWrapper | null | undefined;
  orderData: OrderTaskWrapper | null | undefined;
  ccspData: CcspTaskStats | null | undefined;
  refundData: TaskStats | null | undefined;
  error: Error | null | undefined;
};

const defaultTask = (): TaskStats => ({
  total: 0,
  statOneWeek: 0,
  statOneDay: 0,
});

const defaultCcspTask = (): CcspTaskItem => ({
  waitingNumber: 0,
  waitingNumberTenDays: 0,
});

class Tasks extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userNotif: [],
      rapoData: null,
      orderData: null,
      ccspData: null,
      refundData: null,
      error: null,
    };
  }

  componentDidMount = () => {
    const {
      userInfo,
      fpsRefundEnabled,
      isSubscribersDisplayed,
      isRapoDisplayed,
      isCcspDisplayed,
    } = this.props;
    void this.fetchUserNotif();
    if (userInfo.rights.includes('RAPO_READ') && isRapoDisplayed) {
      void this.fetchRapoData();
    }
    if (userInfo.rights.includes('ORDER_READ') && isSubscribersDisplayed) {
      void this.fetchOrderData();
    }
    if (userInfo.rights.includes('CCSP_READ') && isCcspDisplayed) {
      void this.fetchCcspData();
    }
    if (userInfo.rights.includes('FPS_REFUND') && fpsRefundEnabled) {
      void this.fetchRefundData();
    }
  };

  fetchUserNotif = async () => {
    try {
      const userNotif = await fetchAgentNotifications();
      this.setState({ userNotif });
    } catch (error) {
      this.setState({ error: error as Error });
    }
  };

  fetchRapoData = async () => {
    try {
      const rapoData = await fetchRecoursesTasks();
      this.setState({ rapoData });
    } catch (error) {
      this.setState({ error: error as Error });
    }
  };

  fetchOrderData = async () => {
    try {
      const data = await fetchOrderTasks();
      this.setState({ orderData: data });
    } catch (error) {
      this.setState({ error: error as Error });
    }
  };

  fetchCcspData = async () => {
    try {
      const data = await fetchCcspTasks();
      this.setState({ ccspData: data });
    } catch (error) {
      this.setState({ error: error as Error });
    }
  };

  fetchRefundData = async () => {
    try {
      const data = await fetchRefundTask();
      this.setState({ refundData: data });
    } catch (error) {
      this.setState({ error: error as Error });
    }
  };

  render() {
    const {
      userInfo,
      isRapoDisplayed,
      isCcspDisplayed,
      fpsRefundEnabled,
      isSubscribersDisplayed,
    } = this.props;
    const {
      rapoData,
      ccspData,
      refundData,
      orderData,
      error,
      userNotif,
    } = this.state;

    return (
      <Content>
        <AdvertisingModal module="tasks" />
        <div
          style={{
            marginTop: 40,
            padding: '0% 5%',
            alignItems: 'center',
          }}
        >
          {!userInfo.email && (
            <p style={{ color: BKG_FLASHY, marginBottom: 20 }}>
              {_tg('tefps.tasks.emailMandatory')}
            </p>
          )}
          {userInfo.rights.includes('RAPO_READ') && isRapoDisplayed && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <RecourseTask
                title={_tg('tefps.tasks.recourseTreatment')}
                userNotif={userNotif}
                onChangeNotif={this.fetchUserNotif}
                data={rapoData ? rapoData.treatment : defaultTask()}
                notifications={[
                  'RECOURSE_TREATMENT',
                  'RECOURSE_TREATMENT_ONE_WEEK',
                  'RECOURSE_TREATMENT_ONE_DAY',
                ]}
                filters={recourseTreatmentFilters()}
              />
              <RecourseTask
                title={_tg('tefps.tasks.recourseWaiting')}
                userNotif={userNotif}
                onChangeNotif={this.fetchUserNotif}
                data={rapoData ? rapoData.waiting : defaultTask()}
                notifications={[
                  'RECOURSE_WAITING',
                  'RECOURSE_WAITING_ONE_WEEK',
                  'RECOURSE_WAITING_ONE_DAY',
                ]}
                filters={recourseWaitingFilters()}
              />
              <RecourseTask
                title={_tg('tefps.tasks.recourseValidation')}
                userNotif={userNotif}
                onChangeNotif={this.fetchUserNotif}
                data={rapoData ? rapoData.validation : defaultTask()}
                notifications={[
                  'RECOURSE_VALIDATION',
                  'RECOURSE_VALIDATION_ONE_WEEK',
                  'RECOURSE_VALIDATION_ONE_DAY',
                ]}
                filters={recourseToValidateFilters()}
              />
            </div>
          )}
          {userInfo.rights.includes('ORDER_READ') && isSubscribersDisplayed && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <OrderTask
                title={_tg('tefps.tasks.orderWaitingDecision')}
                userNotif={userNotif}
                onChangeNotif={this.fetchUserNotif}
                data={orderData ? orderData.AWAIT_DECISION : defaultTask()}
                notifications={[
                  'ORDER_WAITING',
                  'ORDER_WAITING_ONE_DAY',
                  'ORDER_WAITING_FIVE_DAY',
                ]}
                filters={orderWaitingDecisionFilters()}
              />
              <OrderTask
                title={_tg('tefps.tasks.orderWaitingTreatmentRenewal')}
                userNotif={userNotif}
                onChangeNotif={this.fetchUserNotif}
                data={
                  orderData ? orderData.AWAIT_TREATMENT_RENEWAL : defaultTask()
                }
                notifications={[
                  'ORDER_AWAIT_TREATMENT_RENEWAL',
                  'ORDER_AWAIT_TREATMENT_RENEWAL_ONE_DAY',
                  'ORDER_AWAIT_TREATMENT_RENEWAL_FIVE_DAY',
                ]}
                filters={orderWaitingTreatmentRenewalFilters()}
              />
              <OrderTask
                title={_tg('tefps.tasks.orderWaitingTreatmentCompletude')}
                userNotif={userNotif}
                onChangeNotif={this.fetchUserNotif}
                data={
                  orderData
                    ? orderData.AWAIT_TREATMENT_COMPLETUDE
                    : defaultTask()
                }
                notifications={[
                  'ORDER_AWAIT_TREATMENT_COMPLETUDE',
                  'ORDER_AWAIT_TREATMENT_COMPLETUDE_ONE_DAY',
                  'ORDER_AWAIT_TREATMENT_COMPLETUDE_FIVE_DAY',
                ]}
                filters={orderWaitingTreatmentCompletudeFilters()}
              />
              <OrderTask
                title={_tg('tefps.tasks.orderWaitingPlateDecision')}
                userNotif={userNotif}
                onChangeNotif={this.fetchUserNotif}
                data={
                  orderData ? orderData.AWAIT_PLATE_DECISION : defaultTask()
                }
                notifications={[
                  'ORDER_AWAIT_PLATE_DECISION',
                  'ORDER_AWAIT_PLATE_DECISION_ONE_DAY',
                  'ORDER_AWAIT_PLATE_DECISION_FIVE_DAY',
                ]}
                filters={orderWaitingPlateDecisionFilters()}
              />
              <OrderTask
                title={_tg('tefps.tasks.orderWaitingClaimDecision')}
                userNotif={userNotif}
                onChangeNotif={this.fetchUserNotif}
                data={
                  orderData ? orderData.AWAIT_CLAIM_DECISION : defaultTask()
                }
                notifications={[
                  'ORDER_AWAIT_CLAIM_DECISION',
                  'ORDER_AWAIT_CLAIM_DECISION_ONE_DAY',
                  'ORDER_AWAIT_CLAIM_DECISION_FIVE_DAY',
                ]}
                filters={orderWaitingClaimDecisionFilters()}
              />
              <OrderTask
                title={_tg('tefps.tasks.orderWaitingClaimTreatmentCompletude')}
                userNotif={userNotif}
                onChangeNotif={this.fetchUserNotif}
                data={
                  orderData
                    ? orderData.AWAIT_CLAIM_TREATMENT_COMPLETUDE
                    : defaultTask()
                }
                notifications={[
                  'ORDER_AWAIT_CLAIM_TREATMENT_COMPLETUDE',
                  'ORDER_AWAIT_CLAIM_TREATMENT_COMPLETUDE_ONE_DAY',
                  'ORDER_AWAIT_CLAIM_TREATMENT_COMPLETUDE_FIVE_DAY',
                ]}
                filters={orderWaitingClaimTreatmentCompletudeFilters()}
              />
            </div>
          )}
          {userInfo.rights.includes('CCSP_READ') && isCcspDisplayed && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <CcspRecourseTask
                title={_tg('tefps.tasks.ccspWaitingTitle')}
                label={_tg('tefps.tasks.ccspWaitingLabel')}
                userNotif={userNotif}
                onChangeNotif={this.fetchUserNotif}
                data={
                  (ccspData && {
                    waitingNumber: ccspData.enAttenteDeProposition,
                    waitingNumberTenDays:
                      ccspData.enAttenteDePropositionTenDays,
                  }) ||
                  defaultCcspTask()
                }
                notifications={[
                  'CCSP_AWAITING_PROPOSAL',
                  'CCSP_PROPOSITION_TEN_DAY',
                ]}
                filters={ccspWaitingPropositionFilters()}
              />
              <CcspRecourseTask
                title={_tg('tefps.tasks.ccspValidationTitle')}
                label={_tg('tefps.tasks.ccspValidationLabel')}
                userNotif={userNotif}
                onChangeNotif={this.fetchUserNotif}
                data={
                  (ccspData && {
                    waitingNumber: ccspData.enAttenteDeValidation,
                    waitingNumberTenDays: ccspData.enAttenteDeValidationTenDays,
                  }) ||
                  defaultCcspTask()
                }
                notifications={[
                  'CCSP_AWAITING_VALIDATION',
                  'CCSP_VALIDATION_TEN_DAY',
                ]}
                filters={ccspWaitingValidationFilters()}
              />
              <CcspRecourseTask
                title={_tg('tefps.tasks.ccspDecisionTitle')}
                label={_tg('tefps.tasks.ccspDecisionLabel')}
                userNotif={userNotif}
                onChangeNotif={this.fetchUserNotif}
                data={
                  (ccspData && {
                    waitingNumber: ccspData.enAttenteEnregistrement,
                    waitingNumberTenDays:
                      ccspData.enAttenteEnregistrementTenDays,
                  }) ||
                  defaultCcspTask()
                }
                notifications={[
                  'CCSP_AWAITING_DECISION',
                  'CCSP_DECISION_TEN_DAY',
                ]}
                filters={ccspWaitingEnregistrementFilters()}
              />
            </div>
          )}
          {userInfo.rights.includes('FPS_REFUND') && fpsRefundEnabled && (
            <RefundTask
              title={_tg('tefps.tasks.refundWaiting')}
              userNotif={userNotif}
              onChangeNotif={this.fetchUserNotif}
              filters={BASE_REFUND_FILTER}
              data={refundData || defaultTask()}
              notifications={[
                'REFUND_WAITING',
                'REFUND_WAITING_ONE_DAY',
                'REFUND_WAITING_FIVE_DAY',
              ]}
            />
          )}
        </div>

        <Snackbar
          open={error !== null}
          message={error?.message}
          autoHideDuration={4000}
          style={{ pointerEvents: 'none', whiteSpace: 'nowrap' }}
          bodyStyle={{ pointerEvents: 'initial', maxWidth: 'none' }}
        />
      </Content>
    );
  }
}

export default connect(state => {
  const {
    fpsRefundEnabled,
    modulesConfiguration: {
      rapo: { enabled: isRapoDisplayed },
      subscribers: { enabled: isSubscribersDisplayed },
      ccsp: { enabled: isCcspDisplayed },
    },
  } = getConfigState(state);
  const { userInfo } = getApiState(state);
  return {
    userInfo,
    isRapoDisplayed,
    isSubscribersDisplayed,
    isCcspDisplayed,
    fpsRefundEnabled,
  };
})(Tasks);
