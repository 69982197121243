import * as React from 'react';

import { MotifFilters } from 'tepv/Motif/List/types';
import Sidebar from 'commons/SidebarV2';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import Select from 'commons/SidebarV2/Components/Select';
import Dates from 'commons/SidebarV2/Components/Dates';
import Input from 'commons/SidebarV2/Components/Input';
import HitsCounter from 'commons/SidebarV2/Components/HitsCounter';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  filters: MotifFilters;
  filtersData: Set<string>;
  updateFilters: (f: MotifFilters) => void;
  resetFilters: () => void;
  totalHits: number;
};

const MotifSidebar = ({
  filters,
  updateFilters,
  resetFilters,
  filtersData,
  totalHits,
}: Props) => {
  function onChange(id: string, value: any) {
    updateFilters({ ...filters, [id]: value });
  }

  const categoriesOptions = Array.from(filtersData).map(categorie => ({
    key: categorie,
    label: categorie,
  }));

  return (
    <Sidebar>
      <BarTitle resetFilters={resetFilters} />
      <HitsCounter hits={totalHits} />
      <Select
        id="categories"
        title={_t('element.categories')}
        options={categoriesOptions}
        multiple
        onChange={onChange}
        selected={filters.categories}
      />
      <Dates
        id="dateImportPeriod"
        title={_tg('field.date.importDate')}
        dates={filters.dateImportPeriod}
        onChange={onChange}
      />
      <Dates
        id="dateDisablePeriod"
        title={_tg('field.date.disablingDate')}
        dates={filters.dateDisablePeriod}
        onChange={onChange}
      />
      <Input
        id="qualificationSearchText"
        title={_t('element.qualification')}
        placeholder={_t('element.filterByQualification')}
        onChange={onChange}
        value={filters.qualificationSearchText}
      />
    </Sidebar>
  );
};

export default MotifSidebar;
