import * as React from 'react';

import { DataBox, DataBoxContent, DataBoxHeader } from 'commons/DataBox';
import FakeInputBlock from 'commons/FakeInputBlock';
import Date from 'commons/Date';
import { GpvOffenderDetail, GpvOffenderEvidence } from 'api/tepv/gpv/types';
import Separator from 'commons/Separator';
import { BKG_LIGHT_VIOLET } from 'theme';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_COLUMNS_CONTAINER: React.CSSProperties = {
  display: 'flex',
  width: '90%',
  justifyContent: 'space-around',
};

const OffenderBlock = ({
  offender,
  responsibleParty,
}: {
  offender: GpvOffenderDetail;
  responsibleParty: GpvOffenderDetail | undefined;
}) => {
  function buildOffenderRender(target: GpvOffenderDetail) {
    return (
      <div style={STYLE_COLUMNS_CONTAINER}>
        <div style={{ flex: '4 0%' }}>
          <FakeInputBlock
            title={_tg('field.human.gender')}
            value={target.civility}
          />
          {target.lastName && target.firstName && (
            <>
              <FakeInputBlock
                title={_tg('field.human.lastname_short')}
                value={target.lastName}
              />
              <FakeInputBlock
                title={_tg('field.human.firstname')}
                value={target.firstName}
              />
            </>
          )}
          {target.birthAddress && (
            <FakeInputBlock
              title={_tg('field.human.birthPlace')}
              value={target.birthAddress}
            />
          )}
          {target.birthDate && (
            <FakeInputBlock
              title={_tg('field.date.birthDate')}
              value={<Date datetime={target.birthDate} withTime={false} />}
            />
          )}
          <FakeInputBlock
            title={_tg('field.address.residingAddress')}
            value={target.offenderAddress}
          />
        </div>
        <Separator />
        <div style={{ flex: '5 5 0%' }}>
          {target.parent1Name && (
            <FakeInputBlock
              title={_t('element.parent')}
              value={target.parent1Name}
            />
          )}
          {target.parent2Name && (
            <FakeInputBlock
              title={_t('element.parent')}
              value={target.parent2Name}
            />
          )}
          {target.siret && (
            <>
              <FakeInputBlock
                title={_t('element.company')}
                value={target.socialReason}
              />
              <FakeInputBlock
                title={_t('element.siret')}
                value={target.siret}
              />
            </>
          )}
          <FakeInputBlock
            title={_t('element.signDate')}
            value={<Date datetime={target.signatureDate} />}
          />
          {target.evidences.length > 0 && (
            <>
              <SeparatorWithTitle title={_t('element.evidence_plural')} />
              {target.evidences.map((evidence: GpvOffenderEvidence) => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                  }}
                >
                  <FakeInputBlock
                    title={_t('element.nature')}
                    value={evidence.nature}
                    containerStyle={{ flex: '2', marginRight: '5%' }}
                  />
                  <FakeInputBlock
                    title={_t('element.deliveredOn')}
                    value={
                      <Date datetime={evidence.deliveryDate} withTime={false} />
                    }
                    containerStyle={{ flex: '2', marginRight: '5%' }}
                  />
                  <FakeInputBlock
                    title={_t('element.in')}
                    value={evidence.deliveryPlace}
                    containerStyle={{ flex: '1' }}
                  />
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <DataBox panel style={{ width: '95%', marginTop: 40 }}>
      <DataBoxHeader color={BKG_LIGHT_VIOLET}>
        {_t('element.offender')}
      </DataBoxHeader>
      <DataBoxContent>
        {buildOffenderRender(offender)}
        {responsibleParty && (
          <>
            <SeparatorWithTitle
              title={_t('element.responsibleParty')}
              style={{ width: '100%', padding: '20px 0px' }}
              titleSize={18}
            />
            {buildOffenderRender(responsibleParty)}
          </>
        )}
      </DataBoxContent>
    </DataBox>
  );
};

export default OffenderBlock;
