import {
  Checkbox,
  CircularProgress,
  Dialog,
  FlatButton,
  MenuItem,
  SelectField,
  TextField,
} from 'material-ui';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import {
  CritAir,
  CritAirSource,
  SubscriberDTO,
  SubscriberMediaDTO,
  SubscriberVehicle,
  SubscriberVehicleUploadDTO,
  VehicleCategory,
  VehicleTypeSource,
} from '@cvfm-front/tefps-types';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import './SubscriberAddVehicle.css';
import {
  getEvidenceDirectUploadLink,
  patchSubscriber,
} from 'api/cvfm-core-subscription/subscriber';
import { ApiError } from 'api/ApiError';
import translateError from 'commons/Utils/translateErrorUtil';
import { ErrorType } from 'commons/types/error';
import { Files } from '@cvfm-front/commons-ui';
import { uploadFileV2 } from 'api/mediaupload';
import { InternalApiState } from 'api/duck';
import { AuthorizedVehicleCategory, getConfigState } from 'config/duck';
import { getLabelFromVehicleType } from 'commons/Utils/vehicleTypeUtils';
import { CRITAIR_COLORS } from '@cvfm-front/tefps-ui';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  open: boolean;
  setOpen: (b: boolean) => void;
  subscriber: SubscriberDTO;
  setSubscriber: (s: SubscriberDTO) => void;
  watermarkText: string | null;
  authorizedVehicleCategories: AuthorizedVehicleCategory[];
  enableSaveCritairSiv: boolean;
};

// Fonction d'initialisation à l'extérieur du composant
const initializeNewVehicle = () => ({
  id: uuidv4(),
  name: '',
  plate: '',
  manuallyChecked: false,
  vehicleCategory: 'CAR' as VehicleCategory,
  documents: [],
  vehicleCritair: null,
  vehicleCritairSource: CritAirSource.AGENT, // USAGER In clean & close
  vehicleTypeSource: VehicleTypeSource.AGENT, // USAGER In clean & close
});

const SubscriberAddVehicle = ({
  open,
  setOpen,
  subscriber,
  setSubscriber,
  watermarkText,
  authorizedVehicleCategories,
  enableSaveCritairSiv,
}: Props): JSX.Element => {
  const [profileUsed, setProfileUsed] = useState<string | null>(null);
  const [newVehicle, setNewVehicle] = useState<SubscriberVehicleUploadDTO>(
    initializeNewVehicle
  );
  const [uploading, setUploading] = useState<boolean>(false);
  // which existing documents are selected in the select
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<Array<string>>(
    []
  );
  // which existing documents from subscriber.evidences are selected in the select
  const [
    selectedDocumentIdsFromEvidences,
    setSelectedDocumentIdsFromEvidences,
  ] = useState<Array<string>>([]);
  // which additional documents are added
  const [additionalDocuments, setAdditionalDocuments] = useState<
    Array<SubscriberMediaDTO>
  >([]);

  const setMessage = useSnackbar();

  // derived state
  const createVehicleEnabled =
    !!newVehicle.name &&
    !!newVehicle.plate &&
    !!profileUsed &&
    !additionalDocuments.some(document => !document.url) &&
    (!!selectedDocumentIds.length ||
      !!selectedDocumentIdsFromEvidences.length ||
      !!additionalDocuments.length ||
      newVehicle.manuallyChecked) &&
    selectedDocumentIds.length +
      selectedDocumentIdsFromEvidences.length +
      additionalDocuments.length <=
      4;
  const profiles = [];
  if (subscriber.personalProfile !== undefined)
    profiles.push({
      id: '/personal-vehicle',
      name: _tg('tefps.subscription.addVehicle.addToPersonalProfile'),
    });
  if (subscriber.professionalProfile !== undefined)
    profiles.push({
      id: '/professional-vehicle',
      name: _tg('tefps.subscription.addVehicle.addToProfessionalProfile'),
    });

  const computeAllVehicles = (): SubscriberVehicle[] => {
    const vehicles: SubscriberVehicle[] = [];
    subscriber?.professionalProfile?.vehicles?.forEach(vehicle =>
      vehicles.push(vehicle)
    );
    subscriber?.personalProfile?.vehicles?.forEach(vehicle =>
      vehicles.push(vehicle)
    );
    return vehicles;
  };

  const vehicles = computeAllVehicles();

  const cleanAndClose = () => {
    setNewVehicle(initializeNewVehicle);
    setProfileUsed(null);
    setAdditionalDocuments([]);
    setSelectedDocumentIds([]);
    setSelectedDocumentIdsFromEvidences([]);
    setOpen(false);
  };

  const handleSelectUserProfile = (e: never, idx: never, value: string) => {
    setProfileUsed(value);
  };

  const findCorrespondingEvidencesInVehicles = (
    selectedIds: Array<string>
  ): Array<SubscriberMediaDTO> => {
    return vehicles
      .filter(vehicle =>
        vehicle?.registrationDocuments?.documents?.some(document =>
          selectedIds.includes(document.id)
        )
      )
      .reduce((acc, vehicle) => {
        vehicle.registrationDocuments?.documents?.forEach(document => {
          if (selectedIds.includes(document.id)) {
            acc.push(document);
          }
        });
        return acc;
      }, [] as Array<SubscriberMediaDTO>);
  };

  const handleExistingFile = (
    _e: never,
    _index: number,
    selectedIds: Array<string>
  ): void => {
    setSelectedDocumentIds(selectedIds);
  };

  const handleExistingFileFromEvidences = (
    _e: never,
    _index: number,
    selectedIds: Array<string>
  ): void => {
    setSelectedDocumentIdsFromEvidences(selectedIds);
  };

  const onChangeFile = async (file: File): Promise<void> => {
    const directUploadDTO = await getEvidenceDirectUploadLink(
      subscriber.subscriberId,
      file.type
    );

    void uploadFileV2(
      directUploadDTO.signedUploadUrl,
      file,
      watermarkText,
      undefined,
      _err => {
        setMessage(_tg('commons.error'));
      }
    )
      .then(_ => {
        const newDocument: SubscriberMediaDTO = {
          id: uuidv4(),
          name: file.name,
          url: directUploadDTO.fileUrn,
          dateCreated: new Date().toISOString(),
        };

        setAdditionalDocuments([...additionalDocuments, newDocument]);
      })
      .catch(err =>
        setMessage((err as Error)?.message || _tg('commons.error'))
      );
  };

  const onRemoveFile = (file: File): void => {
    const newDocuments = additionalDocuments.filter(
      document => document.name !== file.name
    );
    setAdditionalDocuments(newDocuments);
  };

  const validateAndSubmit = async () => {
    if (profileUsed) {
      setUploading(true);
      try {
        const currentDate = new Date(Date.now());
        const documents = [
          ...findCorrespondingEvidencesInVehicles(selectedDocumentIds).map(
            document => ({
              id: uuidv4(),
              name: document.name,
              dateCreated: currentDate.toISOString(),
              url: document.url,
            })
          ),
          ...subscriber.evidences
            .filter(evidence =>
              selectedDocumentIdsFromEvidences.includes(evidence.id)
            )
            .map(document => ({
              id: uuidv4(),
              name: document.name,
              dateCreated: currentDate.toISOString(),
              url: document.url,
            })),
          ...additionalDocuments,
        ];

        const patchObject = {
          path: profileUsed,
          op: 'add',
          value: {
            ...newVehicle,
            documents,
            vehicleCategory: newVehicle?.vehicleCategory,
          },
        };
        const updatedSubscriber: SubscriberDTO = await patchSubscriber(
          subscriber.subscriberId,
          [patchObject]
        );
        setSubscriber(updatedSubscriber);

        // clean in case the user wants to add another one directly
        cleanAndClose();
      } catch (e) {
        // the error should get translated, but we use 'Erreur' as a fallback
        setMessage(
          translateError((e as ApiError).name as ErrorType) ||
            _tg('field.error')
        );
      } finally {
        setUploading(false);
      }
    }
  };

  return (
    <Dialog
      title={_t('dialog.title')}
      onRequestClose={() => setOpen(false)}
      actions={[
        <FlatButton label={_t('dialog.cancel')} onClick={cleanAndClose} />,
        <FlatButton
          label={_t('dialog.validate')}
          onClick={validateAndSubmit}
          disabled={!createVehicleEnabled}
        />,
      ]}
      open={open}
    >
      <div className="add-vehicule">
        <TextField
          id="name"
          onChange={(_, name) => setNewVehicle({ ...newVehicle, name })}
          floatingLabelText={_t('dialog.fields.vehicleName')}
        />
        <TextField
          id="plate"
          onChange={(_, plate) => setNewVehicle({ ...newVehicle, plate })}
          value={newVehicle.plate}
          floatingLabelText={_t('dialog.fields.plate')}
        />
        {authorizedVehicleCategories.length > 0 ? (
          <SelectField
            fullWidth
            id="vehicleCategory"
            value={newVehicle.vehicleCategory}
            onChange={(_, __, vehicleCategory) =>
              setNewVehicle({
                ...newVehicle,
                vehicleCategory: vehicleCategory as VehicleCategory,
              })
            }
            floatingLabelText={_t('dialog.fields.vehicleCategory')}
          >
            {authorizedVehicleCategories.map(item => (
              <MenuItem
                id={item.vehicleCategory}
                key={item.vehicleCategory}
                value={item.vehicleCategory}
                primaryText={getLabelFromVehicleType(item.vehicleCategory)}
              />
            ))}
          </SelectField>
        ) : (
          <></>
        )}
        {enableSaveCritairSiv && (
          <SelectField
            autoWidth
            id="vehicleCritair"
            value={newVehicle.vehicleCritair}
            onChange={(_, __, vehicleCritair) =>
              setNewVehicle({
                ...newVehicle,
                vehicleCritair: vehicleCritair as CritAir,
              })
            }
            floatingLabelText={_t('dialog.fields.vehicleCritair')}
          >
            {Object.keys(CRITAIR_COLORS).map(key => (
              <MenuItem
                id={key}
                key={CRITAIR_COLORS[key].value}
                value={key}
                primaryText={CRITAIR_COLORS[key].value}
                style={{
                  backgroundColor: CRITAIR_COLORS[key].backgroundColor,
                  color: CRITAIR_COLORS[key].foregroundColor,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                }}
              />
            ))}
          </SelectField>
        )}
        <SelectField
          fullWidth
          id="profile"
          value={profileUsed}
          onChange={handleSelectUserProfile}
          floatingLabelText={_t('dialog.fields.profile')}
        >
          {profiles.map(item => (
            <MenuItem
              id={item.id}
              key={item.id}
              value={item.id}
              primaryText={item.name}
            />
          ))}
        </SelectField>
        <div>{_t('dialog.maximumFilesAmount')}</div>

        {uploading ? (
          <div className="add-vehicle__uploading">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="add-vehicle">
              <SelectField
                id="evidence-select"
                className="add-vehicle__evidence-select"
                multiple
                onChange={handleExistingFile}
                value={selectedDocumentIds}
                floatingLabelText={_t('dialog.fields.existingFiles')}
                disabled={newVehicle.manuallyChecked}
                iconStyle={{
                  marginRight: '-50px', // to set the drop down arrow at the correct place
                }}
              >
                {vehicles.map(vehicle =>
                  vehicle?.registrationDocuments?.documents?.map(document => (
                    <MenuItem
                      id={document.id}
                      key={document.id}
                      value={document.id}
                      primaryText={`${vehicle.plate} - ${document.name || ''}`}
                      innerDivStyle={{ padding: '0 24px' }} // without this the padding is O 24px 0 64px
                      insetChildren
                    />
                  ))
                )}
              </SelectField>
              <SelectField
                id="evidence-select-from-evidences"
                className="add-vehicle__evidence-select"
                multiple
                onChange={handleExistingFileFromEvidences}
                value={selectedDocumentIdsFromEvidences}
                floatingLabelText={_t(
                  'dialog.fields.existingFilesFromEvidences'
                )}
                disabled={newVehicle.manuallyChecked}
                iconStyle={{
                  marginRight: '-50px', // to set the drop down arrow at the correct place
                }}
              >
                {subscriber.evidences.map(document => (
                  <MenuItem
                    id={document.id}
                    key={document.id}
                    value={document.id}
                    primaryText={document.name || ''}
                    innerDivStyle={{ padding: '0 24px' }} // without this the padding is O 24px 0 64px
                    insetChildren
                  />
                ))}
              </SelectField>
            </div>
            <Files
              label={_tg('action.addUpToXFiles', {
                X: 4,
              })}
              fileIcon="/static/vehicle-card.svg"
              closeIcon="/static/close.svg"
              withDropzone={false}
              maxSize={2000000}
              maxFiles={4}
              onChange={onChangeFile}
              onError={err => setMessage(err)}
              onRemoveFile={onRemoveFile}
            />
          </>
        )}

        <Checkbox
          label={_t('dialog.fields.verifiedByAgent')}
          className="add-vehicle__verified_by_agent"
          onCheck={(_, manuallyChecked) => {
            setNewVehicle({
              ...newVehicle,
              manuallyChecked,
            });
          }}
        />
      </div>
    </Dialog>
  );
};

function mapStateToProps(state: InternalApiState) {
  const { watermarkText, sivConfiguration } = getConfigState(state);
  const { authorizedVehicleCategories } = getConfigState(state);
  return {
    watermarkText,
    authorizedVehicleCategories,
    enableSaveCritairSiv: sivConfiguration.enableSaveCritairSiv,
  };
}

export default connect(mapStateToProps)(SubscriberAddVehicle);
