import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import EditIcon from 'material-ui/svg-icons/editor/mode-edit';
import moment from 'moment';

import BoButton from 'facade/BoButton';
import SimpleTable from 'commons/SimpleTable';
import { ListBody, ListBottom, ListWrapper } from 'commons/ListWrappers';
import DateComponent from 'commons/Date';
import ErrorBlock from 'commons/ErrorBlock';
import FlexCenter from 'commons/FlexCenter';
import Content from 'commons/Content';
import { InstrumentDTO } from 'api/tepv/instruments/types';
import { getApiState } from 'api/duck';
import {
  createOrUpdateInstrument,
  deleteInstrument,
  getInstruments,
} from 'api/tepv/instruments';
import { BKG_CYAN, BKG_PINK } from 'theme';
import { fetchOrganizations } from 'api/organizations';
import { CityOrganizationDTO } from 'api/organizations/types';

import DevicesSidebar from './DevicesSidebar';
import { InstrumentFilters, InstrumentFiltersPopulateData } from './types';
import { NATURE_LABEL } from './commons';
import EditionModal, { DeletionModal } from './modals';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_ICON: CSSProperties = {
  cursor: 'pointer',
};

const translateTableCols = (): Array<{
  label?: string;
  width: number;
  grow?: number;
  style?: CSSProperties;
  headerStyle?: CSSProperties;
  onSort?: boolean;
}> => [
  { label: _tg('field.device.nature'), width: 120 },
  { label: _tg('field.device.type'), width: 120 },
  {
    label: _tg('field.device.brand'),
    width: 150,
  },
  { label: _tg('field.device.model'), width: 150, grow: 2 },
  { label: _tg('field.device.id'), width: 150 },
  {
    label: _tg('field.device.softwareVersion'),
    width: 140,
  },
  { label: _tg('field.date.lastCheck'), width: 150 },
  { label: _tg('field.date.endValidityDate'), width: 150 },
  {
    label: _tg('action.actions'),
    width: 100,
    headerStyle: { textAlign: 'center' },
  },
];

const getInitialFilters = (): InstrumentFilters => ({
  natures: new Set(),
  types: [],
  brand: '',
  serialNumber: '',
  verificationDate: {
    from: undefined,
    to: undefined,
  },
  validityDate: {
    from: undefined,
    to: undefined,
  },
});

const getInitialFiltersData = (): InstrumentFiltersPopulateData => ({
  types: new Set(),
});

type State = {
  instruments: Array<InstrumentDTO>;
  organizations: CityOrganizationDTO[] | null | undefined;
  filteredInstruments: Array<InstrumentDTO>;
  loading: boolean;
  filters: InstrumentFilters;
  filtersData: InstrumentFiltersPopulateData;
  instrumentsIdentifiers: Array<string>;
  deletionIdentifier: string | null | undefined;
  editedInstrumentIdentifier: string | null | undefined;
  isEditionModalOpen: boolean;
  fetchError: Error | null | undefined;
  deletionError: Error | null | undefined;
  editionError: Error | null | undefined;
};

type Props = {
  canAddDevice: boolean;
  canDeleteDevice: boolean;
};

const filter = (
  filters: InstrumentFilters,
  instruments: Array<InstrumentDTO>
): Array<InstrumentDTO> => {
  const {
    natures,
    types,
    brand,
    serialNumber,
    verificationDate,
    validityDate,
  } = filters;

  return instruments.filter(instrument => {
    if (natures.size > 0 && !natures.has(instrument.nature)) return false;
    if (types.length > 0 && !types.includes(instrument.type)) return false;
    if (!instrument.brand.toLowerCase().includes(brand.toLowerCase()))
      return false;
    if (
      !instrument.serialNumber
        .toLowerCase()
        .includes(serialNumber.toLowerCase())
    )
      return false;

    const validityDateTimestamp = Date.parse(instrument.validityDatetime);
    if (
      validityDate.from &&
      validityDateTimestamp <= validityDate.from.getTime()
    )
      return false;
    if (validityDate.to && validityDateTimestamp >= validityDate.to.getTime())
      return false;

    const verificationDateTimestamp = Date.parse(
      instrument.verificationDatetime
    );
    if (
      verificationDate.from &&
      verificationDateTimestamp <= verificationDate.from.getTime()
    )
      return false;
    if (
      verificationDate.to &&
      verificationDateTimestamp >= verificationDate.to.getTime()
    )
      return false;

    return true;
  });
};

const compareInstruments = (
  objValue: InstrumentDTO,
  othValue: InstrumentDTO
) => {
  if (objValue.brand !== othValue.brand) {
    return true;
  }
  if (objValue.organizationId !== othValue.organizationId) {
    return true;
  }
  if (objValue.identifier !== othValue.identifier) {
    return true;
  }
  if (objValue.model !== othValue.model) {
    return true;
  }
  if (objValue.nature !== othValue.nature) {
    return true;
  }
  if (objValue.serialNumber !== othValue.serialNumber) {
    return true;
  }
  if (objValue.softwareVersion !== othValue.softwareVersion) {
    return true;
  }
  if (objValue.type !== othValue.type) {
    return true;
  }
  if (
    !moment(objValue.validityDatetime).isSame(othValue.validityDatetime, 'day')
  ) {
    return true;
  }
  if (
    !moment(objValue.verificationDatetime).isSame(
      othValue.verificationDatetime,
      'day'
    )
  ) {
    return true;
  }
  return false;
};

class DeviceList extends React.Component<Props, State> {
  state: State = {
    instruments: [],
    filteredInstruments: [],
    loading: true,
    organizations: [],
    filters: getInitialFilters(),
    filtersData: getInitialFiltersData(),
    instrumentsIdentifiers: [],
    deletionIdentifier: null,
    editedInstrumentIdentifier: null,
    isEditionModalOpen: false,
    fetchError: null,
    deletionError: null,
    editionError: null,
  };

  componentDidMount() {
    this.fetchInstruments();
  }

  resetFilters = () => this.filterInstruments(getInitialFilters());

  filterInstruments = (filters: InstrumentFilters) => {
    const filteredInstruments = filter(filters, this.state.instruments);

    this.setState({ filters, filteredInstruments });
  };

  openDeletionModal = ({ currentTarget }: React.MouseEvent<any>) =>
    this.setState({ deletionIdentifier: currentTarget.dataset.identifier });
  closeDeletionModal = () =>
    this.setState({ deletionIdentifier: null, deletionError: null });

  openEditionModal = ({ currentTarget }: React.MouseEvent<any>) =>
    this.setState({
      editedInstrumentIdentifier: currentTarget.dataset.identifier,
      isEditionModalOpen: true,
    });
  closeEditionModal = () =>
    this.setState({
      editedInstrumentIdentifier: null,
      isEditionModalOpen: false,
      editionError: null,
    });

  removeInstrument = async () => {
    const { instruments, deletionIdentifier, filters } = this.state;

    if (!deletionIdentifier) return;
    const updatedInstruments = instruments.filter(
      ({ identifier }: InstrumentDTO) => identifier !== deletionIdentifier
    );
    try {
      await deleteInstrument(deletionIdentifier);
      this.setState({
        instruments: updatedInstruments,
        filteredInstruments: filter(filters, updatedInstruments),
        deletionIdentifier: null,
        deletionError: null,
      });
    } catch (err) {
      this.setState({ deletionError: err });
    }
  };

  editInstrument = async (instrument: InstrumentDTO) => {
    if (!instrument) return;

    const { instruments, editedInstrumentIdentifier, filters } = this.state;

    const editedIndex = instruments.findIndex(
      ({ identifier }: InstrumentDTO) =>
        identifier === editedInstrumentIdentifier
    );

    try {
      if (instruments[editedIndex]) {
        if (compareInstruments(instrument, instruments[editedIndex])) {
          await createOrUpdateInstrument(instrument);
        }
      } else {
        await createOrUpdateInstrument(instrument);
      }
    } catch (err) {
      this.setState({ editionError: err });
      return;
    }

    let updatedInstruments = [];
    if (editedInstrumentIdentifier) {
      updatedInstruments = [
        ...instruments.slice(0, editedIndex),
        instrument,
        ...instruments.slice(editedIndex + 1),
      ];
    } else {
      updatedInstruments = [...instruments, instrument];
    }
    this.setState({
      instruments: updatedInstruments,
      filteredInstruments: filter(filters, updatedInstruments),
      editedInstrumentIdentifier: null,
      isEditionModalOpen: false,
      editionError: null,
    });
  };

  fetchInstruments = async () => {
    let instruments = [];
    try {
      instruments = await getInstruments();
    } catch (error) {
      this.setState({ fetchError: error });
      return;
    }
    const filtersData = getInitialFiltersData();
    const listInstrumentsIdentifiers = instruments.map(
      instrument => instrument.identifier
    );
    instruments.forEach(({ type }: InstrumentDTO) => {
      filtersData.types.add(type);
    });

    let organizations = [];
    try {
      organizations = await fetchOrganizations(false, 'pv');
    } catch (error) {
      this.setState({ fetchError: error });
      return;
    }

    this.setState({
      instruments,
      organizations,
      filteredInstruments: instruments,
      instrumentsIdentifiers: listInstrumentsIdentifiers,
      filtersData,
      loading: false,
      fetchError: null,
    });
  };

  renderRow = ({
    serialNumber,
    brand,
    model,
    softwareVersion,
    type,
    nature,
    identifier,
    verificationDatetime,
    validityDatetime,
  }: InstrumentDTO) => [
    <span>{NATURE_LABEL[nature]}</span>,
    <span>{type}</span>,
    <span>{brand}</span>,
    <span>{model}</span>,
    <span>{serialNumber}</span>,
    <span>{softwareVersion}</span>,
    <span>
      <DateComponent datetime={verificationDatetime} withTime={false} />
    </span>,
    <span>
      <DateComponent datetime={validityDatetime} withTime={false} />
    </span>,
    <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
      {this.props.canDeleteDevice && (
        <DeleteIcon
          style={STYLE_ICON}
          color={BKG_CYAN}
          hoverColor={BKG_PINK}
          onClick={this.openDeletionModal}
          data-identifier={identifier}
        />
      )}
      {this.props.canAddDevice && (
        <EditIcon
          style={STYLE_ICON}
          color={BKG_CYAN}
          hoverColor={BKG_PINK}
          onClick={this.openEditionModal}
          data-identifier={identifier}
        />
      )}
    </div>,
  ];

  render() {
    const {
      loading,
      filteredInstruments,
      filters,
      filtersData,
      deletionIdentifier,
      instrumentsIdentifiers,
      isEditionModalOpen,
      instruments,
      editedInstrumentIdentifier,
      fetchError,
      deletionError,
      editionError,
      organizations,
    } = this.state;
    const { canAddDevice } = this.props;

    const editedInstrument = instruments.find(
      ({ identifier }: InstrumentDTO) =>
        identifier === editedInstrumentIdentifier
    );

    if (fetchError) {
      return (
        <Content>
          <FlexCenter>
            <ErrorBlock message={_t('feedback.error')} error={fetchError} />
          </FlexCenter>
        </Content>
      );
    }

    return (
      <div style={{ height: '100%', fontFamily: 'Roboto', display: 'flex' }}>
        <DevicesSidebar
          filtersData={filtersData}
          filters={filters}
          resetFilters={this.resetFilters}
          updateFilters={this.filterInstruments}
          totalHits={filteredInstruments.length}
        />
        <ListWrapper style={{ width: '100%', paddingTop: 20 }}>
          <ListBody loading={loading} style={{ height: 'calc(100% - 10px)' }}>
            <SimpleTable
              cols={translateTableCols()}
              rowHeight={50}
              itemsRenderer={this.renderRow}
              items={filteredInstruments}
            />
          </ListBody>
          {canAddDevice && (
            <ListBottom>
              <BoButton
                label={_t('element.buttonAdd.label')}
                primary
                onClick={this.openEditionModal}
              />
            </ListBottom>
          )}
        </ListWrapper>
        <DeletionModal
          deletionIdentifier={deletionIdentifier}
          closeModal={this.closeDeletionModal}
          removeInstrument={this.removeInstrument}
          error={deletionError}
        />
        <EditionModal
          closeModal={this.closeEditionModal}
          editInstrument={this.editInstrument}
          organizations={organizations}
          instrument={editedInstrument}
          open={isEditionModalOpen}
          instrumentsIdentifiers={instrumentsIdentifiers}
          error={editionError}
        />
      </div>
    );
  }
}

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    canAddDevice: userInfo && userInfo.rights.includes('DEVICES_WRITE'),
    canDeleteDevice: userInfo && userInfo.rights.includes('DEVICES_DELETE'),
  };
})(DeviceList);
