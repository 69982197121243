import React, { CSSProperties } from 'react';
import FlatButton from 'material-ui/FlatButton';
import CircularProgress from 'material-ui/CircularProgress';

import Content from 'commons/Content';
import ErrorBlock from 'commons/ErrorBlock';
import FlexCenter from 'commons/FlexCenter';
import {
  DataBox,
  DataBoxContent,
  DataBoxHeader,
  DataBoxItem,
  DataBoxItemWrapper,
} from 'commons/DataBox';
import FakeInputBlock from 'commons/FakeInputBlock';
import Date from 'commons/Date';
import { fetchMotifDetails } from 'api/tepv/motifs';
import { MotifDTO } from 'api/tepv/motifs/types';
import Price from 'commons/Price';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_HEADER_LEFT: CSSProperties = {
  fontWeight: 'bold',
  marginLeft: 30,
};

const STYLE_DIV_CONTENT: CSSProperties = {
  padding: '30px 0px 5px 70px',
};

const STYLE_DATABOX_WRAPPER: CSSProperties = {
  marginBottom: 20,
  width: '90%',
};

const STYLE_DATABOX_ITEM: CSSProperties = { maxWidth: '100%' };

type MotifDetailsState = {
  motif: MotifDTO | null;
  error: Error | null;
};

type MotifDetailsProps = {
  match: {
    params: {
      motifId: string;
    };
  };
};

class MotifDetails extends React.Component<
  MotifDetailsProps,
  MotifDetailsState
> {
  state: MotifDetailsState = {
    motif: null,
    error: null,
  };

  componentDidMount() {
    this.fetchMotif(this.props.match.params.motifId);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: MotifDetailsProps) {
    if (this.props.match.params.motifId !== nextProps.match.params.motifId) {
      this.fetchMotif(nextProps.match.params.motifId);
    }
  }

  async fetchMotif(motifId: string) {
    this.setState({
      motif: null,
      error: null,
    });
    try {
      const motif = await fetchMotifDetails(motifId);
      this.setState({
        motif,
      });
    } catch (error) {
      this.setState({
        error,
      });
    }
  }

  render() {
    const { motif, error } = this.state;

    const backToListButton = (
      <FlatButton href="#/motif/list" label={_t('element.backToList')} />
    );
    if (error) {
      return (
        <Content>
          <div style={STYLE_DIV_CONTENT}>{backToListButton}</div>
          <FlexCenter>
            <ErrorBlock
              message={_tg('feedback.error.fetchMotif')}
              error={error}
            />
          </FlexCenter>
        </Content>
      );
    }

    if (!motif) {
      return (
        <Content>
          {backToListButton}
          <FlexCenter>
            <CircularProgress />
          </FlexCenter>
        </Content>
      );
    }
    return (
      <Content>
        <div style={STYLE_DIV_CONTENT}>
          {backToListButton}
          <DataBox panel style={{ width: '95%' }}>
            <DataBoxHeader>
              <div style={STYLE_HEADER_LEFT}> {_tg('commons.reason')} </div>
            </DataBoxHeader>
            <DataBoxContent>
              <DataBoxItemWrapper style={STYLE_DATABOX_WRAPPER}>
                <DataBoxItem style={STYLE_DATABOX_ITEM}>
                  <FakeInputBlock
                    title={_t('fakeInput.qualification')}
                    value={motif.qualification}
                  />
                  <FakeInputBlock
                    title={_t('fakeInput.category')}
                    value={motif.category}
                  />
                  <FakeInputBlock
                    title={_t('fakeInput.case')}
                    value={_t('fakeInput.caseA')}
                  />
                  <FakeInputBlock
                    title={_t('fakeInput.natinfCode')}
                    value={motif.natinfCode || ''}
                  />
                  <FakeInputBlock
                    title={_t('fakeInput.infractionCase')}
                    value={motif.infractionCase || ''}
                  />
                  <FakeInputBlock
                    title={_t('fakeInput.fineMaxAmount')}
                    value={<Price price={motif.fineMaxAmount * 100} />}
                  />
                </DataBoxItem>
              </DataBoxItemWrapper>
              <DataBoxItemWrapper style={STYLE_DATABOX_WRAPPER}>
                <DataBoxItem style={STYLE_DATABOX_ITEM}>
                  <FakeInputBlock
                    title={_tg('field.date.applicationDate')}
                    value={
                      <Date datetime={motif.enforcementDate} withTime={false} />
                    }
                  />
                  <FakeInputBlock
                    title={_tg('field.date.abrogationDate')}
                    value={
                      <Date datetime={motif.abrogrationDate} withTime={false} />
                    }
                  />
                </DataBoxItem>
              </DataBoxItemWrapper>
              <DataBoxItemWrapper style={STYLE_DATABOX_WRAPPER}>
                <DataBoxItem style={STYLE_DATABOX_ITEM}>
                  <FakeInputBlock
                    title={_tg('field.date.importDate')}
                    value={
                      <Date datetime={motif.importDate} withTime={false} />
                    }
                  />
                </DataBoxItem>
              </DataBoxItemWrapper>
            </DataBoxContent>
          </DataBox>
        </div>
      </Content>
    );
  }
}

export default MotifDetails;
