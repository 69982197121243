import React, { CSSProperties, useEffect, useState } from 'react';
import { DropDownMenu, MenuItem } from 'material-ui';
import AddIcon from 'material-ui/svg-icons/content/add-circle-outline';
import ModeIcon from 'material-ui/svg-icons/editor/mode-edit';
import SendIcon from 'material-ui/svg-icons/content/send';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import AccountCircle from 'material-ui/svg-icons/action/account-circle';

import {
  hasFranceConnectIdentity,
  hasCustomerIdentity,
} from '@cvfm-front/tefps-utils';
import BoButton from 'facade/BoButton';
import {
  OrderEsPrivateDTO,
  SubscriberDTO,
  LoginType,
  ZoneDTO,
  SubscriberAccountType,
  Proof,
  OrderProfileAccountDTO,
  OrderEsSearchQueryPagedDTO,
  OrderStatus,
} from '@cvfm-front/tefps-types';
import { ItemIdName } from 'api/commonTypes';
import {
  BKG_CYAN,
  BKG_DARKER_BLUE,
  BKG_DARK_BLUE,
  BKG_LIGHT_BLUE,
  BKG_PINK,
  BKG_DARK,
} from 'theme';
// TODO
import './OrderDetailPage.css';
import DissociateFranceConnectService from 'tefps/Beneficiaries/Subscribers/AddSubscriber/DissociateFranceConnectService';
import {
  deleteProfile,
  getFranceConnectReconciliationLink,
} from 'api/cvfm-core-subscription/subscriber';
import { hasOrdersForProfile } from 'api/cvfm-core-subscription/order';
import { NotificationService } from '@cvfm-front/commons-services';
import ConfirmAction from 'commons/ConfirmAction';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import translateError from 'commons/Utils/translateErrorUtil';

import 'commons/css/buttonGroup.css';

import SubscriberDetailEvidences from './SubscriberDetailEvidences';
import SubscriberDetailSummary from './SubscriberDetailSummary';
import SubscriberDetailPlates from './SubscriberDetailPlates';
import SubscriberDetailHistory from './SubscriberDetailHistory';
import SubscriberDetailVehicles from './SubscriberDetailVehicles';
import SubscriberAddVehicle from './SubscriberAddVehicle';
import SubscriberDetailProof from './SubscriberDetailProof';
import SubscriberAddProof from './SubscriberAddProof';

const { _tg, _t } = window.loadTranslations(__filename);

const Label = ({
  title,
  icon,
}: {
  title: string;
  icon: JSX.Element;
}): React.ReactElement<any> => (
  <div style={{ display: 'inline-block' }}>
    <span style={{ display: 'flex', alignItems: 'center' }}>
      {icon}
      {title}
    </span>
  </div>
);

type SubscriberDetailProps = {
  subscriber: SubscriberDTO;
  isVehicleUsedMap: Map<string, boolean>;
  productItems: Array<ItemIdName>;
  activeOrders: Array<OrderEsPrivateDTO>;
  hasOrders: boolean;
  zones: Array<ZoneDTO>;
  smsEnabled: boolean;
  handleSendSignEmail: (subscriberId: string) => void;
  accessToEligibilityList: () => void;
  accessToEligibilityOrderList: () => void;
  accessToPassedEligibilityOrderList: () => void;
  accessToBundleOrdersList: () => void;
  openAddEligibity: () => void;
  openAddBundle: () => void;
  openUpdateSubscriber: () => void;
  setVehicleToUpdate: (vehicleId: string | null) => void;
  setOpenUpdateVehicle: (open: boolean) => void;
  setOpenRenameVehicle: (open: boolean) => void;
  setOpenDeleteVehicle: (open: boolean) => void;
  handleForgotPassword: (email: string) => void;
  openDeleteSubscriber: () => void;
  loadSubscriberSilently: () => void;
  setSubscriber: (subscriber: SubscriberDTO) => void;
  setProofToDelete: (proof: Proof) => void;
  canEdit: boolean;
  hasPmr: boolean;
  adV3Enabled: boolean;
};

const DROPDOWNMENU_STYLE: CSSProperties = {
  color: '#ffffff',
  textTransform: 'uppercase',
  fontWeight: 500,
  fontSize: 14,
  textAlign: 'center',
  lineHeight: '36px',
  height: 36,
  padding: 0,
};

const SubscriberDetail = ({
  subscriber,
  isVehicleUsedMap,
  productItems,
  activeOrders,
  hasOrders,
  zones,
  smsEnabled,
  handleSendSignEmail,
  accessToEligibilityList,
  accessToEligibilityOrderList,
  accessToPassedEligibilityOrderList,
  accessToBundleOrdersList,
  openAddEligibity,
  openAddBundle,
  openUpdateSubscriber,
  setVehicleToUpdate,
  setOpenUpdateVehicle,
  setOpenRenameVehicle,
  setOpenDeleteVehicle,
  handleForgotPassword,
  openDeleteSubscriber,
  loadSubscriberSilently,
  setSubscriber,
  setProofToDelete,
  canEdit,
  hasPmr,
  adV3Enabled,
}: SubscriberDetailProps): JSX.Element => {
  const getOpenIdFC = (subscriberParam: SubscriberDTO): string | null => {
    let fcOpenId = null;
    subscriberParam.openIds.forEach(subscriberOpenId => {
      if (subscriberOpenId.loginType === LoginType.FRANCE_CONNECT) {
        fcOpenId = subscriberOpenId.openId;
      }
    });
    return fcOpenId;
  };
  const openIdFC = subscriber ? getOpenIdFC(subscriber) : null;

  const [requestAlreadySent, setRequestAlreadySent] = useState(false);
  const [openAddVehicleDialog, setOpenAddVehicleDialog] = useState(false);
  const [openAddProof, setOpenAddProof] = useState<boolean>(false);
  const [
    deletedProfile,
    setDeletedProfile,
  ] = useState<SubscriberAccountType | null>(null);
  const [feedback, setFeedback] = useState<string>();
  const displaySnackbar = useSnackbar();
  const [hasProfileOrders, setProfileOrders] = useState<OrderProfileAccountDTO>(
    { createdForProfessionalAccount: false, createdForPersonalAccount: false }
  );

  function forgotPassword() {
    handleForgotPassword(subscriber.email);
  }

  function handleGetReconciliationLink(subscriberId: string): void {
    getFranceConnectReconciliationLink(subscriberId)
      .then(res => {
        navigator.clipboard.writeText(res.link);
        NotificationService.pushNotification({
          id: 'successGettingLink',
          message: _t('feedback.success.getReconciliationLink'),
          type: 'success',
        });
      })
      .catch(() => {
        NotificationService.pushNotification({
          id: 'errorGettingLink',
          message: _t('feedback.error.getReconciliationLink'),
          type: 'error',
        });
      });
  }

  async function hasOrdersAttachedToProfileAccount() {
    const pageQuery = {
      page: 0,
      maxRecords: 1000,
      query: {
        strictSubscriberIds: [subscriber.subscriberId],
        orderStatuses: [
          OrderStatus.FULFILLED,
          OrderStatus.AWAIT_DECISION,
          OrderStatus.AWAIT_COMPLETION_USER,
          OrderStatus.AWAIT_EVIDENCE,
          OrderStatus.AWAIT_PAYMENT,
          OrderStatus.AWAIT_TREATMENT_COMPLETUDE,
          OrderStatus.AWAIT_PLATE_DECISION,
          OrderStatus.AWAIT_TREATMENT_RENEWAL,
        ],
      },
    } as OrderEsSearchQueryPagedDTO;
    const result = await hasOrdersForProfile(pageQuery);
    setProfileOrders(result);
  }

  function deletingProfile(subscriberAccountType: SubscriberAccountType) {
    deleteProfile(subscriber.subscriberId, subscriberAccountType)
      .then(fetchedSubscriber => {
        setSubscriber(fetchedSubscriber);
        setFeedback(_t('feedback.success.deleted'));
      })
      .catch(err => {
        const message = err?.json?.error
          ? translateError(err?.json?.error)
          : _t('feedback.error.deleteAccount');
        setFeedback(message);
      });
    setDeletedProfile(null);
  }

  useEffect(() => {
    hasOrdersAttachedToProfileAccount();
  }, []);

  useEffect(() => {
    if (feedback) {
      displaySnackbar(feedback);
    }
    hasOrdersAttachedToProfileAccount();
  }, [feedback, deletedProfile, hasOrders]);

  function handleResendEmail() {
    handleSendSignEmail(subscriber.subscriberId);
  }

  const openAddVehicle = () => {
    setOpenAddVehicleDialog(true);
  };

  const RAISED_BUTTON_CONTAINER_STYLE = {
    borderRadius: 0,
    flex: 1,
    maxWidth: '30%',
  };

  return (
    <>
      <SubscriberDetailSummary
        subscriber={subscriber}
        zones={zones}
        smsEnabled={smsEnabled}
        hasPmr={hasPmr}
      />
      {canEdit && (
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <div
            style={{
              marginTop: 50,
              width: '100%',
              flex: 1,
              display: 'flex',
              padding: '0 2.5%',
            }}
          >
            <BoButton
              backgroundColor={BKG_LIGHT_BLUE}
              label={
                <Label
                  title={_t('action.add_eligibility_orders')}
                  icon={<AddIcon style={{ marginRight: 5 }} color="#ffffff" />}
                />
              }
              onClick={openAddEligibity}
              buttonStyle={{ borderRadius: '5px 0px 0px 5px' }}
              labelColor="#ffffff"
              style={{ flex: 1, borderRadius: '5px 0px 0px 5px' }}
            />
            <BoButton
              backgroundColor={BKG_DARKER_BLUE}
              label={
                <Label
                  title={_t('action.add_bundle_orders')}
                  icon={<AddIcon style={{ marginRight: 5 }} color="#ffffff" />}
                />
              }
              onClick={openAddBundle}
              labelColor="#ffffff"
              buttonStyle={{ borderRadius: 0 }}
              style={{ flex: 1, borderRadius: 0 }}
            />

            {subscriber.email && hasCustomerIdentity(subscriber) && (
              <DropDownMenu
                value="usager"
                style={{
                  ...RAISED_BUTTON_CONTAINER_STYLE,
                  backgroundColor: BKG_DARK,
                  height: 36,
                }}
                iconButton=""
                underlineStyle={{ borderTop: 0 }}
                labelStyle={DROPDOWNMENU_STYLE}
                className="usager"
              >
                <MenuItem
                  value="usager"
                  primaryText={
                    <span
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <AccountCircle
                        style={{ marginRight: 5 }}
                        color="#ffffff"
                      />{' '}
                      <span>{_t('account')}</span>
                    </span>
                  }
                  style={{ display: 'none' }}
                />
                <MenuItem
                  value="sendForgotPassword"
                  primaryText={_t('action.sendForgotPassword')}
                  onClick={forgotPassword}
                />
              </DropDownMenu>
            )}

            {subscriber.email && (
              <DropDownMenu
                value="franceconnect"
                style={{
                  ...RAISED_BUTTON_CONTAINER_STYLE,
                  backgroundColor: BKG_DARK_BLUE,
                  height: 36,
                }}
                iconButton=""
                underlineStyle={{ borderTop: 0 }}
                labelStyle={{
                  color: '#ffffff',
                  textTransform: 'uppercase',
                  fontWeight: 500,
                  fontSize: 14,
                  textAlign: 'center',
                  lineHeight: '36px',
                  height: 36,
                  padding: 0,
                }}
                className="franceconnect"
              >
                <MenuItem
                  value="franceconnect"
                  primaryText={
                    <span
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <SendIcon style={{ marginRight: 5 }} color="#ffffff" />{' '}
                      <span>{_tg('field.franceconnect.franceconnect')}</span>
                    </span>
                  }
                  style={{ display: 'none' }}
                />
                {!hasFranceConnectIdentity(subscriber) && (
                  <MenuItem
                    value="reconciliation"
                    primaryText={_t('action.copy_reconciliation_link')}
                    onClick={() =>
                      handleGetReconciliationLink(subscriber.subscriberId)
                    }
                  />
                )}
                {!hasFranceConnectIdentity(subscriber) &&
                  !hasCustomerIdentity(subscriber) && (
                    <MenuItem
                      value="envoyer"
                      primaryText={_t('action.resend_sign_email')}
                      onClick={handleResendEmail}
                    />
                  )}
                {hasFranceConnectIdentity(subscriber) && (
                  <MenuItem
                    value="dissocier"
                    primaryText={_tg('field.franceconnect.dissociateLong')}
                    disabled={openIdFC === null || requestAlreadySent}
                    onClick={() => {
                      DissociateFranceConnectService.dissociate(
                        subscriber.subscriberId,
                        openIdFC
                      );
                      setRequestAlreadySent(true);
                    }}
                  />
                )}
              </DropDownMenu>
            )}
            {!hasOrders && (
              <BoButton
                backgroundColor={BKG_PINK}
                label={
                  <Label
                    title={_tg('action.delete')}
                    icon={
                      <DeleteIcon style={{ marginRight: 5 }} color="#ffffff" />
                    }
                  />
                }
                onClick={openDeleteSubscriber}
                labelColor="#ffffff"
                buttonStyle={{ borderRadius: 0 }}
                style={{ flex: 1, borderRadius: 0 }}
              />
            )}
            {adV3Enabled ? (
              <DropDownMenu
                value="edit"
                style={{
                  ...RAISED_BUTTON_CONTAINER_STYLE,
                  backgroundColor: BKG_CYAN,
                  height: 36,
                  borderRadius: '0px 5px 5px 0px',
                }}
                iconButton=""
                underlineStyle={{ borderTop: 0 }}
                labelStyle={{
                  color: '#ffffff',
                  textTransform: 'uppercase',
                  fontWeight: 500,
                  fontSize: 14,
                  textAlign: 'center',
                  lineHeight: '36px',
                  height: 36,
                  padding: 0,
                }}
              >
                <MenuItem
                  value="edit"
                  primaryText={
                    <span
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <ModeIcon style={{ marginRight: 5 }} color="#ffffff" />{' '}
                      <span>{_tg('action.edit')}</span>
                    </span>
                  }
                  style={{ display: 'none' }}
                />
                <MenuItem
                  value="editProfile"
                  primaryText={<span>{_t('action.edit_profile')}</span>}
                  onClick={openUpdateSubscriber}
                />
                <MenuItem
                  value="addVehicle"
                  primaryText={<span>{_t('action.add_vehicle')}</span>}
                  onClick={openAddVehicle}
                />
                <MenuItem
                  value="addProof"
                  primaryText={<span>{_t('action.add_proof')}</span>}
                  onClick={() => setOpenAddProof(true)}
                />
                {subscriber.personalProfile &&
                  !hasProfileOrders?.createdForPersonalAccount && (
                    <MenuItem
                      value="deletePersonnalProfile"
                      primaryText={
                        <span>{_t('action.delete_personal_profile')}</span>
                      }
                      onClick={() =>
                        setDeletedProfile(SubscriberAccountType.PERSONAL)
                      }
                    />
                  )}
                {!hasProfileOrders?.createdForProfessionalAccount &&
                  subscriber.professionalProfile && (
                    <MenuItem
                      value="deleteProfessionalProfile"
                      primaryText={
                        <span>{_t('action.delete_professional_profile')}</span>
                      }
                      onClick={() =>
                        setDeletedProfile(SubscriberAccountType.PROFESSIONAL)
                      }
                    />
                  )}
              </DropDownMenu>
            ) : (
              <BoButton
                backgroundColor={BKG_CYAN}
                label={
                  <Label
                    title={_tg('action.edit')}
                    icon={
                      <ModeIcon style={{ marginRight: 5 }} color="#ffffff" />
                    }
                  />
                }
                onClick={openUpdateSubscriber}
                labelColor="#ffffff"
                style={{ flex: 1, borderRadius: '0px 5px 5px 0px' }}
                buttonStyle={{ borderRadius: '0px 5px 5px 0px' }}
              />
            )}
          </div>
        </div>
      )}
      <ConfirmAction
        enabled
        action={() => deletedProfile && deletingProfile(deletedProfile)}
        message={
          deletedProfile === SubscriberAccountType.PERSONAL
            ? _t('feedback.warning.deletePersonalAccountConfirmation')
            : _t('feedback.warning.deleteProfessionalAccountConfirmation')
        }
        onClose={() => setDeletedProfile(null)}
        isOpen={deletedProfile != null}
      />
      <div className="button-group full-width order-detail-button_container">
        <BoButton
          className="order-detail-button"
          label={_t('action.go_to_eligibility')}
          onClick={accessToEligibilityList}
        />
        <BoButton
          className="order-detail-button"
          label={_t('action.go_to_eligibility_orders')}
          onClick={accessToEligibilityOrderList}
        />
        <BoButton
          className="order-detail-button"
          label={_t('action.go_to_eligibility_passed_orders')}
          onClick={accessToPassedEligibilityOrderList}
        />
        <BoButton
          className="order-detail-button"
          label={_t('action.go_to_bundle_orders')}
          onClick={accessToBundleOrdersList}
        />
      </div>
      <SubscriberDetailEvidences
        subscriber={subscriber}
        loadSubscriberSilently={loadSubscriberSilently}
        canDelete={canEdit}
      />
      {adV3Enabled ? (
        <SubscriberDetailVehicles
          subscriber={subscriber}
          isVehicleUsedMap={isVehicleUsedMap}
          setVehicleToUpdate={setVehicleToUpdate}
          setOpenUpdateVehicle={setOpenUpdateVehicle}
          setOpenRenameVehicle={setOpenRenameVehicle}
          setOpenDeleteVehicle={setOpenDeleteVehicle}
        />
      ) : (
        <SubscriberDetailPlates activeOrders={activeOrders} />
      )}
      {adV3Enabled && (
        <SubscriberDetailProof
          subscriber={subscriber}
          setProofToDelete={setProofToDelete}
        />
      )}

      <SubscriberDetailHistory
        subscriber={subscriber}
        productItems={productItems}
      />
      <SubscriberAddVehicle
        open={openAddVehicleDialog}
        setOpen={setOpenAddVehicleDialog}
        subscriber={subscriber}
        setSubscriber={setSubscriber}
      />
      <SubscriberAddProof
        subscriber={subscriber}
        setSubscriber={setSubscriber}
        open={openAddProof}
        setOpen={setOpenAddProof}
      />
    </>
  );
};

export default SubscriberDetail;
