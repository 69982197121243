import React from 'react';

import { SubscriberVehicle } from '@cvfm-front/tefps-types';
import { Text } from '@cvfm-front/commons-ui';

import './VehicleDetails.css';

type VehicleDetailsProps = {
  plate: string;
  vehicles?: Array<SubscriberVehicle>;
  showPlate?: boolean;
};

const VehicleDetails = ({
  plate,
  vehicles,
  showPlate,
}: VehicleDetailsProps): JSX.Element | null => {
  const vehicle = vehicles?.find(
    v => v.plate.toUpperCase() === plate.toUpperCase()
  );

  return vehicle ? (
    <>
      <img
        className="vehicle__icon"
        alt="vehicle"
        src={`/static/img/${vehicle.vehicleCategory}_parking_colored.svg`}
      />
      <Text className="text--accent text--intermediate text--no-wrap margin-x--s">
        {showPlate ? vehicle.plate : vehicle.name}
      </Text>
    </>
  ) : null;
};

export default VehicleDetails;
