import React, { CSSProperties, useContext } from 'react';
import { Checkbox } from 'material-ui';
import { connect } from 'react-redux';
import { History } from 'history';

import SimpleTable from 'commons/SimpleTable';
import DateComponent from 'commons/Date';
import { GpvOverviewDTO } from 'api/tepv/gpv/types';
import { gpvStatusesName } from 'api/tepv/gpv/utils';
import { LABEL_STYLE, WIDTH_STYLE, ICON_STYLE, ELLIPSIS_STYLE } from 'theme';
import { getApiState } from 'api/duck';

import {
  BoolContextType,
  BoolContext,
  GpvListContext,
  GpvStateContextType,
} from '../Context';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  items: Array<GpvOverviewDTO>;
  loadMoreRows: () => Promise<void>;
  totalHits: number;
  onUpdateSort: (
    sortFieldColNumber: number,
    increasingOrder: boolean,
    sortId: string
  ) => void;
  colSorted: number;
  sortOrder: boolean;
  canReadGpvs: boolean;
  history: History;
};

const GpvTable = ({
  items,
  totalHits,
  loadMoreRows,
  canReadGpvs,
  history,
  onUpdateSort,
  sortOrder,
  colSorted,
}: Props) => {
  const { selectedGpvs, setSelectedGpvs } = useContext(
    GpvListContext
  ) as GpvStateContextType;
  const { multipleMode } = useContext(BoolContext) as BoolContextType;

  const tableCols: Array<{
    label?: string;
    width: number;
    grow?: number;
    style?: CSSProperties;
    headerStyle?: CSSProperties;
    onSort?: boolean;
    sortId?: string;
  }> = [
    {
      label: _t('table.natinfCode'),
      width: 80,
      onSort: true,
      sortId: 'natinfCode',
    },
    {
      label: _tg('field.date.signDate'),
      width: 150,
      onSort: true,
      sortId: 'signatureDatetime',
    },
    {
      label: _tg('field.agent.agent'),
      width: 140,
      onSort: true,
      sortId: 'agentName',
    },
    {
      label: _tg('field.agent.matricule'),
      width: 90,
      onSort: true,
      sortId: 'agentMatricule',
    },
    {
      label: _tg('field.address.street'),
      width: 210,
      onSort: true,
      sortId: 'streetFullName',
    },
    {
      label: _tg('field.address.postalCode'),
      width: 90,
      onSort: true,
      sortId: 'postalCode',
    },
    {
      label: _tg('field.address.locality'),
      width: 120,
      onSort: true,
      sortId: 'locality',
    },
    {
      label: _t('table.presetNote'),
      width: 140,
      onSort: true,
      sortId: 'presetNote',
    },
    {
      label: _t('table.infractionLocation'),
      width: 140,
      onSort: true,
      sortId: 'infractionLocation',
    },
    { label: _tg('field.status'), width: 100, onSort: true, sortId: 'status' },
  ];
  if (multipleMode) {
    tableCols.unshift({ label: '', width: 40 });
  }

  function onCheck(event: React.ChangeEvent<any>, checked: boolean) {
    // eslint-disable-next-line
    const { rowGpvId } = event.target.dataset;
    const localGpvs = [...selectedGpvs];
    if (checked) {
      localGpvs.push(rowGpvId);
    } else {
      localGpvs.splice(localGpvs.indexOf(rowGpvId), 1);
    }
    setSelectedGpvs(localGpvs);
  }

  function navigateToDetail(mif: GpvOverviewDTO): void {
    history.push(`/gpv/detail/${mif.id}`);
  }

  function renderRow({
    id,
    signatureDatetime,
    agentName,
    agentMatricule,
    streetFullName,
    city,
    postalCode,
    status,
    presetNote,
    infractionLocation,
    natinfCode,
  }: GpvOverviewDTO) {
    const rows = [
      <span>{natinfCode}</span>,
      <span>
        <DateComponent datetime={signatureDatetime} withTime />
      </span>,
      <span>{agentName}</span>,
      <span>{agentMatricule}</span>,
      <span title={streetFullName} style={ELLIPSIS_STYLE}>
        {streetFullName}
      </span>,
      <span>{postalCode}</span>,
      <span title={city} style={ELLIPSIS_STYLE}>
        {city}
      </span>,
      <span title={presetNote} style={ELLIPSIS_STYLE}>
        {presetNote}
      </span>,
      <span title={infractionLocation} style={ELLIPSIS_STYLE}>
        {infractionLocation}
      </span>,
      <span>{gpvStatusesName[status]}</span>,
    ];
    if (multipleMode) {
      rows.unshift(
        <Checkbox
          iconStyle={{ ...ICON_STYLE, fill: '#000' }}
          labelStyle={LABEL_STYLE}
          style={WIDTH_STYLE}
          checked={selectedGpvs.includes(id)}
          onCheck={onCheck}
          data-row-gpv-id={id}
        />
      );
    }
    return rows;
  }

  return (
    <>
      <SimpleTable
        cols={tableCols}
        rowHeight={50}
        loadMoreRows={loadMoreRows}
        remoteRowCount={totalHits}
        items={items}
        itemsRenderer={renderRow}
        onSort={onUpdateSort}
        colSorted={colSorted}
        sortOrder={sortOrder}
        onRowClick={canReadGpvs && !multipleMode ? navigateToDetail : undefined}
      />
    </>
  );
};
export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    canReadGpvs: userInfo && userInfo.rights.includes('GPV_READ'),
  };
})(GpvTable);
