import React from 'react';

import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import { BKG_PINK } from 'theme';

import './DetailSeparator.css';

type DetailSeparatorProps = {
  title: string;
};
const DetailSeparator = ({ title }: DetailSeparatorProps): JSX.Element => {
  return (
    <div className="detail_section-separator">
      <div className="detail_section-separator-content">
        <SeparatorWithTitle title={title} color={BKG_PINK} titleSize={25} />
      </div>
    </div>
  );
};

export default DetailSeparator;
