import querystring from 'querystring';

import {
  CancelOrderDTO,
  CritAirSource,
  EsSubscriptionStatus,
  LightSubscriptionDTO,
  OrderEsSearchQueryDTO,
  OrderEsSearchQueryScrollDTO,
  OrderPrivateDTO,
  OrderStatus,
  OrderTraceType,
  ParkingRightRequestDTO,
  ParkingRightRequestStatus,
  ProductPrivateDTO,
  SortOrder,
  SubscriberAccountType,
  SubscriberDTO,
  SubscriberVehicle,
  SubscriptionSource,
  VehicleTypeSource,
} from '@cvfm-front/tefps-types';
import { SortParameters } from 'api/commonTypes';
import {
  getPaymentInvoicePdfUrl,
  getPaymentProofPdfUrl,
  getSupportingDocumentPdfUrl,
} from 'api/cvfm-core-subscription/order';
import { openNewAuthentifiedTab } from 'api/helpers';
import { getLast } from 'commons/Utils/arrayUtils';
import { InternalAgent } from 'api/auth/types';

export type CancelFormType = {
  message: string;
  notifySubscriber: boolean;
};

export function generateRenewalURI(
  order: OrderPrivateDTO,
  orderProduct: ProductPrivateDTO
): string {
  return `/subscription/create/order/${orderProduct.productType.toLowerCase()}?${querystring.stringify(
    {
      subscriberId:
        order.subscribersHistory[order.subscribersHistory.length - 1]
          .subscriberIds[0],
      renewId: order.orderId,
    }
  )}`;
}

// TODO: Move { shouldRenewOrder, isOrderPrivateDTO } to cvfm-front in order to be used in cvfm-frontoffice, too
// Developer note: A new folder "tefps-utils" should be created due to dependencies conflicts with commons-utils

/**
 * Type narrowing function used to differentiate between V1 and V2 API responses
 * @param order
 */
function isOrderPrivateDTO(
  order: OrderPrivateDTO | LightSubscriptionDTO | ParkingRightRequestDTO
): order is OrderPrivateDTO {
  return (order as OrderPrivateDTO).orderId !== undefined;
}

function doesOrderContainNonProductZones(
  order: OrderPrivateDTO,
  product: ProductPrivateDTO
): boolean {
  const currentZones = getLast(order.zoneHistory);
  return (
    !!currentZones &&
    currentZones.zoneIds.some(zoneId => !product.zones.zoneIds.includes(zoneId))
  );
}

function isOrderStatusValidForRenewal(
  status: OrderStatus | EsSubscriptionStatus | ParkingRightRequestStatus
): boolean {
  return ['REJECTED', 'FULFILLED', 'ACCEPTED'].includes(status);
}

export function shouldRenewOrder(
  order: OrderPrivateDTO | LightSubscriptionDTO | ParkingRightRequestDTO,
  product: ProductPrivateDTO
): boolean {
  if (
    isOrderPrivateDTO(order) &&
    doesOrderContainNonProductZones(order, product)
  ) {
    return false;
  }

  return isOrderStatusValidForRenewal(order.status);
}

export const filtersToScrollRequest = (
  filters: OrderEsSearchQueryDTO,
  sort?: SortParameters,
  scrollId?: number,
  scrollSize?: number,
  scrollTimeout?: number
): OrderEsSearchQueryScrollDTO => {
  const {
    filterOrganizationIds,
    productTypes,
    orderIds,
    orderStatuses,
    subscriberIds,
    strictSubscriberIds,
    productIds,
    productOptionIds,
    plates,
    zoneIds,
    creation,
    requestDatetime,
    validityStart,
    validityEnd,
    usedRightsIds,
    createdRightsIds,
    customFields,
    customFieldValue,
    vehiclesCategories,
  } = filters;
  return {
    sort: {
      sortOrder: sort?.increasingOrder ? SortOrder.ASC : SortOrder.DESC,
      sortField: 'endOfValidity',
    },
    scrollId,
    scrollSize,
    scrollTimeout,
    query: {
      filterOrganizationIds,
      productTypes,
      orderIds,
      orderStatuses,
      subscriberIds,
      strictSubscriberIds,
      productIds,
      productOptionIds,
      plates,
      zoneIds,
      creation,
      requestDatetime,
      validityStart,
      validityEnd,
      usedRightsIds,
      createdRightsIds,
      customFields,
      customFieldValue,
      vehiclesCategories,
    },
  };
};

export function downloadPaymentPDF(orderId: string): void {
  const url = getPaymentProofPdfUrl(orderId);
  openNewAuthentifiedTab(url);
}

export function downloadSupportingDocumentPDF(orderId: string): void {
  const url = getSupportingDocumentPdfUrl(orderId);
  openNewAuthentifiedTab(url);
}

export function downloadInvoicePDF(orderId: string): void {
  const url = getPaymentInvoicePdfUrl(orderId);
  openNewAuthentifiedTab(url);
}

export function filterSubscriberVehiclesFromProductScope(
  product: ProductPrivateDTO,
  subscriber: SubscriberDTO,
  onlyProType?: boolean // undefined for all types
): SubscriberVehicle[] {
  if (!product.conditions.productScope) {
    return [];
  }

  if (
    product.conditions.productScope === SubscriberAccountType.PERSONAL &&
    onlyProType !== true
  ) {
    return subscriber.personalProfile?.vehicles || [];
  }

  if (
    product.conditions.productScope === SubscriberAccountType.PROFESSIONAL &&
    onlyProType !== false
  ) {
    return subscriber.professionalProfile?.vehicles || [];
  }

  if (product.conditions.productScope === SubscriberAccountType.BOTH) {
    if (onlyProType === false) {
      return subscriber.personalProfile?.vehicles || [];
    }
    if (onlyProType === true) {
      return subscriber.professionalProfile?.vehicles || [];
    }
    return (subscriber.personalProfile?.vehicles || []).concat(
      subscriber.professionalProfile?.vehicles || []
    );
  }
  return [];
}

export const getCancelOrderDTO = (
  formInfo: CancelFormType,
  userInfo: InternalAgent | null | undefined,
  massAction: boolean
): CancelOrderDTO => {
  return {
    timestamp: new Date().toISOString(),
    traceType: OrderTraceType.CANCELLATION,
    source: SubscriptionSource.BACK,
    agent: userInfo ? { ...userInfo } : null,
    subscriberId: null,
    massAction,
    comment: formInfo.message,
    notifySubscriber: formInfo.notifySubscriber,
  } as CancelOrderDTO;
};

export const AWAIT_DECISION_STATUSES: Array<OrderStatus> = [
  OrderStatus.AWAIT_COMPLETION_USER,
  OrderStatus.AWAIT_CLAIM_COMPLETION_USER,
  OrderStatus.AWAIT_TREATMENT_RENEWAL,
  OrderStatus.AWAIT_DECISION,
  OrderStatus.AWAIT_EVIDENCE,
  OrderStatus.AWAIT_CLAIM_EVIDENCES,
  OrderStatus.AWAIT_PLATE_DECISION,
  OrderStatus.AWAIT_TREATMENT_COMPLETUDE,
  OrderStatus.AWAIT_CLAIM_TREATMENT_COMPLETUDE,
  OrderStatus.AWAIT_CLAIM_DECISION,
  OrderStatus.AWAIT_CLAIM_EVIDENCES,
];
export const COMPLEMENT_STATUSES: Array<OrderStatus> = [
  OrderStatus.AWAIT_TREATMENT_RENEWAL,
  OrderStatus.AWAIT_DECISION,
  OrderStatus.AWAIT_EVIDENCE,
  OrderStatus.AWAIT_CLAIM_EVIDENCES,
  OrderStatus.AWAIT_PLATE_DECISION,
  OrderStatus.AWAIT_TREATMENT_COMPLETUDE,
  OrderStatus.AWAIT_CLAIM_TREATMENT_COMPLETUDE,
  OrderStatus.AWAIT_CLAIM_DECISION,
];

export const CANCELABLE_STATUSES: Array<OrderStatus> = [
  OrderStatus.FULFILLED,
  OrderStatus.AWAIT_PAYMENT,
];

export const VEHICLES_TYPES_SOURCES: VehicleTypeSource[] = [
  VehicleTypeSource.SIV,
  VehicleTypeSource.USAGER,
  VehicleTypeSource.AGENT,
];

export const CRITAIR_SOURCES: CritAirSource[] = [
  CritAirSource.SIV,
  CritAirSource.USAGER,
  CritAirSource.AGENT,
];

export function filterSubscriberVehiclesFromProductVehiclesCategories(
  product: ProductPrivateDTO,
  subscriberVehicles: SubscriberVehicle[]
): SubscriberVehicle[] {
  // no vehicleCategory configured MEANS all vehiclesCategories are valid
  if (product.conditions.vehiclesCategories.length === 0) {
    return subscriberVehicles;
  }

  return subscriberVehicles.filter(vehicle =>
    product.conditions.vehiclesCategories.includes(vehicle.vehicleCategory)
  );
}
