import * as React from 'react';

import { DataBoxItem } from 'commons/DataBox';
import FakeInputBlock from 'commons/FakeInputBlock';
import Date from 'commons/Date';
import { MifDetail } from 'api/tepv/mif/types';

const { _tg } = window.loadTranslations();

const TimeAndLocationBlock = ({
  mif: { signatureDatetime, address, zoneId },
}: {
  mif: MifDetail;
}) => (
  <DataBoxItem
    style={{
      maxWidth: '100%',
    }}
  >
    <FakeInputBlock
      title={_tg('field.date.date')}
      value={<Date datetime={signatureDatetime} withTime={false} />}
    />
    <FakeInputBlock
      title={_tg('field.date.hour')}
      value={<Date datetime={signatureDatetime} withDate={false} />}
    />
    <FakeInputBlock title={_tg('field.address.address')} value={address} />
    {zoneId && (
      <FakeInputBlock title={_tg('field.address.zone')} value={zoneId} />
    )}
  </DataBoxItem>
);

export default TimeAndLocationBlock;
