import React from 'react';
import moment from 'moment';
import IntlPolyfill from 'intl';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import DatePicker from 'material-ui/DatePicker';
import Checkbox from 'material-ui/Checkbox';
import { connect } from 'react-redux';

import { TXT_BLACK } from 'theme';
import { DecreeDTO, DecreeType } from 'api/tepv/decrees/types';
import { formatDate } from 'commons/Utils/dateUtil';
import ErrorBlock from 'commons/ErrorBlock';
import { getConfigState } from 'config/duck';
import { CityOrganizationDTO } from 'api/organizations/types';

import { TYPE_OPTIONS } from './commons';

const { _t, _tg } = window.loadTranslations(__filename);

type DeletionProps = {
  deletionReference: string | null | undefined;
  closeModal: () => void;
  removeDecree: () => Promise<void>;
  error: Error | null | undefined;
};

export const DeletionModal = ({
  deletionReference,
  closeModal,
  removeDecree,
  error,
}: DeletionProps) => (
  <Dialog
    title={_t('element.dialogDelete.title', {
      id: deletionReference || '',
    })}
    open={!!deletionReference}
    autoScrollBodyContent
    onRequestClose={closeModal}
    actions={[
      <FlatButton label={_tg('action.cancel')} onClick={closeModal} />,
      <FlatButton
        label={_tg('action.delete')}
        secondary
        onClick={removeDecree}
      />,
    ]}
  >
    {_t('feedback.confirm.delete', { id: deletionReference || '' })}
    {error && (
      <ErrorBlock message={_t('feedback.error.delete')} error={error} />
    )}
  </Dialog>
);

type EditionProps = {
  closeModal: () => void;
  editDecree?: (instrument: DecreeDTO) => Promise<void>;
  decree: DecreeDTO | null | undefined;
  open: boolean;
  isGpvEnabled: boolean;
  organizationFilterEnabled: boolean;
  organizations: CityOrganizationDTO[] | null | undefined;
  decreesReferences?: Array<string>;
  display?: boolean;
  error?: Error | null | undefined;
};

type EditionState = {
  type: DecreeType;
  reference: string;
  comment: string;
  applicationDate: Date;
  endOfApplicationDate: Date | null | undefined;
  selectedOrganizationId: string | null;
  mifDecree: boolean;
  gpvDecree: boolean;
  decreeText: string;
  errorText: string | null | undefined;
  decreeErrorText: string | null | undefined;
};

const buildState = (props: EditionProps): EditionState => {
  if (!props.decree) {
    return {
      type: 'MUNICIPAL',
      reference: '',
      comment: '',
      applicationDate: new Date(),
      endOfApplicationDate: null,
      selectedOrganizationId:
        props.organizations && props.organizations.length > 0
          ? props.organizations[0].organizationId
          : null,
      mifDecree: false,
      gpvDecree: false,
      decreeText: '',
      errorText: null,
      decreeErrorText: null,
    };
  }
  return {
    type: props.decree.type,
    reference: props.decree.reference,
    comment: props.decree.comment,
    applicationDate: new Date(props.decree.applicationDatetime),
    endOfApplicationDate: props.decree.endOfApplicationDatetime
      ? new Date(props.decree.endOfApplicationDatetime)
      : null,
    mifDecree: props.decree.mifDecree,
    gpvDecree: props.decree.gpvDecree,
    decreeText: props.decree.decreeText,
    selectedOrganizationId: props.decree.organizationId,
    errorText: null,
    decreeErrorText: null,
  };
};

class EditionModal extends React.Component<EditionProps, EditionState> {
  constructor(props: EditionProps) {
    super(props);
    this.state = buildState(props);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: EditionProps) {
    if (nextProps.open && !this.props.open) {
      this.setState(buildState(nextProps));
    }
  }

  checkReferenceError = () => {
    const { reference } = this.state;
    if (!reference) {
      this.setState({ errorText: _t('feedback.error.missingReference') });
    }
    return !reference;
  };

  checkDecreeError = () => {
    const { isGpvEnabled } = this.props;
    const { mifDecree, gpvDecree } = this.state;
    // if Gpv is not enabled, then the decree is a MIF decree, will be handled in the backend
    const decreeError = !mifDecree && !gpvDecree && isGpvEnabled;
    if (decreeError) {
      this.setState({
        decreeErrorText: _t('feedback.error.missingDecreeType'),
      });
    }
    return decreeError;
  };

  onFieldChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const {
      value,
      dataset: { id },
    } = target;
    if (id) {
      const newState = { ...this.state };
      newState[id] = value;
      this.setState(newState);
    }
  };

  onChangeApplicationDate = (useless: any, newDate: Date) =>
    this.setState({ applicationDate: newDate });

  onChangeEndOfApplicationDate = (useless: any, newDate: Date) =>
    this.setState({ endOfApplicationDate: newDate });

  onTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number,
    payload: any
  ) => this.setState({ type: payload });

  onChangeReference = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = target;

    this.setState({
      errorText: this.isIdAlreadyUsed(value)
        ? _t('feedback.error.alreadyUsed')
        : null,
      reference: value,
    });
  };

  editDecree = () => {
    const {
      type,
      reference,
      comment,
      decreeText,
      applicationDate,
      endOfApplicationDate,
      selectedOrganizationId,
      mifDecree,
      gpvDecree,
    } = this.state;
    const decree: DecreeDTO = {
      type,
      reference,
      organizationId: selectedOrganizationId,
      comment,
      decreeText,
      applicationDatetime: moment(applicationDate).toISOString(),
      endOfApplicationDatetime: endOfApplicationDate
        ? moment(endOfApplicationDate).toISOString()
        : null,
      mifDecree,
      gpvDecree,
    };

    if (this.props.editDecree) {
      this.props.editDecree(decree);
    }
  };

  checkAndEditDecree = () => {
    if (!this.computeCantSave()) {
      this.editDecree();
    }
  };

  handleEndOfApplicationDateCheck = (
    e: React.MouseEvent,
    hasEndOfApplication: boolean
  ) =>
    this.setState({
      endOfApplicationDate: hasEndOfApplication
        ? this.state.applicationDate
        : null,
    });

  handleMifDecreeCheck = (e: React.MouseEvent, mif: boolean) =>
    this.setState({
      mifDecree: mif,
      decreeErrorText: '',
    });

  handleGpvDecreeCheck = (e: React.MouseEvent, gpv: boolean) =>
    this.setState({
      gpvDecree: gpv,
      decreeErrorText: '',
    });

  isIdAlreadyUsed = (value: string) => {
    const { decreesReferences } = this.props;
    return !!decreesReferences && decreesReferences.includes(value);
  };

  computeCantSave = () => {
    return this.checkDecreeError() || this.checkReferenceError();
  };

  onOrganizationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number,
    value: any
  ) => this.setState({ selectedOrganizationId: value });

  render() {
    const {
      closeModal,
      decree,
      open,
      display,
      error,
      isGpvEnabled,
      organizations,
      organizationFilterEnabled,
    } = this.props;
    const {
      type,
      selectedOrganizationId,
      reference,
      comment,
      applicationDate,
      endOfApplicationDate,
      mifDecree,
      gpvDecree,
      decreeText,
      errorText,
      decreeErrorText,
    } = this.state;

    const creationMode = !decree;
    let titleModal;
    if (display) {
      titleModal = _t('element.dialogUpdate.title.display');
    } else {
      titleModal = creationMode
        ? _t('element.dialogUpdate.title.create')
        : _t('element.dialogUpdate.title.update');
    }
    const decreeCheckboxStyle = {
      width: 256,
      marginTop: '1.7em',
      display: 'inline-flex',
    };
    return (
      <Dialog
        title={titleModal}
        open={open}
        autoScrollBodyContent
        onRequestClose={closeModal}
        actions={[
          <FlatButton label={_tg('action.cancel')} onClick={closeModal} />,
          <FlatButton
            label={creationMode ? _tg('action.create') : _tg('action.edit')}
            primary
            onClick={this.checkAndEditDecree}
          />,
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            data-id="reference"
            onChange={this.onChangeReference}
            onBlur={this.checkDecreeError}
            placeholder={_t('element.dialogUpdate.fieldRef.placeholder')}
            value={reference}
            maxlength="50"
            errorText={errorText}
            disabled={!creationMode}
          />
          <span
            style={{
              color: this.state.reference?.length > 40 ? 'red' : 'black',
              fontSize: 10,
            }}
          >
            {this.state.reference?.length}/50
          </span>
          <br />
          {organizationFilterEnabled &&
            organizations &&
            organizations?.length > 0 && (
              <>
                <SelectField
                  data-id="organization"
                  floatingLabelText={`${_tg(
                    'field.organisation.organisation'
                  )}*`}
                  value={selectedOrganizationId}
                  onChange={this.onOrganizationChange}
                  disabled={display}
                  style={display ? { color: TXT_BLACK } : undefined}
                >
                  {organizations?.map(organization => (
                    <MenuItem
                      value={organization.organizationId}
                      primaryText={organization.name}
                    />
                  ))}
                </SelectField>
                <br />
              </>
            )}
          <SelectField
            data-id="type"
            floatingLabelText={_t('element.dialogUpdate.selectType.label')}
            value={type}
            errorText=""
            onChange={this.onTypeChange}
            disabled={display}
            style={display ? { color: TXT_BLACK } : undefined}
          >
            {TYPE_OPTIONS.map(opts => (
              <MenuItem value={opts.value} primaryText={opts.label} />
            ))}
          </SelectField>
          <br />
          <DatePicker
            DateTimeFormat={IntlPolyfill.DateTimeFormat}
            locale={window.i18next.language}
            autoOk
            textFieldStyle={{ width: 200, marginRight: 5 }}
            value={applicationDate}
            onChange={this.onChangeApplicationDate}
            floatingLabelText={_t('element.dialogUpdate.dateStart.label')}
            container="inline"
            formatDate={formatDate}
            disabled={display}
            inputStyle={display ? { color: TXT_BLACK } : undefined}
          />
          <br />
          <Checkbox
            label={_t('element.dialogUpdate.checkboxEnd.label')}
            checked={!!endOfApplicationDate}
            onCheck={this.handleEndOfApplicationDateCheck}
            style={{ width: 256, marginTop: '1.7em' }}
          />
          {endOfApplicationDate && [
            <br />,
            <DatePicker
              DateTimeFormat={IntlPolyfill.DateTimeFormat}
              locale={window.i18next.language}
              autoOk
              textFieldStyle={{ width: 200, marginRight: 5 }}
              value={endOfApplicationDate}
              onChange={this.onChangeEndOfApplicationDate}
              floatingLabelText={_t('element.dialogUpdate.dateEnd.label')}
              container="inline"
              formatDate={formatDate}
              disabled={display}
              inputStyle={display ? { color: TXT_BLACK } : undefined}
            />,
          ]}
          {isGpvEnabled && (
            <div>
              <br />
              <br />
              <Checkbox
                label={_t('element.dialogUpdate.mif.label')}
                id="cMif"
                checked={mifDecree}
                onCheck={this.handleMifDecreeCheck}
                style={decreeCheckboxStyle}
              />
              <Checkbox
                label={_t('element.dialogUpdate.gpv.label')}
                id="cGpv"
                checked={gpvDecree}
                onCheck={this.handleGpvDecreeCheck}
                style={decreeCheckboxStyle}
              />
              <br />
              <br />
              <div style={{ color: '#ff0000', fontSize: '11px' }}>
                {decreeErrorText}
              </div>
            </div>
          )}
          <br />
          <TextField
            data-id="comment"
            onChange={this.onFieldChange}
            floatingLabelText={_t(
              'element.dialogUpdate.fieldComment.placeholder'
            )}
            value={comment}
            errorText=""
            multiLine
            rowsMax={3}
            style={{ width: 'auto', flexGrow: 1, maxWidth: '80%' }}
            disabled={display}
            inputStyle={display ? { color: TXT_BLACK } : undefined}
          />
          <br />
          <TextField
            data-id="decreeText"
            onChange={this.onFieldChange}
            floatingLabelText={_t(
              'element.dialogUpdate.fieldDecreeText.placeholder'
            )}
            value={decreeText}
            errorText=""
            multiLine
            rowsMax={5}
            style={{ width: 'auto', flexGrow: 1, maxWidth: '80%' }}
            disabled={display}
            inputStyle={display ? { color: TXT_BLACK } : undefined}
          />
          {error && (
            <ErrorBlock
              message={
                creationMode
                  ? _t('feedback.error.create')
                  : _t('feedback.error.update')
              }
              error={error}
            />
          )}
        </div>
      </Dialog>
    );
  }
}

export default connect(state => {
  const config = getConfigState(state);
  return {
    isGpvEnabled: config.modulesConfiguration.gpv.enabled,
    organizationFilterEnabled: config.organizationFilterEnabled,
  };
})(EditionModal);
