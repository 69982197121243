import * as React from 'react';

import { DataBoxItem } from 'commons/DataBox';
import FakeInputBlock from 'commons/FakeInputBlock';
import EditionModal from 'tepv/Decree/modals';
import { DecreeDTO, DecreeType } from 'api/tepv/decrees/types';

const { _t } = window.loadTranslations(__filename);

type Props = {
  type: DecreeType;
  reference: string;
  comment: string;
  date: string;
  endOfApplicationDatetime: string | null | undefined;
  mifDecree: boolean;
  gpvDecree: boolean;
  decreeText: string;
};

type State = {
  isDisplayModalOpen: boolean;
};

class DecreeBlock extends React.Component<Props, State> {
  state = {
    isDisplayModalOpen: false,
  };

  openDisplayModal = () => {
    this.setState({
      isDisplayModalOpen: true,
    });
  };

  closeDisplayModal = () => {
    this.setState({
      isDisplayModalOpen: false,
    });
  };

  render() {
    const displayedDecree: DecreeDTO = {
      type: this.props.type,
      reference: this.props.reference,
      comment: this.props.comment,
      applicationDatetime: this.props.date,
      endOfApplicationDatetime: this.props.endOfApplicationDatetime,
      mifDecree: this.props.mifDecree,
      gpvDecree: this.props.gpvDecree,
      decreeText: this.props.decreeText,
      organizationId: null,
    };

    return (
      <DataBoxItem
        style={{
          maxWidth: '100%',
        }}
      >
        {this.props.comment && (
          <div onClick={this.openDisplayModal} style={{ cursor: 'pointer' }}>
            <FakeInputBlock
              title={_t('element.fieldDecree.title')}
              value={this.props.comment}
            />
          </div>
        )}
        <EditionModal
          closeModal={this.closeDisplayModal}
          decree={displayedDecree}
          organizations={[]}
          open={this.state.isDisplayModalOpen}
          display
        />
      </DataBoxItem>
    );
  }
}

export default DecreeBlock;
