import * as React from 'react';
import { IndexRange } from 'react-virtualized';
import { useRouteMatch } from 'react-router-dom';

import { SimpleSubscriberDTO } from '@cvfm-front/tefps-types';
import SimpleTable from 'commons/SimpleTable';
import './SubscriberTablePage.css';
import BoButton from 'facade/BoButton';

const { _t, _tg } = window.loadTranslations(__filename);

type SubscriberTableProps = {
  pagedSubscribers: Array<SimpleSubscriberDTO>;
  totalSubscribers: number;
  sortIndex: number;
  sortAscending: boolean;
  onSort: (colIndex: number, ascending: boolean, field: string) => void;
  onRange: (fromIndex: number) => Promise<void>;
};

const SubscriberTable = ({
  pagedSubscribers: subscribers,
  totalSubscribers: subscribersCount,
  sortIndex,
  sortAscending,
  onSort,
  onRange,
}: SubscriberTableProps): JSX.Element => {
  const { url } = useRouteMatch();

  function handleTableSort(
    colIndex: number,
    ascending?: boolean,
    sortField?: string
  ) {
    if (ascending !== undefined && ascending !== null && sortField) {
      onSort(colIndex, ascending, sortField);
    }
  }

  function handleTableRange(range: IndexRange): Promise<void> {
    return onRange(range.startIndex);
  }

  const getHeaderCols = () => [
    {
      label: _t('table.header.subscriberName'),
      width: 240,
      grow: 1,
      sortId: 'lastName.raw',
      onSort: true,
    },
    {
      label: _t('table.header.email'),
      width: 240,
      grow: 1,
      sortId: 'email',
      onSort: false,
    },
    {
      label: _t('table.header.ordersToProcess'),
      width: 120,
      grow: 1,
      sortId: 'id',
      onSort: false,
    },
    {
      label: _t('table.header.ordersToPay'),
      width: 120,
      grow: 1,
      sortId: 'id',
      onSort: false,
    },
    {
      label: _t('table.header.ordersProcessed'),
      width: 120,
      grow: 1,
      sortId: 'id',
      onSort: false,
    },
    {
      label: _t('table.header.activeRights'),
      width: 120,
      grow: 1,
      sortId: 'id',
      onSort: false,
    },
    { label: '', width: 80, grow: 1, onSort: false },
  ];

  const renderSubscriberNameCell = (
    subscriber: SimpleSubscriberDTO
  ): JSX.Element => {
    let name;
    if (subscriber.gender === 'ORGA') {
      name = subscriber.companyName;
    } else {
      name = `${subscriber.lastName || ''} ${subscriber.firstName || ''}`;
    }
    return (
      <a
        id={`${subscriber.subscriberId}-id`}
        className="order-table-row_id"
        href={`#${url}/${subscriber.subscriberId}`}
      >
        {name}
      </a>
    );
  };

  const renderTextFieldCell = (
    subscriber: SimpleSubscriberDTO,
    field: string
  ): JSX.Element => {
    return (
      <span
        id={`${subscriber.subscriberId}-${field}`}
        className="subscriber-table-row"
      >
        {subscriber[field] as string}
      </span>
    );
  };

  const renderDetailCell = (subscriber: SimpleSubscriberDTO): JSX.Element => {
    return (
      <span
        id={`${subscriber.subscriberId}-detail`}
        className="order-table-row_delete"
      >
        <BoButton
          primary
          label={_tg('field.detail')}
          data-id={subscriber.subscriberId}
          href={`#${url}/${subscriber.subscriberId}`}
        />
      </span>
    );
  };

  const renderRow = (subscriber: SimpleSubscriberDTO) => {
    const row = [
      renderSubscriberNameCell(subscriber),
      renderTextFieldCell(subscriber, 'email'),
      renderTextFieldCell(subscriber, 'waitingSubscriptionsCount'),
      renderTextFieldCell(subscriber, 'toPaySubscriptionsCount'),
      renderTextFieldCell(subscriber, 'validatedSubscriptionsCount'),
      renderTextFieldCell(subscriber, 'activeSubscriptionsCount'),
      renderDetailCell(subscriber),
    ];
    return row;
  };

  return (
    <SimpleTable
      style={{ marginTop: 25 }}
      cols={getHeaderCols()}
      rowHeight={50}
      itemsRenderer={renderRow}
      items={subscribers}
      remoteRowCount={subscribersCount}
      loadMoreRows={handleTableRange}
      onSort={handleTableSort}
      colSorted={sortIndex}
      sortOrder={sortAscending}
    />
  );
};

export default SubscriberTable;
