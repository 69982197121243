import React from 'react';
import { connect } from 'react-redux';
import Checkbox from 'material-ui/Checkbox';

import SimpleTable from 'commons/SimpleTable';
import FlexCenter from 'commons/FlexCenter';
import Content from 'commons/Content';
import ErrorBlock from 'commons/ErrorBlock';
import Exporter from 'commons/Exporter';
import { ListWrapper, ListBody, ListBottom } from 'commons/ListWrappers';
import { getApiState } from 'api/duck';
import { fetchMotifs, getExport, updateMotifEnabled } from 'api/tepv/motifs';
import { MotifDTO } from 'api/tepv/motifs/types';
import MotifSidebar from 'tepv/Motif/List/MotifSidebar';

import ImportMotifs from './ImportMotifs';
import { getExportCols } from './utils';
import { MotifFilters } from './types';

const { _t, _tg } = window.loadTranslations(__filename);

type MotifListState = {
  filters: MotifFilters;
  motifs: Array<MotifDTO>;
  filteredMotifs: Array<MotifDTO>;
  filtersData: Set<string>;
  loading: boolean;
  sortCol: number;
  sortAsc: boolean;
  errorMessage: Error | null;
};

type MotifListProps = {
  canReadMotif: boolean;
  canUpdateMotif: boolean;
  canExportMotif: boolean;
};

function getInitialFilters(): MotifFilters {
  return {
    categories: [],
    qualificationSearchText: '',
    dateDisablePeriod: {
      from: undefined,
      to: undefined,
    },
    dateImportPeriod: {
      from: undefined,
      to: undefined,
    },
  };
}

const getTableCols = () => [
  { label: _t('table.motifs'), width: 300, grow: 30 },
  { label: _t('table.category'), width: 300, onSort: true },
  {
    label: _t('table.mandatoryInterception'),
    width: 200,
    onSort: true,
  },
  { label: _t('table.gpvNumber'), width: 200, onSort: true },
  { label: _t('table.activation'), width: 200, onSort: true },
];

const CATEGORY_COLUMN = 1;
const INTERPEL_COLUMN = 2;
const NUMBER_COLUMN = 3;
const ENABLED_COLUMN = 4;

const sortMotifs = (
  unsortedMotifs: Array<MotifDTO>,
  sortCol: number,
  sortAsc: boolean
): Array<MotifDTO> => {
  const sorted = unsortedMotifs.sort(
    (
      {
        category: catA,
        enabled: enabledA,
        mandatoryInterception: mandatoryInterceptionA,
        gpvCount: gpvCountA,
      },
      {
        category: catB,
        enabled: enabledB,
        mandatoryInterception: mandatoryInterceptionB,
        gpvCount: gpvCountB,
      }
    ) => {
      switch (sortCol) {
        case CATEGORY_COLUMN:
          return catB ? catA.localeCompare(catB) : -1;
        case ENABLED_COLUMN:
          if (enabledA === enabledB) {
            return gpvCountA - gpvCountB;
          }
          return enabledA ? 1 : -1;
        case INTERPEL_COLUMN:
          if (mandatoryInterceptionA === mandatoryInterceptionB) {
            return 0;
          }
          return mandatoryInterceptionA ? 1 : -1;
        case NUMBER_COLUMN:
          return gpvCountA - gpvCountB;
        default:
          return 0;
      }
    }
  );
  return sortAsc ? sorted : sorted.reverse();
};

class MotifList extends React.Component<MotifListProps, MotifListState> {
  state = {
    filters: getInitialFilters(),
    motifs: [],
    filteredMotifs: [],
    filtersData: new Set<string>(),
    loading: true,
    sortCol: 1,
    sortAsc: true,
    errorMessage: null,
  };

  componentDidMount() {
    this.fetchMotifs();
  }

  onCreate = async () => {
    await this.fetchMotifs();
  };

  onUpdateSort = (sortCol: number, sortAsc: boolean) => {
    this.setState({
      sortCol,
      sortAsc,
      filteredMotifs: sortMotifs(this.state.filteredMotifs, sortCol, sortAsc),
    });
  };

  resetFilters = () => this.filterMotifs(getInitialFilters());

  filterMotifs = (filters: MotifFilters) => {
    const { motifs, sortCol, sortAsc } = this.state;
    const {
      categories,
      dateImportPeriod,
      dateDisablePeriod,
      qualificationSearchText,
    } = filters;

    const filteredMotifs = motifs.filter(
      ({ qualification, category, importDate, abrogrationDate }: MotifDTO) => {
        if (categories.length && !categories.includes(category)) {
          return false;
        }

        const dateImportTimestamp = Date.parse(importDate);
        if (
          dateImportPeriod.from &&
          dateImportTimestamp <= dateImportPeriod.from.getTime()
        )
          return false;
        if (
          dateImportPeriod.to &&
          dateImportTimestamp >= dateImportPeriod.to.getTime()
        )
          return false;

        const dateDisableTimestamp = Date.parse(abrogrationDate);
        if (
          dateDisablePeriod.from &&
          dateDisableTimestamp <= dateDisablePeriod.from.getTime()
        )
          return false;
        if (
          dateDisablePeriod.to &&
          dateDisableTimestamp >= dateDisablePeriod.to.getTime()
        )
          return false;

        if (
          !qualification
            .toLowerCase()
            .includes(qualificationSearchText.toLowerCase())
        )
          return false;
        return true;
      }
    );
    this.setState({
      filteredMotifs: sortMotifs(filteredMotifs, sortCol, sortAsc),
      filters,
    });
  };

  onCheckMotif = async (event: React.SyntheticEvent<any>, checked: boolean) => {
    const { motifId } = event.currentTarget.dataset;
    try {
      await updateMotifEnabled(motifId, checked);
      this.fetchMotifs();
    } catch (err) {
      this.setState({ errorMessage: err });
    }
  };

  async fetchMotifs() {
    const { sortCol, sortAsc } = this.state;
    let motifs = [];
    try {
      motifs = await fetchMotifs();
    } catch (error) {
      this.setState({ errorMessage: error });
      return;
    }
    const categories = new Set<string>();

    motifs.forEach((motif: MotifDTO) => {
      categories.add(motif.category);
    });

    this.setState({
      motifs,
      filteredMotifs: sortMotifs(motifs, sortCol, sortAsc),
      filtersData: categories,
      loading: false,
      errorMessage: null,
    });
  }

  renderRow = ({
    id,
    qualification,
    category,
    mandatoryInterception,
    enabled,
    gpvCount,
  }: MotifDTO) => [
    <span>
      {this.props.canReadMotif ? (
        <a href={this.props.canReadMotif ? `#/motif/detail/${id}` : ``}>
          {qualification}
        </a>
      ) : (
        qualification
      )}
    </span>,
    <span>{category}</span>,
    <span>{mandatoryInterception ? _tg('field.yes') : _tg('field.no')}</span>,
    <span>{gpvCount}</span>,
    <span>
      <Checkbox
        checked={enabled}
        onCheck={this.onCheckMotif}
        disabled={!this.props.canUpdateMotif}
        data-motif-id={id}
      />
    </span>,
  ];

  render() {
    const {
      filteredMotifs,
      loading,
      filtersData,
      sortCol,
      sortAsc,
      errorMessage,
      filters,
    } = this.state;
    const { canExportMotif, canUpdateMotif } = this.props;

    if (errorMessage) {
      return (
        <Content>
          <FlexCenter>
            <ErrorBlock
              message={_tg('feedback.error.fetchMotif_plural')}
              error={errorMessage}
            />
          </FlexCenter>
        </Content>
      );
    }
    return (
      <div style={{ height: '100%', fontFamily: 'Roboto', display: 'flex' }}>
        <MotifSidebar
          filters={filters}
          filtersData={filtersData}
          updateFilters={this.filterMotifs}
          resetFilters={this.resetFilters}
          totalHits={filteredMotifs.length}
        />
        <ListWrapper style={{ width: '100%', paddingTop: 20 }}>
          <ListBody loading={loading} style={{ height: 'calc(100% - 10px)' }}>
            <SimpleTable
              cols={getTableCols()}
              rowHeight={100}
              itemsRenderer={this.renderRow}
              items={filteredMotifs}
              remoteRowCount={filteredMotifs.length}
              colSorted={sortCol}
              sortOrder={sortAsc}
              onSort={this.onUpdateSort}
            />
          </ListBody>
          {(canExportMotif || canUpdateMotif) && (
            <ListBottom>
              <div style={{ marginRight: 30 }}>
                {canExportMotif && (
                  <Exporter
                    disabled={filteredMotifs.length === 0}
                    type="motifs"
                    columns={getExportCols()}
                    fileExport={getExport}
                  />
                )}
              </div>
              <div>
                {canUpdateMotif && <ImportMotifs onCreate={this.onCreate} />}
              </div>
            </ListBottom>
          )}
        </ListWrapper>
      </div>
    );
  }
}

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    canReadMotif: userInfo && userInfo.rights.includes('MOTIFS_READ'),
    canUpdateMotif: userInfo && userInfo.rights.includes('MOTIFS_IMPORT'),
    canExportMotif: userInfo && userInfo.rights.includes('MOTIFS_EXPORT'),
  };
})(MotifList);
