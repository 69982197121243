import * as React from 'react';
import TextField from 'material-ui/TextField';

import BoButton from 'facade/BoButton';
import FakeInputBlock from 'commons/FakeInputBlock';

const { _t } = window.loadTranslations(__filename);

type FreeCommentsBlockProps = {
  canAddComments: boolean;
  comments: Array<string>;
  updateComments: (comment: string) => any;
};

type FreeCommentsBlockState = {
  newCommentText: string | null | undefined;
};

class FreeCommentsBlock extends React.Component<
  FreeCommentsBlockProps,
  FreeCommentsBlockState
> {
  state: FreeCommentsBlockState = {
    newCommentText: null,
  };

  onNewCommentEdit = (e: any, newCommentText: string) =>
    this.setState({ newCommentText });

  addComment = () =>
    this.state.newCommentText === null && this.setState({ newCommentText: '' });

  patchNewComment = () => {
    const { updateComments, canAddComments } = this.props;
    const { newCommentText } = this.state;

    if (
      newCommentText === null ||
      newCommentText === undefined ||
      !canAddComments
    ) {
      return;
    }
    updateComments(newCommentText);

    this.setState({ newCommentText: null });
  };

  render() {
    const { comments, canAddComments } = this.props;
    const { newCommentText } = this.state;
    return (
      <>
        {comments.map((comment: string, index: number) => (
          <FakeInputBlock
            title={index === 0 ? _t('element.fieldInfo.title') : ''}
            value={comment}
          />
        ))}
        <div>
          {newCommentText !== null && [
            <TextField
              onChange={this.onNewCommentEdit}
              value={newCommentText}
            />,
            <span
              style={{ marginLeft: 5, cursor: 'pointer' }}
              onClick={this.patchNewComment}
            >
              +
            </span>,
          ]}
        </div>
        {canAddComments && (
          <BoButton
            onClick={this.addComment}
            label={_t('element.buttonAdd.label')}
          />
        )}
      </>
    );
  }
}

export default FreeCommentsBlock;
