import React from 'react';

const { _t } = window.loadTranslations(__filename);

function SubscriberTransferAction({ action }: any): JSX.Element {
  return (
    <span>
      {_t('element.content', {
        from: action.fromSubscriberId,
      })}
    </span>
  );
}

export default SubscriberTransferAction;
