import React from 'react';

import { SubscriberDTO, ZoneDTO } from '@cvfm-front/tefps-types';
import { coreAddressRenderer } from 'commons/Address/SimpleAddressRenderer';
import './OrderDetailPage.css';
import { DataBoxItem, DataBoxItemWrapper } from 'commons/DataBox';
import Separator from 'commons/Separator';
import FakeInputBlock from 'commons/FakeInputBlock';
import { formatGender } from '@cvfm-front/tefps-utils';

import { frontTypes } from '../Beneficiaries/utils';

const { _tg } = window.loadTranslations(__filename);

const SubscriberV2Details = ({
  subscriber,
  zones,
  hasPmr,
  langEnabled,
}: {
  subscriber: SubscriberDTO;
  zones: Array<ZoneDTO>;
  hasPmr: boolean;
  langEnabled: boolean;
}): JSX.Element => {
  const { personalProfile, professionalProfile } = subscriber;

  const DATA_BOX_ITEM_STYLE = {
    maxWidth: '500px',
  };
  return (
    <DataBoxItemWrapper
      style={{ justifyContent: 'start', marginBottom: 20, width: '95%' }}
    >
      <DataBoxItem style={DATA_BOX_ITEM_STYLE}>
        <FakeInputBlock
          title={_tg('field.human.gender')}
          value={_tg(formatGender(subscriber.gender))}
        />
        <FakeInputBlock
          title={_tg('field.human.lastname_short')}
          value={subscriber.lastName}
        />
        <FakeInputBlock
          title={_tg('field.human.firstname')}
          value={subscriber.firstName}
        />
        {subscriber.gender === 'ORGA' && (
          <FakeInputBlock
            title={_tg('field.company.name')}
            value={subscriber.companyName}
          />
        )}
        <FakeInputBlock
          title={_tg('field.human.email')}
          value={subscriber.email}
        />
        {langEnabled && (
          <FakeInputBlock
            title={_tg('commons.lang')}
            value={_tg(`commons.langs.${subscriber.lang}`)}
          />
        )}
        <FakeInputBlock
          title={_tg('field.human.phoneNumber')}
          value={subscriber.phoneNumber}
        />
        <FakeInputBlock
          title={_tg('field.human.homePhoneNumber')}
          value={subscriber.homePhoneNumber ? subscriber.homePhoneNumber : ''}
        />
        {personalProfile && (
          <>
            <FakeInputBlock
              title={_tg('field.address.residenceZone')}
              value={
                personalProfile?.location.zoneId
                  ? zones.find(z => z.id === personalProfile?.location.zoneId)
                      ?.name
                  : undefined
              }
            />
            <FakeInputBlock
              title={_tg('field.address.address')}
              value={coreAddressRenderer(personalProfile.location.address)}
            />
          </>
        )}
        {subscriber.subscriberPendingEmail &&
          new Date(subscriber.subscriberPendingEmail.expiryDate) >
            new Date() && (
            <FakeInputBlock
              title={_tg('field.human.pending_email')}
              value={subscriber.subscriberPendingEmail.pendingEmail}
            />
          )}
        {hasPmr && (
          <FakeInputBlock
            title={_tg('field.defaultFrontType')}
            value={
              frontTypes.find(type => type.id === subscriber.defaultFrontType)
                ?.name
            }
          />
        )}
      </DataBoxItem>
      {professionalProfile && <Separator />}
      {professionalProfile && (
        <DataBoxItem style={DATA_BOX_ITEM_STYLE}>
          <FakeInputBlock
            title={_tg('field.company.siret')}
            value={professionalProfile.company.siret}
          />
          <FakeInputBlock
            title={_tg('field.company.name')}
            value={professionalProfile.company.companyName}
          />
          <FakeInputBlock
            title={_tg('field.company.activity')}
            value={professionalProfile.company.activity}
          />
          <FakeInputBlock
            title={_tg('field.company.phoneNumber')}
            value={subscriber.phoneNumber}
          />
          {professionalProfile.location.address && (
            <>
              <FakeInputBlock
                title={_tg('field.address.professionalZone')}
                value={
                  professionalProfile?.location.zoneId
                    ? zones.find(
                        z => z.id === professionalProfile?.location.zoneId
                      )?.name
                    : undefined
                }
              />
              <FakeInputBlock
                title={_tg('field.address.professionalAddress')}
                value={coreAddressRenderer(
                  professionalProfile.location.address
                )}
              />
            </>
          )}
        </DataBoxItem>
      )}
    </DataBoxItemWrapper>
  );
};

export default SubscriberV2Details;
