import * as React from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';

import MissNotFound from 'commons/MissNotFound';

import GpvList from './List';
import GpvDetails from './Detail';

const PvDashboard = ({ match }: RouteComponentProps) => (
  <div style={{ height: '100%', margin: 'auto' }}>
    <Switch>
      <Redirect exact path={`${match.url}`} to={`${match.url}/list`} />
      <Route path={`${match.url}/list`} component={GpvList} />
      <Route path={`${match.url}/detail/:gpvId`} component={GpvDetails} />
      <MissNotFound />
    </Switch>
  </div>
);

export default withRouter(PvDashboard);
