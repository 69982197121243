import React from 'react';
import Card, { CardHeader, CardText } from 'material-ui/Card';

import SimpleTable from 'commons/SimpleTable';
import Date from 'commons/Date';
import { SubscriberDTO } from '@cvfm-front/tefps-types';
import { ItemIdName, JsonDatetime } from 'api/commonTypes';

import { computeHistory, History } from './history';
import './ProductDetailPage.css';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_CARD = {
  flex: 1,
  margin: 10,
  minWidth: 300,
};
type SubscriberDetailPlatesProps = {
  subscriber: SubscriberDTO;
  productItems: Array<ItemIdName>;
};

const SubscriberDetailHistory = ({
  subscriber,
  productItems,
}: SubscriberDetailPlatesProps): JSX.Element => {
  const translateHistoryCols = () => [
    { label: _tg('field.date.date'), width: 40, grow: 1 },
    { label: _t('historyCols.exchange'), width: 190, grow: 3 },
  ];

  return (
    <div style={{ flex: 1, width: '85%' }}>
      <Card style={STYLE_CARD}>
        <CardHeader
          title={_t('cardHeader')}
          showExpandableButton
          actAsExpander
        />
        <CardText expandable>
          <div className="product-detail_tab ">
            <span className="product-detail_cell-100">
              <SimpleTable
                maxHeight={10000}
                cols={translateHistoryCols()}
                rowHeight={50}
                header
                itemsRenderer={(item: History) => [
                  <span>
                    <Date datetime={item.date as JsonDatetime} />
                  </span>,
                  <span>{item.message}</span>,
                ]}
                items={computeHistory(subscriber, productItems)}
              />
            </span>
          </div>
        </CardText>
      </Card>
    </div>
  );
};

export default SubscriberDetailHistory;
