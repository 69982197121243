import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import CircularProgress from 'material-ui/CircularProgress';
import FlatButton from 'material-ui/FlatButton';

import { getApiState, InternalApiState } from 'api/duck';
import { MifDetail } from 'api/tepv/mif/types';
import { fetchMifDetails, patchComments } from 'api/tepv/mif';
import Content from 'commons/Content';
import ErrorBlock from 'commons/ErrorBlock';
import FlexCenter from 'commons/FlexCenter';
import {
  DataBox,
  DataBoxContent,
  DataBoxHeader,
  DataBoxItemWrapper,
} from 'commons/DataBox';
import { BKG_BLUE, BKG_CYAN, BRD_GREY, TXT_BLACK } from 'theme';

import NatinfBlock from './NatinfBlock';
import VehicleBlock from './VehicleBlock';
import AgentBlock from './AgentBlock';
import TimeAndLocationBlock from './TimeAndLocationBlock';
import CommentsBlock from './CommentsBlock';
import MeasurementsBlock from './MeasurementsBlock';
import InstrumentBlock from './InstrumentBlock';
import SaisineBlock from './SaisineBlock';
import PdfGenerateButton from './PdfGenerateButton';

const { _t, _tg } = window.loadTranslations(__filename);

type MifDetailsState = {
  mif: MifDetail | null | undefined;
  error: Error | null | undefined;
};

type MifDetailsProps = {
  match: {
    params: {
      mifId: string;
    };
  };
  canAddComments: boolean;
};

const STYLE_COLUMNS_CONTAINER: CSSProperties = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-around',
};

const STYLE_HEADER: CSSProperties = {
  display: 'flex',
  paddingBottom: '5px',
};

const Separator = () => (
  <div
    style={{
      width: 1,
      height: 80,
      backgroundColor: BRD_GREY,
      margin: '5px 30px',
    }}
  />
);

const translateMifStatus = () => {
  return {
    SENT: _t('element.mifStatus.SENT'),
    NOT_SENT: _t('element.mifStatus.NOT_SENT'),
    INTEGRATED: _t('element.mifStatus.INTEGRATED'),
    REJECTED: _t('element.mifStatus.REJECTED'),
    FAILED_TO_SEND: _t('element.mifStatus.FAILED_TO_SEND'),
    IMPORTED: _t('element.mifStatus.IMPORTED'),
  };
};

class MifDetails extends React.Component<MifDetailsProps, MifDetailsState> {
  state: MifDetailsState = {
    mif: null,
    error: null,
  };

  componentDidMount() {
    this.fetchNatinf(this.props.match.params.mifId);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: MifDetailsProps) {
    if (this.props.match.params.mifId !== nextProps.match.params.mifId) {
      this.fetchNatinf(nextProps.match.params.mifId);
    }
  }

  fetchNatinf = async (mifId: string) => {
    this.setState({
      mif: null,
      error: null,
    });
    try {
      const mif = await fetchMifDetails(mifId);
      this.setState({
        mif,
      });
    } catch (error) {
      this.setState({
        error,
      });
    }
  };

  patchComments = async (newComment: string) => {
    const { mif } = this.state;
    if (!mif) {
      return;
    }
    try {
      await patchComments(this.props.match.params.mifId, newComment);
      this.setState({
        mif: {
          ...mif,
          comments: [...mif.comments, newComment],
        },
      });
    } catch (error) {
      this.setState({
        error,
      });
    }
  };

  render() {
    const { mif, error } = this.state;
    const {
      canAddComments,
      match: {
        params: { mifId },
      },
    } = this.props;

    if (error) {
      return (
        <Content>
          <div>
            <FlatButton
              href="#/mif/list"
              label={_t('element.buttonGoBackList.label')}
            />
          </div>
          <FlexCenter>
            <ErrorBlock message={_t('feedback.error')} error={error} />
          </FlexCenter>
        </Content>
      );
    }

    if (!mif) {
      return (
        <Content>
          <FlatButton
            href="#/mif/list"
            label={_t('element.buttonGoBackList.label')}
          />
          <FlexCenter>
            <CircularProgress />
          </FlexCenter>
        </Content>
      );
    }
    const infractionContent = mif.vehicle ? (
      <div style={STYLE_COLUMNS_CONTAINER}>
        <div style={{ flex: '7 7 0%' }}>
          <NatinfBlock mif={mif} />
        </div>
        <Separator />
        <div style={{ flex: '3 3 0%' }}>
          <VehicleBlock
            vehicleDetails={mif.vehicle}
            enlevementDemande={mif.enlevementDemande}
          />
        </div>
      </div>
    ) : (
      <DataBoxItemWrapper
        style={{
          marginBottom: 20,
          width: '90%',
        }}
      >
        <NatinfBlock mif={mif} />
      </DataBoxItemWrapper>
    );

    return (
      <Content>
        <div
          style={{
            padding: '20px 0px 5px 70px',
          }}
        >
          <div style={STYLE_HEADER}>
            <FlatButton
              href="#/mif/list"
              label={_t('element.buttonGoBackList.label')}
            />
            <div style={{ marginTop: '10px' }}>|</div>
            <PdfGenerateButton mifId={mifId} />
            <div style={{ margin: '10px 50px 10px 300px', color: TXT_BLACK }}>
              {translateMifStatus()[mif.status]}
            </div>
          </div>
          <DataBox panel style={{ width: '95%' }}>
            <DataBoxHeader>{_t('element.dataBoxInfraction')}</DataBoxHeader>
            <DataBoxContent>{infractionContent}</DataBoxContent>
          </DataBox>
          <DataBox panel style={{ width: '95%', marginTop: 40 }}>
            <DataBoxHeader color={BKG_CYAN}>
              {_t('element.dataBoxContest')}
            </DataBoxHeader>
            <DataBoxContent>
              <div style={STYLE_COLUMNS_CONTAINER}>
                <div style={{ flex: '1 1 0%' }}>
                  <TimeAndLocationBlock mif={mif} />
                </div>
                <Separator />
                <div style={{ flex: '1 1 0%' }}>
                  <AgentBlock mif={mif} />
                </div>
                {mif.measures && (
                  <>
                    <Separator />
                    <div style={{ flex: '1 1 0%' }}>
                      <InstrumentBlock
                        nature={mif.natureInstrument}
                        type={mif.typeInstrument}
                        brand={mif.brandInstrument}
                        model={mif.modelInstrument}
                        serialNumber={mif.serialNumber}
                        softwareVersion={mif.softwareVersion}
                        validityDate={mif.validityDateInstrument}
                        verificationDate={mif.verificationDateInstrument}
                        identifier={mif.identifierInstrument}
                      />
                      <MeasurementsBlock measures={mif.measures} />
                    </div>
                  </>
                )}
              </div>
            </DataBoxContent>
          </DataBox>
          {mif.saisine && (
            <DataBox panel style={{ width: '95%', marginTop: 40 }}>
              <DataBoxHeader color={BKG_BLUE}>
                {_t('element.dataBoxReferral')}
              </DataBoxHeader>
              <DataBoxContent>
                <DataBoxItemWrapper
                  style={{
                    marginBottom: 20,
                    width: '90%',
                  }}
                >
                  <SaisineBlock saisine={mif.saisine} />
                </DataBoxItemWrapper>
              </DataBoxContent>
            </DataBox>
          )}
          <DataBox panel style={{ width: '95%', marginTop: 40 }}>
            <DataBoxHeader color={BKG_BLUE}>
              {_tg('field.comment_plural')}
            </DataBoxHeader>
            <DataBoxContent>
              <DataBoxItemWrapper
                style={{
                  marginBottom: 20,
                  width: '90%',
                }}
              >
                <CommentsBlock
                  mif={mif}
                  canAddComments={canAddComments}
                  updateComments={this.patchComments}
                />
              </DataBoxItemWrapper>
            </DataBoxContent>
          </DataBox>
        </div>
      </Content>
    );
  }
}

const mapStateToProps = (state: InternalApiState) => {
  const { userInfo } = getApiState(state);
  return {
    canAddComments: userInfo ? userInfo.rights.includes('MIFS_WRITE') : false,
  };
};

export default connect(mapStateToProps)(MifDetails);
