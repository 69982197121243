import { fetchGpvFilteredList } from 'api/tepv/gpv';
import { GpvOverviewDTO } from 'api/tepv/gpv/types';
import PageFetcherFactory, {
  PageFetcher,
} from 'commons/Generics/PageFetcherFactory';
import { Filters } from 'commons/types/filterbar';

const useGpvPageFetcher = (
  filters: Filters
): PageFetcher<Filters, GpvOverviewDTO> =>
  PageFetcherFactory<Filters, GpvOverviewDTO>(fetchGpvFilteredList, filters, {
    sortOrder: 'DESC',
    sortField: 'signatureDatetime',
  })();

export default useGpvPageFetcher;
