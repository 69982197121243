import React from 'react';

export type GpvStateContextType = {
  selectedGpvs: string[];
  setSelectedGpvs: (gpvs: string[]) => void;
};

export const GpvListContext = React.createContext<GpvStateContextType | {}>({});

export type BoolContextType = {
  multipleMode: boolean;
  setMultipleMode: (enabled: (enabled: boolean) => boolean) => void;
};

export const BoolContext = React.createContext<BoolContextType | {}>({});
