import moment from 'moment';

import { convertToCents } from 'commons/Utils/paymentUtil';
import { Pager, SortParameters } from 'api/commonTypes';
import { ParkingRightFilterParam } from '@cvfm-front/tefps-types';

import { TicketsSearchCriteria } from './types';

const { _tg } = window.loadTranslations(__filename);

// The key should be the same as the id in TvExportColumnsConfig in the back.
export function getExportCol(
  hashPartnerPlateEnabled: boolean
): Array<{
  key: string;
  label: string;
  checked?: boolean;
}> {
  const exportCol = [
    {
      key: 'ticketId',
      label: _tg('field.ticket.ticketNumber_3'),
      checked: true,
    },
    {
      key: 'plate',
      label: _tg('field.vehicle.licensePlate'),
      checked: true,
    },
    {
      key: 'creationDatetime',
      label: _tg('field.date.buyDatetime'),
      checked: true,
    },
    {
      key: 'endDatetime',
      label: _tg('field.date.datetimeOfValidityEnd'),
      checked: true,
    },
    { key: 'price', label: _tg('commons.tariff'), checked: true },
    { key: 'zoneId', label: _tg('field.address.zone'), checked: true },
    {
      key: 'parkId',
      label: _tg('field.address.sector'),
    },
    { key: 'startDatetime', label: _tg('field.date.datetimeOfValidityStart') },
    {
      key: 'type',
      label: _tg('field.ticket.type'),
    },
    { key: 'source', label: _tg('field.ticket.origin') },
    {
      key: 'cancelDatetime',
      label: _tg('field.date.cancelDate'),
    },
    { key: 'pricingCategory', label: _tg('field.pricingCategory') },
    {
      key: 'pricingContext',
      label: _tg('field.pricingContext'),
    },
    { key: 'plateCountry', label: _tg('field.device.immatriculationCountry') },
    {
      key: 'fineLegalId',
      label: _tg('field.ticket.associatedFineId'),
    },
    { key: 'versionDatetime', label: _tg('tefps.filters.rapo.receptionDate') },
    {
      key: 'clientApp',
      label: _tg('field.organisation.supplierName'),
    },
    {
      key: 'lastModifiedDatetime',
      label: _tg('field.date.lastModificationDate'),
    },
    {
      key: 'tariffCode',
      label: _tg('field.pricing.tariffCode'),
    },
    {
      key: 'originalPlate',
      label: _tg('field.vehicle.originalPlate'),
    },
    {
      key: 'vehicleCategory',
      label: _tg('field.vehicle.category'),
    },
  ];

  if (hashPartnerPlateEnabled) {
    exportCol.push({
      key: 'hashedPartnerPlate',
      label: _tg('field.vehicle.hashedPartnerPlate'),
    });
    exportCol.push({
      key: 'originalPartnerPlate',
      label: _tg('field.vehicle.originalPartnerPlate'),
    });
  }

  return exportCol;
}

function getSortFieldText(sortField?: number) {
  switch (sortField) {
    case 0:
      return 'ticketId';
    case 1:
      return 'plate';
    case 3:
      return 'endDatetime';
    case 4:
      return 'zoneId';
    case 5:
      return 'price';
    case 2:
    default:
      return 'startDatetime';
  }
}

export const initialFilters = (
  plate?: string,
  fpsStatementDate?: Date
): TicketsSearchCriteria => ({
  zoneId: undefined,
  ticketId: undefined,
  plate,
  clientAppIds: new Set(),
  creationDate: {
    from: undefined,
    to: undefined,
  },
  startDate: {
    from: undefined,
    to: moment(fpsStatementDate)
      .endOf('day')
      .toDate(),
  },
  endDate: {
    from:
      fpsStatementDate ||
      moment()
        .subtract(3, 'months')
        .toDate(),
    to: undefined,
  },
  creationTime: {
    from: '00:00',
    to: '23:59',
  },
  startTime: {
    from: '00:00',
    to: '23:59',
  },
  endTime: {
    from: '00:00',
    to: '23:59',
  },
  duration: { from: undefined, to: undefined },
  price: { from: NaN, to: NaN },
  vehicleCategories: new Set(),
});

export const filtersToRequest = (
  filters: TicketsSearchCriteria,
  sort?: SortParameters,
  pager?: Pager
): ParkingRightFilterParam => {
  const {
    creationDate,
    startDate,
    endDate,
    creationTime,
    startTime,
    endTime,
    ticketId,
    plate,
    zoneId,
    clientAppIds,
    duration,
    price,
    vehicleCategories,
  } = filters;
  const { page, maxRecords } = pager || { page: 0, maxRecords: 0 };
  return {
    fromCreationDatetime: creationDate.from
      ? moment(creationDate.from).toISOString()
      : null,
    toCreationDatetime: creationDate.to
      ? moment(creationDate.to).toISOString()
      : null,
    fromStartDatetime: startDate.from
      ? moment(startDate.from).toISOString()
      : null,
    toStartDatetime: startDate.to ? moment(startDate.to).toISOString() : null,
    fromEndDatetime: endDate.from ? moment(endDate.from).toISOString() : null,
    toEndDatetime: endDate.to ? moment(endDate.to).toISOString() : null,
    startCreationTime: creationTime.from,
    endCreationTime: creationTime.to,
    startStartTime: startTime.from,
    endStartTime: startTime.to,
    startEndTime: endTime.from,
    endEndTime: endTime.to,
    ticketId,
    clientAppIds: Array.from(clientAppIds),
    fromAmount: price.from ? convertToCents(price.from) : null,
    toAmount: price.to ? convertToCents(price.to) : null,
    plate,
    zoneIds: zoneId ? [zoneId] : [],
    page,
    maxRecords,
    sortOrder: sort?.increasingOrder ? 'ASC' : 'DESC',
    sortField: getSortFieldText(sort?.sortField),
    fromDurationMinutes: duration.from,
    toDurationMinutes: duration.to,
    vehicleCategories: Array.from(vehicleCategories),
  };
};

// In Front we have no clue if the plate is hashed or not here so we have to check the length of the plate
// we assume that if the plate is more than 20 characters long, it is a hash so we truncate it to 6 char
export function buildDisplayingPlate(plate: string | null | undefined): string {
  if (!plate) {
    return _tg('status.expired');
  }
  return plate.length > 20 ? plate.substring(0, 6) : plate;
}

export function buildDisplayPlateCountry(
  plateCountry: string | null | undefined
): string {
  return plateCountry ? `[${plateCountry}]` : '';
}
