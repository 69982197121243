import * as React from 'react';

import { GpvDetailDecree } from 'api/tepv/gpv/types';
import FakeInputBlock from 'commons/FakeInputBlock';

const { _tg } = window.loadTranslations();

function DecreeBlock({ decree }: { decree: GpvDetailDecree }) {
  return (
    <>
      <FakeInputBlock
        title={_tg('field.decree.reference')}
        value={decree.reference}
      />
      {decree.decreeText && (
        <FakeInputBlock
          title={_tg('field.decree.text')}
          value={decree.decreeText}
        />
      )}
    </>
  );
}

export default DecreeBlock;
