import * as React from 'react';

import Sidebar from 'commons/SidebarV2';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import HitsCounter from 'commons/SidebarV2/Components/HitsCounter';
import Dates from 'commons/SidebarV2/Components/Dates';
import Input from 'commons/SidebarV2/Components/Input';
import Select from 'commons/SidebarV2/Components/Select';
import PriceRange from 'commons/SidebarV2/Components/PriceRange';

import { NatinfFilters, NatinfFiltersPopulateData } from './types';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  filters: NatinfFilters;
  filtersData: NatinfFiltersPopulateData;
  updateFilters: (f: NatinfFilters) => void;
  resetFilters: () => void;
  totalHits: number;
  version: string;
};

class NatinfSidebar extends React.Component<Props> {
  onChange = (id: string, value: any) => {
    const { updateFilters, filters } = this.props;
    updateFilters({ ...filters, [id]: value });
  };

  render() {
    const {
      resetFilters,
      filters,
      totalHits,
      filtersData,
      version,
    } = this.props;
    const classeOptions = Array.from(filtersData.cas)
      .sort((a, b) => a.localeCompare(b))
      .map(classe => ({
        key: `${classe}`,
        label: `${classe}`,
      }));
    const categorieOptions = Array.from(filtersData.categories).map(
      categorie => ({
        key: categorie,
        label: categorie,
      })
    );
    return (
      <Sidebar>
        <BarTitle title={_tg('field.version', { version })} />
        <br />
        <BarTitle resetFilters={resetFilters} />
        <HitsCounter hits={totalHits} />
        <Input
          id="codes"
          title={_tg('tepv.natinfCode')}
          placeholder={_t('element.fieldNatinfCode.placeholder')}
          underlineText={_t('element.fieldNatinfCode.underlineText')}
          onChange={this.onChange}
          value={filters.codes}
        />
        <Select
          id="cas"
          title={_t('element.selectCas.title')}
          onChange={this.onChange}
          selected={filters.cas}
          options={classeOptions}
          multiple
        />
        <Select
          id="categories"
          title={_t('element.selectCategories.title')}
          onChange={this.onChange}
          selected={filters.categories}
          options={categorieOptions}
          multiple
        />
        <Select
          id="groupIds"
          title={_t('element.selectGroupsIds.title')}
          onChange={this.onChange}
          selected={filters.groupIds}
          options={filtersData.groupKeys}
          multiple
        />
        <Dates
          id="dateImportPeriod"
          title={_t('element.datesImport.title')}
          dates={filters.dateImportPeriod}
          onChange={this.onChange}
        />
        <PriceRange
          id="montantForfaitaireRange"
          title={_t('element.pricerangeForfait.title')}
          price={filters.montantForfaitaireRange}
          onChange={this.onChange}
        />
        <Dates
          id="dateDisablePeriod"
          title={_t('element.dateDisable.title')}
          dates={filters.dateDisablePeriod}
          onChange={this.onChange}
        />
        <Input
          id="qualificationSearchText"
          title={_t('element.fieldQualitifcation.title')}
          placeholder={_t('element.fieldQualitifcation.placeholder')}
          onChange={this.onChange}
          value={filters.qualificationSearchText}
        />
        <Input
          id="texteSearchText"
          title={_t('element.fieldSearchText.title')}
          placeholder={_t('element.fieldSearchText.placeholder')}
          onChange={this.onChange}
          value={filters.texteSearchText}
        />
      </Sidebar>
    );
  }
}

export default NatinfSidebar;
