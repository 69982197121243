import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import TextField from 'material-ui/TextField';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import { v4 as uuidv4 } from 'uuid';

import BoButton from 'facade/BoButton';
import ConfirmAction from 'commons/ConfirmAction';
import SimpleTable from 'commons/SimpleTable';
import ErrorBlock from 'commons/ErrorBlock';
import FlexCenter from 'commons/FlexCenter';
import { ListBody, ListBottom, ListWrapper } from 'commons/ListWrappers';
import Content from 'commons/Content';
import { InfractionLocationDTO } from 'api/tepv/infractionLocation/types';
import { getApiState } from 'api/duck';
import {
  getInfractionLocations,
  upsertInfractionLocation,
  deleteInfractionLocation,
} from 'api/tepv/infractionLocation';
import { BKG_CYAN, BKG_PINK } from 'theme';
import { fetchOrganizations } from 'api/organizations';
import { CityOrganizationDTO } from 'api/organizations/types';

import CreationModal from './modal';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_ICON: CSSProperties = {
  cursor: 'pointer',
};

type LocationsState = {
  locations: Array<InfractionLocationDTO>;
  organizations: CityOrganizationDTO[] | null | undefined;
  loading: boolean;
  searchText: string;
  creationModalOpen: boolean;
  creationRequestError: Error | null | undefined;
  deletionRequestError: Error | null | undefined;
  listError: Error | null | undefined;
};

type LocationsProps = {
  canAddLocation: boolean;
  canDeleteLocation: boolean;
};

const translateTableCols = (): Array<{
  label?: string;
  width: number;
  grow?: number;
  style?: CSSProperties;
  headerStyle?: CSSProperties;
  onSort?: boolean;
}> => [
  { label: _t('element.tableCols.name'), width: 100, grow: 1 },
  { label: _t('element.tableCols.count'), width: 150 },
  {
    label: _tg('action.actions'),
    width: 100,
    headerStyle: { textAlign: 'center' },
  },
];

class Locations extends React.Component<LocationsProps, LocationsState> {
  state: LocationsState = {
    locations: [],
    organizations: [],
    loading: true,
    searchText: '',
    creationModalOpen: false,
    creationRequestError: null,
    deletionRequestError: null,
    listError: null,
  };

  componentDidMount() {
    this.loadLocations();
    this.loadOrganizations();
  }

  onSearchChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: target.value });
  };

  openCreationModal = () =>
    this.setState({
      creationModalOpen: true,
      creationRequestError: null,
    });
  closeCreationModal = () =>
    this.setState({
      creationModalOpen: false,
      creationRequestError: null,
    });

  removeLocation = async (locationId: string) => {
    try {
      await deleteInfractionLocation(locationId);
      this.loadLocations();
    } catch (err) {
      this.setState({ deletionRequestError: err });
    }
  };

  addLocation = async (
    locationCreationText: string,
    organizationId: string | null
  ) => {
    const locationId = uuidv4();
    const location = {
      id: locationId,
      location: locationCreationText,
      organizationId,
    };

    try {
      await upsertInfractionLocation(locationId, location);
      this.loadLocations();
      this.closeCreationModal();
    } catch (err) {
      this.setState({ creationRequestError: err });
    }
  };

  loadLocations = async () => {
    try {
      const locations = await getInfractionLocations();
      this.setState({ locations, loading: false, listError: null });
    } catch (error) {
      this.setState({ listError: error });
    }
  };

  loadOrganizations = async () => {
    let organizations = [];
    try {
      organizations = await fetchOrganizations(false, 'pv');
      this.setState({ organizations });
    } catch (error) {
      this.setState({ listError: error });
    }
  };

  render() {
    const {
      locations,
      organizations,
      loading,
      searchText,
      creationModalOpen,
      creationRequestError,
      deletionRequestError,
      listError,
    } = this.state;
    const { canAddLocation, canDeleteLocation } = this.props;
    const filteredLocations = locations.filter(location =>
      location.location.toLowerCase().includes(searchText.toLowerCase())
    );

    if (listError) {
      return (
        <Content>
          <FlexCenter>
            <ErrorBlock
              message={_t('feedback.error.fetch')}
              error={listError}
            />
          </FlexCenter>
        </Content>
      );
    }

    if (creationRequestError) {
      return (
        <ErrorBlock
          message={_t('feedback.error.create')}
          error={creationRequestError}
        />
      );
    }

    if (deletionRequestError) {
      return (
        <ErrorBlock
          message={_t('feedback.error.delete')}
          error={deletionRequestError}
        />
      );
    }

    return (
      <Content>
        <div
          style={{
            marginTop: 30,
            marginBottom: 20,
            marginLeft: '3%',
            marginRight: '3%',
          }}
        >
          <TextField
            id="search"
            autoFocus={false}
            onChange={this.onSearchChange}
            placeholder={_t('element.fieldSearch.placeholder')}
            value={searchText}
          />
        </div>
        <ListWrapper style={{ width: '100%', height: '70%' }}>
          <ListBody loading={loading}>
            <SimpleTable
              maxHeight={500}
              cols={translateTableCols()}
              rowHeight={50}
              itemsRenderer={({
                id,
                location,
                mifCount,
              }: InfractionLocationDTO) => [
                <span>{location}</span>,
                <span>{mifCount}</span>,
                <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                  {canDeleteLocation && (
                    <ConfirmAction
                      enabled
                      message={_t('feedback.confirm', { name: location })}
                      action={() => this.removeLocation(id)}
                    >
                      <DeleteIcon
                        style={STYLE_ICON}
                        color={BKG_CYAN}
                        hoverColor={BKG_PINK}
                      />
                    </ConfirmAction>
                  )}
                </div>,
              ]}
              items={filteredLocations}
            />
          </ListBody>
          {canAddLocation && (
            <ListBottom>
              <BoButton
                label={_t('element.dialogCreate.title')}
                primary
                onClick={this.openCreationModal}
              />
            </ListBottom>
          )}
        </ListWrapper>
        {creationModalOpen && (
          <CreationModal
            addLocation={this.addLocation}
            closeCreationModal={this.closeCreationModal}
            locations={locations}
            organizations={organizations}
          />
        )}
      </Content>
    );
  }
}

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    canAddLocation:
      userInfo && userInfo.rights.includes('INFRACTION_LOCATIONS_WRITE'),
    canDeleteLocation:
      userInfo && userInfo.rights.includes('INFRACTION_LOCATIONS_DELETE'),
  };
})(Locations);
