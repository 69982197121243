import { DecreeType } from 'api/tepv/decrees/types';

const { _t } = window.loadTranslations(__filename);

export const TYPE_LABEL: { [k in DecreeType]: string } = {
  MUNICIPAL: _t('typeLabel.MUNICIPAL'),
  PREFECTORAL: _t('typeLabel.PREFECTORAL'),
  CONSEIL_GENERAL: _t('typeLabel.CONSEIL_GENERAL'),
};
export const TYPE_OPTIONS: Array<{
  value: string;
  label: string;
}> = Object.entries(TYPE_LABEL).map(value => {
  const entry = value as [string, string]; // we are sure of the typing
  return {
    value: entry[0],
    label: entry[1],
  };
});
