import * as React from 'react';

import Sidebar from 'commons/SidebarV2';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import HitsCounter from 'commons/SidebarV2/Components/HitsCounter';
import Checkboxes from 'commons/SidebarV2/Components/Checkboxes';
import Input from 'commons/SidebarV2/Components/Input';
import Dates from 'commons/SidebarV2/Components/Dates';

import { DecreeFilters } from './types';
import { TYPE_OPTIONS } from './commons';

const { _t } = window.loadTranslations(__filename);

type Props = {
  filters: DecreeFilters;
  updateFilters: (f: DecreeFilters) => void;
  resetFilters: () => void;
  totalHits: number;
};

class DecreeSidebar extends React.Component<Props> {
  onChange = (id: string, value: any) => {
    const { updateFilters, filters } = this.props;
    updateFilters({ ...filters, [id]: value });
  };

  render() {
    const { resetFilters, totalHits, filters } = this.props;
    return (
      <Sidebar>
        <BarTitle resetFilters={resetFilters} />
        <HitsCounter hits={totalHits} />
        <Checkboxes
          id="types"
          title={_t('element.checkboxType.title')}
          options={TYPE_OPTIONS}
          filters={filters.types}
          faceting={null}
          onChange={this.onChange}
        />
        <Dates
          id="applicationDate"
          title={_t('element.inputDate.title')}
          dates={filters.applicationDate}
          onChange={this.onChange}
        />
        <Input
          id="reference"
          title={_t('element.inputRef.title')}
          placeholder={_t('element.inputRef.placeholder')}
          onChange={this.onChange}
          value={filters.reference}
        />
        <Input
          id="comment"
          title={_t('element.inputComment.title')}
          placeholder={_t('element.inputComment.placeholder')}
          onChange={this.onChange}
          value={filters.comment}
        />
      </Sidebar>
    );
  }
}

export default DecreeSidebar;
