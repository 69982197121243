import React from 'react';

import { SubscriberDTO } from '@cvfm-front/tefps-types';
import UserNotificationDialog from 'tefps/Beneficiaries/commons/UserNotificationDialog';

type SubscriberSimpleOrderHistoryActionProps = {
  message: string;
  action: any;
  subscriber?: SubscriberDTO;
};

function SubscriberSimpleOrderHistoryAction({
  message,
  action,
  subscriber,
}: SubscriberSimpleOrderHistoryActionProps): JSX.Element {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>{message}</span>
      {action.notificationId && (
        <UserNotificationDialog action={action} subscriber={subscriber} />
      )}
    </div>
  );
}

export default SubscriberSimpleOrderHistoryAction;
