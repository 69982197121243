import React, { CSSProperties, useEffect, useState } from 'react';
import { Dialog, CircularProgress } from 'material-ui';
import { connect } from 'react-redux';
import { History } from 'history';

import FilterBar from 'commons/Generics/FilterBar';
import { getPresetNotesAsFilter } from 'tepv/Presets/utils';
import { getInfractionLocationsAsFilter } from 'tepv/Locations/utils';
import { getOrganizationsAsFilter } from 'Administration/Organizations/utils';
import { getProfilesAsFilter } from 'Administration/Profiles/utils';
import ListWithActions from 'commons/ListWithActions';
import { SortParameters } from 'api/commonTypes';
import { FETCH_PV_ZONING_CONFIG } from 'commons/FetchZoningConfigs';
import {
  fetchGpvFilteredList,
  getGpvExport,
  getGpvExportDBF,
} from 'api/tepv/gpv';
import Exporter from 'commons/Exporter';
import { getApiState } from 'api/duck';
import { GPV_COLUMNS_EXPORT, GPV_COLUMNS_EXPORT_DBF } from 'api/tepv/gpv/utils';
import { fetchZoning } from 'api/pricing';
import { fetchMatchingMotifs } from 'api/tepv/motifs';

import { initGpvFilters, motifQualificationFilter } from './Filters/gpvFilters';
import useGpvPageFetcher from './Filters/useGpvPageFetcher';
import GpvTable from './GpvTable/GpvTable';
import GpvMultipleAction from './GpvTable/GpvMultipleAction';
import { BoolContext, GpvListContext } from './Context';

const { _t } = window.loadTranslations(__filename);

const MAX_BATCH_SIZE = 9999;

const STYLE_CONTENT: CSSProperties = {
  display: 'flex',
  height: '100%',
};

interface Props {
  history: History;
  canExportGpvs: boolean;
}

const GpvList = ({ history, canExportGpvs }: Props) => {
  const [filterMotifOptions, setfilterMotifOptions] = useState<
    { id: string; name: string }[]
  >([]);
  const [filterMotifText, setfilterMotifText] = useState('');

  const fetchMotifs = async (motifText?: string): Promise<undefined> => {
    if (
      motifText === undefined ||
      (!filterMotifOptions && motifText.trim().length < 1)
    ) {
      return undefined;
    }

    const isQualif = isNaN(Number(motifText.trim().charAt(0)));
    const motifs = (await fetchMatchingMotifs(motifText)).matchingMotifs;

    const codesMotifs = motifs.map(({ natinfCode, qualification }) => ({
      id: isQualif ? qualification : natinfCode,
      name: `${natinfCode}: ${qualification}`,
    }));
    setfilterMotifOptions(codesMotifs);
    setfilterMotifText(motifText);
    return undefined;
  };

  const [filters, setFilters] = useState(
    initGpvFilters([], [], [], [], null, [], filterMotifText, fetchMotifs)
  );

  async function initDynamicFilters() {
    setFilters(
      initGpvFilters(
        await getPresetNotesAsFilter(),
        await getInfractionLocationsAsFilter(),
        await getOrganizationsAsFilter(false, 'pv'),
        await getProfilesAsFilter(false, 'pv'),
        await fetchZoning(FETCH_PV_ZONING_CONFIG),
        [],
        filterMotifText,
        fetchMotifs
      )
    );
  }

  useEffect(() => {
    void initDynamicFilters();
  }, []);

  useEffect(() => {
    const newFilters = {
      ...filters,
      motifQualification: motifQualificationFilter(
        filterMotifOptions,
        filterMotifText,
        fetchMotifs
      ),
    };
    setFilters(newFilters);
  }, [filterMotifOptions, filterMotifText]);

  const [openLoadingModal, setOpenLoadingModal] = useState<boolean>(false);

  const [selectedGpvs, setSelectedGpvs] = useState<string[]>([]);

  const [multipleMode, setMultipleMode] = useState<boolean>(false);

  const [sortParam, setSortParam] = useState<SortParameters>({
    increasingOrder: false,
    sortField: 0,
  });

  // filterValues is used for the export filters ONLY, filters is the one that filterbar will use
  const [filterValues, setFilterValues] = useState({});

  const {
    list,
    totalHits,
    fetchWithNewFilter,
    fetchNextPage,
    facetings,
    convertFiltersToRequest,
    updateSortParameters,
  } = useGpvPageFetcher(filters);

  async function selectAll() {
    setOpenLoadingModal(true);
    const gpvOverviews = await fetchGpvFilteredList({
      query: convertFiltersToRequest(filters),
      page: 0,
      maxRecords: MAX_BATCH_SIZE,
    });
    const ids = gpvOverviews.response.map(gpv => gpv.id);
    setSelectedGpvs(ids);
    setOpenLoadingModal(false);
  }

  function resetSelection() {
    setMultipleMode(false);
    setSelectedGpvs([]);
  }

  function reload() {
    resetSelection();
    fetchWithNewFilter(filters);
  }

  function onUpdateSort(
    colNumber: number,
    isAscendingOrder: boolean,
    sortId: string
  ) {
    updateSortParameters(sortId, isAscendingOrder ? 'ASC' : 'DESC');
    setSortParam({ increasingOrder: isAscendingOrder, sortField: colNumber });
  }

  function getActions() {
    const lst = [];
    if (canExportGpvs) {
      lst.push(
        <Exporter
          disabled={totalHits === 0}
          columns={GPV_COLUMNS_EXPORT}
          type="Cas A"
          filters={filterValues}
          fileExport={getGpvExport}
          style={{ marginRight: '30px' }}
        />
      );
      lst.push(
        <Exporter
          label="EXPORT DBF"
          modalTitle="Export DBF"
          disabled={totalHits === 0}
          columns={GPV_COLUMNS_EXPORT_DBF}
          type="Cas A"
          filters={filterValues}
          fileExport={getGpvExportDBF}
        />
      );
    }
    lst.push(
      <GpvMultipleAction
        items={list}
        reload={reload}
        onSelectAll={selectAll}
        totalHits={totalHits}
      />
    );
    return lst;
  }

  return (
    <div style={STYLE_CONTENT}>
      <FilterBar
        totalHits={totalHits}
        initialFilters={filters}
        onChangeFilters={newFilters => {
          // TODO fix double request
          setFilters(newFilters);
          fetchWithNewFilter(newFilters);
          convertFiltersToRequest(newFilters);
          setFilterValues(convertFiltersToRequest(newFilters));
        }}
        facetings={facetings}
      />
      <GpvListContext.Provider value={{ selectedGpvs, setSelectedGpvs }}>
        <BoolContext.Provider value={{ multipleMode, setMultipleMode }}>
          <ListWithActions
            list={
              <GpvTable
                items={list}
                totalHits={totalHits}
                loadMoreRows={async () => {
                  await fetchNextPage(undefined);
                }}
                history={history}
                onUpdateSort={onUpdateSort}
                sortOrder={sortParam.increasingOrder}
                colSorted={sortParam.sortField}
              />
            }
            actions={getActions()}
          />
        </BoolContext.Provider>
      </GpvListContext.Provider>

      <Dialog open={openLoadingModal} title={_t('element.selectGpv')}>
        <CircularProgress />
      </Dialog>
    </div>
  );
};
export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    canExportGpvs: userInfo && userInfo.rights.includes('GPV_EXPORT'),
  };
})(GpvList);
