import React from 'react';
import { Card, CardHeader, CardText } from 'material-ui';
import FileIcon from 'material-ui/svg-icons/content/content-paste';
import DeleteIcon from 'material-ui/svg-icons/action/delete';

import { Proof, ProofType, SubscriberDTO } from '@cvfm-front/tefps-types';
import SimpleTable from 'commons/SimpleTable';
import './ProductDetailPage.css';
import { BKG_CYAN, ICON_BLACK } from 'theme';
import ThirdPartyValidatedElement from 'commons/ThirdPartyValidatedElement';

type Props = {
  subscriber: SubscriberDTO;
  setProofToDelete: (proof: Proof) => void;
};

const STYLE_CARD = {
  flex: 1,
  margin: 10,
  minWidth: 300,
};

const { _tg } = window.loadTranslations(__filename);

const SubscriberDetailProof = ({
  subscriber,
  setProofToDelete,
}: Props): JSX.Element => {
  const translateCols = () => [
    { label: _tg('field.evidenceType.type'), width: 80, grow: 1 },
    { label: _tg('field.evidenceType.document'), width: 40, grow: 1 },
  ];

  const isPersonalAddressVerifiedByDgFip = !!subscriber.personalProfile
    ?.location.dgFipAddressVerified;

  const documents: Set<Proof> = new Set();
  if (subscriber.identity) {
    documents.add({
      id: subscriber.identity.id,
      purged: false,
      purgeDatetime: new Date(),
      expiration: new Date(),
      type: ProofType.IDENTITY_CARD,
      manuallyChecked: !!subscriber.identity.manuallyChecked,
      documents: subscriber.identity.documents ?? [],
    });
  }
  if (
    subscriber.personalProfile?.location.proofOfAddress ||
    isPersonalAddressVerifiedByDgFip
  ) {
    documents.add({
      id: subscriber.personalProfile?.location.proofOfAddress?.id ?? '',
      purged: false,
      purgeDatetime: new Date(),
      expiration: new Date(),
      type: ProofType.PERSONAL_ADDRESS,
      manuallyChecked: !!subscriber.personalProfile?.location.proofOfAddress
        ?.manuallyChecked,
      documents:
        subscriber.personalProfile?.location.proofOfAddress?.documents || [],
    });
  }
  if (subscriber.professionalProfile?.company?.proofDocuments) {
    documents.add({
      id: subscriber.professionalProfile.company.proofDocuments.id,
      purged: false,
      purgeDatetime: new Date(),
      expiration: new Date(),
      type: ProofType.PROFESSIONAL_ACTIVITY,
      manuallyChecked: !!subscriber.professionalProfile.company.proofDocuments
        .manuallyChecked,
      documents:
        subscriber.professionalProfile.company.proofDocuments.documents ?? [],
    });
  }
  if (subscriber.professionalProfile?.location.proofOfAddress) {
    documents.add({
      id: subscriber.professionalProfile.location.proofOfAddress.id,
      purged: false,
      purgeDatetime: new Date(),
      expiration: new Date(),
      type: ProofType.PROFESSIONAL_ADDRESS,
      manuallyChecked: !!subscriber.professionalProfile.location.proofOfAddress
        .manuallyChecked,
      documents:
        subscriber.professionalProfile.location.proofOfAddress.documents ?? [],
    });
  }

  const translateProofType = (proofType: ProofType): string => {
    switch (proofType) {
      case ProofType.IDENTITY_CARD:
        return _tg('field.evidenceType.proofOfId');
      case ProofType.PERSONAL_ADDRESS:
        return _tg('field.evidenceType.personalProofOfAddress');
      case ProofType.PROFESSIONAL_ACTIVITY:
        return _tg('field.evidenceType.professionalProofOfActivity');
      case ProofType.PROFESSIONAL_ADDRESS:
        return _tg('field.evidenceType.professionalProofOfAddress');
      default:
        return '';
    }
  };

  return (
    <div style={{ flex: 1, width: '85%' }}>
      <Card style={STYLE_CARD}>
        <CardHeader
          title={_tg('field.evidence.essentialEvidences')}
          showExpandableButton
          actAsExpander
        />
        <CardText expandable>
          <div className="product-detail_tab ">
            {documents && (
              <SimpleTable
                maxHeight={10000}
                cols={translateCols()}
                rowHeight={50}
                header
                itemsRenderer={(item: Proof) => [
                  <span>{translateProofType(item.type)}</span>,
                  <span>
                    {isPersonalAddressVerifiedByDgFip &&
                    item.type === ProofType.PERSONAL_ADDRESS ? (
                      <ThirdPartyValidatedElement
                        title={_tg('commons.dgFip.validated')}
                      />
                    ) : (
                      <>
                        {item.documents &&
                          item.documents.map(document => (
                            <a
                              href={document.contentUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <FileIcon
                                style={{ width: 40, height: 50 }}
                                color={BKG_CYAN}
                              />
                            </a>
                          ))}
                        {item.manuallyChecked && !item.documents && (
                          <span>{_tg('field.manuallyChecked')}</span>
                        )}
                      </>
                    )}
                  </span>,
                  <span>
                    <DeleteIcon
                      color={ICON_BLACK}
                      onClick={() => setProofToDelete(item)}
                      hoverColor={BKG_CYAN}
                      className="action-icon"
                    />
                  </span>,
                ]}
                items={Array.from(documents)}
              />
            )}
          </div>
        </CardText>
      </Card>
    </div>
  );
};

export default SubscriberDetailProof;
