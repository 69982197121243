import * as React from 'react';
import Snackbar from 'material-ui/Snackbar';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import CircularProgress from 'material-ui/CircularProgress';

import { createMotifsFromCsv } from 'api/tepv/motifs';
import { ICON_RED } from 'theme';
import CsvImportDropZone from 'commons/CsvImportDropZone';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  onCreate: (message: string) => Promise<void>;
};

type State = {
  rejected: boolean;
  success: boolean;
  loading: boolean;
  errors: Array<string>;
};

class ImportMotifs extends React.Component<Props, State> {
  state: State = {
    rejected: false,
    success: false,
    loading: false,
    errors: [],
  };

  onDrop = (files: Array<File>) => {
    this.setState({
      rejected: false,
      success: false,
      loading: false,
      errors: [],
    });
    if (files.length) {
      const file = files[0];
      this.submit(file);
    } else {
      this.setState({ success: false, rejected: true });
    }
  };

  handleClose = () => {
    this.setState({
      errors: [],
    });
  };

  submit = async (file: File) => {
    const { onCreate } = this.props;
    this.setState({ loading: true });
    const { success, errors } = await createMotifsFromCsv(file);
    this.setState({ success, errors, loading: false });
    if (this.state.success) {
      await onCreate(_t('feedback.success.importMotifs'));
    }
  };

  render() {
    const { rejected, success, loading, errors } = this.state;
    return (
      <div>
        <CsvImportDropZone
          buttonStyle={{ marginRight: 30 }}
          buttonLabel={_t('button.importCsv')}
          onDrop={this.onDrop}
          accept="text/csv, application/csv, .csv"
        />

        <Dialog
          title={success ? '' : _t('feedback.error.importMotifs')}
          onRequestClose={this.handleClose}
          modal
          open={false}
          autoScrollBodyContent
          actions={[
            <FlatButton
              label={_tg('action.close')}
              onClick={this.handleClose}
              disabled={loading}
            />,
          ]}
        >
          {loading && (
            <div
              style={{
                height: 150,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </div>
          )}
          {!loading && rejected && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 10,
              }}
            >
              <p>{_t('feedback.error.fileNotCsv')}</p>
            </div>
          )}
          {!loading && !rejected && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 10,
              }}
            >
              <ul style={{ marginTop: 20 }}>
                {errors.map(error => (
                  <li key={error} style={{ marginBottom: 2 }}>
                    ? {error}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </Dialog>
        <Snackbar
          open={success}
          message={_t('feedback.success.importMotifs')}
          autoHideDuration={4000}
        />
        <Snackbar
          bodyStyle={{ backgroundColor: ICON_RED }}
          open={errors.length >= 1}
          message={_t('feedback.error.importMotifs')}
          autoHideDuration={4000}
        />
      </div>
    );
  }
}

export default ImportMotifs;
