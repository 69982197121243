import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import EditIcon from 'material-ui/svg-icons/editor/mode-edit';

import BoButton from 'facade/BoButton';
import SimpleTable from 'commons/SimpleTable';
import { ListBody, ListBottom, ListWrapper } from 'commons/ListWrappers';
import ErrorBlock from 'commons/ErrorBlock';
import FlexCenter from 'commons/FlexCenter';
import Content from 'commons/Content';
import DateComponent from 'commons/Date';
import { DecreeDTO } from 'api/tepv/decrees/types';
import { getApiState } from 'api/duck';
import {
  createOrUpdateDecree,
  deleteDecree,
  getDecrees,
} from 'api/tepv/decrees';
import { BKG_CYAN, BKG_PINK } from 'theme';
import { CityOrganizationDTO } from 'api/organizations/types';
import { fetchOrganizations } from 'api/organizations';

import DecreeSidebar from './DecreeSidebar';
import { DecreeFilters } from './types';
import { TYPE_LABEL } from './commons';
import EditionModal, { DeletionModal } from './modals';

const { _t } = window.loadTranslations(__filename);

const STYLE_ICON: CSSProperties = {
  cursor: 'pointer',
};

const translateTableCols = (): Array<{
  label?: string;
  width: number;
  grow?: number;
  style?: CSSProperties;
  headerStyle?: CSSProperties;
  onSort?: boolean;
}> => [
  { label: _t('element.table.col.ref'), width: 150 },
  { label: _t('element.table.col.type'), width: 150 },
  {
    label: _t('element.table.col.dateStart'),
    width: 150,
  },
  { label: _t('element.table.col.dateEnd'), width: 150 },
  { label: _t('element.table.col.comment'), width: 150, grow: 15 },
  {
    label: _t('element.table.col.actions'),
    width: 100,
    headerStyle: { textAlign: 'center' },
  },
];

const getInitialFilters = (): DecreeFilters => ({
  types: new Set(),
  comment: '',
  reference: '',
  applicationDate: {
    from: undefined,
    to: undefined,
  },
});

type DecreeListState = {
  decrees: Array<DecreeDTO>;
  filteredDecrees: Array<DecreeDTO>;
  organizations: CityOrganizationDTO[] | null | undefined;
  loading: boolean;
  filters: DecreeFilters;
  decreesReferences: Array<string>;
  deletionReference: string | null | undefined;
  editedDecreeReference: string | null | undefined;
  isEditionModalOpen: boolean;
  fetchError: Error | null | undefined;
  editionError: Error | null | undefined;
  deletionError: Error | null | undefined;
};

type DecreeListProps = {
  canAddDecree: boolean;
  canDeleteDecree: boolean;
};

const filter = (
  filters: DecreeFilters,
  decrees: Array<DecreeDTO>
): Array<DecreeDTO> => {
  const { types, reference, comment, applicationDate } = filters;
  return decrees.filter(decree => {
    if (types.size > 0 && !types.has(decree.type)) return false;
    if (!decree.reference.toLowerCase().includes(reference.toLowerCase()))
      return false;
    if (!decree.comment.toLowerCase().includes(comment.toLowerCase()))
      return false;
    const applicationDateTimestamp = Date.parse(decree.applicationDatetime);
    if (
      applicationDate.from &&
      applicationDateTimestamp <= applicationDate.from.getTime()
    )
      return false;
    if (
      applicationDate.to &&
      applicationDateTimestamp >= applicationDate.to.getTime()
    )
      return false;

    return true;
  });
};

class DecreeList extends React.Component<DecreeListProps, DecreeListState> {
  state: DecreeListState = {
    decrees: [],
    filteredDecrees: [],
    loading: true,
    organizations: [],
    filters: getInitialFilters(),
    decreesReferences: [],
    deletionReference: null,
    editedDecreeReference: null,
    isEditionModalOpen: false,
    fetchError: null,
    deletionError: null,
    editionError: null,
  };

  componentDidMount() {
    this.fetchDecrees();
  }

  resetFilters = () => this.filterDecrees(getInitialFilters());

  filterDecrees = (filters: DecreeFilters) => {
    const filteredDecrees = filter(filters, this.state.decrees);
    this.setState({ filters, filteredDecrees });
  };

  openDeletionModal = ({ currentTarget }: React.MouseEvent<any>) =>
    this.setState({ deletionReference: currentTarget.dataset.reference });
  closeDeletionModal = () =>
    this.setState({ deletionReference: null, deletionError: null });

  openEditionModal = ({ currentTarget }: React.MouseEvent<any>) =>
    this.setState({
      editedDecreeReference: currentTarget.dataset.reference,
      isEditionModalOpen: true,
    });
  closeEditionModal = () =>
    this.setState({
      editedDecreeReference: null,
      isEditionModalOpen: false,
      editionError: null,
    });

  removeDecree = async () => {
    const { decrees, deletionReference, filters } = this.state;
    if (!deletionReference) return;
    const updatedDecrees = decrees.filter(
      ({ reference }: DecreeDTO) => reference !== deletionReference
    );
    try {
      await deleteDecree(deletionReference);
      this.setState({
        decrees: updatedDecrees,
        filteredDecrees: filter(filters, updatedDecrees),
        deletionReference: null,
        deletionError: null,
      });
    } catch (err) {
      this.setState({ deletionError: err });
    }
  };

  editDecree = async (decree: DecreeDTO) => {
    if (!decree) return;
    try {
      await createOrUpdateDecree(decree);
    } catch (err) {
      this.setState({ editionError: err });
      return;
    }
    const { decrees, editedDecreeReference, filters } = this.state;
    let updatedDecrees = [];
    if (editedDecreeReference) {
      const editedIndex = decrees.findIndex(
        ({ reference }: DecreeDTO) => reference === editedDecreeReference
      );
      updatedDecrees = [
        ...decrees.slice(0, editedIndex),
        decree,
        ...decrees.slice(editedIndex + 1),
      ];
    } else {
      updatedDecrees = [...decrees, decree];
    }
    this.setState({
      decrees: updatedDecrees,
      filteredDecrees: filter(filters, updatedDecrees),
      editedDecreeReference: null,
      isEditionModalOpen: false,
      editionError: null,
    });
  };

  fetchDecrees = async () => {
    try {
      const decrees = await getDecrees();
      const listDecreesReferences = decrees.map(decree => decree.reference);

      let organizations = [];
      try {
        organizations = await fetchOrganizations(false, 'pv');
      } catch (error) {
        this.setState({ fetchError: error });
        return;
      }

      this.setState({
        decrees,
        organizations,
        filteredDecrees: decrees,
        decreesReferences: listDecreesReferences,
        loading: false,
        fetchError: null,
      });
    } catch (error) {
      this.setState({ fetchError: error });
    }
  };

  renderRow = ({
    type,
    reference,
    comment,
    applicationDatetime,
    endOfApplicationDatetime,
  }: DecreeDTO) => [
    <span>{reference}</span>,
    <span>{TYPE_LABEL[type]}</span>,
    <span>
      <DateComponent datetime={applicationDatetime} withTime={false} />
    </span>,
    <span>
      <DateComponent datetime={endOfApplicationDatetime} withTime={false} />
    </span>,
    <span>{comment}</span>,
    <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
      {this.props.canDeleteDecree && (
        <DeleteIcon
          style={STYLE_ICON}
          color={BKG_CYAN}
          hoverColor={BKG_PINK}
          onClick={this.openDeletionModal}
          data-reference={reference}
        />
      )}
      {this.props.canAddDecree && (
        <EditIcon
          style={STYLE_ICON}
          color={BKG_CYAN}
          hoverColor={BKG_PINK}
          onClick={this.openEditionModal}
          data-reference={reference}
        />
      )}
    </div>,
  ];

  render() {
    const {
      loading,
      filteredDecrees,
      filters,
      deletionReference,
      decreesReferences,
      isEditionModalOpen,
      decrees,
      editedDecreeReference,
      fetchError,
      deletionError,
      editionError,
      organizations,
    } = this.state;
    const { canAddDecree } = this.props;
    const editedDecree = decrees.find(
      ({ reference }: DecreeDTO) => reference === editedDecreeReference
    );

    if (fetchError) {
      return (
        <Content>
          <FlexCenter>
            <ErrorBlock message={_t('feedback.error')} error={fetchError} />
          </FlexCenter>
        </Content>
      );
    }

    return (
      <div style={{ height: '100%', fontFamily: 'Roboto', display: 'flex' }}>
        <DecreeSidebar
          filters={filters}
          resetFilters={this.resetFilters}
          updateFilters={this.filterDecrees}
          totalHits={filteredDecrees.length}
        />
        <ListWrapper style={{ width: '100%', paddingTop: 20 }}>
          <ListBody loading={loading} style={{ height: 'calc(100% - 10px)' }}>
            <SimpleTable
              cols={translateTableCols()}
              rowHeight={50}
              itemsRenderer={this.renderRow}
              items={filteredDecrees}
            />
          </ListBody>
          {canAddDecree && (
            <ListBottom>
              <BoButton
                label={_t('element.buttonCreate.label')}
                primary
                onClick={this.openEditionModal}
              />
            </ListBottom>
          )}
        </ListWrapper>
        <DeletionModal
          deletionReference={deletionReference}
          closeModal={this.closeDeletionModal}
          removeDecree={this.removeDecree}
          error={deletionError}
        />
        <EditionModal
          closeModal={this.closeEditionModal}
          editDecree={this.editDecree}
          organizations={organizations}
          decree={editedDecree}
          open={isEditionModalOpen}
          decreesReferences={decreesReferences}
          error={editionError}
        />
      </div>
    );
  }
}

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    canAddDecree: userInfo && userInfo.rights.includes('DECREES_WRITE'),
    canDeleteDecree: userInfo && userInfo.rights.includes('DECREES_DELETE'),
  };
})(DecreeList);
