import * as React from 'react';

import { DataBoxHeader, DataBoxContent, DataBox } from 'commons/DataBox';
import { GpvDetail } from 'api/tepv/gpv/types';
import Separator from 'commons/Separator';
import { BKG_CYAN } from 'theme';

import TimeAndLocationBlock from './TimeAndLocationBlock';
import AgentBlock from './AgentBlock';

const { _t } = window.loadTranslations(__filename);

const STYLE_COLUMNS_CONTAINER: React.CSSProperties = {
  display: 'flex',
  width: '90%',
  justifyContent: 'space-around',
};

const ConstatationBlock = ({
  gpv: { metadata, zoneId },
}: {
  gpv: GpvDetail;
}) => (
  <DataBox panel style={{ width: '95%', marginTop: 40 }}>
    <DataBoxHeader color={BKG_CYAN}>
      {_t('element.header.ascertainment')}
    </DataBoxHeader>
    <DataBoxContent>
      <div style={STYLE_COLUMNS_CONTAINER}>
        <div style={{ flex: '1' }}>
          <TimeAndLocationBlock metadata={metadata} zoneId={zoneId} />
        </div>
        <Separator />
        <div style={{ flex: '1' }}>
          <AgentBlock agent={metadata.agent} />
        </div>
      </div>
    </DataBoxContent>
  </DataBox>
);

export default ConstatationBlock;
