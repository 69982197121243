import React, { CSSProperties } from 'react';

import { TXT_BLACK } from 'theme';

const STYLE_WRAPPER: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
};

type Props = {
  value: number;
  text: string;
  color: string;
  width: string;
};

const TaskData = ({ value, text, color, width }: Props): JSX.Element => (
  <div style={{ width, textAlign: 'center' }}>
    <div style={STYLE_WRAPPER}>
      <div style={{ fontWeight: 'bold', color, fontSize: 60 }}>{value}</div>
      <div style={{ color: TXT_BLACK }}>{text}</div>
    </div>
  </div>
);

export default TaskData;
