import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import { InternalAgent } from 'api/auth/types';
import { getApiState } from 'api/duck';
import { AgentRight } from '@cvfm-front/tefps-types';

import './SubscriptionNavigation.css';

const { _t } = window.loadTranslations(__filename);

type SubscriptionNavigationProps = {
  style?: Record<string, any>;
  userInfo: InternalAgent;
};

type LinkData = {
  to: string;
  label: string;
  right: AgentRight;
  ariaLabel?: string;
};

const getTranslatableLink = (): { [key: string]: Array<LinkData> } => {
  return {
    accounts: [
      {
        to: '/subscription/subscribers',
        label: _t('element.accounts'),
        right: 'SUBSCRIBER_READ',
      },
    ],
    eligibility: [
      {
        to: '/subscription/eligibility/product',
        label: _t('element.offers'),
        ariaLabel: _t('element.eligibility.configuration'),
        right: 'PRODUCT_READ',
      },
      {
        to: '/subscription/eligibility/order',
        label: _t('element.orders.eligibility'),
        right: 'ORDER_READ',
      },
      {
        to: '/subscription/eligibility/eligibilities',
        label: _t('element.granted'),
        right: 'ORDER_READ',
      },
    ],
    bundle: [
      {
        to: '/subscription/bundle/product',
        label: _t('element.offers'),
        right: 'PRODUCT_READ',
      },
      {
        to: '/subscription/bundle/order',
        label: _t('element.orders.bundle'),
        right: 'ORDER_READ',
      },
    ],
  };
};

function getClassName(index: number, length: number): string {
  if (length === 1) {
    return 'sub-nav-content sub-nav-content_middle';
  }

  if (index === 0) {
    return 'sub-nav-content sub-nav-content_left';
  }
  if (index === length - 1) {
    return 'sub-nav-content sub-nav-content_right';
  }
  return 'sub-nav-content';
}

const SubscriptionNavigation = ({
  style,
  userInfo,
}: SubscriptionNavigationProps) => {
  const nav: {
    [k: string]: LinkData[];
  } = {};
  Object.keys(getTranslatableLink()).forEach(key => {
    nav[key] = getTranslatableLink()[key].filter((link: LinkData) =>
      userInfo.rights.includes(link.right)
    );
  });

  return (
    <div style={style} className="sub-nav_component">
      {nav.accounts.length > 0 && (
        <span>
          <div className="sub-nav_title">{_t('element.users')}</div>
          <div className="sub-nav_container">
            {nav.accounts.map((link, index) => (
              <NavLink
                key={index}
                to={link.to}
                className={getClassName(index, nav.accounts.length)}
                activeClassName="sub-nav-content_active"
              >
                <span>{link.label}</span>
              </NavLink>
            ))}
          </div>
        </span>
      )}

      {nav.eligibility.length > 0 && (
        <span>
          <div className="sub-nav_title">{_t('element.eligibility.label')}</div>
          <div className="sub-nav_container">
            {nav.eligibility.map((link, index) => (
              <NavLink
                key={index}
                to={link.to}
                className={getClassName(index, nav.eligibility.length)}
                activeClassName="sub-nav-content_active"
                aria-label={link.ariaLabel}
              >
                <span>{link.label}</span>
              </NavLink>
            ))}
          </div>
        </span>
      )}

      {nav.bundle.length > 0 && (
        <span>
          <div className="sub-nav_title">{_t('element.bundle')}</div>
          <div className="sub-nav_container">
            {nav.bundle.map((link, index) => (
              <NavLink
                key={index}
                to={link.to}
                className={getClassName(index, nav.bundle.length)}
                activeClassName="sub-nav-content_active"
              >
                <span>{link.label}</span>
              </NavLink>
            ))}
          </div>
        </span>
      )}
    </div>
  );
};

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    userInfo,
  };
})(SubscriptionNavigation);
