const critairOptions = [
  { id: 'ZERO', name: '0' },
  { id: 'ONE', name: '1' },
  { id: 'TWO', name: '2' },
  { id: 'THREE', name: '3' },
  { id: 'FOUR', name: '4' },
  { id: 'FIVE', name: '5' },
  { id: 'UNRANKED', name: 'Sans vignette' },
];

export default critairOptions;
