import { Dialog } from 'material-ui';
import React from 'react';

import BoButton from 'facade/BoButton';
import { patchSubscriber } from 'api/cvfm-core-subscription/subscriber';
import { Proof, ProofType, SubscriberDTO } from '@cvfm-front/tefps-types';
import { unreachable } from 'commons/Enums';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  subscriber: SubscriberDTO;
  updateSubscriber: React.Dispatch<React.SetStateAction<SubscriberDTO>>;
  proof: Proof;
  closeModal: () => void;
};

const SubscriberDeleteProof = ({
  subscriber,
  updateSubscriber,
  proof,
  closeModal,
}: Props): JSX.Element => {
  const deleteProof = async () => {
    let path = '';
    switch (proof.type) {
      case ProofType.IDENTITY_CARD:
        path = '/identity/proofs';
        break;
      case ProofType.PERSONAL_ADDRESS:
        path = '/personal-address/proofs';
        break;
      case ProofType.PROFESSIONAL_ACTIVITY:
        path = '/professional-enterprise/proofs';
        break;
      case ProofType.PROFESSIONAL_ADDRESS:
        path = '/professional-address/proofs';
        break;
      default:
        unreachable(proof.type);
    }

    const patchObject = [
      {
        path,
        op: 'remove',
        value: proof.id,
      },
    ];

    const updatedSubscriber = await patchSubscriber(
      subscriber.subscriberId,
      patchObject
    );
    updateSubscriber(updatedSubscriber);
    closeModal();
  };

  const actions = [
    <BoButton
      key="cancel"
      label={_tg('action.cancel')}
      onClick={closeModal}
      style={{ marginRight: '1vh' }}
    />,
    <BoButton
      key="add"
      label={_tg('action.delete')}
      onClick={deleteProof}
      secondary
    />,
  ];

  return (
    <Dialog
      title={_tg('tefps.subscription.subscriberDeleteProof.title')}
      open
      actions={actions}
    />
  );
};

export default SubscriberDeleteProof;
