import React, { CSSProperties, useState } from 'react';
import Dialog from 'material-ui/Dialog';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import { getActions } from 'commons/ModalUtils';
import { BKG_CYAN } from 'theme';
import { GpvStatus } from 'api/tepv/gpv/types';
import { STATUSES_FILTER_OPTIONS } from 'api/tepv/gpv/utils';

const { _t } = window.loadTranslations(__filename);

const STYLE_TITLE: CSSProperties = {
  backgroundColor: BKG_CYAN,
  color: '#ffffff',
  fontWeight: 'bold',
};

const STYLE_CONTENT_STATUS: CSSProperties = {
  marginTop: '20px',
};

type Props = {
  onSubmit: (status: GpvStatus) => void;
  isOpen: boolean;
  onClose: () => void;
};

function UpdateGpvStatusModal({ onSubmit, onClose, isOpen }: Props) {
  const [status, setStatus] = useState<GpvStatus>('PENDING');

  function onClickValidate() {
    onSubmit(status);
  }

  function handleChange(
    _event: React.ChangeEvent<any>,
    _index: number,
    value: any
  ) {
    setStatus(value);
  }

  const actions = getActions(onClickValidate, onClose);

  const statusOnChange = STATUSES_FILTER_OPTIONS.map(({ value, label }) => (
    <MenuItem key={value} value={value} primaryText={label} />
  ));

  return (
    <Dialog
      title={_t('element.newStatus')}
      actions={actions}
      modal
      autoScrollBodyContent
      open={isOpen}
      titleStyle={STYLE_TITLE}
    >
      <div style={STYLE_CONTENT_STATUS}>
        <SelectField
          value={status}
          floatingLabelText={_t('element.changeStatus')}
          onChange={handleChange}
        >
          {statusOnChange}
        </SelectField>
      </div>
    </Dialog>
  );
}

export default UpdateGpvStatusModal;
