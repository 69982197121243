import React from 'react';

import SubscriberSimpleOrderHistoryAction from './SubscriberSimpleOrderHistoryAction';

const { _t } = window.loadTranslations(__filename);

function SubscriberStartEligibilityAction({ action }: any): JSX.Element {
  return (
    <SubscriberSimpleOrderHistoryAction
      message={_t('element.content', {
        planName: action.planName,
        plates: action.plates.join(', '),
      })}
      action={action}
    />
  );
}

export default SubscriberStartEligibilityAction;
