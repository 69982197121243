import React, { CSSProperties } from 'react';
import CircularProgress from 'material-ui/CircularProgress';
import FlatButton from 'material-ui/FlatButton';

import Content from 'commons/Content';
import ErrorBlock from 'commons/ErrorBlock';
import FlexCenter from 'commons/FlexCenter';
import {
  DataBox,
  DataBoxContent,
  DataBoxHeader,
  DataBoxItem,
  DataBoxItemWrapper,
} from 'commons/DataBox';
import FakeInputBlock from 'commons/FakeInputBlock';
import Price from 'commons/Price';
import Date from 'commons/Date';
import { fetchNatinfDetails } from 'api/tepv/natinfs';
import { NatinfDetail } from 'api/tepv/natinfs/types';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_HEADER_LEFT: CSSProperties = {
  fontWeight: 'bold',
  marginLeft: 30,
};

const STYLE_HEADER_RIGHT: CSSProperties = {
  fontSize: 14,
  marginRight: 30,
};

type NatinfDetailsState = {
  natinf: NatinfDetail | null | undefined;
  error: Error | null | undefined;
};

type NatinfDetailsProps = {
  match: {
    params: {
      natinfId: string;
    };
  };
};

class NatinfDetails extends React.Component<
  NatinfDetailsProps,
  NatinfDetailsState
> {
  state: NatinfDetailsState = {
    natinf: null,
    error: null,
  };

  componentDidMount() {
    this.fetchNatinf(this.props.match.params.natinfId);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: NatinfDetailsProps) {
    if (this.props.match.params.natinfId !== nextProps.match.params.natinfId) {
      this.fetchNatinf(nextProps.match.params.natinfId);
    }
  }

  async fetchNatinf(natinfId: string) {
    this.setState({
      natinf: null,
      error: null,
    });
    try {
      const natinf = await fetchNatinfDetails(natinfId);
      this.setState({
        natinf,
      });
    } catch (error) {
      this.setState({
        error,
      });
    }
  }

  render() {
    const { natinf, error } = this.state;

    if (error) {
      return (
        <Content>
          <div
            style={{
              padding: '30px 0px 5px 70px',
            }}
          >
            <FlatButton
              href="#/natinf/list"
              label={_t('element.buttonBackList.label')}
            />
          </div>
          <FlexCenter>
            <ErrorBlock message={_t('feedback.error')} error={error} />
          </FlexCenter>
        </Content>
      );
    }

    if (!natinf) {
      return (
        <Content>
          <FlatButton
            href="#/natinf/list"
            label={_t('element.buttonBackList.label')}
          />
          <FlexCenter>
            <CircularProgress />
          </FlexCenter>
        </Content>
      );
    }
    return (
      <Content>
        <div
          style={{
            padding: '30px 0px 5px 70px',
          }}
        >
          <FlatButton
            href="#/natinf/list"
            label={_t('element.buttonBackList.label')}
          />
          <DataBox panel style={{ width: '95%' }}>
            <DataBoxHeader>
              <div style={STYLE_HEADER_LEFT}>{_tg('tepv.natinf')}</div>
              <div style={STYLE_HEADER_RIGHT}>
                {_t('element.headerRight')}{' '}
                <span
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {natinf.code}
                </span>
              </div>
            </DataBoxHeader>
            <DataBoxContent>
              <DataBoxItemWrapper
                style={{
                  marginBottom: 20,
                  width: '90%',
                }}
              >
                <DataBoxItem
                  style={{
                    maxWidth: '100%',
                  }}
                >
                  <FakeInputBlock
                    title={_t('element.fieldQualification.title')}
                    value={natinf.qualification}
                  />
                  <FakeInputBlock
                    title={_t('element.fieldCategorie.title')}
                    value={natinf.categorie}
                  />
                  <FakeInputBlock
                    title={_t('element.fieldGroup.title')}
                    value={
                      natinf.groupId
                        ? _t('element.fieldGroup.value.todo')
                        : _t('element.fieldGroup.value.noValue')
                    }
                  />
                  <FakeInputBlock
                    title={_t('element.fieldCas.title')}
                    value={`${natinf.cas}`}
                  />

                  <FakeInputBlock
                    title={_t('element.fieldMifCount.title')}
                    value={`${natinf.mifCount}`}
                  />
                </DataBoxItem>
              </DataBoxItemWrapper>
              <DataBoxItemWrapper
                style={{
                  marginBottom: 20,
                  width: '90%',
                }}
              >
                <DataBoxItem
                  style={{
                    maxWidth: '100%',
                  }}
                >
                  <FakeInputBlock
                    title={_tg('field.payment.priceReduced')}
                    value={
                      natinf.montantMinorable ? (
                        <Price price={natinf.montantMinore * 100} />
                      ) : (
                        _tg('field.unavailable')
                      )
                    }
                  />
                  <FakeInputBlock
                    title={_tg('field.payment.priceForfait')}
                    value={
                      <Price price={natinf.montantAmendeForfaitaire * 100} />
                    }
                  />
                  <FakeInputBlock
                    title={_tg('field.payment.priceSurcharged')}
                    value={<Price price={natinf.montantMajore * 100} />}
                  />
                </DataBoxItem>
              </DataBoxItemWrapper>
              <DataBoxItemWrapper
                style={{
                  marginBottom: 20,
                  width: '90%',
                }}
              >
                <DataBoxItem
                  style={{
                    maxWidth: '100%',
                  }}
                >
                  <FakeInputBlock
                    title={_t('element.fieldTextPrevu.title')}
                    value={natinf.textePrevu}
                  />
                  <FakeInputBlock
                    title={_t('element.fieldTextReprimant.title')}
                    value={natinf.texteReprimant}
                  />
                </DataBoxItem>
              </DataBoxItemWrapper>
              <DataBoxItemWrapper
                style={{
                  marginBottom: 20,
                  width: '90%',
                }}
              >
                <DataBoxItem
                  style={{
                    maxWidth: '100%',
                  }}
                >
                  <FakeInputBlock
                    title={_t('element.fieldDateStart.title')}
                    value={
                      <Date
                        datetime={natinf.dateApplication}
                        withTime={false}
                      />
                    }
                  />
                  <FakeInputBlock
                    title={_t('element.fieldDateAbrogation.title')}
                    value={
                      <Date
                        datetime={natinf.dateAbrogration}
                        withTime={false}
                      />
                    }
                  />
                </DataBoxItem>
              </DataBoxItemWrapper>
              <DataBoxItemWrapper
                style={{
                  marginBottom: 20,
                  width: '90%',
                }}
              >
                <DataBoxItem
                  style={{
                    maxWidth: '100%',
                  }}
                >
                  <FakeInputBlock
                    title={_t('element.fieldDateImport.title')}
                    value={
                      <Date datetime={natinf.dateImport} withTime={false} />
                    }
                  />
                  <FakeInputBlock
                    title={_t('element.fieldDateDeactivation.title')}
                    value={
                      <Date datetime={natinf.dateDisable} withTime={false} />
                    }
                  />
                </DataBoxItem>
              </DataBoxItemWrapper>
            </DataBoxContent>
          </DataBox>
        </div>
      </Content>
    );
  }
}

export default NatinfDetails;
