import { InstrumentNature } from 'api/tepv/instruments/types';

const { _t } = window.loadTranslations(__filename);

export const NATURE_LABEL: { [k in InstrumentNature]: string } = {
  ALCOHOL: _t('natureLabel.ALCOHOL'),
  SPEED: _t('natureLabel.SPEED'),
  WEIGHT: _t('natureLabel.WEIGHT'),
  NOISE: _t('natureLabel.NOISE'),
  POLLUTION: _t('natureLabel.POLLUTION'),
};

export const NATURE_OPTIONS: Array<{
  value: string;
  label: string;
}> = Object.entries(NATURE_LABEL).map(value => {
  const entry = value as [string, string];
  return {
    value: entry[0],
    label: entry[1],
  };
});
