import moment from 'moment';

import { Pager, SortParameters } from 'api/commonTypes';
import { MifSearchParams } from 'api/tepv/mif/types';

import { MifFilters } from './types';

const { _tg } = window.loadTranslations(__filename);

function getSortFieldText(sortField: number) {
  switch (sortField) {
    case 0:
      return 'natinfCode';
    case 1:
      return 'signatureDatetime';
    case 2:
      return 'agentName';
    case 3:
      return 'agentMatricule';
    case 4:
      return 'streetName';
    case 5:
      return 'cityIdentifier';
    case 6:
      return 'locality';
    case 7:
      return 'presetNote';
    default:
      return 'signatureDatetime';
  }
}

export const filterToSearchParams = (
  filters: MifFilters,
  sort: SortParameters = {
    sortField: 1,
    increasingOrder: true,
  },
  pager: Pager = { page: 0, maxRecords: 0 }
): MifSearchParams => {
  const {
    natinfText,
    cas,
    groupIds,
    infractionDate,
    infractionTime,
    address,
    vehicleRemovalStatus,
    saisineStatus,
    photosStatus,
    antaiStatus,
    agentSearchText,
    organizationIds,
    profiles,
    vehicleCategory,
    immatriculationFulltext,
    presetNotes,
    infractionLocations,
    zoneIds,
    mifId,
    postalCode,
  } = filters;
  const { page, maxRecords } = pager;

  return {
    natinfText,
    cas,
    groupIds,
    infractionDateStart: infractionDate.from
      ? moment(infractionDate.from).toISOString()
      : undefined,
    infractionDateEnd: infractionDate.to
      ? moment(infractionDate.to).toISOString()
      : undefined,
    infractionTimeStart: infractionTime.from,
    infractionTimeEnd: infractionTime.to,
    infractionDate: infractionDate.from
      ? moment(infractionDate.from).toISOString()
      : undefined,
    infractionTime: infractionTime.to
      ? moment(infractionTime.to).toISOString()
      : undefined,
    address,
    vehicleRemovalStatus: Array.from(vehicleRemovalStatus),
    saisineStatus: Array.from(saisineStatus),
    photosStatus: Array.from(photosStatus),
    antaiStatus: Array.from(antaiStatus),
    agentSearchText,
    organizationIds,
    profiles,
    vehicleCategory,
    immatriculationFulltext,
    presetNotes,
    infractionLocations,
    zoneIds: Array.from(zoneIds),
    page,
    maxRecords,
    sortOrder: sort.increasingOrder ? 'ASC' : 'DESC',
    sortField: getSortFieldText(sort.sortField),
    mifId,
    postalCode,
  };
};

export const vehicleCategories = [
  'CYCLE',
  'CYCLO',
  'VP',
  'PL',
  'TCP',
  'TAXI',
  'MOTO',
  'CTTE',
  'PIETON',
  'EDPM',
  'BATEAU',
  'AUTRE',
];

export const EXPORT_COL = [
  { key: 'natinfCode', label: 'Code NATINF', checked: true },
  {
    key: 'natinfCas',
    label: 'Cas NATINF',
    checked: true,
  },
  { key: 'natinfLabel', label: 'Libellé NATINF', checked: true },
  {
    key: 'signatureDate',
    label: 'Date de signature',
    checked: true,
  },
  {
    key: 'infractionDate',
    label: "Date d'infraction",
    checked: true,
  },
  { key: 'agentName', label: _tg('field.agent.name'), checked: true },
  { key: 'assignment', label: _tg('field.agent.role'), checked: true },
  {
    key: 'agentMatricule',
    label: "Matricule de l'agent",
    checked: true,
  },
  {
    key: 'agentOrganizationId',
    label: _tg('field.organisation.organisation'),
    checked: true,
  },
  { key: 'road', label: 'Voie', checked: true },
  { key: 'city', label: _tg('field.address.locality'), checked: true },
  {
    key: 'presetNote',
    label: 'Renseignement prédéfini',
    checked: true,
  },
  {
    key: 'infractionLocation',
    label: 'Lieu de verbalisation',
    checked: true,
  },
  {
    key: 'antaiStatus',
    label: "Etat de transmission à l'ANTAI",
    checked: true,
  },
  { key: 'vehicleCategory', label: _tg('field.vehicle.category') },
  {
    key: 'vehicleBrand',
    label: _tg('field.vehicle.brand'),
  },
  { key: 'vehicleModel', label: 'Modèle de véhicule' },
  {
    key: 'vehiclePlate',
    label: _tg('field.vehicle.license'),
  },
  { key: 'plateCountry', label: _tg('field.device.immatriculationCountry') },
  {
    key: 'freeComments',
    label: 'Renseignement libre',
  },
  { key: 'photoStatus', label: _tg('field.picture') },
  { key: 'vehicleRemovalStatus', label: 'Enlèvement' },
  {
    key: 'natinfCategory',
    label: 'Catégorie de NATINF',
  },
  { key: 'infractionCoordinates', label: "Coordonnées GPS de l'infraction" },
  { key: 'zoneId', label: 'Zone' },
  { key: 'signatureCoordinates', label: 'Coordonnées GPS de signature' },
  { key: 'mifId', label: 'Identifiant du MIF' },
  { key: 'saisineMotif', label: 'Motif de la saisine' },
  { key: 'saisineComment', label: 'Commentaire de la saisine' },
  {
    key: 'creationDate',
    label: 'Date de transmission par le PDA',
  },
];
