import React, { CSSProperties, useState, useEffect } from 'react';
import FlatButton from 'material-ui/FlatButton';
import { CircularProgress } from 'material-ui';

import { fetchGpvDetails, patchComments, getGpvPdfUrl } from 'api/tepv/gpv';
import { GpvDetail } from 'api/tepv/gpv/types';
import Content from 'commons/Content';
import { TXT_BLACK } from 'theme';
import { gpvStatusesName } from 'api/tepv/gpv/utils';
import { openNewAuthentifiedTab } from 'api/helpers';
import FlexCenter from 'commons/FlexCenter';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';

import OffenderBlock from './OffenderBlock';
import ConstatationBlock from './ConstatationBlock';
import CommentsBlock from './CommentsBlock';
import SaisineBlock from './SaisineBlock';
import InfractionBlock from './InfractionBlock';

const { _t } = window.loadTranslations(__filename);

const STYLE_HEADER: CSSProperties = {
  display: 'flex',
  paddingBottom: '5px',
};

interface Props {
  match: {
    params: {
      gpvId: string;
    };
  };
}
function GpvDetailPage({
  match: {
    params: { gpvId },
  },
}: Props) {
  const [gpvDetail, setGpvDetail] = useState<GpvDetail | null>(null);

  const setMessage = useSnackbar();

  async function fetch() {
    try {
      const detail = await fetchGpvDetails(gpvId);
      setGpvDetail(detail);
    } catch (ex) {
      setMessage(_t('feedback.error.fetchGpvDetails', { err: ex }));
    }
  }

  useEffect(() => {
    fetch();
  }, [gpvId]);

  function openPdf() {
    const url = getGpvPdfUrl(gpvId);
    openNewAuthentifiedTab(url);
  }

  async function updateComment(comment: string) {
    if (!gpvDetail) {
      return;
    }
    try {
      await patchComments(gpvId, comment);
      setGpvDetail({
        ...gpvDetail,
        metadata: {
          ...gpvDetail.metadata,
          comments: [...gpvDetail.metadata.comments, comment],
        },
      });
    } catch (ex) {
      setMessage(_t('feedback.error.addComment', { err: ex }));
    }
  }

  if (!gpvDetail) {
    return (
      <>
        <Content>
          <FlatButton
            href="#/gpv/list"
            label={_t('action.button.backToGpvList')}
          />
          <FlexCenter>
            <CircularProgress />
          </FlexCenter>
        </Content>
        );
      </>
    );
  }

  return (
    <Content>
      <div
        style={{
          padding: '20px 0px 5px 70px',
        }}
      >
        <div style={STYLE_HEADER}>
          <FlatButton
            href="#/gpv/list"
            label={_t('action.button.backToGpvList')}
          />
          <div style={{ marginTop: '10px' }}>|</div>
          <FlatButton label="Exporter" onClick={openPdf} />
          <div style={{ margin: '10px 50px 10px 300px', color: TXT_BLACK }}>
            {gpvStatusesName[gpvDetail.status]}
          </div>
        </div>
        <InfractionBlock gpvDetail={gpvDetail} />
        <ConstatationBlock gpv={gpvDetail} />
        {gpvDetail.metadata.offender && (
          <OffenderBlock
            offender={gpvDetail.metadata.offender}
            responsibleParty={gpvDetail.metadata.responsibleParty}
          />
        )}
        {gpvDetail.metadata.saisine && (
          <SaisineBlock saisine={gpvDetail.metadata.saisine} />
        )}
        <CommentsBlock
          metadata={gpvDetail.metadata}
          updateComment={updateComment}
        />
      </div>
    </Content>
  );
}

export default GpvDetailPage;
