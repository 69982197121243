import React from 'react';
import { Dialog } from 'material-ui';

import { SubscriberDTO } from '@cvfm-front/tefps-types';
import BoButton from 'facade/BoButton';
import { patchSubscriber } from 'api/cvfm-core-subscription/subscriber';

type Props = {
  subscriber: SubscriberDTO;
  setSubscriber: React.Dispatch<React.SetStateAction<SubscriberDTO>>;
  vehicleId: string;
  setOpenSecondConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
};

const { _tg } = window.loadTranslations(__filename);

const SubscriberDeleteVehicleProof = ({
  subscriber,
  setSubscriber,
  vehicleId,
  setOpenSecondConfirmation,
}: Props): JSX.Element => {
  const findVehicle = (): Record<string, string> => {
    let vehicle = subscriber.personalProfile?.vehicles.find(
      v => v.id === vehicleId
    );
    if (vehicle) {
      return { vehicleId: vehicle.id, profileType: 'personal-profile' };
    }
    vehicle = subscriber.professionalProfile?.vehicles.find(
      v => v.id === vehicleId
    );
    if (vehicle) {
      return { vehicleId: vehicle.id, profileType: 'professional-profile' };
    }
    throw new Error('Should not happen : vehicle not found');
  };

  const removeProof = async (): Promise<void> => {
    const { vehicleId, profileType } = findVehicle();
    const patchObject = [
      {
        path:
          profileType === 'personal-profile'
            ? '/personal-vehicle/proofs'
            : '/professional-vehicle/proofs',
        op: 'remove',
        value: vehicleId,
      },
    ];
    const updatedSubscriber = await patchSubscriber(
      subscriber.subscriberId,
      patchObject
    );
    setSubscriber(updatedSubscriber);
    setOpenSecondConfirmation(false);
    return new Promise(resolve => resolve());
  };

  const actions = [
    <BoButton
      key="cancel"
      label={_tg('action.cancel')}
      onClick={() => setOpenSecondConfirmation(false)}
    />,
    <BoButton
      secondary
      key="remove"
      label={_tg('action.delete')}
      onClick={(): Promise<void> => removeProof()}
    />,
  ];

  return (
    <Dialog
      title={_tg('tefps.subscription.subscriberDeleteVehicleProof.title')}
      open
      actions={actions}
    />
  );
};

export default SubscriberDeleteVehicleProof;
