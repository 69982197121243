import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import _cloneDeep from 'lodash.clonedeep';
import _isEqual from 'lodash.isequal';
import Dialog from 'material-ui/Dialog';
import Checkbox from 'material-ui/Checkbox';
import Snackbar from 'material-ui/Snackbar';
import AlertIcon from 'material-ui/svg-icons/alert/add-alert';
import ArrowIcon from 'material-ui/svg-icons/navigation/arrow-forward';

import BoButton from 'facade/BoButton';
import { getApiState } from 'api/duck';
import { upsertAgentNotifications } from 'api/tasks';
import { NotificationType } from 'api/tasks/types';
import { BKG_CYAN, BKG_PINK } from 'theme';
import { computeNewNotifications, NOTIF_CHECKBOX } from 'tefps/Tasks/helpers';

const { _tg } = window.loadTranslations(__filename);

const STYLE_WRAPPER: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
};

const STYLE_BUTTON: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: 30,
  height: 30,
  borderRadius: 5,
  padding: 5,
  cursor: 'pointer',
};

type TaskButtonsProps = {
  agentId: string;
  userNotif: Array<NotificationType>;
  notifications: Array<NotificationType>;
  redirect: () => void;
  onSave: () => void;
};

type TaskButtonsState = {
  addNotifOpen: boolean;
  newUserNotif: Array<NotificationType>;
  message: string;
};

class TaskButtons extends React.Component<TaskButtonsProps, TaskButtonsState> {
  constructor(props: TaskButtonsProps) {
    super(props);
    this.state = {
      addNotifOpen: false,
      newUserNotif: [],
      message: '',
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps: TaskButtonsProps) {
    this.setState({ newUserNotif: _cloneDeep(newProps.userNotif) });
  }

  onClickNotif = ({ currentTarget }: React.MouseEvent<HTMLInputElement>) => {
    const { newUserNotif } = this.state;
    const notification: NotificationType = currentTarget.dataset
      .notification as NotificationType;

    this.setState({
      newUserNotif: computeNewNotifications(notification, newUserNotif),
    });
  };

  onClickValidate = async () => {
    const { onSave } = this.props;
    const { newUserNotif } = this.state;
    try {
      await upsertAgentNotifications(newUserNotif);
      this.setState({ message: _tg('tefps.tasks.alerts.updated') });
      onSave();
    } catch (e) {
      this.setState({ message: _tg('tefps.tasks.alerts.updateError') });
    }
    this.closeNotifs();
  };

  openNotifs = () => this.setState({ addNotifOpen: true });

  closeBar = () => this.setState({ message: '' });
  closeNotifs = () => this.setState({ addNotifOpen: false });

  render() {
    const { notifications, redirect, userNotif } = this.props;
    const { addNotifOpen, newUserNotif, message } = this.state;
    const actions = [
      <BoButton
        disabled={_isEqual(newUserNotif, userNotif)}
        onClick={this.onClickValidate}
        primary
        label={_tg('action.validate')}
        style={{ marginRight: 5 }}
      />,
      <BoButton label={_tg('action.cancel')} onClick={this.closeNotifs} />,
    ];

    return (
      <div style={STYLE_WRAPPER}>
        <div
          onClick={this.openNotifs}
          style={{
            ...STYLE_BUTTON,
            backgroundColor: BKG_PINK,
            marginBottom: 20,
          }}
        >
          <AlertIcon color="white" />
        </div>
        <div
          onClick={redirect}
          style={{ ...STYLE_BUTTON, backgroundColor: BKG_CYAN }}
        >
          <ArrowIcon color="white" />
        </div>
        <Dialog
          open={addNotifOpen}
          onRequestClose={this.closeNotifs}
          title={_tg('tefps.tasks.alerts.create')}
          contentStyle={{ width: 600 }}
          actions={actions}
        >
          <div style={{ fontWeight: 'bold', marginBottom: 20 }}>
            <span style={{ color: BKG_CYAN, fontSize: 20 }}>1/ </span>
            {_tg('tefps.tasks.alerts.sendSelf')}
          </div>
          {notifications.map(notif => (
            <Checkbox
              onClick={this.onClickNotif}
              checked={newUserNotif.includes(notif)}
              key={notif}
              style={{ marginLeft: 15 }}
              label={NOTIF_CHECKBOX()[notif].label}
              data-notification={notif}
            />
          ))}
        </Dialog>
        <Snackbar
          open={message !== ''}
          message={message}
          autoHideDuration={4000}
          style={{ pointerEvents: 'none', whiteSpace: 'nowrap' }}
          bodyStyle={{ pointerEvents: 'initial', maxWidth: 'none' }}
          onRequestClose={this.closeBar}
        />
      </div>
    );
  }
}

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    agentId: userInfo ? userInfo.agentId : '',
  };
})(TaskButtons);
