import * as React from 'react';

import Picture from 'commons/FpsPictures/Picture';
import {
  DataBox,
  DataBoxHeader,
  DataBoxContent,
  DataBoxItemWrapper,
  DataBoxItem,
} from 'commons/DataBox';
import FakeInputBlock from 'commons/FakeInputBlock';
import { GpvDetailMetadata } from 'api/tepv/gpv/types';
import { BKG_DARK_BLUE } from 'theme';
import FreeCommentsBlock from 'tepv/Mif/Detail/FreeCommentsBlock';
import { gpvSignaturesName } from 'api/tepv/gpv/utils';

const { _t, _tg } = window.loadTranslations(__filename);

const CommentsBlock = ({
  metadata: { photos, comments, infractionLocation, presetNote },
  updateComment,
}: {
  metadata: GpvDetailMetadata;
  updateComment: (comment: string) => void;
}) => {
  return (
    <DataBox panel style={{ width: '95%', marginTop: 40 }}>
      <DataBoxHeader color={BKG_DARK_BLUE}>
        {_tg('field.comment_plural')}
      </DataBoxHeader>
      <DataBoxContent>
        <DataBoxItemWrapper
          style={{
            marginBottom: 20,
          }}
        >
          <DataBoxItem
            style={{
              maxWidth: '90%',
            }}
          >
            {photos &&
              photos.map((media, idx) => (
                <Picture
                  index={idx}
                  media={{
                    ...media,
                    dateCreated: '',
                    name: gpvSignaturesName[media.name],
                  }}
                />
              ))}
            <FakeInputBlock
              title={_t('element.infractionLocation')}
              value={infractionLocation}
            />
            <FakeInputBlock
              title={_t('element.presetNote')}
              value={presetNote}
            />
            <FreeCommentsBlock
              comments={comments}
              canAddComments
              updateComments={updateComment}
            />
          </DataBoxItem>
        </DataBoxItemWrapper>
      </DataBoxContent>
    </DataBox>
  );
};

export default CommentsBlock;
