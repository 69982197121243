import React, { CSSProperties } from 'react';

import { GpvDetail, GpvDetailMetadata } from 'api/tepv/gpv/types';
import { DataBox, DataBoxHeader, DataBoxContent } from 'commons/DataBox';
import Separator from 'commons/Separator';
import FakeInputBlock from 'commons/FakeInputBlock';

import VehicleBlock from './VehicleBlock';
import MotifBlock from './MotifBlock';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_COLUMNS_CONTAINER: CSSProperties = {
  display: 'flex',
  width: '90%',
  justifyContent: 'space-around',
};

function InfractionBlock({ gpvDetail }: { gpvDetail: GpvDetail }): JSX.Element {
  const gpvMetadata = gpvDetail.metadata;

  return (
    <DataBox panel style={{ width: '95%' }}>
      <DataBoxHeader>{_tg('field.gpv')}</DataBoxHeader>
      <DataBoxContent>
        <div style={STYLE_COLUMNS_CONTAINER}>
          <div style={{ flex: '7 7 0%' }}>
            <FakeInputBlock
              title={_t('element.infractionCase')}
              value={gpvDetail.metadata.infractionCase || ''}
              containerStyle={{ maxWidth: '90%' }}
            />
          </div>
          <Separator />
          <div style={{ flex: '3 3 0%' }}>
            <FakeInputBlock
              title={_t('element.natinfCode')}
              value={gpvDetail.metadata.natinfCode || ''}
            />
          </div>
        </div>
        <div style={STYLE_COLUMNS_CONTAINER}>
          <div style={{ flex: '7 7 0%' }}>
            <MotifBlock motif={gpvMetadata.motif} decree={gpvMetadata.decree} />
          </div>
          {gpvMetadata.vehicle && (
            <>
              <Separator />
              <div style={{ flex: '3 3 0%' }}>
                <VehicleBlock
                  vehicle={gpvMetadata.vehicle}
                  vehicleImmobilization={gpvMetadata.vehicleImmobilization}
                  vehicleRemoval={gpvMetadata.vehicleRemoval}
                />
              </div>
            </>
          )}
        </div>
      </DataBoxContent>
    </DataBox>
  );
}

export default InfractionBlock;
