import React, { useState } from 'react';
import {
  Checkbox,
  Dialog,
  FlatButton,
  MenuItem,
  SelectField,
} from 'material-ui';
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';

import { Files } from '@cvfm-front/commons-ui';
import {
  ProofUploadDTO,
  SubscriberDTO,
  SubscriberMediaDTO,
  RegistrationSubscriberDocument,
} from '@cvfm-front/tefps-types';
import {
  getEvidenceDirectUploadLink,
  patchSubscriber,
} from 'api/cvfm-core-subscription/subscriber';
import { ItemIdName } from 'api/commonTypes';
import { uploadFileV2 } from 'api/mediaupload';
import { getConfigState } from 'config/duck';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';

import './SubscriberAddProof.css';

const { _t, _tg } = window.loadTranslations(__filename);

type ReduxProps = {
  watermarkText: string | null;
};

type Props = ReduxProps & {
  open: boolean;
  setOpen: (b: boolean) => void;
  subscriber: SubscriberDTO;
  setSubscriber: React.Dispatch<
    React.SetStateAction<SubscriberDTO | undefined>
  >;
};

const documentList: SubscriberMediaDTO[] = [];

const SubscriberAddProof = ({
  open,
  setOpen,
  subscriber,
  setSubscriber,
  watermarkText,
}: Props): JSX.Element => {
  // constants
  const userProfiles = [
    {
      id: 'common',
      name: _t('addToSubscriberRoot'),
    },
  ];
  if (subscriber.personalProfile !== undefined)
    userProfiles.push({
      id: 'perso',
      name: _t('addToPersonalProfile'),
    });
  if (subscriber.professionalProfile !== undefined)
    userProfiles.push({
      id: 'pro',
      name: _t('addToProfessionalProfile'),
    });

  const evidencePatchEndpoints: {
    common: ItemIdName[];
    perso: ItemIdName[];
    pro: ItemIdName[];
  } = {
    common: [
      {
        id: '/identity/proofs',
        name: _tg('field.evidenceType.proofOfId'),
      },
    ],
    perso: [
      {
        id: '/personal-address/proofs',
        name: _tg('field.evidenceType.personalProofOfAddress'),
      },
    ],
    pro: [
      {
        id: '/professional-address/proofs',
        name: _tg('field.evidenceType.professionalProofOfAddress'),
      },
      {
        id: '/professional-enterprise/proofs',
        name: _tg('field.evidenceType.professionalProofOfActivity'),
      },
    ],
  };

  // state
  const [manuallyChecked, setManuallyChecked] = useState<boolean>(false);
  const [profileSelected, setProfileSelected] = useState('');
  const [
    evidenceSelected,
    setEvidenceSelected,
  ] = useState<SubscriberMediaDTO | null>(null);
  const [evidenceTypeSelected, setEvidenceTypeSelected] = useState<string>('');

  const setMessage = useSnackbar();

  const getInitialEvidences = () => {
    let registrationDocs: RegistrationSubscriberDocument | undefined;
    switch (evidenceTypeSelected) {
      case '/identity/proofs':
        registrationDocs = subscriber.identity;
        break;
      case '/personal-address/proofs':
        registrationDocs = subscriber.personalProfile?.location.proofOfAddress;
        break;
      case '/professional-enterprise/proofs':
        registrationDocs =
          subscriber.professionalProfile?.company.proofDocuments;
        break;
      case '/professional-address/proofs':
        registrationDocs =
          subscriber.professionalProfile?.location.proofOfAddress;
        break;
      default:
        registrationDocs = undefined;
    }
    return registrationDocs?.documents || [];
  };

  // derived state
  const chooseEvidenceEnabled =
    profileSelected === '' || evidenceTypeSelected === '';

  // custom methods
  const onSelectExistingEvidence = (_e: never, _idx: never, value: string) => {
    const document = subscriber.evidences.find(e => e.id === value);
    if (document) {
      setEvidenceSelected(document);
      documentList.push(document);
    }
  };

  const reset = () => {
    setOpen(false);
    setManuallyChecked(false);
    setProfileSelected('');
    setEvidenceSelected(null);
    setEvidenceTypeSelected('');
    documentList.length = 0;
  };

  const onChangeFile = async (file: File): Promise<void> => {
    try {
      const directUploadDTO = await getEvidenceDirectUploadLink(
        subscriber.subscriberId,
        file.type
      );

      void uploadFileV2(
        directUploadDTO.signedUploadUrl,
        file,
        watermarkText,
        undefined,
        _err => {
          setMessage(_tg('commons.error'));
        }
      )
        .catch(err =>
          setMessage((err as Error)?.message || _tg('commons.error'))
        )
        .then(_z => {
          const document: SubscriberMediaDTO = {
            id: uuidv4(),
            name: file.name,
            url: directUploadDTO.fileUrn,
            dateCreated: new Date().toISOString(),
          };
          documentList.push(document);
        });
    } catch (err) {
      const castError = err as Error;
      let errorMessage: string = _tg('feedback.error.simple', {
        error: castError.message,
      });

      if (castError.name === 'InvalidFile') {
        errorMessage = _tg(`feedback.error.InvalidFile`, {
          fileName: file.name,
        });
      }
      setMessage(errorMessage);
    }
  };

  const addProof = async () => {
    if (evidenceTypeSelected) {
      const newDocs = getInitialEvidences();
      const newProof: ProofUploadDTO = {
        manuallyChecked,
        documents: documentList ? newDocs.concat(documentList) : newDocs,
      };
      const patchObject = [
        {
          path: evidenceTypeSelected,
          op: 'replace',
          value: newProof,
        },
      ];
      const updateSubscriber: SubscriberDTO = await patchSubscriber(
        subscriber.subscriberId,
        patchObject
      );

      setSubscriber(updateSubscriber);
      reset(); // resets state and closes modal
    }
  };

  return (
    <Dialog
      title={_t('dialogTitle')}
      open={open}
      onRequestClose={() => setOpen(false)}
      actions={[
        <FlatButton label={_t('cancel')} onClick={reset} />,
        <FlatButton
          label={_t('create')}
          onClick={addProof}
          disabled={
            profileSelected === '' ||
            !evidenceTypeSelected ||
            (!documentList && !manuallyChecked)
          }
        />,
      ]}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div id="text">
          <SelectField
            id="profile"
            value={profileSelected}
            onChange={(_e, _idx, value: string) => {
              setProfileSelected(value);
              setEvidenceTypeSelected('');
            }}
            floatingLabelText={_t('profileLabel')}
            style={{ marginBottom: '2vh' }}
          >
            {userProfiles.map(item => (
              <MenuItem
                id={item.id}
                key={item.id}
                value={item.id}
                primaryText={item.name}
              />
            ))}
          </SelectField>
          {profileSelected ? (
            <SelectField
              id="evidenceType"
              value={evidenceTypeSelected}
              onChange={(_e, _idx, value: string) =>
                setEvidenceTypeSelected(value)
              }
              floatingLabelText={_t('evidenceTypeLabel')}
              disabled={!profileSelected}
              style={{ margin: '0 0 2vh 1vw' }}
            >
              {evidencePatchEndpoints[profileSelected].map(
                (item: ItemIdName) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    primaryText={item.name}
                  />
                )
              )}
            </SelectField>
          ) : (
            <></>
          )}
        </div>
        <Files
          label={_t('uploadNewFiles')}
          closeIcon="/static/close.svg"
          withDropzone={false}
          maxSize={2000000}
          maxFiles={5}
          onChange={onChangeFile}
          onError={err => setMessage(err)}
          onRemoveFile={file => {
            const newDocs = documentList.filter(d => d.name !== file.name);
            documentList.length = 0;
            documentList.concat(newDocs);
          }}
          disabled={chooseEvidenceEnabled || manuallyChecked}
        />
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          {subscriber.evidences.length > 0 ? (
            <SelectField
              id="evidences"
              floatingLabelText={_t('existingEvidenceLabel')}
              onChange={onSelectExistingEvidence}
              value={evidenceSelected?.id}
              disabled={manuallyChecked || chooseEvidenceEnabled}
              style={{ width: '45%' }}
            >
              {subscriber.evidences.map(evidence => (
                <MenuItem
                  id={evidence.id}
                  key={evidence.id}
                  value={evidence.id}
                  primaryText={evidence.name}
                />
              ))}
            </SelectField>
          ) : (
            <></>
          )}
          <Checkbox
            label={_t('manuallyCheckedLabel')}
            onCheck={(_e, checked) => setManuallyChecked(checked)}
            disabled={chooseEvidenceEnabled}
            style={{ width: '45%', marginLeft: '1vw', marginBottom: '1vh' }}
          />
        </div>
      </div>
    </Dialog>
  );
};

function mapStateToProps(state: unknown): ReduxProps {
  const { watermarkText } = getConfigState(state);
  return {
    watermarkText,
  };
}

export default connect(mapStateToProps)(SubscriberAddProof);
