import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import {
  Link,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';

import MissNotFound from 'commons/MissNotFound';
import { BKG_CYAN, TXT_BLACK } from 'theme';
import { getApiState } from 'api/duck';
import Presets from 'tepv/Presets';
import Locations from 'tepv/Locations';

import PvZoningConfiguration from './Zoning';

const { _t } = window.loadTranslations(__filename);

const STYLE_CONTAINER: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  flexWrap: 'wrap',
  color: TXT_BLACK,
  width: '60%',
  margin: '0 auto',
};

const STYLE_TITLE: CSSProperties = {
  fontSize: 25,
  margin: 30,
};

const STYLE_MODULE: CSSProperties = {
  width: 500,
  height: 200,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 5,
  border: `1px solid ${BKG_CYAN}`,
  backgroundColor: 'white',
  margin: 30,
  fontWeight: 'bold',
  fontSize: 35,
  textAlign: 'center',
};

const ConfigurationHome = ({
  isZoningDisplayed,
}: {
  isZoningDisplayed: boolean;
}) => (
  <div style={{ height: '100%', fontFamily: 'Roboto' }}>
    <div style={STYLE_CONTAINER}>
      <span style={STYLE_TITLE}>{_t('element.title')}</span>
    </div>
    <div style={STYLE_CONTAINER}>
      <Link to="/configuration/presets" style={STYLE_MODULE}>
        {_t('element.navItems.presets')}
      </Link>
      <Link to="/configuration/locations" style={STYLE_MODULE}>
        {_t('element.navItems.locations')}
      </Link>
      {isZoningDisplayed && (
        <Link to="/configuration/zoning" style={STYLE_MODULE}>
          {_t('element.navItems.zoning')}
        </Link>
      )}
    </div>
  </div>
);

const ConfigHome = connect(state => {
  const { userInfo } = getApiState(state);
  return {
    isZoningDisplayed: userInfo?.rights.includes('ZONING_READ'),
  };
})(ConfigurationHome);

const Configuration = ({
  match,
  isZoningDisplayed,
}: RouteComponentProps & { isZoningDisplayed: boolean }) => (
  <div style={{ height: '100%' }}>
    <Switch>
      <Route path={`${match.url}`} exact component={ConfigHome} />
      <Route path={`${match.url}/presets`} exact component={Presets} />
      <Route path={`${match.url}/locations`} exact component={Locations} />
      {isZoningDisplayed && (
        <Route
          path={`${match.url}/zoning`}
          exact
          component={PvZoningConfiguration}
        />
      )}
      <MissNotFound />
    </Switch>
  </div>
);

const ConnectedConfiguration = connect(state => {
  const { userInfo } = getApiState(state);
  return {
    isZoningDisplayed: userInfo?.rights.includes('ZONING_READ'),
  };
})(Configuration);

export default withRouter(ConnectedConfiguration);
