import * as React from 'react';

import { GpvDetailMotif, GpvDetailDecree } from 'api/tepv/gpv/types';
import { DataBoxItem } from 'commons/DataBox';
import FakeInputBlock from 'commons/FakeInputBlock';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';

import DecreeBlock from './DecreeBlock';

const { _t, _tg } = window.loadTranslations(__filename);

function MotifBlock({
  motif: { category, qualification },
  decree,
}: {
  motif: GpvDetailMotif;
  decree: GpvDetailDecree | null;
}) {
  return (
    <DataBoxItem
      style={{
        maxWidth: '90%',
      }}
    >
      <SeparatorWithTitle title={_tg('tepv.motif')} />
      <FakeInputBlock title={_t('element.category')} value={category} />
      <FakeInputBlock
        title={_t('element.qualification')}
        value={qualification}
      />
      {decree && (
        <>
          <SeparatorWithTitle title={_tg('field.decree.decree_uppercase')} />{' '}
          <DecreeBlock decree={decree} />
        </>
      )}
    </DataBoxItem>
  );
}

export default MotifBlock;
