import * as React from 'react';
import Dialog from 'material-ui/Dialog';
import CircularProgress from 'material-ui/CircularProgress';
import { connect } from 'react-redux';

import BoButton from 'facade/BoButton';
import FakeInputBlock from 'commons/FakeInputBlock';
import FlexCenter from 'commons/FlexCenter';
import ErrorBlock from 'commons/ErrorBlock';
import FpsNumber from 'commons/FpsNumber';
import Date from 'commons/Date';
import Price from 'commons/Price';
import { ParkingRightDTO } from 'api/tickets/types';
import { fetchParkingRight } from 'api/tickets';
import { BKG_PINK } from 'theme';
import { getConfigState } from 'config/duck';

import PlateRow from './PlateRow';
import ExportProofDialog from './ExportProofDialog';

const { _tg } = window.loadTranslations(__filename);

type State = {
  loading: boolean;
  error: Error | null | undefined;
  ticket: ParkingRightDTO | null | undefined;
  isPlateModalOpen: boolean;
};

type ReduxProps = {
  isParkingMeterEnabled: boolean;
};

type Props = ReduxProps & {
  ticketId: string | null | undefined;
  onClose: (arg0: void) => any;
  onUpdate: (arg0: string) => any;
};

function buildTitle(ticketId: string | null | undefined) {
  return ticketId ? `${_tg('tefps.ticket')}: ${ticketId}` : _tg('tefps.ticket');
}

const buildTicketContent = (
  ticket: ParkingRightDTO,
  reload: (arg0: string) => any,
  onUpdate: (arg0: string) => any
) => (
  <div>
    <FakeInputBlock title={_tg('field.device.type')} value={ticket.type} />
    <FakeInputBlock title={_tg('field.source')} value={ticket.source} />
    {ticket.fineLegalId && (
      <FakeInputBlock
        title={_tg('commons.fps')}
        value={<FpsNumber fpsId={ticket.fineLegalId} />}
      />
    )}
    <FakeInputBlock title={_tg('field.address.zone')} value={ticket.zoneId} />
    {ticket.parkId && (
      <FakeInputBlock
        title={_tg('field.address.sector')}
        value={ticket.parkId}
      />
    )}
    <FakeInputBlock
      title={_tg('field.vehicle.plate')}
      value={
        <PlateRow
          ticketId={ticket.ticketId}
          licensePlate={ticket.licensePlate}
          reload={reload}
          onUpdate={onUpdate}
        />
      }
    />
    {ticket.licensePlate.pricingCategory && (
      <FakeInputBlock
        title={_tg('field.pricingCategory')}
        value={ticket.licensePlate.pricingCategory}
      />
    )}
    {ticket.tariffCode && (
      <FakeInputBlock
        title={_tg('field.pricing.tariffCode')}
        value={ticket.tariffCode}
      />
    )}
    <FakeInputBlock
      title={_tg('tefps.filters.rapo.receptionDate')}
      value={<Date datetime={ticket.dateOfReceipt} withSeconds />}
    />
    <FakeInputBlock
      title={_tg('field.date.creation')}
      value={<Date datetime={ticket.creationDatetime} withSeconds />}
    />
    <FakeInputBlock
      title={_tg('field.date.dateOfValidityStart')}
      value={<Date datetime={ticket.startDatetime} withSeconds />}
    />
    <FakeInputBlock
      title={_tg('field.date.dateOfValidityEnd')}
      value={<Date datetime={ticket.endDatetime} withSeconds />}
    />
    {ticket.cancelDatetime && (
      <FakeInputBlock
        inputStyle={{ color: 'red' }}
        title={_tg('field.date.cancelDate')}
        value={<Date datetime={ticket.cancelDatetime} withSeconds />}
      />
    )}
    <FakeInputBlock
      title={_tg('commons.tariff')}
      inputStyle={{ border: `1px solid ${BKG_PINK}`, fontWeight: 'bold' }}
      value={<Price price={ticket.price} />}
    />
  </div>
);

class TicketDetail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      ticket: null,
      isPlateModalOpen: false,
    };
  }

  componentDidMount(): void {
    this.handleModificationOfTicketIdProps();
  }

  componentDidUpdate(prevProps: Props) {
    const { ticketId } = this.props;
    if (prevProps.ticketId !== ticketId) {
      this.handleModificationOfTicketIdProps();
    }
  }

  handleModificationOfTicketIdProps = () => {
    const { ticketId } = this.props;
    if (!ticketId) {
      this.setState({ ticket: null });
      return;
    }

    void this.getParkingRight(ticketId);
  };

  getParkingRight = async (ticketId: string) => {
    let ticket = null;
    let error = null;
    this.setState({ loading: true, error: null });
    try {
      ticket = await fetchParkingRight(ticketId);
    } catch (e) {
      error = e;
    }
    this.setState({ loading: false, error, ticket });
  };

  closeModal = () => this.props.onClose();

  render() {
    const { ticket, loading, error, isPlateModalOpen } = this.state;
    const { onUpdate, isParkingMeterEnabled } = this.props;
    const actions = [
      <BoButton label={_tg('action.close')} onClick={this.closeModal} />,
    ];
    if (
      isParkingMeterEnabled &&
      ticket &&
      ticket.appClientId === 'cvfm-parking-meter'
    ) {
      actions.push(
        <BoButton
          style={{ marginLeft: 16 }}
          primary
          label={_tg('action.pdf')}
          onClick={() => this.setState({ isPlateModalOpen: true })}
        />
      );
    }

    return (
      <>
        <Dialog
          title={buildTitle(this.props.ticketId)}
          open={!!this.state.ticket}
          actions={actions}
          autoScrollBodyContent
          onRequestClose={this.closeModal}
        >
          {error && (
            <FlexCenter>
              <ErrorBlock
                message={_tg('feedback.error.fetchTicket')}
                error={error}
              />
            </FlexCenter>
          )}
          {loading && (
            <FlexCenter>
              <CircularProgress />
            </FlexCenter>
          )}
          {ticket && buildTicketContent(ticket, this.getParkingRight, onUpdate)}
        </Dialog>
        {isPlateModalOpen && ticket && (
          <ExportProofDialog
            ticketId={ticket.ticketId}
            onClose={() => this.setState({ isPlateModalOpen: false })}
          />
        )}
      </>
    );
  }
}

export default connect(state => {
  const config = getConfigState(state);
  const isParkingMeterEnabled =
    config.modulesConfiguration.parkingMeter.enabled;
  return {
    isParkingMeterEnabled,
  };
})(TicketDetail);
