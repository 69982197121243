import React, { CSSProperties, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Snackbar from 'material-ui/Snackbar';
import CircularProgress from 'material-ui/CircularProgress';

import AdvertisingModal from 'commons/AdvertisingModal';
import FlexCenter from 'commons/FlexCenter';
import { ListBody, ListBottom, ListWrapper } from 'commons/ListWrappers';
import Content from 'commons/Content';
import ErrorBlock from 'commons/ErrorBlock';
import { getExport } from 'api/tickets';
import { Pager } from 'api/commonTypes';
import Exporter from 'commons/Exporter';
import { getApiState } from 'api/duck';
import { getConfigState } from 'config/duck';
import services from 'commons/services';
import useWatcher from 'commons/hooks/useWatcher';

import ImportTickets from './ImportTickets';
import TicketTable from './TicketTable';
import TicketDetail from './TicketDetail';
import TicketFilters from './TicketFilters';
import { filtersToRequest, getExportCol } from './utils';

const { _tg } = window.loadTranslations(__filename);

const STYLE_CONTENT: CSSProperties = {
  display: 'flex',
  height: '100%',
  overflowY: 'hidden',
};

type TicketsListProps = {
  canImport: boolean;
  canExport: boolean;
  location: Location;
  hashPartnerPlateEnabled: boolean;
};

const MAX_RECORDS = 20;

const TicketsList = (props: TicketsListProps): JSX.Element => {
  const { canImport, canExport, location, hashPartnerPlateEnabled } = props;

  const parkingRightResult = useWatcher(
    services.ticketsList.watchTickets,
    services.ticketsList.getTickets()
  );
  const isLoading = useWatcher(
    services.ticketsList.watchLoading,
    services.ticketsList.isLoading()
  );
  const error = useWatcher(
    services.ticketsList.watchError,
    services.ticketsList.getError()
  );
  const sortParameter = useWatcher(
    services.ticketsList.watchSortParameter,
    services.ticketsList.getSortParameter()
  );
  const filters = useWatcher(
    services.ticketsList.watchFilters,
    services.ticketsList.getFilters()
  );

  const [pager] = useState<Pager>({
    page: 0,
    maxRecords: MAX_RECORDS,
  });
  const [message, setMessage] = useState<string>('');
  const [ticketId, setTicketId] = useState<string | null>(null);

  const handleTicketDetailClose = () => setTicketId(null);
  const closeMessageBar = () => setMessage('');

  const onImport = async (newMessage: string) => {
    setMessage(newMessage);
    await services.ticketsList.reload();
  };

  // Effect sur mount
  useEffect(() => {
    void services.ticketsList.init(
      location && location.search ? location.search : null
    );
  }, []);

  if (error) {
    return (
      <Content>
        <FlexCenter>
          <ErrorBlock
            message={_tg('tefps.tickets.errors.fetchingTickets')}
            error={error}
          />
        </FlexCenter>
      </Content>
    );
  }

  const items = parkingRightResult ? parkingRightResult.parkingRights : [];
  const totalHits = parkingRightResult ? parkingRightResult.totalHits : 0;

  return (
    <div style={STYLE_CONTENT}>
      <AdvertisingModal module="tv" />
      <TicketFilters
        totalHits={totalHits}
        facetings={parkingRightResult && parkingRightResult.checkboxFaceting}
      />
      <ListWrapper>
        <ListBody loading={!parkingRightResult}>
          <TicketTable
            items={items}
            onClick={setTicketId}
            onUpdateSort={services.ticketsList.updateSortParameter}
            colSorted={sortParameter.sortField}
            sortOrder={sortParameter.increasingOrder}
            loadMoreRows={services.ticketsList.loadMoreRows}
            remoteRowCount={
              parkingRightResult ? parkingRightResult.totalHits : 0
            }
          />
        </ListBody>
        <ListBottom style={{ position: 'relative' }}>
          {canExport && (
            <Exporter
              disabled={totalHits === 0}
              columns={getExportCol(hashPartnerPlateEnabled)}
              type={_tg('tefps.tickets.export.exportType')}
              filters={filtersToRequest(filters, sortParameter, pager)}
              fileExport={getExport}
            />
          )}
          {canImport && <ImportTickets onImport={onImport} />}
          {isLoading && (
            <div style={{ position: 'absolute', left: '50%' }}>
              <CircularProgress />
            </div>
          )}
        </ListBottom>
      </ListWrapper>
      <TicketDetail
        ticketId={ticketId}
        onClose={handleTicketDetailClose}
        onUpdate={setMessage}
      />
      <Snackbar
        open={!!message}
        message={message}
        autoHideDuration={4000}
        onRequestClose={closeMessageBar}
      />
    </div>
  );
};

export default connect(state => {
  const { userInfo } = getApiState(state);
  const { hashPartnerPlateEnabled } = getConfigState(state);
  return {
    canImport: userInfo && userInfo.rights.includes('TV_IMPORT'),
    canExport: userInfo && userInfo.rights.includes('BACKOFFICE_TV_EXPORT'),
    hashPartnerPlateEnabled,
  };
})(TicketsList);
