import * as React from 'react';

import { DataBoxItem } from 'commons/DataBox';
import FakeInputBlock from 'commons/FakeInputBlock';
import { GpvDetailAgent } from 'api/tepv/gpv/types';
import Date from 'commons/Date';

const { _t, _tg } = window.loadTranslations(__filename);

function AgentBlock({
  agent: { name, organizationName, profileName, serviceCode, signatureDate },
}: {
  agent: GpvDetailAgent;
}) {
  return (
    <DataBoxItem style={{ maxWidth: '100%' }}>
      <FakeInputBlock title={_tg('field.agent.agent')} value={name} />
      <FakeInputBlock title={_tg('field.agent.role')} value={profileName} />
      <FakeInputBlock
        title={_tg('field.organisation.organisation')}
        value={organizationName}
      />
      <FakeInputBlock title={_t('element.serviceCode')} value={serviceCode} />
      {signatureDate && (
        <FakeInputBlock
          title={_tg('field.date.signDate')}
          value={<Date datetime={signatureDate} />}
        />
      )}
    </DataBoxItem>
  );
}

export default AgentBlock;
