import * as React from 'react';
import { connect } from 'react-redux';
import Checkbox from 'material-ui/Checkbox';

import BoButton from 'facade/BoButton';
import SimpleTable from 'commons/SimpleTable';
import FlexCenter from 'commons/FlexCenter';
import Content from 'commons/Content';
import ErrorBlock from 'commons/ErrorBlock';
import { ListBody, ListBottom, ListWrapper } from 'commons/ListWrappers';
import { getApiState } from 'api/duck';
import {
  createOrUpdateGroup,
  fetchNatinfGroups,
  fetchNatinfs,
  removeNatinfsFromGroup,
} from 'api/tepv/natinfs';
import { NatinfGroup, NatinfListItem } from 'api/tepv/natinfs/types';

import NatinfSidebar from './NatinfSidebar';
import { DeletionModal, EditionModal } from './modals';
import { NatinfFilters, NatinfFiltersPopulateData } from './types';

const { _t, _tg } = window.loadTranslations(__filename);

type GroupModalStatus = 'EDITION' | 'DELETION';

type NatinfListState = {
  filters: NatinfFilters;
  natinfs: Array<NatinfListItem>;
  version: string;
  filteredNatinfs: Array<NatinfListItem>;
  loading: boolean;
  filtersData: NatinfFiltersPopulateData;
  sortCol: number;
  sortAsc: boolean;
  groupModal: GroupModalStatus | null | undefined;
  groups: Array<NatinfGroup>;
  groupAddError: Error | null | undefined;
  groupRemovalError: Error | null | undefined;
  natinfFetchError: Error | null | undefined;
  natinfGroupsFetchError: Error | null | undefined;
};

type NatinfListProps = {
  canReadNatinf: boolean;
  canAddNatinfGroup: boolean;
  canDeleteNatinfGroup: boolean;
};

function getInitialFiltersData(): NatinfFiltersPopulateData {
  return {
    categories: new Set(),
    cas: new Set(),
    groupKeys: [],
  };
}

function getInitialFilters(): NatinfFilters {
  return {
    codes: '',
    categories: [],
    cas: [],
    groupIds: [],
    montantForfaitaireRange: {
      from: undefined,
      to: undefined,
    },
    dateImportPeriod: {
      from: undefined,
      to: undefined,
    },
    dateDisablePeriod: {
      from: undefined,
      to: undefined,
    },
    qualificationSearchText: '',
    texteSearchText: '',
  };
}

const translateTableCols = () => [
  { label: _tg('tepv.natinfCode'), width: 70, onSort: true },
  {
    label: _t('element.tableCols.qualification'),
    width: 150,
    grow: 15,
  },
  {
    label: _t('element.tableCols.cat'),
    width: 150,
  },
  {
    label: _t('element.tableCols.cas'),
    width: 125,
    onSort: true,
  },
  {
    label: _t('element.tableCols.price'),
    width: 125,
    onSort: true,
  },
  {
    label: _t('element.tableCols.count'),
    width: 125,
    onSort: true,
  },
  {
    label: _tg('field.enabled'),
    width: 125,
    onSort: true,
  },
];

const sortNatinfs = (
  unsortedNatinfs: Array<NatinfListItem>,
  sortCol: number,
  sortAsc: boolean
): Array<NatinfListItem> => {
  const sorted = unsortedNatinfs.sort(
    (
      {
        code: codeA,
        cas: casA,
        montantAmendeForfaitaire: montantA,
        mifCount: mifsA,
        enabled: enabledA,
      },
      {
        code: codeB,
        cas: casB,
        montantAmendeForfaitaire: montantB,
        mifCount: mifsB,
        enabled: enabledB,
      }
    ) => {
      let diff = 0;
      switch (sortCol) {
        // Code NATINF
        case 0:
          diff = codeA - codeB;
          break;
        // Classe
        case 3:
          diff = casA.localeCompare(casB);
          break;
        // Montant amende
        case 4:
          diff = montantA - montantB;
          break;
        // # MIFs
        case 5:
          diff = mifsA - mifsB;
          break;
        case 6:
          if (enabledA === enabledB) {
            break;
          }

          diff = enabledA ? 1 : -1;
          break;
        default:
          diff = 0;
          break;
      }
      // Secondary filtering
      if (diff === 0) {
        diff = codeA - codeB;
      }
      if (diff === 0) {
        diff = mifsA - mifsB;
      }
      return diff;
    }
  );
  return sortAsc ? sorted : sorted.reverse();
};

class NatinfList extends React.Component<NatinfListProps, NatinfListState> {
  state: NatinfListState = {
    filters: getInitialFilters(),
    version: '',
    natinfs: [],
    filteredNatinfs: [],
    loading: true,
    filtersData: getInitialFiltersData(),
    sortCol: 0,
    sortAsc: true,
    groupModal: null,
    groups: [],
    groupAddError: null,
    groupRemovalError: null,
    natinfFetchError: null,
    natinfGroupsFetchError: null,
  };

  componentDidMount() {
    this.fetchNatinfs();
    this.fetchNatinfGroups();
  }

  onUpdateSort = (sortCol: number, sortAsc: boolean) =>
    this.setState({
      sortCol,
      sortAsc,
      filteredNatinfs: sortNatinfs(
        this.state.filteredNatinfs,
        sortCol,
        sortAsc
      ),
    });

  resetFilters = () => this.filterNatinfs(getInitialFilters());

  filterNatinfs = (
    filters: NatinfFilters,
    givenGroups?: Array<NatinfGroup>
  ) => {
    const { natinfs, sortCol, sortAsc, groups: stateGroups } = this.state;
    const groups = givenGroups || stateGroups;
    const {
      codes,
      categories,
      cas,
      groupIds,
      montantForfaitaireRange,
      dateImportPeriod,
      dateDisablePeriod,
      qualificationSearchText,
      texteSearchText,
    } = filters;

    const groupsById = {};
    groups.forEach(group => {
      groupsById[group.identifier] = group;
    });

    const natinfIdsFromGroups = new Set();

    groupIds.forEach(groupId => {
      const group = groupsById[groupId];
      group.natinfIds.forEach((natinfId: any) => {
        natinfIdsFromGroups.add(natinfId);
      });
    });

    const filteredNatinfs = natinfs.filter(
      ({
        id,
        code,
        qualification,
        categorie,
        cas: natinfCas,
        montantAmendeForfaitaire,
        dateImport,
        dateDisable,
        textePrevu,
        texteReprimant,
      }: NatinfListItem) => {
        if (codes) {
          const splitCodes = codes.split(',').map(c => c.trim());
          if (!splitCodes.includes(code.toString())) return false;
        }
        if (categories.length && !categories.includes(categorie)) {
          return false;
        }
        if (cas.length && !cas.includes(natinfCas)) {
          return false;
        }
        if (groupIds.length && !natinfIdsFromGroups.has(id)) return false;
        if (
          montantForfaitaireRange.from &&
          montantAmendeForfaitaire <= montantForfaitaireRange.from
        )
          return false;
        if (
          montantForfaitaireRange.to &&
          montantAmendeForfaitaire >= montantForfaitaireRange.to
        )
          return false;

        const dateImportTimestamp = Date.parse(dateImport);
        if (
          dateImportPeriod.from &&
          dateImportTimestamp <= dateImportPeriod.from.getTime()
        )
          return false;
        if (
          dateImportPeriod.to &&
          dateImportTimestamp >= dateImportPeriod.to.getTime()
        )
          return false;

        const dateDisableTimestamp = Date.parse(dateDisable);
        if (
          dateDisablePeriod.from &&
          dateDisableTimestamp <= dateDisablePeriod.from.getTime()
        )
          return false;
        if (
          dateDisablePeriod.to &&
          dateDisableTimestamp >= dateDisablePeriod.to.getTime()
        )
          return false;

        if (
          !qualification
            .toLowerCase()
            .includes(qualificationSearchText.toLowerCase())
        )
          return false;
        if (
          !textePrevu.toLowerCase().includes(texteSearchText.toLowerCase()) &&
          !texteReprimant.toLowerCase().includes(texteSearchText.toLowerCase())
        )
          return false;

        return true;
      }
    );

    this.setState({
      filteredNatinfs: sortNatinfs(filteredNatinfs, sortCol, sortAsc),
      filters,
    });
  };

  openEditionModal = () => this.setState({ groupModal: 'EDITION' });
  openDeletionModal = () => this.setState({ groupModal: 'DELETION' });
  closeModal = () => this.setState({ groupModal: null });

  addToGroup = async (identifier: string, name: string) => {
    const { filteredNatinfs, groups, filtersData } = this.state;
    const natinfIds = filteredNatinfs.map((natinf: any) => natinf.id);

    const existingGroupIndex = groups.findIndex(
      (group: any) => group.identifier === identifier
    );
    const groupUpdate =
      existingGroupIndex !== -1
        ? { ...groups[existingGroupIndex] }
        : {
            identifier,
            name,
            natinfIds: [],
          };
    groupUpdate.natinfIds = [...groupUpdate.natinfIds, ...natinfIds];

    try {
      await createOrUpdateGroup(groupUpdate);
    } catch (err) {
      this.setState({ groupAddError: err });
      return;
    }

    this.closeModal();
    if (existingGroupIndex !== -1) {
      const updatedGroups = [
        ...groups.slice(0, existingGroupIndex),
        groupUpdate,
        ...groups.slice(existingGroupIndex + 1),
      ];
      this.setState({ groups: updatedGroups });
    } else {
      const updatedGroups = [...groups, groupUpdate];
      const updatedFiltersData = {
        ...filtersData,
        groupKeys: [
          ...filtersData.groupKeys,
          {
            key: identifier,
            label: name,
          },
        ],
      };
      this.setState({
        groups: updatedGroups,
        filtersData: updatedFiltersData,
        groupAddError: null,
      });
    }
  };

  removeFromGroup = async (identifier: string) => {
    const { filteredNatinfs } = this.state;
    const natinfIds = filteredNatinfs.map((natinf: any) => natinf.id);

    try {
      await removeNatinfsFromGroup(identifier, natinfIds);
      this.fetchNatinfGroups();
      this.setState({ groupRemovalError: null });
    } catch (err) {
      this.setState({ groupRemovalError: err });
    }
  };

  async fetchNatinfs() {
    const { sortCol, sortAsc } = this.state;
    let natinfs = [];
    let version = '';
    try {
      const natinfListDto = await fetchNatinfs();
      ({ natinfs, version } = natinfListDto);
    } catch (error) {
      this.setState({ natinfFetchError: error });
      return;
    }
    const categories = new Set<string>();
    const cas = new Set<string>();

    natinfs.forEach(({ categorie, cas: natinfCas }: NatinfListItem) => {
      categories.add(categorie);
      cas.add(natinfCas);
    });

    this.setState({
      natinfs,
      version,
      filtersData: {
        ...this.state.filtersData,
        categories,
        cas,
      },
      filteredNatinfs: sortNatinfs(natinfs, sortCol, sortAsc),
      loading: false,
      natinfFetchError: null,
    });
  }

  async fetchNatinfGroups() {
    const { filters, filtersData } = this.state;
    let groups = [];
    try {
      groups = await fetchNatinfGroups();
    } catch (error) {
      this.setState({ natinfGroupsFetchError: error });
      return;
    }
    const groupKeys = groups.map(group => ({
      key: group.identifier,
      label: group.name,
    }));

    // If a group we had selected as a filter has been removed, remove from the list of filters
    const currentFiltersGroupsIdentifiers = new Set(filters.groupIds);
    const updatedGroupIdsFilter = groups
      .filter(group => currentFiltersGroupsIdentifiers.has(group.identifier))
      .map(group => group.identifier);

    const updatedFilters = {
      ...filters,
      groupIds: updatedGroupIdsFilter,
    };

    this.setState({
      groups,
      filtersData: { ...filtersData, groupKeys },
      natinfGroupsFetchError: null,
    });
    this.filterNatinfs(updatedFilters, groups);
  }

  renderRow = ({
    id,
    code,
    qualification,
    categorie,
    cas,
    montantAmendeForfaitaire,
    mifCount,
    enabled,
  }: NatinfListItem) => [
    <span>
      {this.props.canReadNatinf ? (
        <a href={this.props.canReadNatinf ? `#/natinf/detail/${id}` : ``}>
          {code}
        </a>
      ) : (
        code
      )}
    </span>,
    <span>{qualification}</span>,
    <span>{categorie}</span>,
    <span>{cas}</span>,
    <span>{montantAmendeForfaitaire}€</span>,
    <span>{mifCount}</span>,
    <span>
      <Checkbox disabled checked={enabled} />
    </span>,
  ];

  render() {
    const {
      filters,
      filteredNatinfs,
      loading,
      filtersData,
      sortCol,
      sortAsc,
      groupModal,
      groups,
      groupAddError,
      groupRemovalError,
      natinfFetchError,
      natinfGroupsFetchError,
      version,
    } = this.state;
    const { canAddNatinfGroup, canDeleteNatinfGroup } = this.props;
    // Only propose groups who have at least one member available for deletion
    const groupsForDeletion = groups.filter((group: any) => {
      return group.natinfIds.some((natinfId: any) =>
        filteredNatinfs.some((natinf: any) => natinf.id === natinfId)
      );
    });

    if (natinfFetchError) {
      return (
        <Content>
          <FlexCenter>
            <ErrorBlock
              message={_t('feedback.error.fetchNatinf')}
              error={natinfFetchError}
            />
          </FlexCenter>
        </Content>
      );
    }

    if (natinfGroupsFetchError) {
      return (
        <Content>
          <FlexCenter>
            <ErrorBlock
              message={_t('feedback.error.fetchNatinfGroup')}
              error={natinfGroupsFetchError}
            />
          </FlexCenter>
        </Content>
      );
    }

    return (
      <div style={{ height: '100%', fontFamily: 'Roboto', display: 'flex' }}>
        <NatinfSidebar
          version={version}
          filtersData={filtersData}
          filters={filters}
          resetFilters={this.resetFilters}
          updateFilters={this.filterNatinfs}
          totalHits={filteredNatinfs.length}
        />
        <ListWrapper style={{ width: '100%', paddingTop: 20 }}>
          <ListBody loading={loading} style={{ height: 'calc(100% - 10px)' }}>
            <SimpleTable
              cols={translateTableCols()}
              rowHeight={100}
              itemsRenderer={this.renderRow}
              items={filteredNatinfs}
              remoteRowCount={filteredNatinfs.length}
              colSorted={sortCol}
              sortOrder={sortAsc}
              onSort={this.onUpdateSort}
            />
          </ListBody>
          <ListBottom>
            {canAddNatinfGroup && (
              <BoButton
                label={_t('element.buttonAdd.label')}
                primary
                onClick={this.openEditionModal}
              />
            )}
            {canDeleteNatinfGroup && (
              <BoButton
                label={_t('element.buttonRemove.label')}
                primary
                onClick={this.openDeletionModal}
              />
            )}
          </ListBottom>
        </ListWrapper>
        <EditionModal
          closeModal={this.closeModal}
          open={groupModal === 'EDITION'}
          groups={groups}
          addToGroup={this.addToGroup}
          error={groupAddError}
        />
        <DeletionModal
          closeModal={this.closeModal}
          open={groupModal === 'DELETION'}
          groups={groupsForDeletion}
          removeFromGroup={this.removeFromGroup}
          error={groupRemovalError}
        />
      </div>
    );
  }
}

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    canReadNatinf: userInfo && userInfo.rights.includes('NATINF_READ'),
    canAddNatinfGroup:
      userInfo && userInfo.rights.includes('NATINF_GROUP_WRITE'),
    canDeleteNatinfGroup:
      userInfo && userInfo.rights.includes('NATINF_GROUP_DELETE'),
  };
})(NatinfList);
