import { SelectOption } from 'commons/SidebarV2/Components/Select';
import { getPresetNotes } from 'api/tepv/presetNote';
import { PresetDTO } from 'api/tepv/presetNote/types';

export async function getPresetNotesAsFilter(): Promise<SelectOption[]> {
  const presetNotes = await getPresetNotes();
  return presetNotes.map((presetNote: PresetDTO) => {
    return { key: presetNote.preset, label: presetNote.preset };
  });
}
