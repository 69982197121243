import * as React from 'react';

import { MifDetail } from 'api/tepv/mif/types';
import { DataBoxItem } from 'commons/DataBox';
import FakeInputBlock from 'commons/FakeInputBlock';

import DecreeBlock from './DecreeBlock';

const { _t } = window.loadTranslations(__filename);

const NatinfBlock = ({
  mif: {
    natinfCode,
    natinfCas,
    natinfQualification,
    natinfType,
    natinfCategory,
    commentArrete,
    typeArrete,
    applicationDatetime,
    mifDecree,
    gpvDecree,
    referenceArrete,
    decreeText,
    endOfApplicationDatetime,
  },
}: {
  mif: MifDetail;
}) => (
  <DataBoxItem
    style={{
      maxWidth: '100%',
    }}
  >
    <FakeInputBlock
      title={_t('element.fieldNatint.title')}
      value={`${natinfCode} (${natinfCas})`}
    />
    <FakeInputBlock title={_t('element.fieldType.title')} value={natinfType} />
    <FakeInputBlock
      title={_t('element.fieldCategory.title')}
      value={natinfCategory}
    />
    <FakeInputBlock
      title={_t('element.fieldQualification.title')}
      value={natinfQualification}
    />
    <DecreeBlock
      comment={commentArrete}
      type={typeArrete}
      date={applicationDatetime}
      reference={referenceArrete}
      decreeText={decreeText}
      endOfApplicationDatetime={endOfApplicationDatetime}
      mifDecree={mifDecree}
      gpvDecree={gpvDecree}
    />
  </DataBoxItem>
);

export default NatinfBlock;
