import * as React from 'react';

import Picture from 'commons/FpsPictures/Picture';
import { DataBoxItem } from 'commons/DataBox';
import FakeInputBlock from 'commons/FakeInputBlock';
import { MifDetail } from 'api/tepv/mif/types';

import FreeCommentsBlock from './FreeCommentsBlock';

const { _t } = window.loadTranslations(__filename);

const CommentsBlock = ({
  mif: { presetNote, comments, medias, infractionLocation },
  canAddComments,
  updateComments,
}: {
  mif: MifDetail;
  canAddComments: boolean;
  updateComments: (comment: string) => any;
}) => (
  <DataBoxItem
    style={{
      maxWidth: '100%',
    }}
  >
    {medias &&
      medias.map((media, idx) => (
        <Picture index={idx} media={{ ...media, dateCreated: '' }} />
      ))}
    {infractionLocation && (
      <FakeInputBlock
        title={_t('element.fieldPlace.title')}
        value={infractionLocation}
      />
    )}
    <FakeInputBlock title={_t('element.fieldInfo.title')} value={presetNote} />
    <FreeCommentsBlock
      comments={comments}
      canAddComments={canAddComments}
      updateComments={updateComments}
    />
  </DataBoxItem>
);

export default CommentsBlock;
