import * as React from 'react';

import { MifDetail } from 'api/tepv/mif/types';
import { DataBoxItem } from 'commons/DataBox';
import FakeInputBlock from 'commons/FakeInputBlock';

const { _tg } = window.loadTranslations();

const AgentBlock = ({
  mif: { agentName, agentOrganization, agentProfileName },
}: {
  mif: MifDetail;
}) => (
  <DataBoxItem style={{ maxWidth: '100%' }}>
    <FakeInputBlock value={agentName} title={_tg('field.agent.agent')} />
    <FakeInputBlock value={agentProfileName} title={_tg('field.agent.role')} />
    <FakeInputBlock
      value={agentOrganization}
      title={_tg('field.organisation.organisation')}
    />
  </DataBoxItem>
);

export default AgentBlock;
