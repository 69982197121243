import React from 'react';
import Card, { CardHeader, CardText } from 'material-ui/Card';
import FileIcon from 'material-ui/svg-icons/content/content-paste';
import EditIcon from 'material-ui/svg-icons/editor/mode-edit';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import Moment from 'moment';

import SimpleTable from 'commons/SimpleTable';
import {
  SubscriberDTO,
  SubscriberMediaDTO,
  SubscriberVehicle,
} from '@cvfm-front/tefps-types';
import { BKG_CYAN, ICON_BLACK, ICON_RED, TEXT_COLOR_LIGHT } from 'theme';

import './ProductDetailPage.css';
import './SubscriberDetailVehicles.css';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_CARD = {
  flex: 1,
  margin: 10,
  minWidth: 300,
};
type SubscriberDetailVehiclesProps = {
  subscriber: SubscriberDTO;
  isVehicleUsedMap: Map<string, boolean>;
  setVehicleToUpdate: (vehicleId: string) => void;
  setOpenUpdateVehicle: (open: boolean) => void;
  setOpenRenameVehicle: (open: boolean) => void;
  setOpenDeleteVehicle: (open: boolean) => void;
};

type DisplayVehicle = SubscriberVehicle & {
  vehicleType: string;
  expired: boolean;
};

const SubscriberDetailVehicles = ({
  subscriber,
  isVehicleUsedMap,
  setVehicleToUpdate,
  setOpenUpdateVehicle,
  setOpenRenameVehicle,
  setOpenDeleteVehicle,
}: SubscriberDetailVehiclesProps): JSX.Element => {
  const translateCols = () => [
    { label: _t('plateTable.vehicleName'), width: 80, grow: 1 },
    { label: '', width: 10, grow: 0 },
    { label: _tg('field.vehicle.plate'), width: 40, grow: 1 },
    { label: _t('plateTable.vehicleType'), width: 40, grow: 1 },
    { label: _t('plateTable.documents'), width: 40, grow: 0 },
    { label: '', width: 10, grow: 1 },
  ];

  function checkIfExpired(
    documents: SubscriberMediaDTO[] | undefined | null
  ): boolean {
    let expires = false;
    documents?.forEach(document => {
      if (document?.expires) {
        const expirationDate = Moment(document.expires);
        if (expirationDate.isBefore()) {
          expires = true;
        }
      }
    });
    return expires;
  }
  const vehicles: Array<DisplayVehicle> = [];
  if (subscriber.personalProfile)
    subscriber.personalProfile.vehicles.forEach(vehicle => {
      vehicles.push({
        ...vehicle,
        vehicleType: _t('plateTable.personalVehicleType'),
        expired: checkIfExpired(vehicle.registrationDocuments?.documents),
      });
    });
  if (subscriber.professionalProfile)
    subscriber.professionalProfile.vehicles.forEach(vehicle => {
      vehicles.push({
        ...vehicle,
        vehicleType: _t('plateTable.professionalVehicleType'),
        expired: checkIfExpired(vehicle.registrationDocuments?.documents),
      });
    });

  return (
    <div style={{ flex: 1, width: '85%' }}>
      <Card style={STYLE_CARD}>
        <CardHeader
          title={_t('cardHeader')}
          showExpandableButton
          actAsExpander
        />
        <CardText expandable>
          <div className="product-detail_tab ">
            <span className="product-detail_cell-100">
              {vehicles.length === 0 && (
                <p style={{ textAlign: 'center', marginTop: 30 }}>
                  {_t('element.noActiveRight')}
                </p>
              )}
              {vehicles.length > 0 && (
                <SimpleTable
                  maxHeight={10000}
                  cols={translateCols()}
                  rowHeight={50}
                  header
                  itemsRenderer={(item: DisplayVehicle) => {
                    const isVehicleUsed = isVehicleUsedMap.get(item.id);

                    return [
                      <span key={item.id}>{item.name}</span>,
                      <span>
                        <EditIcon
                          color={ICON_BLACK}
                          hoverColor={BKG_CYAN}
                          onClick={() => {
                            setVehicleToUpdate(item.id);
                            setOpenRenameVehicle(true);
                          }}
                          key={item.id}
                          ref={item.id}
                          className="action-icon"
                        />
                      </span>,
                      <span key={item.id}>{item.plate}</span>,
                      <span key={item.id}>{item.vehicleType}</span>,
                      <span>
                        {!!item?.registrationDocuments?.documents?.length &&
                          !item.expired && (
                            <>
                              {item.registrationDocuments.documents.map(
                                document => (
                                  <a
                                    href={document.contentUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    key={document.id}
                                  >
                                    <FileIcon
                                      style={{ width: 40, height: 50 }}
                                      color={BKG_CYAN}
                                      key={document.id}
                                    />
                                  </a>
                                )
                              )}
                            </>
                          )}
                        {!!item.registrationDocuments?.documents &&
                          item.expired && <>{_tg('field.expiredDocuments')}</>}
                        {!item.registrationDocuments?.documents?.length &&
                          item.registrationDocuments?.manuallyChecked && (
                            <>{_tg('field.manuallyChecked')}</>
                          )}
                        {!item.registrationDocuments?.documents?.length &&
                          !item.registrationDocuments?.manuallyChecked && (
                            <>{_tg('field.missingDocuments')}</>
                          )}
                      </span>,
                      <span>
                        <EditIcon
                          color={ICON_BLACK}
                          hoverColor={BKG_CYAN}
                          onClick={() => {
                            setVehicleToUpdate(item.id);
                            setOpenUpdateVehicle(true);
                          }}
                          key={item.id}
                          ref={item.id}
                          className="action-icon"
                        />
                        <DeleteIcon
                          style={{
                            cursor: isVehicleUsed ? 'auto' : 'pointer',
                          }}
                          color={isVehicleUsed ? TEXT_COLOR_LIGHT : ICON_RED}
                          hoverColor={isVehicleUsed ? 'auto' : BKG_CYAN}
                          onClick={() => {
                            if (!isVehicleUsed) {
                              setVehicleToUpdate(item.id);
                              setOpenDeleteVehicle(true);
                            }
                          }}
                          key={item.id}
                          ref={item.id}
                          className="action-icon"
                        />
                      </span>,
                    ];
                  }}
                  items={vehicles}
                />
              )}
            </span>
          </div>
        </CardText>
      </Card>
    </div>
  );
};

export default SubscriberDetailVehicles;
