import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import AutoComplete from 'material-ui/AutoComplete';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import ErrorBlock from 'commons/ErrorBlock';
import { NatinfGroup } from 'api/tepv/natinfs/types';

const { _t, _tg } = window.loadTranslations(__filename);

type EditionProps = {
  open: boolean;
  closeModal: () => void;
  groups: Array<NatinfGroup>;
  addToGroup: (id: string, name: string) => void | Promise<void>;
  error: Error | null | undefined;
};

type EditionState = {
  id: string | null | undefined;
  name: string;
};

const dataSourceConfig = {
  text: 'text',
  value: 'value',
};

export class EditionModal extends React.Component<EditionProps, EditionState> {
  state = {
    id: null,
    name: '',
  };

  handleNewRequest = (data: { text: string; value: string }) => {
    const { text, value } = data;
    this.setState({
      id: value,
      name: text,
    });
  };

  handleUpdateInput = (name: string) => {
    this.setState({
      id: '',
      name,
    });
  };

  addToGroup = () => {
    const { id, name } = this.state;
    this.props.addToGroup(id || uuidv4(), name);
    this.setState({ id: null, name: '' });
  };

  closeModal = () => {
    this.props.closeModal();
    this.setState({ id: null, name: '' });
  };

  render() {
    const { open, closeModal, groups, error } = this.props;
    const { name } = this.state;

    const groupsAutocomplete = groups.map(group => ({
      text: group.name,
      value: group.identifier,
    }));

    return (
      <Dialog
        title={_t('element.dialogEdition.title')}
        open={open}
        autoScrollBodyContent
        onRequestClose={closeModal}
        actions={[
          <FlatButton label={_tg('action.cancel')} onClick={this.closeModal} />,
          <FlatButton
            label={_tg('action.add')}
            secondary
            disabled={name === ''}
            onClick={this.addToGroup}
          />,
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <AutoComplete
            // eslint-disable-next-line @typescript-eslint/unbound-method
            filter={AutoComplete.caseInsensitiveFilter}
            openOnFocus
            onUpdateInput={this.handleUpdateInput}
            onNewRequest={this.handleNewRequest}
            dataSource={groupsAutocomplete}
            dataSourceConfig={dataSourceConfig}
            searchText={name}
          />
          {error && (
            <ErrorBlock message={_t('feedback.error.delete')} error={error} />
          )}
        </div>
      </Dialog>
    );
  }
}

type DeletionProps = {
  open: boolean;
  closeModal: () => void;
  groups: Array<NatinfGroup>;
  removeFromGroup: (id: string) => void | Promise<void>;
  error: Error | null | undefined;
};

type DeletionState = {
  groupId: string | null | undefined;
};

export class DeletionModal extends React.Component<
  DeletionProps,
  DeletionState
> {
  state: DeletionState = {
    groupId: null,
  };

  onSelectChange = (
    event: React.SyntheticEvent<{}>,
    index: number,
    value: string
  ) => this.setState({ groupId: value });

  closeModal = () => {
    this.props.closeModal();
    this.setState({ groupId: null });
  };

  removeFromGroup = () => {
    const { groupId } = this.state;
    if (!groupId) return;
    this.props.removeFromGroup(groupId);
    this.setState({ groupId: null });
  };

  render() {
    const { open, closeModal, groups, error } = this.props;
    const { groupId } = this.state;

    return (
      <Dialog
        title={_t('element.dialogDelete.title')}
        open={open}
        autoScrollBodyContent
        onRequestClose={closeModal}
        actions={[
          <FlatButton label={_tg('action.cancel')} onClick={this.closeModal} />,
          <FlatButton
            label={_tg('action.delete')}
            secondary
            disabled={groupId === null}
            onClick={this.removeFromGroup}
          />,
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <SelectField value={groupId} onChange={this.onSelectChange}>
            {groups.map(group => (
              <MenuItem value={group.identifier} primaryText={group.name} />
            ))}
          </SelectField>
          {error && (
            <ErrorBlock message={_t('feedback.error.delete')} error={error} />
          )}
        </div>
      </Dialog>
    );
  }
}
