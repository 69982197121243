import * as React from 'react';
import { connect } from 'react-redux';
import { History } from 'history';

import Exporter from 'commons/Exporter';
import DateComponent from 'commons/Date';
import SimpleTable from 'commons/SimpleTable';
import FlexCenter from 'commons/FlexCenter';
import Content from 'commons/Content';
import ErrorBlock from 'commons/ErrorBlock';
import { ListBody, ListBottom, ListWrapper } from 'commons/ListWrappers';
import { MifListItemDTO, MifSearchFacets } from 'api/tepv/mif/types';
import { SortParameters } from 'api/commonTypes';
import { getMifExport, searchMifs } from 'api/tepv/mif';
import { fetchCityPvConfig } from 'api/tepv/config';
import { getApiState } from 'api/duck';
import { fetchZoning } from 'api/pricing';
import { ZoningDTO } from '@cvfm-front/tefps-types';
import { FETCH_PV_ZONING_CONFIG } from 'commons/FetchZoningConfigs';
import { ELLIPSIS_STYLE } from 'theme';

import { getMifFiltersState, setSortParameters } from './duck';
import MifSidebar from './MifSidebar';
import { MifFilters } from './types';
import { EXPORT_COL, filterToSearchParams } from './utils';

const { _t, _tg } = window.loadTranslations(__filename);

type MifListState = {
  totalHits: number;
  mifs: Array<MifListItemDTO>;
  filtersData: MifSearchFacets;
  loading: boolean;
  presetNotes: Array<{ key: string; label: string }>;
  infractionLocations: Array<{ key: string; label: string }>;
  columns: Array<{ key: string; label: string; checked?: boolean }>;
  error: Error | null | undefined;
  zoning: ZoningDTO | null | undefined;
};

type MifListProps = {
  mifList: {
    filters: MifFilters;
    sort: SortParameters;
  };
  updateSortParameters: (sort: SortParameters) => void;
  canReadMifs: boolean;
  canExportMifs: boolean;
  history: History;
};

const MAX_RECORDS = 20;

const translateTableCols = () => [
  {
    label: _tg('tepv.natinf'),
    width: 120,
    onSort: true,
  },
  {
    label: _t('element.tableCols.signDate'),
    width: 150,
    onSort: true,
  },
  {
    label: _tg('field.agent.agent'),
    width: 150,
    onSort: true,
  },
  {
    label: _tg('field.agent.matricule'),
    width: 100,
    onSort: true,
  },
  {
    label: _tg('field.address.streetName'),
    width: 250,
    onSort: true,
  },
  {
    label: _tg('field.address.postalCode'),
    width: 100,
    onSort: true,
  },
  {
    label: _tg('field.address.locality'),
    width: 150,
    onSort: true,
  },
  {
    label: _t('element.tableCols.info'),
    width: 150,
    grow: 15,
    onSort: true,
  },
];

const getEmptyFiltersData = (): MifSearchFacets => ({
  vehicleRemovalStatus: [],
  saisineStatus: [],
  photosStatus: [],
  antaiStatus: [],
  zoneId: [],
});

class MifList extends React.Component<MifListProps, MifListState> {
  constructor(props: MifListProps) {
    super(props);
    this.state = {
      totalHits: 0,
      mifs: [],
      loading: true,
      filtersData: getEmptyFiltersData(),
      presetNotes: [],
      infractionLocations: [],
      columns: EXPORT_COL,
      error: null,
      zoning: null,
    };
  }

  componentDidMount(): void {
    const { canExportMifs } = this.props;

    void this.fetchMifs(this.props);
    void this.fetchZones();
    if (canExportMifs) {
      void this.fetchColumns();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps: MifListProps): void {
    void this.fetchMifs(newProps);
  }

  onUpdateSort = async (
    colNumber: number,
    increasingOrder: boolean
  ): Promise<void> => {
    const { updateSortParameters } = this.props;
    updateSortParameters({ sortField: colNumber, increasingOrder });
    return Promise.resolve();
  };

  fetchMifs = async (props: MifListProps): Promise<void> => {
    const initialPager = { page: 0, maxRecords: MAX_RECORDS };
    const {
      mifList: { filters, sort },
    } = props;
    try {
      const mifSearch = await searchMifs(
        filterToSearchParams(filters, sort, initialPager)
      );
      let newPreset: Array<{ key: string; label: string }> = [];
      let newLocation: Array<{ key: string; label: string }> = [];
      if (mifSearch) {
        newPreset = mifSearch.presetNotes.map(preset => ({
          key: preset.preset,
          label: `${preset.preset}: ${preset.mifCount}`,
        }));
        newLocation = mifSearch.infractionLocations.map(location => ({
          key: location.location,
          label: `${location.location}: ${location.mifCount}`,
        }));
      }
      this.setState({
        mifs: mifSearch.listItems,
        filtersData: mifSearch.facets,
        loading: false,
        presetNotes: newPreset,
        infractionLocations: newLocation,
        totalHits: mifSearch.totalHits,
      });
    } catch (error) {
      this.setState({ error: error as Error });
    }
  };

  fetchColumns = async (): Promise<void> => {
    try {
      const boPvConfigDTO = await fetchCityPvConfig();
      this.setState({
        columns: boPvConfigDTO.keepingRawPlates
          ? EXPORT_COL
          : EXPORT_COL.filter(col => col.key !== 'vehiclePlate'),
      });
    } catch (error) {
      this.setState({ error: error as Error });
    }
  };

  fetchZones = async (): Promise<void> => {
    try {
      const zoning = await fetchZoning(FETCH_PV_ZONING_CONFIG);
      this.setState({
        zoning,
      });
    } catch (e) {
      // NO-OP
    }
  };

  loadMoreRows = async ({
    startIndex,
  }: {
    startIndex: number;
  }): Promise<void> => {
    const { mifs, totalHits } = this.state;
    const {
      mifList: { filters, sort },
    } = this.props;
    const pager = { page: startIndex / MAX_RECORDS, maxRecords: MAX_RECORDS };
    try {
      const mifAppendSearchResult = await searchMifs(
        filterToSearchParams(filters, sort, pager)
      );

      if (mifs) {
        const newMifList = mifs.concat(mifAppendSearchResult.listItems);

        this.setState({
          mifs: newMifList,
          totalHits,
        });
      }
    } catch (error) {
      this.setState({ error: error as Error });
    }
  };

  navigateToDetail = (mif: MifListItemDTO): void => {
    const { history } = this.props;
    history.push(`/mif/detail/${mif.id}`);
  };

  renderRow = ({
    natinfCode,
    natinfCas,
    signatureDatetime,
    agentName,
    agentMatricule,
    address,
    cityIdentifier,
    city,
    presetNote,
  }: MifListItemDTO) => [
    <span>{`${natinfCode} (${natinfCas})`}</span>,
    <span>
      <DateComponent datetime={signatureDatetime} withTime />
    </span>,
    <span>{agentName}</span>,
    <span>{agentMatricule}</span>,
    <span>{address}</span>,
    <span>{cityIdentifier}</span>,
    <span>{city}</span>,
    <span title={presetNote} style={ELLIPSIS_STYLE}>
      {presetNote}
    </span>,
  ];

  render() {
    const {
      filtersData,
      mifs,
      loading,
      presetNotes,
      infractionLocations,
      totalHits,
      columns,
      error,
      zoning,
    } = this.state;
    const {
      mifList: { sort, filters },
      canReadMifs,
      canExportMifs,
    } = this.props;

    if (error) {
      return (
        <Content>
          <FlexCenter>
            <ErrorBlock message={_t('feedback.error')} error={error} />
          </FlexCenter>
        </Content>
      );
    }

    return (
      <div style={{ height: '100%', fontFamily: 'Roboto', display: 'flex' }}>
        <MifSidebar
          filtersData={filtersData}
          totalHits={totalHits}
          presetNotes={presetNotes}
          infractionLocations={infractionLocations}
          zoning={zoning}
        />
        <ListWrapper style={{ width: '100%', paddingTop: 20 }}>
          <ListBody loading={loading} style={{ height: 'calc(100% - 10px)' }}>
            <SimpleTable
              cols={translateTableCols()}
              rowHeight={50}
              onSort={this.onUpdateSort}
              itemsRenderer={this.renderRow}
              items={mifs}
              loadMoreRows={this.loadMoreRows}
              remoteRowCount={mifs ? totalHits : 0}
              colSorted={sort.sortField}
              sortOrder={sort.increasingOrder}
              onRowClick={canReadMifs ? this.navigateToDetail : undefined}
            />
          </ListBody>
          {canExportMifs && (
            <ListBottom>
              <Exporter
                disabled={totalHits === 0}
                columns={columns}
                type={_tg('tepv.mif')}
                filters={filters}
                fileExport={getMifExport}
              />
            </ListBottom>
          )}
        </ListWrapper>
      </div>
    );
  }
}

export default connect(
  state => {
    const mifList = getMifFiltersState(state);
    const { userInfo } = getApiState(state);
    return {
      mifList,
      canReadMifs: userInfo && userInfo.rights.includes('MIFS_READ'),
      canExportMifs: userInfo && userInfo.rights.includes('MIFS_EXPORT'),
    };
  },
  dispatch => ({
    updateSortParameters: (sort: SortParameters): unknown =>
      dispatch(setSortParameters(sort)),
  })
)(MifList);
