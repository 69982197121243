import * as React from 'react';

import Date from 'commons/Date';
import { DataBoxItem } from 'commons/DataBox';
import FakeInputBlock from 'commons/FakeInputBlock';
import { MifSaisine } from 'api/tepv/mif/types';

const { _t, _tg } = window.loadTranslations(__filename);

const SaisineBlock = ({ saisine }: { saisine: MifSaisine }) => (
  <DataBoxItem
    style={{
      maxWidth: '100%',
    }}
  >
    <FakeInputBlock
      title={_t('element.fieldMotif.title')}
      value={saisine.motif}
    />
    <FakeInputBlock title={_tg('field.comment')} value={saisine.commentaire} />
    <FakeInputBlock
      title={_tg('field.date.date')}
      value={<Date datetime={saisine.date} withTime />}
    />
  </DataBoxItem>
);

export default SaisineBlock;
