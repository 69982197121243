import { CircularProgress, Dialog, TextField } from 'material-ui';
import React, { useState } from 'react';

import BoButton from 'facade/BoButton';
import { getProofOfPayment } from 'api/parkingMeter';
import downloadFile from 'commons/DownloadFile';
import ErrorBlock from 'commons/ErrorBlock';

const { _tg } = window.loadTranslations(__filename);

interface ExportProofDialogProps {
  ticketId: string;
  onClose: () => void;
}

type DownloadStatus = 'IDLE' | 'DOWNLOADING' | 'SUCCESS' | 'ERROR';

function getTitleFromDownloadStatus(downloadStatus: DownloadStatus) {
  switch (downloadStatus) {
    case 'DOWNLOADING':
      return _tg('tefps.tickets.export.status.downloading');
    case 'SUCCESS':
      return _tg('tefps.tickets.export.status.success');
    case 'ERROR':
      return _tg('tefps.tickets.export.status.error');
    case 'IDLE':
    default:
      return _tg('tefps.tickets.export.status.idle');
  }
}

export default function ExportProofDialog({
  ticketId,
  onClose,
}: ExportProofDialogProps): JSX.Element {
  const [inputPlate, setInputPlate] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [downloadStatus, setDownloadState] = useState<DownloadStatus>('IDLE');
  const dialogTitle = getTitleFromDownloadStatus(downloadStatus);
  const actions = [
    <BoButton label={_tg('action.close')} onClick={() => onClose()} />,
  ];

  if (downloadStatus === 'IDLE') {
    actions.push(
      <BoButton
        style={{ marginLeft: 16 }}
        primary
        label={_tg('tefps.tickets.export.downloadProof')}
        disabled={inputPlate === ''}
        onClick={async () => {
          setDownloadState('DOWNLOADING');
          try {
            const blob = await getProofOfPayment(ticketId, inputPlate);
            const file = new File(
              [blob],
              `${_tg('tefps.tickets.export.proofDocumentName')}_${ticketId}.pdf`
            );
            downloadFile(file);
            setDownloadState('SUCCESS');
          } catch (e) {
            if (e.json && e.json.message) {
              setErrorMessage(e.json.message);
            } else {
              setErrorMessage(''); // Default one will be used
            }
            setDownloadState('ERROR');
          }
        }}
      />
    );
  }

  if (downloadStatus === 'ERROR') {
    actions.push(
      <BoButton
        style={{ marginLeft: 16 }}
        primary
        label={_tg('action.restart')}
        onClick={() => {
          setDownloadState('IDLE');
        }}
      />
    );
  }

  return (
    <Dialog
      title={dialogTitle}
      open
      actions={actions}
      onRequestClose={() => onClose()}
    >
      {downloadStatus === 'IDLE' && (
        <TextField
          floatingLabelText={_tg('tefps.tickets.export.proofPlate')}
          onChange={(_, newValue) => setInputPlate(newValue)}
        />
      )}
      {downloadStatus === 'DOWNLOADING' && <CircularProgress />}
      {downloadStatus === 'ERROR' && (
        <ErrorBlock
          message={
            errorMessage !== ''
              ? errorMessage
              : _tg('tefps.tickets.errors.duringDownload')
          }
        />
      )}
    </Dialog>
  );
}
