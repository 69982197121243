import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';

import BoButton from 'facade/BoButton';
import {
  OrderSortParameters,
  SimpleSubscriberDTO,
  SortOrder,
  SubscriberCreationOrUpdateDTO,
  SubscribersSearchResultDTO,
  ZoneDTO,
} from '@cvfm-front/tefps-types';
import { getApiState, InternalApiState } from 'api/duck';
import FlexCenter from 'commons/FlexCenter';
import ErrorBlock from 'commons/ErrorBlock';
import { ListBody, ListBottom, ListWrapper } from 'commons/ListWrappers';
import { fetchZoning } from 'api/pricing';
import { FETCH_LIGHT_ZONING_CONFIG } from 'commons/FetchZoningConfigs';
import { SubscribersSearchCriteria } from 'tefps/Beneficiaries/Subscribers/types';
import { upsertSubscriber } from 'api/cvfm-core-subscription/subscriber';
import Exporter, { Column } from 'commons/Exporter';
import ImportSubscribers from 'tefps/Beneficiaries/Subscribers/ImportSubscribers';
import AddSubscriber from 'tefps/Beneficiaries/Subscribers/AddSubscriber';
import { askSubscriberExport } from 'api/subscribers';
import { getConfigState } from 'config/duck';

import SubscriptionNavigation from './SubscriptionNavigation';
import SubscriberTableSidebar from './SubscriberTableSidebar';
import SubscriberTable from './SubscriberTable';
import './SubscriberTablePage.css';

import { NotificationService } from '@cvfm-front/commons-services';

import SubscriberFilterService from './SubscriberFilterService';

const { _t, _tg } = window.loadTranslations(__filename);

const translateExportCol = (langEnabled: boolean): Column[] => [
  { key: 'id', label: _t('exportCol.id'), checked: true },
  {
    key: 'lastName',
    label: _tg('field.human.lastname_short'),
    checked: true,
  },
  { key: 'firstName', label: _tg('field.human.firstname'), checked: true },
  { key: 'gender', label: _tg('field.human.gender'), checked: false },
  {
    key: 'email',
    label: _tg('field.human.email'),
    checked: true,
  },
  { key: 'companyName', label: _tg('field.company.name'), checked: false },
  { key: 'activity', label: _tg('field.company.activity'), checked: false },
  {
    key: 'zoneResolverStatus',
    label: _t('exportCol.zoneResolverStatus'),
    checked: false,
  },
  {
    key: 'geocodingStatus',
    label: _t('exportCol.geocodingStatus'),
    checked: false,
  },
  {
    key: 'phoneNumber',
    label: _tg('field.human.phoneNumber_short'),
    checked: false,
  },
  {
    key: 'address',
    label: _tg('field.address.address'),
    checked: false,
  },
  { key: 'comment', label: _tg('field.comment'), checked: false },
  {
    key: 'homePhoneNumber',
    label: _tg('field.human.homePhoneNumber'),
    checked: false,
  },
  {
    key: 'creationDateTime',
    label: _tg('field.subscription.subscriberCreationDate'),
    checked: false,
  },
  ...(langEnabled
    ? [
        {
          key: 'lang',
          label: _tg('commons.lang'),
          checked: false,
        },
      ]
    : []),
];

type SubscriberTablePageReduxProps = {
  canWrite: boolean;
  canImport: boolean;
  canExport: boolean;
  langEnabled: boolean;
};

type SubscriberTablePageProps = SubscriberTablePageReduxProps;

const SubscriberTablePage = ({
  canWrite,
  canImport,
  canExport,
  langEnabled,
}: SubscriberTablePageProps): JSX.Element => {
  const defaultQuery: SubscribersSearchCriteria = {
    zoneIds: new Set(),
    professionalZoneIds: new Set(),
    accountTypes: new Set(),
  };

  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(true);
  const [searching, setSearching] = useState(true);
  const [zones, setZones] = useState<Array<ZoneDTO>>();
  const { searchPageMaxRecords } = SubscriberFilterService;
  const [sortParameters, setSortParameters] = useState<OrderSortParameters>(
    SubscriberFilterService.getSortParameter()
  );
  const [searchResults, setSearchResults] = useState<
    SubscribersSearchResultDTO
  >();
  const [subscribers, setSubscribers] = useState<Array<SimpleSubscriberDTO>>(
    []
  );
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const history = useHistory();

  async function loadPage() {
    try {
      setLoading(true);
      SubscriberFilterService.setFilters(
        (prevFilters: SubscribersSearchCriteria) => {
          return { ...prevFilters, ...defaultQuery };
        }
      );
      const loadedZones = await fetchZoning(FETCH_LIGHT_ZONING_CONFIG);
      setZones(loadedZones.zones);
    } catch (err) {
      setError(err as Error);
    }
    setLoading(false);
    setSearching(false);
  }

  function moreSubscribers(searchPage: number): void {
    try {
      setSearching(true);
      SubscriberFilterService.moreSubscribers(searchPage);
    } catch (err) {
      setError(err as Error);
    } finally {
      setSearching(false);
    }
  }

  function onTableSort(index: number, ascending: boolean, field: string) {
    const sortOrder = ascending ? SortOrder.ASC : SortOrder.DESC;
    try {
      setSearching(true);
      SubscriberFilterService.updateSortParameters(
        (prevFilters: OrderSortParameters) => {
          return { ...prevFilters, index, sortOrder, field };
        }
      );
    } catch (err) {
      setError(err as Error);
    } finally {
      setSearching(false);
    }
  }

  async function onTableRange(fromIndex: number): Promise<void> {
    const nextPage = Math.floor(fromIndex / searchPageMaxRecords);
    moreSubscribers(nextPage);
    return Promise.resolve();
  }

  async function onCreateSubscriber(formValue: SubscriberCreationOrUpdateDTO) {
    if (
      formValue.professionalAddress &&
      JSON.stringify(formValue.professionalAddress) === JSON.stringify({})
    ) {
      formValue.professionalAddress = null;
    }

    try {
      const newSubscriberId = uuidv4();
      await upsertSubscriber(newSubscriberId, formValue);
      history.push(`/subscription/subscribers/${newSubscriberId}`);
    } catch (err) {
      if (err.name === 'SubscriberConflict') {
        setOpenCreate(false);
        NotificationService.pushNotification({
          id: 'email-conflict',
          type: 'error',
          message: _t('emailConflict'),
        });
      } else {
        setError(err as Error);
        setOpenCreate(false);
      }
    }
  }

  useEffect(() => {
    // Load page once
    void loadPage();
    return SubscriberFilterService.watchResults(setSearchResults);
  }, []);

  useEffect(() => {
    return SubscriberFilterService.watchSortParameter(setSortParameters);
  }, []);

  useEffect(() => {
    return SubscriberFilterService.watchSubscribers(setSubscribers);
  }, []);
  if (error) {
    return (
      <FlexCenter>
        <ErrorBlock message="Error: " error={error} />
      </FlexCenter>
    );
  }

  return (
    <div className="subscriber-page_container">
      <SubscriberTableSidebar
        defaultFilters={defaultQuery}
        searchResults={searchResults}
        zones={zones || []}
      />
      <div className="subscriber-page_content">
        <SubscriptionNavigation />
        <div className="subscriber-page_table">
          <ListWrapper
            style={{
              padding: '0px 32px',
              margin: 0,
              maxWidth: '100%',
              maxHeight: '95%',
            }}
          >
            <ListBody loading={loading || searching}>
              <SubscriberTable
                pagedSubscribers={subscribers}
                totalSubscribers={searchResults?.totalHits || 0}
                onSort={onTableSort}
                sortIndex={sortParameters.index}
                sortAscending={sortParameters.sortOrder === SortOrder.ASC}
                onRange={onTableRange}
              />
            </ListBody>
            <ListBottom style={{ margin: '1% 3% 0%', display: 'flex' }}>
              {canWrite && (
                <BoButton
                  style={{ marginRight: 10 }}
                  label={_tg('action.create')}
                  onClick={() => {
                    setOpenCreate(true);
                  }}
                  primary
                />
              )}
              {canExport && (
                <Exporter
                  disabled={!searchResults || searchResults.totalHits === 0}
                  type={_tg('action.export')}
                  columns={translateExportCol(langEnabled)}
                  filters={SubscriberFilterService.buildSearchParameters()}
                  fileExport={askSubscriberExport}
                />
              )}
              {canImport && <ImportSubscribers style={{ marginLeft: 10 }} />}
            </ListBottom>
          </ListWrapper>
        </div>
        {canWrite && openCreate && (
          <AddSubscriber
            createNewSubscriber={onCreateSubscriber}
            closeModal={() => setOpenCreate(false)}
          />
        )}
      </div>
    </div>
  );
};

function mapStateToProps(
  state: InternalApiState
): SubscriberTablePageReduxProps {
  const { userInfo } = getApiState(state);
  const { subscriptionConfigurationDTO } = getConfigState(state);
  return {
    canWrite: !!userInfo && userInfo.rights.includes('SUBSCRIBER_WRITE'),
    canImport: !!userInfo && userInfo.rights.includes('SUBSCRIPTION_IMPORT'),
    canExport:
      !!userInfo && userInfo.rights.includes('BACKOFFICE_SUBSCRIBERS_EXPORT'),
    langEnabled: subscriptionConfigurationDTO.langEnabled,
  };
}

export default connect(mapStateToProps)(SubscriberTablePage);
