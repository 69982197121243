import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import Edit from 'material-ui/svg-icons/editor/mode-edit';
import Cancel from 'material-ui/svg-icons/content/clear';
import IconButton from 'material-ui/IconButton';
import TextField from 'material-ui/TextField';

import BoButton from 'facade/BoButton';
import { TicketPlate } from 'api/tickets/types';
import { patch } from 'api/tickets';
import { getApiState } from 'api/duck';
import { BKG_CYAN, BKG_PINK } from 'theme';

import { buildDisplayingPlate, buildDisplayPlateCountry } from '../utils';

const { _tg } = window.loadTranslations(__filename);

const STYLE_ICON: CSSProperties = {
  width: 16,
  height: 16,
  padding: 0,
};

const STYLE_INPUT: CSSProperties = {
  height: 22,
  backgroundColor: 'white',
  border: '1px solid black',
};

const STYLE_EDIT_WRAPPER: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 10,
};

type PlateRowProps = {
  ticketId: string;
  licensePlate: TicketPlate;
  reload: (arg0: string) => any;
  onUpdate: (arg0: string) => any;
  canUpdate: boolean;
};

type PlateRowState = {
  editing: boolean;
  plate: string;
  plateCountry: string;
};

class PlateRow extends React.Component<PlateRowProps, PlateRowState> {
  constructor(props: PlateRowProps) {
    super(props);
    this.state = {
      editing: false,
      plate: props.licensePlate.plate || '',
      plateCountry: props.licensePlate.plateCountry || '',
    };
  }

  onChangePlate = (e: React.ChangeEvent<HTMLInputElement>, plate: string) =>
    this.setState({ plate });
  onChangeCountry = (
    e: React.ChangeEvent<HTMLInputElement>,
    plateCountry: string
  ) => this.setState({ plateCountry });

  onValidate = async () => {
    const { ticketId, licensePlate, reload, onUpdate } = this.props;
    const { plate, plateCountry } = this.state;
    const newPlate = {
      ...licensePlate,
      plate: plate || null,
      plateCountry: plateCountry || null,
    };

    try {
      await patch(ticketId, [
        {
          op: 'replace',
          value: newPlate,
          path: '/licensePlate',
        },
      ]);
      onUpdate(_tg('tefps.tickets.success.updatingTicket'));
    } catch (e) {
      onUpdate(_tg('tefps.tickets.errors.updatingTicket'));
    }

    reload(ticketId);
    this.handleEdition();
  };

  handleEdition = () => this.setState({ editing: !this.state.editing });

  computeIcon = (): React.ReactElement<any> => {
    const { editing } = this.state;

    const tooltip = editing ? _tg('action.cancel') : _tg('action.edit');
    const Icon = editing ? Cancel : Edit;
    const color = editing ? BKG_PINK : BKG_CYAN;

    return (
      <IconButton
        style={STYLE_ICON}
        iconStyle={STYLE_ICON}
        tooltipPosition="top-left"
        tooltip={tooltip}
        onClick={this.handleEdition}
      >
        <Icon color={color} />
      </IconButton>
    );
  };

  computeDisabled = () => {
    const { licensePlate } = this.props;
    const { plate, plateCountry } = this.state;

    // No change
    return (
      plate === licensePlate.plate && plateCountry === licensePlate.plateCountry
    );
  };

  render() {
    const { licensePlate, canUpdate } = this.props;
    const { editing, plate, plateCountry } = this.state;

    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {buildDisplayingPlate(licensePlate.plate) +
            buildDisplayPlateCountry(licensePlate.plateCountry)}
          {canUpdate && this.computeIcon()}
        </div>
        {canUpdate && editing && (
          <div style={STYLE_EDIT_WRAPPER}>
            <div>
              <TextField
                name="plate"
                underlineShow={false}
                style={{
                  ...STYLE_INPUT,
                  maxWidth: 100,
                }}
                inputStyle={{ paddingLeft: 5 }}
                value={buildDisplayingPlate(plate)}
                onChange={this.onChangePlate}
              />
              <TextField
                name="plateCountry"
                underlineShow={false}
                maxlength="2"
                style={{
                  ...STYLE_INPUT,
                  maxWidth: 50,
                  marginLeft: 10,
                }}
                inputStyle={{ textAlign: 'center' }}
                value={plateCountry}
                onChange={this.onChangeCountry}
              />
            </div>
            <BoButton
              primary
              label={_tg('action.validate')}
              style={{ height: 22 }}
              onClick={this.onValidate}
              disabled={this.computeDisabled()}
            />
          </div>
        )}
      </div>
    );
  }
}

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    canUpdate: userInfo && userInfo.rights.includes('TV_UPDATE'),
  };
})(PlateRow);
