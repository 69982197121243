const { _t, _tg } = window.loadTranslations(__filename);

export const getExportCols = (): {
  key: string;
  label: string;
  checked?: boolean;
}[] => [
  { key: 'natinfCode', label: _t('table.natinfCode') },
  { key: 'infractionCase', label: _t('table.infractionCase') },
  { key: 'infractionType', label: _t('table.gpvType'), checked: true },
  { key: 'qualification', label: _t('table.gpvMotif'), checked: true },
  { key: 'searchKeywords', label: _t('table.searchKeywords') },
  { key: 'mandatoryInterception', label: _t('table.mandatoryInterception') },
  { key: 'imputableToEmployer', label: _t('table.imputableToEmployer') },
  { key: 'mandatoryVehicleInfo', label: _t('table.mandatoryVehicleInfo') },
  {
    key: 'eligibleForRemovalRequest',
    label: _t('table.eligibleForRemovalRequest'),
  },
  {
    key: 'eligibleForVehiculeImmobilisation',
    label: _t('table.eligibleForVehiculeImmobilisation'),
  },
  {
    key: 'mandatoryLicenceExchange',
    label: _t('table.mandatoryLicenceExchange'),
  },
  {
    key: 'fineMaxAmount',
    label: _t('table.fineMaxAmount'),
  },
  { key: 'enforcementDate', label: _tg('field.date.applicationDate') },
  { key: 'abrogationDate', label: _tg('field.date.abrogationDate') },
  {
    key: 'activeInfraction',
    label: _t('table.activeInfraction'),
    checked: true,
  },
];
