import * as React from 'react';

import { DataBoxItem } from 'commons/DataBox';
import FakeInputBlock from 'commons/FakeInputBlock';
import { Measurement, MifMeasuresDetails } from 'api/tepv/mif/types';

const { _t } = window.loadTranslations(__filename);

const measureDisplay = (measure: Measurement | null | undefined) =>
  measure && measure.valeur ? `${measure.valeur} ${measure.unite}` : '';

const MeasurementsBlock = ({ measures }: { measures: MifMeasuresDetails }) => {
  let content;
  switch (measures.type) {
    case 'ALCOHOL':
      content = (
        <>
          <FakeInputBlock
            title={_t('element.fieldReason.title')}
            value={measures.data.motif}
          />
          <FakeInputBlock
            title={_t('element.fieldFirstMeasure.title')}
            value={measureDisplay(measures.data.premiereMesure)}
          />
          <FakeInputBlock
            title={_t('element.fieldSecondeMeasure.title')}
            value={measureDisplay(measures.data.secondeMesure)}
          />
        </>
      );
      break;
    case 'SPEED':
      content = (
        <>
          <FakeInputBlock
            title={_t('element.fieldAllowedSpeed.title')}
            value={measureDisplay(measures.data.vitesseLegaleAutorisee)}
          />
          <FakeInputBlock
            title={_t('element.fieldSpeedMeasure.title')}
            value={measureDisplay(measures.data.vitesseMesuree)}
          />
          <FakeInputBlock
            title={_t('element.fieldSpeedTaken.title')}
            value={measureDisplay(measures.data.vitesseRetenue)}
          />
        </>
      );
      break;
    case 'NOISE':
      content = (
        <>
          <FakeInputBlock
            title={_t('element.fieldBaseEngine.title')}
            value={measureDisplay(measures.data.regimeMoteurStabiliseReference)}
          />
          <FakeInputBlock
            title={_t('element.fieldEngine.title')}
            value={measureDisplay(measures.data.regimeMoteurStabilise)}
          />
          <FakeInputBlock
            title={_t('element.fieldBaseNoise.title')}
            value={measureDisplay(measures.data.emissionSonoreReference)}
          />
          <FakeInputBlock
            title={_t('element.fieldNoise.title')}
            value={measureDisplay(measures.data.emissionSonore)}
          />
        </>
      );
      break;
    case 'POLLUTION':
      content = (
        <>
          <FakeInputBlock
            title={_t('element.fieldBaseEngine.title')}
            value={measureDisplay(measures.data.regimeMoteurStabiliseReference)}
          />
          <FakeInputBlock
            title={_t('element.fieldEngine.title')}
            value={measureDisplay(measures.data.regimeMoteurStabilise)}
          />
          <FakeInputBlock
            title={_t('element.fieldBasePollution.title')}
            value={measureDisplay(measures.data.emissionPolluantReference)}
          />
          <FakeInputBlock
            title={_t('element.fieldPollution.title')}
            value={measureDisplay(measures.data.emissionPolluant)}
          />
        </>
      );
      break;
    default:
      content = <div />;
      break;
  }
  return (
    <DataBoxItem
      style={{
        maxWidth: '100%',
      }}
    >
      {content}
    </DataBoxItem>
  );
};

export default MeasurementsBlock;
