import React, { useState } from 'react';
import { Dialog, TextField } from 'material-ui';

import { SubscriberDTO, SubscriberVehicle } from '@cvfm-front/tefps-types';
import { Flex } from '@cvfm-front/commons-ui';
import { patchSubscriber } from 'api/cvfm-core-subscription/subscriber';
import BoButton from 'facade/BoButton';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  subscriber: SubscriberDTO;
  updateSubscriber: React.Dispatch<React.SetStateAction<SubscriberDTO>>;
  vehicleId: string | null;
  closeModal: () => void;
};

const vehiclePatchEndpoint = {
  pro: '/professional-vehicle',
  perso: '/personal-vehicle',
};

const findVehicle = (
  subscriber: SubscriberDTO,
  vehicleId: string | null
): { vehicle: SubscriberVehicle | undefined; vehicleType: string } => {
  if (!vehicleId) {
    return { vehicle: undefined, vehicleType: '' };
  }

  let vehicle: SubscriberVehicle | undefined;
  let vehicleFound = false;
  let vehicleType = '';
  if (subscriber.personalProfile) {
    const pos = subscriber.personalProfile.vehicles
      .map(v => v.id)
      .indexOf(vehicleId);
    if (pos !== -1) {
      vehicleType = vehiclePatchEndpoint.perso;
      vehicle = subscriber.personalProfile.vehicles[pos];
      vehicleFound = true;
    }
  }
  if (subscriber.professionalProfile && !vehicleFound) {
    const pos = subscriber.professionalProfile.vehicles
      .map(v => v.id)
      .indexOf(vehicleId);
    if (pos !== -1) {
      vehicleType = vehiclePatchEndpoint.pro;
      vehicle = subscriber.professionalProfile.vehicles[pos];
      vehicleFound = true;
    }
  }
  return { vehicle, vehicleType };
};

const SubscriberRenameVehicle = ({
  subscriber,
  updateSubscriber,
  vehicleId,
  closeModal,
}: Props): JSX.Element => {
  const [name, setName] = useState<string | undefined>(undefined);

  const { vehicle, vehicleType } = findVehicle(subscriber, vehicleId);

  if (vehicle === undefined) {
    closeModal();
    return <></>;
  }

  const isConfirmDisabled = name === undefined || name === vehicle.name;

  const updateVehicle = async () => {
    const patchObject = [
      {
        path: vehicleType,
        op: 'replace',
        value: {
          ...vehicle,
          name,
          documents: vehicle?.registrationDocuments?.documents ?? [],
        },
      },
    ];
    const updatedSubscriber = await patchSubscriber(
      subscriber.subscriberId,
      patchObject
    );
    updateSubscriber(updatedSubscriber);
    closeModal();
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !isConfirmDisabled) {
      void updateVehicle();
    } else if (e.key === 'Escape') {
      closeModal();
    }
  };

  const actions = [
    <BoButton
      key="cancel"
      label={_tg('action.cancel')}
      onClick={closeModal}
      className="action-buttons--margin"
    />,
    <BoButton
      primary
      key="confirm"
      label={_tg('action.add')}
      onClick={updateVehicle}
      className="action-buttons--margin"
      disabled={isConfirmDisabled}
    />,
  ];

  return (
    <Dialog
      title={_tg('tefps.subscription.renameVehicle.modalTitle')}
      open
      actions={actions}
    >
      <Flex flexDirection="column">
        <TextField
          id="name"
          onChange={(_, newName) => setName(newName)}
          value={name === undefined ? vehicle.name : name}
          floatingLabelText={_tg('tefps.subscription.renameVehicle.nameLabel')}
          onKeyDown={handleKeyDown}
          autoFocus
        />
      </Flex>
    </Dialog>
  );
};

export default SubscriberRenameVehicle;
