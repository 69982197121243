import { SelectOption } from 'commons/SidebarV2/Components/Select';
import { getInfractionLocations } from 'api/tepv/infractionLocation';

export async function getInfractionLocationsAsFilter(): Promise<
  SelectOption[]
> {
  const infractionLocations = await getInfractionLocations();
  return infractionLocations.map(infractionLocation => {
    return {
      key: infractionLocation.location,
      label: infractionLocation.location,
    };
  });
}
