import React, { CSSProperties, useContext, useState } from 'react';

import BoButton from 'facade/BoButton';
import { GpvOverviewDTO, GpvStatus } from 'api/tepv/gpv/types';
import { patchGpvStatus } from 'api/tepv/gpv';

import {
  BoolContext,
  BoolContextType,
  GpvListContext,
  GpvStateContextType,
} from '../Context';

import UpdateGpvStatusModal from './UpdateGpvStatusModal';
import ProgressModal from './ProgressModal';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_BUTTON: CSSProperties = { marginLeft: '5pt' };

type Props = {
  items: Array<GpvOverviewDTO>;
  reload: () => void;
  onSelectAll: () => void;
  totalHits: number;
};

function GpvMultipleAction({ items, onSelectAll, totalHits, reload }: Props) {
  const [openUpdateStatusModal, setOpenUpdateStatusModal] = useState<boolean>(
    false
  );

  const [openProgressModal, setOpenProgressModal] = useState<boolean>(false);

  const [status, setStatus] = useState<GpvStatus>('PENDING');

  const { selectedGpvs, setSelectedGpvs } = useContext(
    GpvListContext
  ) as GpvStateContextType;
  const { multipleMode, setMultipleMode } = useContext(
    BoolContext
  ) as BoolContextType;

  function switchMode() {
    if (multipleMode) {
      // if we cancel, we clear the checked RefundList
      setSelectedGpvs([]);
    }
    setMultipleMode(() => !multipleMode);
  }

  function unSelectAll() {
    setSelectedGpvs([]);
  }

  function onsubmit(gpvStatus: GpvStatus) {
    setOpenUpdateStatusModal(false);
    setStatus(gpvStatus);
    setOpenProgressModal(true);
  }

  async function updateStatus(gpvId: string) {
    return patchGpvStatus(gpvId, status);
  }

  function onClose() {
    setOpenProgressModal(false);
    reload();
  }

  const selectionLength = Object.keys(selectedGpvs).length;

  const button = multipleMode ? (
    [
      <BoButton
        key="annuler"
        label={_tg('action.cancel')}
        style={STYLE_BUTTON}
        onClick={switchMode}
      />,
      <BoButton
        key="selectAll"
        label={
          items.length === selectionLength || selectionLength === totalHits
            ? _t('button.removeAll')
            : _t('button.selectAll')
        }
        style={STYLE_BUTTON}
        onClick={
          items.length === selectionLength || selectionLength === totalHits
            ? unSelectAll
            : onSelectAll
        }
      />,
      <BoButton
        key="status"
        label={_t('button.statusChange')}
        primary
        onClick={() => setOpenUpdateStatusModal(true)}
        style={STYLE_BUTTON}
        disabled={selectionLength === 0}
      />,
    ]
  ) : (
    <BoButton
      label={_t('button.modifyStatus')}
      secondary
      onClick={switchMode}
    />
  );

  return (
    <div
      style={{
        marginLeft: 'auto',
      }}
    >
      {button}
      <UpdateGpvStatusModal
        isOpen={openUpdateStatusModal}
        onSubmit={onsubmit}
        onClose={() => setOpenUpdateStatusModal(false)}
      />
      {openProgressModal && (
        <ProgressModal
          title={_t('element.updateGpv')}
          gpvList={selectedGpvs}
          massAction={updateStatus}
          onClose={onClose}
        />
      )}
    </div>
  );
}

export default GpvMultipleAction;
