import React from 'react';
import Checkbox from 'material-ui/Checkbox';
import { connect } from 'react-redux';

import { SubscriberDTO, ZoneDTO } from '@cvfm-front/tefps-types';
import { formatGender } from '@cvfm-front/tefps-utils';
import { coreAddressRenderer } from 'commons/Address/SimpleAddressRenderer';
import './OrderDetailPage.css';
import {
  DataBox,
  DataBoxContent,
  DataBoxHeader,
  DataBoxItem,
  DataBoxItemWrapper,
} from 'commons/DataBox';
import Separator from 'commons/Separator';
import FakeInputBlock from 'commons/FakeInputBlock';
import CopyValueButton from 'commons/CopyValueButton';
import { InternalApiState } from 'api/duck';
import { getConfigState } from 'config/duck';
import { frontTypes } from 'tefps/Beneficiaries/utils';

import SubscriberV2Details from './SubscriberV2Details';

const { _tg, _t } = window.loadTranslations(__filename);

type CityConfigReduxProps = {
  adV3Enabled: boolean;
  langEnabled: boolean;
};

type SubscriberDetailSummaryProps = {
  subscriber: SubscriberDTO;
  zones: Array<ZoneDTO>;
  smsEnabled: boolean;
  hasPmr: boolean;
} & CityConfigReduxProps;

const SubscriberDetailSummary = ({
  subscriber,
  zones,
  smsEnabled,
  hasPmr,
  adV3Enabled,
  langEnabled,
}: SubscriberDetailSummaryProps): JSX.Element => {
  const residenceZoneName = subscriber.zoneId
    ? zones.find(z => z.id === subscriber.zoneId)?.name
    : undefined;

  const professionalZoneName = subscriber.professionalZoneId
    ? zones.find(z => z.id === subscriber.professionalZoneId)?.name
    : undefined;

  return (
    <DataBox panel style={{ width: '95%' }}>
      <DataBoxHeader>
        <div className="order-detail-page_header_left">{_t('title.label')}</div>
        <div className="order-detail-page_header_right">
          {_t('title.id')}:
          <div className="order-detail-page_header_right-identifiant">
            {subscriber.subscriberId}
            <CopyValueButton
              value={subscriber.subscriberId}
              iconStyle={{ height: 15, color: 'white' }}
              spanStyle={{ marginLeft: 5 }}
            />
          </div>
        </div>
      </DataBoxHeader>
      <DataBoxContent>
        {!adV3Enabled ? (
          <DataBoxItemWrapper style={{ marginBottom: 20, width: '90%' }}>
            <DataBoxItem>
              <FakeInputBlock
                title={_tg('field.human.gender')}
                value={_tg(formatGender(subscriber.gender))}
              />
              <FakeInputBlock
                title={_tg('field.human.lastname_short')}
                value={subscriber.lastName}
              />
              <FakeInputBlock
                title={_tg('field.human.firstname')}
                value={subscriber.firstName}
              />
              {subscriber.gender === 'ORGA' && (
                <FakeInputBlock
                  title={_tg('field.company.name')}
                  value={subscriber.companyName}
                />
              )}
              <FakeInputBlock
                title={_tg('field.human.email')}
                value={subscriber.email}
              />
              {subscriber.subscriberPendingEmail &&
                new Date(subscriber.subscriberPendingEmail.expiryDate) >
                  new Date() && (
                  <FakeInputBlock
                    title={_tg('field.human.pending_email')}
                    value={subscriber.subscriberPendingEmail.pendingEmail}
                  />
                )}
              <FakeInputBlock
                title={_tg('field.human.phoneNumber')}
                value={subscriber.phoneNumber}
              />
              {hasPmr && (
                <FakeInputBlock
                  title={_tg('field.defaultFrontType')}
                  value={
                    frontTypes.find(
                      type => type.id === subscriber.defaultFrontType
                    )?.name
                  }
                />
              )}
            </DataBoxItem>
            <Separator />
            <DataBoxItem>
              <FakeInputBlock
                title={_tg('field.address.residenceZone')}
                value={residenceZoneName}
              />
              <FakeInputBlock
                title={_tg('field.address.address')}
                value={coreAddressRenderer(subscriber.address)}
              />
              {subscriber.professionalAddress && (
                <>
                  <FakeInputBlock
                    title={_tg('field.address.professionalZone')}
                    value={professionalZoneName}
                  />
                  <FakeInputBlock
                    title={_tg('field.address.professionalAddress')}
                    value={coreAddressRenderer(subscriber.professionalAddress)}
                  />
                </>
              )}
              <FakeInputBlock
                title={_tg('field.company.activity')}
                value={subscriber.activity}
              />
            </DataBoxItem>
          </DataBoxItemWrapper>
        ) : (
          <SubscriberV2Details
            subscriber={subscriber}
            zones={zones}
            hasPmr={hasPmr}
            langEnabled={langEnabled}
          />
        )}
        <DataBoxItemWrapper>
          <DataBoxItem style={{ marginBottom: 20, maxWidth: '', flex: '' }}>
            {smsEnabled && (
              <div className="order-detail_row">
                <Checkbox
                  disabled
                  checked={subscriber.shouldReceiveSms}
                  label={_t('element.shouldReceiveSms.label')}
                  labelStyle={{ width: '' }}
                />
              </div>
            )}
          </DataBoxItem>
        </DataBoxItemWrapper>
      </DataBoxContent>
    </DataBox>
  );
};

function mapStateToProps(state: InternalApiState): CityConfigReduxProps {
  const { adV3Enabled, langEnabled } = getConfigState(
    state
  ).subscriptionConfigurationDTO;
  return {
    adV3Enabled,
    langEnabled,
  };
}

export default connect(mapStateToProps)(SubscriberDetailSummary);
