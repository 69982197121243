import React from 'react';
import { Dialog } from 'material-ui';

import { SubscriberDTO } from '@cvfm-front/tefps-types';
import BoButton from 'facade/BoButton';
import { patchSubscriber } from 'api/cvfm-core-subscription/subscriber';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';

type Props = {
  subscriber: SubscriberDTO;
  updateSubscriber: React.Dispatch<React.SetStateAction<SubscriberDTO>>;
  vehicleId: string | null;
  closeModal: () => void;
};

const { _tg } = window.loadTranslations(__filename);

const SubscriberDeleteVehicle = ({
  subscriber,
  updateSubscriber,
  vehicleId,
  closeModal,
}: Props): JSX.Element => {
  const setMessage = useSnackbar();

  const deleteVehicle = async () => {
    const vehicleType = subscriber.personalProfile?.vehicles.find(
      v => v.id === vehicleId
    );
    const patchObject = [
      {
        path: vehicleType ? '/personal-vehicle' : '/professional-vehicle',
        op: 'remove',
        value: vehicleId,
      },
    ];
    try {
      const updatedSubscriber = await patchSubscriber(
        subscriber.subscriberId,
        patchObject
      );
      updateSubscriber(updatedSubscriber);
      setMessage(_tg('tefps.subscription.deleteVehicle.successGenericMessage'));
      closeModal();
    } catch (err) {
      if (err.json?.error === 'VehicleNotDeletable') {
        setMessage(_tg('tefps.subscription.deleteVehicle.errorMessage'));
      } else {
        setMessage(_tg('tefps.subscription.deleteVehicle.errorGenericMessage'));
      }
      closeModal();
    }
  };

  const actions = [
    <BoButton
      key="cancel"
      label={_tg('action.cancel')}
      onClick={closeModal}
      secondary
      style={{ marginRight: '1vh' }}
    />,
    <BoButton
      key="add"
      label={_tg('action.delete')}
      onClick={deleteVehicle}
      primary
    />,
  ];

  return (
    <Dialog
      title={_tg('tefps.subscription.deleteVehicle.modalTitle')}
      open
      actions={actions}
    />
  );
};

export default SubscriberDeleteVehicle;
