import React from 'react';
import Card, { CardHeader, CardText } from 'material-ui/Card';

import SimpleTable from 'commons/SimpleTable';
import { OrderEsPrivateDTO } from '@cvfm-front/tefps-types';

import './ProductDetailPage.css';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_CARD = {
  flex: 1,
  margin: 10,
  minWidth: 300,
};

type SubscriberDetailPlatesProps = {
  activeOrders: Array<OrderEsPrivateDTO>;
};

const SubscriberDetailPlates = ({
  activeOrders,
}: SubscriberDetailPlatesProps): JSX.Element => {
  const translateCols = () => [
    { label: _tg('field.vehicle.plate'), width: 40, grow: 1 },
    { label: _t('plate_table.active_number'), width: 190, grow: 3 },
  ];

  const plates: Array<{ plate: string; number: number }> = [];
  activeOrders.forEach(order => {
    order.rawPlates.forEach(plate => {
      const item = plates.find(p => p.plate === plate);
      if (item) {
        item.number += 1;
      } else {
        plates.push({ plate, number: 1 });
      }
    });
  });

  return (
    <div style={{ flex: 1, width: '85%' }}>
      <Card style={STYLE_CARD}>
        <CardHeader
          title={_t('cardHeader')}
          showExpandableButton
          actAsExpander
        />
        <CardText expandable>
          <div className="product-detail_tab ">
            <span className="product-detail_cell-100">
              {plates.length === 0 && (
                <p style={{ textAlign: 'center', marginTop: 30 }}>
                  {_t('element.noActiveRight')}
                </p>
              )}
              {plates.length > 0 && (
                <SimpleTable
                  maxHeight={10000}
                  cols={translateCols()}
                  rowHeight={50}
                  header
                  itemsRenderer={(item: { plate: string; number: number }) => [
                    <span>{item.plate}</span>,
                    <span>{item.number}</span>,
                  ]}
                  items={plates}
                />
              )}
            </span>
          </div>
        </CardText>
      </Card>
    </div>
  );
};

export default SubscriberDetailPlates;
