import React from 'react';
import { connect } from 'react-redux';

import Sidebar from 'commons/SidebarV2';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import HitsCounter from 'commons/SidebarV2/Components/HitsCounter';
import Autocomplete from 'commons/SidebarV2/Components/Autocomplete';
import Select from 'commons/SidebarV2/Components/Select';
import Checkboxes from 'commons/SidebarV2/Components/Checkboxes';
import { CheckboxOption } from 'commons/SidebarV2/types';
import Times from 'commons/SidebarV2/Components/Times';
import Dates from 'commons/SidebarV2/Components/Dates';
import Input from 'commons/SidebarV2/Components/Input';
import { ZoningDTO } from '@cvfm-front/tefps-types';
import { MifSearchFacets } from 'api/tepv/mif/types';
import { fetchMatchingNatinfs, fetchNatinfGroups } from 'api/tepv/natinfs';
import { fetchMatchingMifs } from 'api/tepv/mif';
import { listProfiles } from 'api/profiles';
import { fetchOrganizations } from 'api/organizations';
import { fetchMatchingAgents } from 'api/accounts';

import { getMifFiltersState, initialFilters, setFilters } from './duck';
import { MifFilters } from './types';
import { vehicleCategories } from './utils';

const { _t, _tg } = window.loadTranslations(__filename);

type MifSidebarProps = {
  filters: MifFilters;
  filtersData: MifSearchFacets;
  updateFilters: (f: MifFilters) => void;
  totalHits?: number;
  presetNotes: Array<{ key: string; label: string }>;
  infractionLocations?: Array<{ key: string; label: string }>;
  zoning?: ZoningDTO | null | undefined;
};

const vehicleRemovalStatusOptions = () => [
  {
    value: 'REMOVED',
    label: _t('element.vehicleRemovalStatusOptions.REMOVED'),
  },
  {
    value: 'NOT_REMOVED',
    label: _t('element.vehicleRemovalStatusOptions.NOT_REMOVED'),
  },
];
const saisineStatusOptions = () => [
  {
    value: 'IS_SAISINE',
    label: _t('element.saisineStatusOptions.IS_SAISINE'),
  },
  {
    value: 'NOT_SAISINE',
    label: _t('element.saisineStatusOptions.NOT_SAISINE'),
  },
];
const photosStatusOptions = () => [
  {
    value: 'HAS_PHOTOS',
    label: _t('element.photosStatusOptions.HAS_PHOTOS'),
  },
  {
    value: 'NO_PHOTOS',
    label: _t('element.photosStatusOptions.NO_PHOTOS'),
  },
];
const antaiStatusOptions = () => [
  {
    value: 'SENT',
    label: _t('element.antaiStatusOptions.SENT'),
  },
  {
    value: 'NOT_SENT',
    label: _t('element.antaiStatusOptions.NOT_SENT'),
  },
  {
    value: 'INTEGRATED',
    label: _t('element.antaiStatusOptions.INTEGRATED'),
  },
  {
    value: 'REJECTED',
    label: _t('element.antaiStatusOptions.REJECTED'),
  },
  {
    value: 'FAILED_TO_SEND',
    label: _t('element.antaiStatusOptions.FAILED_TO_SEND'),
  },
  {
    value: 'IMPORTED',
    label: _t('element.antaiStatusOptions.IMPORTED'),
  },
];

const natinfClassOptions = [
  '1',
  '2',
  '2B',
  '3',
  '3B',
  '4',
  '4B',
  '5',
  '6',
  '6B',
].map(cas => ({
  key: `${cas}`,
  label: `${cas}`,
}));

const vehicleCategoriesOptions = vehicleCategories.map(cat => ({
  key: `${cat}`,
  label: `${cat}`,
}));

type MifSidebarState = {
  groupKeys: Array<{ key: string; label: string }>;
  filterNatinf: Array<{ id: string; name: string }>;
  addressMif: Array<{ id: string; name: string }>;
  profileKeys: Array<{ key: string; label: string }>;
  organizationKeys: Array<{ key: string; label: string }>;
  agentSearchOptions: Array<{ id: string; name: string }>;
  zoneOptions: Array<CheckboxOption>;
};

class MifSidebar extends React.Component<MifSidebarProps, MifSidebarState> {
  state: MifSidebarState = {
    groupKeys: [],
    filterNatinf: [],
    addressMif: [],
    profileKeys: [],
    organizationKeys: [],
    agentSearchOptions: [],
    zoneOptions: [],
  };

  componentDidMount() {
    this.fetchNatinf();
    this.fetchMifAddress();
    this.fetchNatinfGroups();
    this.fetchProfiles();
    this.fetchOrganizations();
    this.buildZoneOptions(this.props.zoning);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps: MifSidebarProps) {
    this.buildZoneOptions(newProps.zoning);
  }

  onChange = (id: string, value: any) => {
    const { updateFilters, filters } = this.props;
    updateFilters({ ...filters, [id]: value });
  };

  fetchNatinf = async (natinfFilter?: string) => {
    if (!natinfFilter || natinfFilter.trim().length < 1) {
      return;
    }

    const isQualif = isNaN(Number(natinfFilter.trim().charAt(0)));
    const natinfs = (await fetchMatchingNatinfs(natinfFilter)).matchingNatinfs;

    const codesNatinf = natinfs.map(({ code, qualification }) => ({
      id: isQualif ? qualification : code,
      name: `${code}: ${qualification}`,
    }));

    this.setState({ filterNatinf: codesNatinf });
  };

  fetchMifAddress = async (mifFilter?: string) => {
    if (!mifFilter || mifFilter.length < 3) {
      return;
    }

    const mifs = (await fetchMatchingMifs(mifFilter)).matchingMifs;

    const addressMif = mifs.map(address => ({
      id: address,
      name: address,
    }));

    this.setState({ addressMif });
  };

  fetchNatinfGroups = async () => {
    const groups = await fetchNatinfGroups();
    const groupKeys = groups.map(group => ({
      key: group.identifier,
      label: group.name,
    }));
    this.setState({ groupKeys });
  };

  fetchProfiles = async () => {
    try {
      const profiles = await listProfiles(false, 'pv');
      const profileKeys = profiles.map(profile => ({
        key: profile.profileId,
        label: profile.name,
      }));
      this.setState({
        profileKeys,
      });
    } catch (err) {
      this.setState({ profileKeys: [] });
    }
  };

  fetchOrganizations = async () => {
    try {
      const organizations = await fetchOrganizations(false, 'pv');
      const organizationKeys = organizations.map(organization => {
        return {
          key: organization.organizationId,
          label: organization.name,
        };
      });

      this.setState({ organizationKeys });
    } catch (err) {
      this.setState({ organizationKeys: [] });
    }
  };

  fetchAgentsAndGenerateOptions = async (agentFilter?: string) => {
    let agents: any = [];
    if (agentFilter && agentFilter.length >= 3) {
      agents = (await fetchMatchingAgents(agentFilter, 'pv')).matchingAgents;
    }

    const agentSearchOptions = agents.map(
      ({ id, fullName }: { id: string; fullName: string }) => ({
        id,
        name: fullName,
      })
    );
    this.setState({ agentSearchOptions });
  };

  resetFilters = () => this.props.updateFilters(initialFilters());

  buildZoneOptions(zoning: ZoningDTO | null | undefined) {
    this.setState({
      zoneOptions: zoning
        ? zoning.zones.map(zone => ({
            value: zone.id,
            label: zone.name,
          }))
        : [],
    });
  }

  render() {
    const {
      totalHits,
      filters,
      filtersData,
      presetNotes,
      infractionLocations,
    } = this.props;
    const {
      groupKeys,
      filterNatinf,
      addressMif,
      profileKeys,
      organizationKeys,
      agentSearchOptions,
      zoneOptions,
    } = this.state;
    return (
      <Sidebar>
        <BarTitle resetFilters={this.resetFilters} />
        {typeof totalHits === 'number' && <HitsCounter hits={totalHits} />}
        <Autocomplete
          id="natinfText"
          title={_t('element.autocompleteNatinf.title')}
          options={filterNatinf}
          onChange={this.onChange}
          onAutocomplete={this.fetchNatinf}
          search={filters.natinfText}
        />
        <Select
          id="cas"
          title={_t('element.selectCas.title')}
          onChange={this.onChange}
          selected={filters.cas}
          options={natinfClassOptions}
          multiple
        />
        <Select
          id="groupIds"
          title={_t('element.selectGroupIds.title')}
          onChange={this.onChange}
          selected={filters.groupIds}
          options={groupKeys}
          multiple
        />
        {presetNotes && presetNotes.length > 0 && (
          <Select
            id="presetNotes"
            title={_t('element.selectPresetNotes.title')}
            onChange={this.onChange}
            selected={filters.presetNotes}
            options={presetNotes}
            multiple
          />
        )}
        {infractionLocations && infractionLocations.length > 0 && (
          <Select
            id="infractionLocation"
            title={_t('element.selectInfractionLocations.title')}
            onChange={this.onChange}
            selected={filters.infractionLocations}
            options={infractionLocations}
            multiple
          />
        )}
        <Dates
          id="infractionDate"
          title={_t('element.infractionDate.title')}
          dates={filters.infractionDate}
          onChange={this.onChange}
        />
        <Times
          id="infractionTime"
          title={_tg('field.date.hours')}
          times={filters.infractionTime}
          onChange={this.onChange}
        />
        <Autocomplete
          id="address"
          title={_tg('field.address.address')}
          options={addressMif}
          onChange={this.onChange}
          onAutocomplete={this.fetchMifAddress}
          search={filters.address}
        />
        <Input
          id="postalCode"
          title={_t('element.postalCode.title')}
          placeholder={_t('element.postalCode.placeholder')}
          onChange={this.onChange}
          value={filters.postalCode}
        />
        <Input
          id="immatriculationFulltext"
          title={_tg('field.vehicle.licensePlate')}
          placeholder={_t('element.plateInput.placeholder')}
          onChange={this.onChange}
          value={filters.immatriculationFulltext}
        />
        <Checkboxes
          id="vehicleRemovalStatus"
          title={_t('element.checkboxVehicleRemovalStatus.title')}
          options={vehicleRemovalStatusOptions()}
          filters={filters.vehicleRemovalStatus}
          faceting={filtersData.vehicleRemovalStatus}
          onChange={this.onChange}
        />
        <Checkboxes
          id="saisineStatus"
          title={_t('element.checkboxSaisineStatus.title')}
          options={saisineStatusOptions()}
          filters={filters.saisineStatus}
          faceting={filtersData.saisineStatus}
          onChange={this.onChange}
        />
        <Checkboxes
          id="photosStatus"
          title={_tg('field.picture_plural')}
          options={photosStatusOptions()}
          filters={filters.photosStatus}
          faceting={filtersData.photosStatus}
          onChange={this.onChange}
        />
        <Checkboxes
          id="antaiStatus"
          title={_t('element.checkboxAntaiStatus.title')}
          options={antaiStatusOptions()}
          filters={filters.antaiStatus}
          faceting={filtersData.antaiStatus}
          onChange={this.onChange}
        />
        <Autocomplete
          id="agentSearchText"
          title={_tg('field.agent.agent')}
          options={agentSearchOptions}
          onChange={this.onChange}
          onAutocomplete={this.fetchAgentsAndGenerateOptions}
          search={filters.agentSearchText}
        />
        {organizationKeys.length > 0 && (
          <Select
            id="organizationIds"
            title={_tg('field.organisation.organisation')}
            onChange={this.onChange}
            selected={filters.organizationIds}
            options={organizationKeys}
            multiple
          />
        )}
        {profileKeys.length > 0 && (
          <Select
            id="profiles"
            title={_tg('field.agent.profile')}
            onChange={this.onChange}
            selected={filters.profiles}
            options={profileKeys}
            multiple
          />
        )}
        <Select
          id="vehicleCategory"
          title={_tg('field.vehicle.category')}
          onChange={this.onChange}
          selected={filters.vehicleCategory}
          options={vehicleCategoriesOptions}
          multiple
        />
        {zoneOptions.length > 0 && (
          <Checkboxes
            id="zoneIds"
            title="Zones"
            options={zoneOptions}
            filters={filters.zoneIds}
            onChange={this.onChange}
            faceting={filtersData ? filtersData.zoneId : null}
          />
        )}
        <Input
          id="mifId"
          title={_t('element.mifId.title')}
          placeholder={_t('element.mifId.placeholder')}
          onChange={this.onChange}
          value={filters.mifId}
        />
      </Sidebar>
    );
  }
}

export default connect(
  state => {
    const { filters } = getMifFiltersState(state);
    return {
      filters,
    };
  },
  dispatch => ({
    updateFilters: (filters: MifFilters) => dispatch(setFilters(filters)),
  })
)(MifSidebar);
