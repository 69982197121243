import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import TextField from 'material-ui/TextField';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import { v4 as uuidv4 } from 'uuid';

import BoButton from 'facade/BoButton';
import SimpleTable from 'commons/SimpleTable';
import ErrorBlock from 'commons/ErrorBlock';
import FlexCenter from 'commons/FlexCenter';
import { ListBody, ListBottom, ListWrapper } from 'commons/ListWrappers';
import Content from 'commons/Content';
import { PresetDTO } from 'api/tepv/presetNote/types';
import { getApiState } from 'api/duck';
import {
  deletePresetNote,
  getPresetNotes,
  upsertPresetNote,
} from 'api/tepv/presetNote';
import { BKG_CYAN, BKG_PINK } from 'theme';
import { fetchOrganizations } from 'api/organizations';
import { CityOrganizationDTO } from 'api/organizations/types';
import ConfirmAction from 'commons/ConfirmAction';

import CreationModal from './modal';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_ICON: CSSProperties = {
  cursor: 'pointer',
};

type State = {
  presets: Array<PresetDTO>;
  organizations: CityOrganizationDTO[] | null | undefined;
  loading: boolean;
  searchText: string;
  presetCreationText: string;
  creationModalOpen: boolean;
  creationModalError: string | null | undefined;
  creationRequestError: Error | null | undefined;
  deletionPreset: string | null | undefined;
  deletionRequestError: Error | null | undefined;
  listError: Error | null | undefined;
};

type Props = {
  canAddPreset: boolean;
  canDeletePreset: boolean;
};

const translateTableCols = (): Array<{
  label?: string;
  width: number;
  grow?: number;
  style?: CSSProperties;
  headerStyle?: CSSProperties;
  onSort?: boolean;
}> => [
  { label: _t('element.tableCols.name'), width: 100, grow: 1 },
  { label: _t('element.tableCols.count'), width: 150 },
  {
    label: _tg('action.actions'),
    width: 100,
    headerStyle: { textAlign: 'center' },
  },
];

class Presets extends React.Component<Props, State> {
  state = {
    presets: [],
    organizations: [],
    loading: true,
    searchText: '',
    presetCreationText: '',
    creationModalOpen: false,
    creationModalError: null,
    creationRequestError: null,
    deletionPreset: null,
    deletionRequestError: null,
    listError: null,
  };

  componentDidMount(): void {
    this.loadPresets();
    this.loadOrganizations();
  }

  onSearchChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: target.value });
  };

  openCreationModal = () =>
    this.setState({
      creationModalOpen: true,
      presetCreationText: '',
      creationModalError: null,
      creationRequestError: null,
    });

  closeCreationModal = () =>
    this.setState({
      creationModalOpen: false,
      presetCreationText: '',
      creationModalError: null,
      creationRequestError: null,
    });

  addPreset = async (text: string, organizationId: string | null) => {
    const noteId = uuidv4();
    const preset: PresetDTO = {
      id: noteId,
      preset: text,
      organizationId,
    };

    try {
      await upsertPresetNote(noteId, preset);
      this.loadPresets();
      this.closeCreationModal();
    } catch (err) {
      this.setState({ creationRequestError: err });
    }
  };

  removePreset = async (noteId: string) => {
    try {
      await deletePresetNote(noteId);
      this.loadPresets();
    } catch (err) {
      this.setState({ deletionRequestError: err });
    }
  };

  loadPresets = async () => {
    try {
      const presets = await getPresetNotes();
      this.setState({ presets, loading: false, listError: null });
    } catch (error) {
      this.setState({ listError: error });
    }
  };

  loadOrganizations = async () => {
    let organizations = [];
    try {
      organizations = await fetchOrganizations(false, 'pv');
      this.setState({ organizations });
    } catch (error) {
      this.setState({ listError: error });
    }
  };

  render() {
    const {
      presets,
      organizations,
      loading,
      searchText,
      creationModalOpen,
      creationRequestError,
      deletionRequestError,
      listError,
    } = this.state;
    const { canAddPreset, canDeletePreset } = this.props;
    const filteredPresets = presets.filter((preset: PresetDTO) =>
      preset.preset.toLowerCase().includes(searchText.toLowerCase())
    );

    if (listError) {
      return (
        <Content>
          <FlexCenter>
            <ErrorBlock
              message={_t('feedback.error.fetch')}
              error={listError}
            />
          </FlexCenter>
        </Content>
      );
    }

    if (creationRequestError) {
      return (
        <ErrorBlock
          message={_t('feedback.error.create')}
          error={creationRequestError}
        />
      );
    }

    if (deletionRequestError) {
      return (
        <ErrorBlock
          message={_t('feedback.error.create')}
          error={deletionRequestError}
        />
      );
    }

    return (
      <Content>
        <div
          style={{
            marginTop: 30,
            marginBottom: 20,
            marginLeft: '3%',
            marginRight: '3%',
          }}
        >
          <TextField
            id="search"
            autoFocus={false}
            onChange={this.onSearchChange}
            placeholder={_t('element.fieldSearch.placeholder')}
            value={searchText}
          />
        </div>
        <ListWrapper style={{ width: '100%', height: '70%' }}>
          <ListBody loading={loading}>
            <SimpleTable
              maxHeight={500}
              cols={translateTableCols()}
              rowHeight={50}
              itemsRenderer={({ id, preset, mifCount }: PresetDTO) => [
                <span>{preset}</span>,
                <span>{mifCount}</span>,
                <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                  {canDeletePreset && (
                    <ConfirmAction
                      enabled
                      message={_t('feedback.confirm', { name: preset })}
                      action={() => this.removePreset(id)}
                    >
                      <DeleteIcon
                        style={STYLE_ICON}
                        color={BKG_CYAN}
                        hoverColor={BKG_PINK}
                      />
                    </ConfirmAction>
                  )}
                </div>,
              ]}
              items={filteredPresets}
            />
          </ListBody>
          {canAddPreset && (
            <ListBottom>
              <BoButton
                label={_t('element.buttonCreateInfo.label')}
                primary
                onClick={this.openCreationModal}
              />
            </ListBottom>
          )}
        </ListWrapper>

        {creationModalOpen && (
          <CreationModal
            addNote={this.addPreset}
            closeCreationModal={this.closeCreationModal}
            presets={presets}
            organizations={organizations}
          />
        )}
      </Content>
    );
  }
}

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    canAddPreset: userInfo && userInfo.rights.includes('PRESET_NOTES_WRITE'),
    canDeletePreset:
      userInfo && userInfo.rights.includes('PRESET_NOTES_DELETE'),
  };
})(Presets);
