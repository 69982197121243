import * as React from 'react';

import Sidebar from 'commons/SidebarV2';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import HitsCounter from 'commons/SidebarV2/Components/HitsCounter';
import Checkboxes from 'commons/SidebarV2/Components/Checkboxes';
import Select from 'commons/SidebarV2/Components/Select';
import Input from 'commons/SidebarV2/Components/Input';
import Dates from 'commons/SidebarV2/Components/Dates';

import { InstrumentFilters, InstrumentFiltersPopulateData } from './types';
import { NATURE_OPTIONS } from './commons';

const { _t } = window.loadTranslations(__filename);

type Props = {
  filters: InstrumentFilters;
  filtersData: InstrumentFiltersPopulateData;
  updateFilters: (f: InstrumentFilters) => void;
  resetFilters: () => void;
  totalHits: number;
};

class DevicesSidebar extends React.Component<Props> {
  onChange = (id: string, value: any) => {
    const { updateFilters, filters } = this.props;
    updateFilters({ ...filters, [id]: value });
  };

  render() {
    const { resetFilters, totalHits, filters, filtersData } = this.props;

    const typeOptions = Array.from(filtersData.types).map(type => ({
      key: type,
      label: type,
    }));

    return (
      <Sidebar>
        <BarTitle resetFilters={resetFilters} />
        <HitsCounter hits={totalHits} />
        <Checkboxes
          id="natures"
          title={_t('element.checboxNature.title')}
          options={NATURE_OPTIONS}
          filters={filters.natures}
          faceting={null}
          onChange={this.onChange}
        />
        <Select
          id="types"
          title={_t('element.selectType.title')}
          onChange={this.onChange}
          selected={filters.types}
          options={typeOptions}
          multiple
        />
        <Input
          id="brand"
          title={_t('element.fieldBrand.title')}
          placeholder={_t('element.fieldBrand.placeholder')}
          onChange={this.onChange}
          value={filters.brand}
        />
        <Input
          id="serialNumber"
          title={_t('element.fieldId.title')}
          placeholder={_t('element.fieldId.placeholder')}
          onChange={this.onChange}
          value={filters.serialNumber}
        />
        <Dates
          id="verificationDate"
          title={_t('element.dateVerification.title')}
          dates={filters.verificationDate}
          onChange={this.onChange}
        />
        <Dates
          id="validityDate"
          title={_t('element.dateValidity.title')}
          dates={filters.validityDate}
          onChange={this.onChange}
        />
      </Sidebar>
    );
  }
}

export default DevicesSidebar;
