import React, { CSSProperties, useEffect, useState } from 'react';
import Dialog from 'material-ui/Dialog';
import SuccessIcon from 'material-ui/svg-icons/action/check-circle';
import ErrorIcon from 'material-ui/svg-icons/action/highlight-off';
import WaitIcon from 'material-ui/svg-icons/action/schedule';

import BoButton from 'facade/BoButton';
import SimpleTable from 'commons/SimpleTable';
import { BKG_GREEN, BKG_PINK, TXT_BLACK } from 'theme';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_ICON: CSSProperties = {
  width: 25,
  height: 25,
  margin: '2px 10px 2px 0',
};

const SUCCESS = 'Réussite';
const FAILURE = 'Échec';

type Props = {
  title: string;
  gpvList: string[];
  massAction: (arg0: string) => Promise<void>;
  onClose: () => void;
};

type ActionStatus = {
  gpvId: string;
  status: string | null | undefined;
  errorMessage: string | null | undefined;
};

const getTableCols = () => [
  { label: '', width: 30 },
  { label: _t('table.gpvId'), width: 250 },
  {
    label: _tg('field.status'),
    width: 100,
  },
  { label: _tg('field.comment'), width: 300 },
];

function renderIcon(status: string | null | undefined) {
  if (status === SUCCESS) {
    return <SuccessIcon style={STYLE_ICON} color={BKG_GREEN} />;
  }
  if (status === FAILURE) {
    return <ErrorIcon style={STYLE_ICON} color={BKG_PINK} />;
  }
  return <WaitIcon style={STYLE_ICON} color={TXT_BLACK} />;
}

function ProgressModal({ title, gpvList, massAction, onClose }: Props) {
  const [gpvsStatus, setGpvsStatus] = useState<{
    [key: string]: ActionStatus;
  }>({});

  useEffect(() => {
    async function process() {
      const gpvMap = {};
      for (let i = 0; i < gpvList.length; i += 1) {
        const gpvId = gpvList[i];
        try {
          // Les requêtes doivent être synchrones et exécutées les unes à la suite des autres
          // eslint-disable-next-line no-await-in-loop
          await massAction(gpvId);
          gpvMap[gpvId] = {
            gpvId,
            status: SUCCESS,
            errorMessage: null,
          };
        } catch (err) {
          gpvMap[gpvId] = {
            gpvId,
            status: FAILURE,
            errorMessage: err.json.message,
          };
        } finally {
          setGpvsStatus({ ...gpvsStatus, ...gpvMap });
        }
      }
    }
    process();
  }, []);

  function renderRow(gpvId: string) {
    const state = gpvsStatus[gpvId];

    if (state) {
      return [
        renderIcon(state.status),
        state.gpvId,
        state.status,
        state.errorMessage,
      ];
    }
    return [renderIcon(null), gpvId, _t('element.waiting'), ''];
  }

  const action = (
    <BoButton
      style={{ marginRight: 10 }}
      key={1}
      label={_tg('action.close')}
      onClick={onClose}
      disabled={Object.keys(gpvList).length !== Object.keys(gpvsStatus).length}
    />
  );

  return (
    <Dialog title={title} modal autoScrollBodyContent open actions={[action]}>
      <SimpleTable
        maxHeight={300}
        cols={getTableCols()}
        rowHeight={50}
        header
        itemsRenderer={renderRow}
        style={{ marginTop: 10 }}
        items={gpvList}
      />
    </Dialog>
  );
}

export default ProgressModal;
