// Layout
import { CSSProperties } from 'react';

export const LEFTBAR_WIDTH = 380;

// Misc Colors
export const BKG_ERROR_RED = '#ffdddd';
export const BKG_LIGHT = '#EEEEEE';
export const BKG_DARK = '#2B3A42';
export const BKG_FLASHY = '#e85b2c';
export const BKG_BLUE = '#364E7A';
export const BKG_BLUE_SELECTED = '#4A5f87';
export const BKG_CYAN = '#41B7C3';
export const BKG_CYAN_SELECTED = '#67C5CF';
export const BKG_GREY = '#EDEDED';
export const BKG_GREY_LIGHT = '#F7F7F7';
export const BKG_GREYBLUE = '#677A9B';
export const BKG_PINK = '#DE526C';
export const BKG_LIGHT_BLUE = '#0F7FAC';
export const BKG_DARK_BLUE = '#084C6B';
export const BKG_DARKER_BLUE = '#023247';
export const BKG_GREEN = '#69CE7D';
export const BKG_ORANGE = '#FFA726';
export const BKG_BLUE_GREY = '#97A5CC';
export const BKG_LIGHT_VIOLET = '#A096CB';

export const TXT_RED = '#f2303b';
export const TXT_ERROR_RED = TXT_RED;
export const TEXT_COLOR_LIGHTER = '#B0B0B0';
export const TEXT_COLOR_LIGHT = '#9F9F9F';
export const TXT_BLACK = '#767676';
export const TXT_GREY = '#E1E4EB';
export const TXT_GREYBLUE = '#d0d3e2';
export const TXT_CHART_TITLE = '#666';

// Couleurs de texte qui sont valide pour le RGAA sur un fond blanc
export const TXT_GREEN_RGAA = '#008000';
export const TXT_RED_RGGAA = '#C00000';

export const ICON_BLACK = '#000000';
export const ICON_RED = '#d32f2f';
export const ICON_YELLOW = '#ffa000';
export const ICON_GREEN = '#388e3c';
export const ICON_BLUE = '#2196f3';

export const BRD_BLUE = '#677A9B';
export const BRD_GREY = '#EDEDED';

export const ANTAI_BLUE = '#00337f';

export const ROW_EVEN = 'rgba(150, 150, 150, 0.15)';
export const ROW_OVER = 'rgba(150, 150, 150, 0.5)';
export const ROW_SELECTED = 'rgba(150, 150, 150, 0.75)';

export const DARK_LIGHT = 'rgba(255, 255, 255, 0.15)';

// Misc themes
export const STYLE_LOGIN_BAR: CSSProperties = {
  width: '100%',
  height: 55,
  backgroundColor: BKG_BLUE,
  display: 'flex',
  fontFamily: 'Roboto, sans-serif',
};

export const STYLE_MENU_BAR: CSSProperties = {
  width: '100%',
  height: 60,
  backgroundColor: BKG_CYAN,
  position: 'relative',
  display: 'flex',
  fontFamily: 'Roboto, sans-serif',
};

export const STYLE_TITLE_BORDER: CSSProperties = {
  display: 'block',
  height: 1,
  border: 0,
  borderTop: `1px solid ${BRD_BLUE}`,
  margin: '7px 0 7px 10px',
  padding: 0,
  flex: 1,
};

export const ICON_STYLE: CSSProperties = {
  fill: TXT_GREYBLUE,
  height: 21,
  width: 21,
  marginRight: 6,
  marginTop: 1,
};

export const LABEL_STYLE: CSSProperties = {
  color: TXT_GREYBLUE,
  fontSize: 14,
  fontWeight: 'normal',
  lineHeight: '25px',
  width: '100%',
};

export const WIDTH_STYLE: CSSProperties = {
  width: '90%',
};

export const FLEX_WIDTH_STYLE: CSSProperties = {
  width: 'auto',
  flex: '1 1 80%',
};

export const STYLE_LOADING_WRAPPER: CSSProperties = {
  position: 'absolute',
  zIndex: 100,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255,255,255,0.5)',
  width: '100%',
  height: '100%',
};

export const STYLE_ERROR_WRAPPER: CSSProperties = {
  marginTop: 20,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
};

export const STYLE_LINK: CSSProperties = {
  fontSize: '1em',
  textDecoration: 'underline',
  fontStyle: 'oblique',
  cursor: 'pointer',
};

export const STYLE_INPUTS_CONTAINER: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  padding: 5,
  marginTop: 10,
  fontFamily: 'Roboto',
};

export const STYLE_ITEM: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  padding: 5,
  flex: 1,
};

export const STYLE_FAKE_INPUT: CSSProperties = {
  marginLeft: 5,
  padding: '5px 10px',
  backgroundColor: BKG_GREY_LIGHT,
  display: 'flex',
  flexGrow: 1,
  whiteSpace: 'normal',
};

export const STYLE_ITEM_NAME: CSSProperties = {
  marginLeft: 5,
  padding: '5px 10px',
  display: 'flex',
  whiteSpace: 'nowrap',
};

export const STYLE_FIELD: CSSProperties = {
  minWidth: 200,
  flexGrow: 1,
  marginLeft: 20,
};

export const STYLE_FIELD_LARGE: CSSProperties = {
  ...STYLE_FIELD,
  width: '95%',
};

export const STYLE_SEPARATOR: CSSProperties = {
  width: '100%',
  marginTop: '20px',
};

export const STYLE_TITLE: CSSProperties = {
  marginTop: 20,
};

export const STYLE_UPPERCASE: CSSProperties = {
  textTransform: 'uppercase',
};

export const STYLE_CONTENT: CSSProperties = {
  display: 'flex',
  height: '100%',
};

export const STYLE_INPUTS: CSSProperties = {
  borderColor: BKG_CYAN,
  color: BKG_CYAN,
};

export const STYLE_MODAL_TITLE: CSSProperties = {
  backgroundColor: BKG_CYAN,
  color: '#ffffff',
  fontWeight: 'bold',
};

export const STYLE_CONTENT_WRAPPER: CSSProperties = {
  marginTop: '-6px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
};

export const STYLE_INPUTS_DARK: CSSProperties = {
  borderColor: BKG_DARK_BLUE,
  color: BKG_DARK_BLUE,
};

export const LINE_STYLE: CSSProperties = {
  display: 'flex',
  padding: '2.5px',
};

export const STYLE_BLOCK_TITLE: CSSProperties = {
  marginTop: 20,
  fontSize: 18,
  fontWeight: 'bold',
  color: TXT_BLACK,
  flex: 2,
};

export const STYLE_SELECT_TEXT: CSSProperties = {
  color: 'black',
  fontSize: 16,
};

export const STYLE_BLOCK: CSSProperties = {
  marginTop: 20,
};

export const STYLE_RESET: CSSProperties = {
  color: 'grey',
  marginLeft: 20,
  width: 24,
  cursor: 'pointer',
  marginTop: 15,
};

export const BUTTON_MODAL_STYLE: CSSProperties = {
  flex: 1,
  marginRight: '5%',
  marginLeft: '5%',
};

export const ITEM_STYLE: CSSProperties = {
  flex: 2,
  marginRight: '3%',
};

export const STYLE_PLAN_INPUTS_CONTAINER: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
};

export const BUTTON_STYLE: CSSProperties = { marginRight: 10, marginTop: 10 };

export const BUTTON_CONTAINER: CSSProperties = {
  justifyContent: 'center',
  display: 'flex',
  marginTop: 10,
};

export const ELLIPSIS_STYLE = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'block',
  whiteSpace: 'nowrap',
} as React.CSSProperties;

interface Color {
  opaque: string;
  transparant: string;
}
export const CHART_THEME: Array<Color> = [
  { opaque: 'rgba(45, 126, 134, 1)', transparant: 'rgba(45, 126, 134, 0.9)' },
  {
    opaque: 'rgba(121, 201, 210, 1)',
    transparant: 'rgba(121, 201, 210, 0.9)',
  },
  {
    opaque: 'rgba(205, 205, 205, 1)',
    transparant: 'rgba(205, 205, 205, 0.9)',
  },
  { opaque: 'rgba(45, 82, 134, 1)', transparant: 'rgba(45, 82, 134, 0.9)' },
  { opaque: 'rgba(64, 98, 191, 1)', transparant: 'rgba(64, 98, 191, 0.9)' },
  {
    opaque: 'rgba(179, 192, 230, 1)',
    transparant: 'rgba(179, 192, 230, 0.9)',
  },
  { opaque: 'rgba(0, 0, 0, 1)', transparant: 'rgba(0, 0, 0, 0.9)' },
  {
    opaque: 'rgba(96, 96, 96, 1)',
    transparant: 'rgba(96, 96, 96, 0.9)',
  },
  { opaque: 'rgba(99, 45, 134, 1)', transparant: 'rgba(99, 45, 134, 0.9)' },
  { opaque: 'rgba(141, 64, 191, 1)', transparant: 'rgba(141, 64, 191, 0.9)' },
  {
    opaque: 'rgba(175, 121, 210, 1)',
    transparant: 'rgba(175, 121, 210, 0.9)',
  },
  { opaque: 'rgba(190, 191, 64, 1)', transparant: 'rgba(190, 191, 64, 0.9)' },
  {
    opaque: 'rgba(210, 210, 121, 1)',
    transparant: 'rgba(210, 210, 121, 0.9)',
  },
  {
    opaque: 'rgba(229, 230, 179, 1)',
    transparant: 'rgba(229, 230, 179, 0.9)',
  },
  { opaque: 'rgba(128, 52, 14, 1)', transparant: 'rgba(128, 52, 14, 0.9)' },
  {
    opaque: 'rgba(179, 133, 110, 1)',
    transparant: 'rgba(179, 133, 110, 0.9)',
  },
  {
    opaque: 'rgba(255, 96, 255, 1)',
    transparant: 'rgba(255, 96, 255, 0.9)',
  },
  {
    opaque: 'rgba(255, 0, 255, 1)',
    transparant: 'rgba(255, 0, 255, 0.9)',
  },
  {
    opaque: 'rgba(150, 0, 150, 1)',
    transparant: 'rgba(150, 0, 150, 0.9)',
  },
  {
    opaque: 'rgba(96, 96, 255, 1)',
    transparant: 'rgba(96, 96, 255, 0.9)',
  },
  {
    opaque: 'rgba(0, 0, 255, 1)',
    transparant: 'rgba(0, 0, 255, 0.9)',
  },
  {
    opaque: 'rgba(0, 0, 150, 1)',
    transparant: 'rgba(0, 0, 150, 0.9)',
  },
  {
    opaque: 'rgba(96, 255, 255, 1)',
    transparant: 'rgba(96, 255, 255, 0.9)',
  },
  {
    opaque: 'rgba(0, 255, 255, 1)',
    transparant: 'rgba(0, 255, 255, 0.9)',
  },
  {
    opaque: 'rgba(0, 150, 150, 1)',
    transparant: 'rgba(0, 150, 150, 0.9)',
  },
  {
    opaque: 'rgba(150, 150, 0, 1)',
    transparant: 'rgba(150, 150, 0, 0.9)',
  },
  {
    opaque: 'rgba(255, 255, 0, 1)',
    transparant: 'rgba(255, 255, 0, 0.9)',
  },
  {
    opaque: 'rgba(255, 255, 96, 1)',
    transparant: 'rgba(255, 255, 96, 0.9)',
  },
  {
    opaque: 'rgba(96, 255, 96, 1)',
    transparant: 'rgba(96, 255, 96, 0.9)',
  },
  {
    opaque: 'rgba(0, 255, 0, 1)',
    transparant: 'rgba(0, 255, 0, 0.9)',
  },
  {
    opaque: 'rgba(0, 150, 0, 1)',
    transparant: 'rgba(0, 150, 0, 0.9)',
  },
  {
    opaque: 'rgba(0, 80, 0, 1)',
    transparant: 'rgba(0, 80, 0, 0.9)',
  },
  {
    opaque: 'rgba(150, 0, 0, 1)',
    transparant: 'rgba(150, 0, 0, 0.9)',
  },
  {
    opaque: 'rgba(255, 0, 0, 1)',
    transparant: 'rgba(255, 0, 0, 0.9)',
  },
  {
    opaque: 'rgba(255, 96, 96, 1)',
    transparant: 'rgba(255, 96, 96, 0.9)',
  },
];
